import { TenantType } from 'src/types/enums';

import type { AttachedThread } from '../threads/threads.types';

export type Contact = {
  id: string;
  first_name: string;
  last_name: string;
  phone: string;
  type: TenantType;
  email: string;
  status: string;
  source: ContactSource;
  thread: AttachedThread;
  account?: {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    organization: {
      company_name: string;
      company_information: string;
      company_type: string;
    }
  }
  created_at: '2024-07-16T22:44:56.000Z';
};

export enum ContactSource {
  JOB_INTERACTION = 'job_interaction',
  MANUAL_LINK = 'manual_link',
}
