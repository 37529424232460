import { useMemo, useState, useCallback } from 'react';

import { Box, Stack } from '@mui/system';
import Grid from '@mui/system/Unstable_Grid';
import { Tab, Card, Tabs, Alert, CardHeader, CardContent } from '@mui/material';

import { ApplicationStageDivergence } from 'src/utils/applications-kanban';

import { useTranslate } from 'src/locales';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import PostCommentForm from 'src/layouts/common/threads/post-comment-form';
import PostCommentList from 'src/layouts/common/threads/post-comment-list';
import {
  Application,
  ApplicationStage,
  RejectionContext,
} from 'src/services/applications/applications.types';
import { useGetApplicationActivitiesQuery, useGetApplicationCandidateProfileQuery } from 'src/services/applications/applications.service';

import Iconify from 'src/components/iconify';

import ActivityListCard from 'src/sections/activity/card/activity-feed';
import RejectionContextAlert from 'src/sections/jobs/applications/alerts/rejected-alert';
import CandidateProfileEditor from 'src/sections/profile/profile-sections/profile-editor';

import { TenantType } from 'src/types/enums';

import { ApplicationInsightsCard } from '../cards/insights-card';
import UpcomingInterviewCard from '../cards/upcoming-interview-card';

type Props = {
  application: Application;
  profileId: string;
  internalThreadId?: string;
  managerCandidateThreadId?: string;
  managerClientThreadId?: string;
  noteId?: string;
};

export default function ApplicationCandidateProfile({
  application,
  profileId,
  internalThreadId,
  managerCandidateThreadId,
  managerClientThreadId,
  noteId,
}: Props) {

  const { currentData: profile, isLoading } = useGetApplicationCandidateProfileQuery(application.id, {
    skip: !application?.id
  });

  const { t } = useTranslate();

  const tenantType = useOrgTenant();

  const [currentActivitiesPage, setCurrentActivitiesPage] = useState<number>(1);

  const canEdit = useMemo(
    () =>
      tenantType === TenantType.Recruiter && ApplicationStageDivergence.includes(application.stage),
    [application.stage, tenantType]
  );

  const [selectedThreadTab, setSelectedThreadTab] = useState<string | null>('internal');

  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setSelectedThreadTab(newValue);
  }, []);

  const { currentData: activities } = useGetApplicationActivitiesQuery(
    {
      applicationId: application?.id as string,
      page: currentActivitiesPage,
      perPage: 5,
    },
    {
      skip: !application?.id,
    }
  );

  // all applications
  // rejects -> candidate can't communicate back
  // if candidate withdraws, agency/client can't communicate back
  const isCandidateManagerThreadLocked = useMemo(() => {
    if (application?.stage === ApplicationStage.WITHDRAWN) {
      if (tenantType !== TenantType.Candidate) {
        return true;
      }
    } else if (application?.stage === ApplicationStage.REJECTED) {
      if (tenantType === TenantType.Candidate) {
        return true;
      }
    }

    return false;
  }, [application, tenantType]);

  return (
    <Grid container spacing={3}>
      <Grid sm={12} md={8}>
        {tenantType === TenantType.Recruiter &&
          ![
            ApplicationStage.REJECTED,
            ApplicationStage.WITHDRAWN,
            ApplicationStage.OFFER_ACCEPTED,
          ].includes(application.stage) && (
            <Alert severity="info" sx={{ mb: 2 }}>
              {canEdit
                ? t('applications.detail.candidate_profile.subheader_not_shortlisted')
                : t('applications.detail.candidate_profile.subheader_shortlisted')}
            </Alert>
          )}

        {application.stage === ApplicationStage.REJECTED && (
          <RejectionContextAlert context={application.rejection_context as RejectionContext} />
        )}

        <CandidateProfileEditor
          profile={profile}
          isLoading={isLoading}
          mode={canEdit ? 'edit' : 'view'}
        />
      </Grid>
      <Grid sm={12} md={4}>
        <Stack direction="column" spacing={2}>
          <UpcomingInterviewCard applicationId={application.id} />

          <ApplicationInsightsCard applicationId={application.id} />

          <Card>
            <CardHeader
              title={
                <Stack direction="row" gap={1} justifyContent="start" alignItems="center">
                  <Iconify icon="mdi:comments-outline" />
                  <Box>{t('applications.detail.candidate_profile.comments')}</Box>
                </Stack>
              }
            />
            <CardContent>
              <Alert severity="info" sx={{ paddingBottom: 2 }}>
                {t(
                  `applications.detail.candidate_profile.${selectedThreadTab}_thread.subheader_${tenantType}`
                )}
              </Alert>
              <Box display="flex" justifyContent="center" width="100%">
                <Tabs
                  sx={{ marginY: 2 }}
                  centered
                  value={selectedThreadTab}
                  onChange={handleChangeTab}
                >
                  <Tab
                    key="internal"
                    iconPosition="end"
                    value="internal"
                    label={t(`applications.detail.candidate_profile.internal_thread.title`)}
                  />
                  {managerCandidateThreadId && (
                    <Tab
                      key="manager_candidate"
                      iconPosition="end"
                      value="manager_candidate"
                      label={t(
                        `applications.detail.candidate_profile.manager_candidate_thread.${tenantType}_title`
                      )}
                    />
                  )}
                  {managerClientThreadId && (
                    <Tab
                      key="manager_client"
                      iconPosition="end"
                      value="manager_client"
                      label={t(
                        `applications.detail.candidate_profile.manager_client_thread.${tenantType}_title`
                      )}
                    />
                  )}
                </Tabs>
              </Box>

              {selectedThreadTab === 'internal' && (
                <>
                  <PostCommentForm thread_id={internalThreadId} />
                  <PostCommentList thread_id={internalThreadId} />
                </>
              )}

              {selectedThreadTab === 'manager_candidate' && (
                <>
                  <PostCommentForm
                    thread_id={managerCandidateThreadId}
                    disabled={isCandidateManagerThreadLocked}
                  />
                  <PostCommentList thread_id={managerCandidateThreadId} />
                </>
              )}

              {selectedThreadTab === 'manager_client' && (
                <>
                  <PostCommentForm thread_id={managerClientThreadId} />
                  <PostCommentList thread_id={managerClientThreadId} />
                </>
              )}
            </CardContent>
          </Card>
          <Card>
            <CardHeader title={t('activities.feed.title')} />
            <CardContent>
              <ActivityListCard
                activities={activities?.results ?? []}
                page={currentActivitiesPage}
                totalItems={activities?.count ?? 0}
                perPage={5}
                onPageChange={(e: React.ChangeEvent<unknown>, newPage: number) =>
                  setCurrentActivitiesPage(newPage)
                }
              />
            </CardContent>
          </Card>
        </Stack>
      </Grid>
    </Grid>
  );
}
