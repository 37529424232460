import { Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet, useLocation } from 'react-router';

import { Stack, useTheme, Container } from '@mui/material';

import { paths } from 'src/routes/paths';

import { useTranslate } from 'src/locales';

import { useSettingsContext } from 'src/components/settings';
import { LoadingScreen } from 'src/components/loading-screen';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';

export default function OrganizationIntegrationsPage() {

  const settings = useSettingsContext();

  const theme = useTheme();

  const location = useLocation();

  const path = location.pathname;

  const { t } = useTranslate();

  return (
    <>
      <Helmet>
        <title>Admin: Integrations</title>
      </Helmet>

      <Container
        maxWidth={settings.themeStretch ? false : 'lg'}
      >
        <Stack>
          <CustomBreadcrumbs
            heading={t('integrations.title')}
            links={[
              { name: t('common.dashboard'), href: paths.dashboard.root },
              { name: t('integrations.title'), href: paths.dashboard.admin.integrations.root },
            ]}
            sx={{
              mb: { xs: 3, md: 5 },
            }}
          />
          <Stack >
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </Stack>
        </Stack>
      </Container>
    </>
  );

}