import { m } from 'framer-motion';
import { enqueueSnackbar } from 'notistack';
import { useMemo, useState, useEffect, useCallback } from 'react';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import List from '@mui/material/List';
import Tabs from '@mui/material/Tabs';
import Badge from '@mui/material/Badge';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import { Pagination } from '@mui/material';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';

import { useTranslate } from 'src/locales';
import { rootApi } from 'src/services/rootApi';
import { useAppDispatch } from 'src/store/store';
import { useAuthContext } from 'src/auth/hooks/useAuthContext';
import socketService from 'src/services/pusher/pusher.service';
import { SignalTypes } from 'src/services/pusher/pusher.types';
import { useGetNotificationsQuery } from 'src/services/notifications/notifications.service';
import {
  IOnlineAccount,
  addOnlineAccount,
  setOnlineAccounts,
  removeOnlineAccount,
} from 'src/store/slices/accounts/accountsSlice';
import {
  NotificationType,
  NotificationStatus,
  SocketNotification,
  NotificationCategory,
} from 'src/services/notifications/notifications.types';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { varHover } from 'src/components/animate';

import NotificationItem from './notification-item';

// ----------------------------------------------------------------------

const TABS = [
  {
    value: 'unread',
    label: 'Unread',
  },
  {
    value: 'read',
    label: 'Read',
  },
];

const socket = socketService();

const DEFAULT_PAGE = {
  page: 1,
  per_page: 10,
};

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const { t } = useTranslate();

  const authContext = useAuthContext();

  const drawer = useBoolean();

  const dispatch = useAppDispatch();

  const smUp = useResponsive('up', 'sm');

  const router = useRouter();

  const [currentTab, setCurrentTab] = useState('unread');

  const [notificationPage, setNotificationPage] = useState(DEFAULT_PAGE);

  const [readNotificationsPage, setReadNotificationsPage] = useState(DEFAULT_PAGE);

  const { currentData: unreadNotifications, refetch } = useGetNotificationsQuery({
    ...notificationPage,
    status: NotificationStatus.DELIVERED,
  });

  const { currentData: readNotifications, refetch: refetchRead } = useGetNotificationsQuery({
    ...readNotificationsPage,
    status: NotificationStatus.READ,
  });

  const totalUnRead = useMemo(() => unreadNotifications?.count ?? 0, [unreadNotifications?.count]);

  // const [markAllAsRead] = useMarkAllAsReadMutation();

  // const handleMarkAllAsRead = () => {
  //   if (!unreadNotifications) return;

  //   const notificationsToMarkRead = unreadNotifications.results.map(
  //     (notificationObj) => notificationObj.id
  //   );

  //   markAllAsRead({
  //     notifications: notificationsToMarkRead,
  //   });
  // };

  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  }, []);

  useEffect(() => {
    if (!authContext.user) return;

    const ACCOUNT_CHANNEL = `account-${authContext.account.id}`;
    const pusher = socket.usePusherClient();
    const accountChannel = pusher.subscribe(ACCOUNT_CHANNEL);

    accountChannel.bind(SignalTypes.NOTIFICATION, (data: SocketNotification) => {
      // TODO: Determine better way to localise the text.
      enqueueSnackbar(data.body as string, { variant: 'success' });
      refetch();
      refetchRead();

      if (data.category === NotificationCategory.CONNECTION) {
        console.log('[Socket]: Refetching connection data.');
        dispatch(rootApi.util.invalidateTags(['ConnectionRequests', 'Connections']));
      }

      if (data.category === NotificationCategory.JOB_INVITE) {
        console.log('[Socket]: Refetching job invite data.');
        dispatch(rootApi.util.invalidateTags(['Job', 'JobInvites']));

        if (data.type === NotificationType.JOB_INVITE_ACCEPTED && data.variables?.job_id) {
          dispatch(
            rootApi.util.invalidateTags([{ type: 'JobApplications', id: data.variables?.job_id }])
          );
        }
      }

      if (data.category === NotificationCategory.JOB) {
        console.log('[Socket]: Refetching job data.');
        dispatch(rootApi.util.invalidateTags(['Job', 'JobLinkRequest']));
        if (data.variables?.application_id) {
          dispatch(rootApi.util.invalidateTags([{ type: 'Applications' }]));
        }
      }

      if (data.category === NotificationCategory.JOB_APPLICATION) {
        if (data.variables?.application_id) {
          console.log('[Socket]: Refetching job application data.');
          dispatch(rootApi.util.invalidateTags(['Applications', 'ApplicationRequests']));
          if (data.variables?.job_id) {
            dispatch(rootApi.util.invalidateTags([{ type: 'Job' }]));
          }
        }
      }

      if (data.type === NotificationType.INTEGRATION_EXPIRED) {
        console.log('[Socket]: Refetching integrations data.');
        dispatch(rootApi.util.invalidateTags(['OrgIntegrations']));
      }

      if (data.type === NotificationType.THREAD_COMMENT_CREATED) {
        console.log('[Socket]: Refetching comment data.');
        if (data.variables?.thread_id) {
          dispatch(
            rootApi.util.invalidateTags([
              {
                type: 'Comments',
                id: data.variables?.thread_id,
              },
            ])
          );
        }
      }

      if (
        [
          NotificationType.APPLICATION_OFFER_ACCEPTED,
          NotificationType.APPLICATION_OFFER_CHANGES_REQUESTED,
          NotificationType.APPLICATION_OFFER_EXTENDED,
          NotificationType.APPLICATION_OFFER_RESCINDED,
          NotificationType.APPLICATION_OFFER_CREATED,
          NotificationType.APPLICATION_OFFER_REJECTED,
        ].includes(data.type)
      ) {
        console.log('[Socket]: Refetching offer data.');

        dispatch(
          rootApi.util.invalidateTags([
            {
              type: 'Offers',
            },
          ])
        );

        if (data.variables?.job_id) {
          dispatch(
            rootApi.util.invalidateTags([
              {
                type: 'JobOffers',
                id: data.variables?.job_id,
              },
              {
                type: 'JobSummary',
                id: data.variables?.job_id,
              },
            ])
          );
        }
      }

      if (
        [
          NotificationType.CANDIDATE_UNAVAILABLE,
          NotificationType.CANDIDATE_AVAILABILITY_UPDATED,
          NotificationType.CANDIDATE_INVITE_ACCEPTED,
        ].includes(data.type)
      ) {
        console.log('[Socket]: Refetching candidate data.');
        dispatch(rootApi.util.invalidateTags(['OrgCandidates', 'OutgoingCandidateInvites']));
      }

      if ([NotificationType.CANDIDATE_INVITE_INITIATED].includes(data.type)) {
        console.log('[Socket]: Refetching candidate invite data.');
        dispatch(rootApi.util.invalidateTags(['IncomingCandidateInvites']));
      }

      if (data.category === NotificationCategory.INTERVIEW) {
        if (data.variables?.application_id) {
          console.log('[Socket]: Refetching interview data.');
          dispatch(rootApi.util.invalidateTags(['Interviews']));
          if (data.variables?.job_id) {
            dispatch(rootApi.util.invalidateTags([{ type: 'Job' }]));
          }
        }
      }
    });

    // eslint-disable-next-line consistent-return
    return () => {
      pusher.unsubscribe(ACCOUNT_CHANNEL);
    };
  }, [authContext, refetch, refetchRead, dispatch]);

  useEffect(() => {
    if (!authContext.user) return;

    const APP_CHANNEL = `app-all`;

    const pusher = socket.usePusherClient();
    const appWideChannel = pusher.subscribe(APP_CHANNEL);

    appWideChannel.bind(SignalTypes.SETTINGS_UPDATE, (data: any) => {
      dispatch(rootApi.util.invalidateTags(['SystemSettings']));
    });

    // Unsubscribe from the channel when the user logs out
    // eslint-disable-next-line consistent-return
    return () => {
      pusher.unsubscribe(APP_CHANNEL);
    };
  }, [authContext.user, dispatch]);

  useEffect(() => {
    if (!authContext.user) return;

    const PRESENCE_CHANNEL = `presence-client`;

    const pusher = socket.usePusherClient();
    const presChannel = pusher.subscribe(PRESENCE_CHANNEL);

    presChannel.bind('pusher:subscription_succeeded', (memberData: any) => {
      if (memberData.members) {
        const data: IOnlineAccount[] = Object.keys(memberData.members).map((member) => ({
          id: member,
          organization_id: memberData.members[member].organization,
          user_id: memberData.members[member].user,
        }));

        dispatch(setOnlineAccounts(data));
      }
    });

    presChannel.bind('pusher:member_added', (member: any) => {
      // For example
      // add_member(member.id, member.info);
      dispatch(
        addOnlineAccount({
          id: member.id,
          organization_id: member.info.organization,
          user_id: member.info.user,
        })
      );
    });

    presChannel.bind('pusher:member_removed', (member: any) => {
      // For example
      // add_member(member.id, member.info);

      dispatch(
        removeOnlineAccount({
          id: member.id,
          organization_id: member.info.organization,
          user_id: member.info.user,
        })
      );
    });

    // Unsubscribe from the channel when the user logs out
    // eslint-disable-next-line consistent-return
    return () => {
      pusher.unsubscribe(PRESENCE_CHANNEL);
    };
  }, [authContext.user, dispatch]);

  const renderHead = (
    <Stack direction="row" alignItems="center" sx={{ py: 2, pl: 2.5, pr: 1, minHeight: 68 }}>
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        {t('notifications.popover.title')}
      </Typography>

      {/* {!!totalUnRead && (
        <Tooltip title="Mark all as read">
          <IconButton color="primary" onClick={handleMarkAllAsRead}>
            <Iconify icon="eva:done-all-fill" />
          </IconButton>
        </Tooltip>
      )} */}

      {!smUp && (
        <IconButton onClick={drawer.onFalse}>
          <Iconify icon="mingcute:close-line" />
        </IconButton>
      )}
    </Stack>
  );

  const renderTabs = (
    <Tabs value={currentTab} onChange={handleChangeTab}>
      {TABS.map((tab) => (
        <Tab
          key={tab.value}
          iconPosition="end"
          value={tab.value}
          label={tab.label}
          // icon={
          //   <Label
          //     variant={((tab.value === 'all' || tab.value === currentTab) && 'filled') || 'soft'}
          //     color={
          //       (tab.value === 'unread' && 'info') ||
          //       (tab.value === 'archived' && 'success') ||
          //       'default'
          //     }
          //   >
          //     {0}
          //   </Label>
          // }
          sx={{
            '&:not(:last-of-type)': {
              mr: 3,
            },
          }}
        />
      ))}
    </Tabs>
  );

  const renderUnread = (
    <Stack spacing={1} direction="column" height="100%">
      <Scrollbar>
        <List disablePadding>
          {unreadNotifications &&
            unreadNotifications.results.map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
                closeDrawer={drawer.onFalse}
              />
            ))}
        </List>
      </Scrollbar>

      <Box sx={{ p: 1 }} display="flex" justifyContent="center">
        <Pagination
          page={notificationPage.page || 1}
          count={Math.ceil((unreadNotifications?.count || 1) / notificationPage.per_page)}
          onChange={(_e, value) => {
            setNotificationPage((prev) => ({
              ...prev,
              page: value,
            }));
          }}
        />
      </Box>
    </Stack>
  );

  const renderReadNotifications = (
    <Stack spacing={1} direction="column" height="100%">
      <Scrollbar>
        <List disablePadding>
          {readNotifications &&
            readNotifications.results.map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
                closeDrawer={drawer.onFalse}
              />
            ))}
        </List>
      </Scrollbar>

      <Box sx={{ p: 1 }} display="flex" justifyContent="center">
        <Pagination
          page={readNotificationsPage.page || 1}
          count={Math.ceil((readNotifications?.count || 1) / readNotificationsPage.per_page)}
          onChange={(_e, value) => {
            setReadNotificationsPage((prev) => ({
              ...prev,
              page: value,
            }));
          }}
        />
      </Box>
    </Stack>
  );

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        color={drawer.value ? 'primary' : 'default'}
        onClick={drawer.onTrue}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="solar:bell-bing-bold-duotone" width={24} />
        </Badge>
      </IconButton>

      <Drawer
        open={drawer.value}
        onClose={drawer.onFalse}
        anchor="right"
        slotProps={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: { width: 1, maxWidth: 420 },
        }}
      >
        {renderHead}

        <Divider />

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ pl: 2.5, pr: 1 }}
        >
          {renderTabs}
          <IconButton onClick={() => router.push(paths.dashboard.account.notifications)}>
            <Iconify icon="solar:settings-bold-duotone" />
          </IconButton>
        </Stack>

        <Divider />

        {currentTab === 'unread' ? renderUnread : renderReadNotifications}
      </Drawer>
    </>
  );
}
