import React, { useMemo, useState, useCallback } from 'react';

import { Box } from '@mui/system';
import Stack from '@mui/material/Stack';
import { Button, Popover, Tooltip, useTheme, TextField, IconButton, FormControl, useMediaQuery, InputAdornment } from '@mui/material';

import { useTranslate } from 'src/locales';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { isOrganisationTenant } from 'src/services/auth/auth.utils';

import Iconify from 'src/components/iconify';

type Props = {
  filterQuery: string;
  onFilters: (type: string, value: string) => void;
  clearFilters: () => void;
  onInviteCandidates?: () => void;
};

export default function InvitesTableToolbar({ filterQuery, onFilters, clearFilters, onInviteCandidates }: Props) {

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const tenantType = useOrgTenant();

  const [searchValue, setSearchValue] = useState('');
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const { t } = useTranslate();

  const onClearFilters = () => {
    setSearchValue('');
    clearFilters();
  };

  const handleFilterName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onFilters('search_query', event.target.value);
      setSearchValue(event.target.value);
    },
    [onFilters]
  );

  const disableReset = useMemo(() => !searchValue, [searchValue]);

  const handleOpenFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setAnchorEl(null);
  };

  const filterContent = (
    <Stack spacing={2} p={1} display="flex" direction={{ xs: 'column', sm: 'row' }} sx={{ justifyContent: 'space-between', alignItems: 'center', alignContent: 'center', width: '100%' }}>
      <Stack spacing={2} display="flex" direction={{ xs: 'column', sm: 'row' }} sx={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
        <TextField
          onChange={handleFilterName}
          placeholder="Search..."
          name="description"
          sx={{ width: isMobile ? '100%' : '300px' }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            ),
          }}
        />

        {isMobile ? (
          <Button
            variant="text"
            disabled={disableReset}
            onClick={onClearFilters}
          >
            {t('common.clear')}
          </Button>
        ) :
          <FormControl>
            <Tooltip title={t('common.clear')}>
              <IconButton
                size="large"
                disabled={disableReset}
                onClick={onClearFilters}
                aria-label={t('common.clear')}
              >
                <Iconify icon="material-symbols:cancel" />
              </IconButton>
            </Tooltip>
          </FormControl>
        }
      </Stack>
    </Stack>
  );

  return (
    <Stack
      spacing={2}
      display="flex"
      direction={{ xs: 'column', sm: 'row' }}
      alignItems={{ xs: 'stretch', sm: 'center' }}
      justifyContent={{ xs: 'center', sm: 'space-between' }}
      sx={{
        p: 2,
        width: '100%',
      }}
    >
      {isMobile ? (
        <>
          <Button
            variant="outlined"
            onClick={handleOpenFilter}
            fullWidth
            startIcon={<Iconify icon="mdi:filter" />}
          >
            {t('common.filter')}
          </Button>
          {isOrganisationTenant(tenantType) && (
            <Button
              sx={{ maxHeight: 36 }}
              size="medium"
              onClick={onInviteCandidates}
              endIcon={<Iconify icon="material-symbols:add" />}
              variant="contained"
            >
              {t('candidates.invites.invite_button_text')}
            </Button>
          )}
          <Popover
            sx={{ padding: 2 }}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleCloseFilter}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {filterContent}
          </Popover>
        </>
      ) : (
        <Stack sx={{ justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', 'width': '100%' }}>
          {filterContent}

          <Box display="flex" alignContent="center" justifyContent="center">
            {(isOrganisationTenant(tenantType) && onInviteCandidates) && (
              <Button
                size="medium"
                onClick={onInviteCandidates}
                endIcon={<Iconify icon="material-symbols:add" />}
                variant="contained"
                sx={{ textWrap: 'nowrap' }}
              >
                {t('candidates.invites.invite_button_text')}
              </Button>
            )}
          </Box>
        </Stack>
      )}
    </Stack>
  );
}