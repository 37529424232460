export enum UserRole {
  Admin = 'admin',
  Team = 'team',
  Op_Manager = 'operational_admin',
  Op_Team = 'operational_team',
}

export enum SystemRole {
  SUPER_ADMIN = 'super_admin',
  SUPPORT_TEAM = 'support_team',
}
