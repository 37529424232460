import { useRef, useState, ReactNode, useEffect } from 'react';

import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Typography, { TypographyProps } from '@mui/material/Typography';

import { useTranslate } from 'src/locales';

type TruncateMode = 'lines' | 'characters';

type Props = {
  text: string | ReactNode;
  mode: TruncateMode;
  limit: number;
  showMoreEnabled?: boolean;
} & TypographyProps;

export default function TruncatedTextField({ text, mode = 'lines', limit = 1, showMoreEnabled = true, ...typographyProps }: Props) {
  const [isTruncated, setIsTruncated] = useState(true);
  const [showButton, setShowButton] = useState(false);
  const textRef = useRef<HTMLElement>(null);

  const { t } = useTranslate();
  const theme = useTheme();

  useEffect(() => {
    const checkTruncation = () => {
      if (textRef.current) {
        if (mode === 'lines') {
          const lineHeight = parseInt(window.getComputedStyle(textRef.current).lineHeight, 10);
          const maxHeight = lineHeight * limit;
          setShowButton(textRef.current.scrollHeight > maxHeight);
        } else if (mode === 'characters') {
          const content = typeof text === 'string' ? text : textRef.current.textContent || '';
          setShowButton(content.length > limit);
        }
      }
    };

    checkTruncation();
    window.addEventListener('resize', checkTruncation);
    return () => window.removeEventListener('resize', checkTruncation);
  }, [limit, text, mode]);

  const truncateByCharacters = (content: string, charLimit: number) => {
    if (content.length <= charLimit) return content;
    return `${content.slice(0, charLimit)}...`;
  };

  const renderContent = () => {
    if (mode === 'characters' && typeof text === 'string') {
      return isTruncated ? truncateByCharacters(text, limit) : text;
    }
    return text;
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Typography
        ref={textRef}
        {...typographyProps}
        sx={{
          ...(mode === 'lines' && {
            display: '-webkit-box',
            WebkitLineClamp: isTruncated ? limit : 'unset',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }),
          ...typographyProps.sx,
        }}
      >
        {renderContent()}
      </Typography>
      {(showButton && showMoreEnabled) && (
        <Button
          disableRipple
          onClick={() => setIsTruncated(!isTruncated)}
          sx={{
            mt: 1,
            p: 0,
            ':hover': { backgroundColor: 'transparent' },
            color: theme.palette.text.disabled,
          }}
        >
          {isTruncated ? t('common.show_more') : t('common.show_less')}
        </Button>
      )}
    </Box>
  );
}