import { t } from 'i18next';
import { debounce } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { useMemo, useState, ChangeEvent, useCallback } from 'react';

import { useRouter } from 'src/routes/hooks';

import { useBoolean } from 'src/hooks/use-boolean';

import { PageOptions } from 'src/services/api.types';
import { useAuthContext } from 'src/auth/hooks/useAuthContext';
import { useRemoveContactMutation, useGetOrganizationContactsQuery } from 'src/services/organisation/organisation.service';

import ContactsTable from 'src/components/custom-tables/contacts-table/contacts-table';

import AddContactModal from 'src/sections/contacts/modal/add-contact-modal';

import { TenantType } from 'src/types/enums';

const DEFAULT_PAGE: PageOptions = {
  page: 1,
  per_page: 10,
};

export default function ContactsView() {
  const router = useRouter();
  const authContext = useAuthContext();

  const showAddContactModal = useBoolean();

  const [pageOptions, setPageOptions] = useState<PageOptions>(DEFAULT_PAGE);
  const [filterQuery, setFilterQuery] = useState<string | undefined>();
  const [filterType, setFilterType] = useState<TenantType | undefined>();

  const { currentData: contactsPaginated, isLoading: isRetrievingContacts } = useGetOrganizationContactsQuery(
    {
      org_id: authContext.organization?.id,
      search_query: filterQuery,
      contact_type: filterType,
      page: pageOptions,
    },
    { skip: !authContext.organization?.id }
  );

  const [removeContact] = useRemoveContactMutation();

  const onChangePage = useCallback((_event: any, pageNumber: number) => {
    setPageOptions((prev) => ({
      ...prev,
      page: pageNumber + 1,
    }));
  }, []);

  const onChangeRowsPerPage = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setPageOptions((prev) => ({
        ...prev,
        per_page: +event.target.value,
      }));
    },
    []
  );

  const handleFilters = useMemo(
    () => debounce((filter, value: string | TenantType) => {
      if (filter === 'type') {
        setFilterType(value as TenantType);
      } else {
        setFilterQuery(value as string);
      }
    }, 500),
    [setFilterQuery]
  );

  const clearFilters = useCallback(() => {
    setFilterQuery('');
    setFilterType(undefined);
  }, []);

  const handleRemoveContact = useCallback(async (contactId: string) => {
    try {
      await removeContact({
        contact_id: contactId,
      }).unwrap();

      enqueueSnackbar(t('contacts.delete_modal.success'), { variant: 'success' });
    } catch (e) {
      console.error(e);
      enqueueSnackbar(t('contacts.delete_modal.default_error'), { variant: 'error' });
    }
  }, [removeContact]);

  return (
    <>
      <ContactsTable
        onFilters={handleFilters}
        clearFilters={clearFilters}
        contacts={contactsPaginated}
        isLoading={isRetrievingContacts}
        pageOptions={pageOptions}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onAddContact={showAddContactModal.onTrue}
        onRemoveContact={handleRemoveContact}
      />

      <AddContactModal open={showAddContactModal.value} onClose={showAddContactModal.onFalse} />
    </>
  );
}
