import * as Yup from 'yup';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Card from '@mui/material/Card';
import { Skeleton } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import LoadingButton from '@mui/lab/LoadingButton';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';

import { useTranslate } from 'src/locales';
import { VisibilityRule } from 'src/services/jobs/jobs.types';
import { useGetTypeDefinitionsQuery } from 'src/services/system/system.service';
import { useSetCandidateProfileMutation } from 'src/services/candidates/candidates.service';
import { generateCandidateProfileErrorMessage } from 'src/services/candidates/candidates.utils';
import {
  CandidateProfile,
  PreferredLocation,
  CandidateProfileSectionType,
} from 'src/services/candidates/candidates.types';

import FormProvider from 'src/components/hook-form';
import { RHFMultiSelect } from 'src/components/hook-form/rhf-select';
import RHFVisibilityToggle from 'src/components/hook-form/rhf-visibility-toggle';
import RHFMapsAutocomplete from 'src/components/hook-form/rhf-maps-autocomplete';

type Props = {
  profile?: CandidateProfile;
  isLoading: boolean;
  mode?: 'edit' | 'view';
};

export default function WorkPreferencesEditor({ profile, isLoading, mode = 'edit' }: Props) {
  const { t } = useTranslate();

  const { enqueueSnackbar } = useSnackbar();

  const { data: typeDefinitionData } = useGetTypeDefinitionsQuery();

  const [updateProfile] = useSetCandidateProfileMutation();

  const defaultValues = {
    work_styles: [],
    working_styles_visibility: true,

    job_types: [],
    job_types_visibility: true,

    preferred_locations: [],
    work_locations_visibility: true,
  };

  const validationSchema = Yup.object().shape({
    work_styles: Yup.array().of(Yup.string()),
    working_styles_visibility: Yup.boolean(),

    job_types: Yup.array().of(Yup.string()),
    job_types_visibility: Yup.boolean(),

    preferred_locations: Yup.array().of(
      Yup.object().shape({
        place_id: Yup.string().defined(),
        address: Yup.string().defined(),
        latitude: Yup.number(),
        longitude: Yup.number(),
      })
    ),
    work_locations_visibility: Yup.boolean(),
  });

  const formMethods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: 'all',
  });

  const {
    handleSubmit,
    formState: { isSubmitting, isDirty },
    watch,
    reset,
  } = formMethods;

  const setProfileData = () => {
    if (!isLoading && profile) {
      reset(
        {
          work_styles: profile.work_styles?.length
            ? profile.work_styles.map((entry) => entry.style as string)
            : [],
          working_styles_visibility:
            profile.sections?.find(
              (section) => section.section_type === CandidateProfileSectionType.WORK_STYLES
            )?.visibility_rule === VisibilityRule.PUBLIC,

          job_types: profile.job_types?.map((entry) => entry.type) ?? [],
          job_types_visibility:
            profile.sections?.find(
              (section) => section.section_type === CandidateProfileSectionType.JOB_TYPES
            )?.visibility_rule === VisibilityRule.PUBLIC,

          preferred_locations: profile.preferred_locations ?? [],
          work_locations_visibility:
            profile.sections?.find(
              (section) => section.section_type === CandidateProfileSectionType.WORK_LOCATIONS
            )?.visibility_rule === VisibilityRule.PUBLIC,
        },
        { keepDirty: false }
      );
    }
  };

  useEffect(() => {
    setProfileData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, profile]);

  const work_locations_visibility = watch('work_locations_visibility');
  const working_styles_visibility = watch('working_styles_visibility');
  const job_types_visibility = watch('job_types_visibility');

  const onSubmit = handleSubmit(async (data) => {
    try {
      await updateProfile({
        id: profile?.id as string,
        data: {
          work_styles: data.work_styles ? data.work_styles.map((style) => ({ style })) : undefined,
          job_types: data.job_types ? data.job_types.map((type) => ({ type })) : undefined,
          preferred_locations: data.preferred_locations
            ? (data.preferred_locations as PreferredLocation[])
            : undefined,

          sections: [
            {
              type: CandidateProfileSectionType.WORK_STYLES,
              visibility_rule: data.working_styles_visibility
                ? VisibilityRule.PUBLIC
                : VisibilityRule.HIDDEN,
            },
            {
              type: CandidateProfileSectionType.JOB_TYPES,
              visibility_rule: data.job_types_visibility
                ? VisibilityRule.PUBLIC
                : VisibilityRule.HIDDEN,
            },
            {
              type: CandidateProfileSectionType.WORK_LOCATIONS,
              visibility_rule: data.work_locations_visibility
                ? VisibilityRule.PUBLIC
                : VisibilityRule.HIDDEN,
            },
          ],
        },
      }).unwrap();
    } catch (e) {
      console.log(e);

      enqueueSnackbar(generateCandidateProfileErrorMessage(e), { variant: 'error' });
    }
  });

  const renderForm = () => (
    <FormProvider methods={formMethods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        {
          isLoading ? (
            <Grid
              xs={12}
            >
              <Skeleton variant="rectangular" height={200} />
            </Grid>
          ) : (
            <>
              {/* Work Styles */}
              <Grid
                xs={12}
                sm={6}
                sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}
              >
                <RHFMultiSelect
                  fullWidth
                  checkbox
                  name="work_styles"
                  label={t('enums.work_style.input_label')}
                  options={
                    typeDefinitionData
                      ? typeDefinitionData.WorkStyleType.map((work_style: string) => ({
                        label: t(`enums.work_style.${work_style}`),
                        value: work_style,
                      }))
                      : []
                  }
                  disabled={isLoading || mode === 'view'}
                />

                {
                  mode === 'edit' && (
                    <RHFVisibilityToggle
                      name="working_styles_visibility"
                      disabled={isLoading}
                      labelPosition="top"
                      checked={working_styles_visibility}
                      onLabel={t('common.visible')}
                      offLabel={t('common.hidden')}
                      tooltip={t('profile.form.toggle_tooltip')}
                      sx={{ ml: 2 }}
                    />
                  )
                }
              </Grid>

              {/* Job Types */}
              <Grid
                xs={12}
                sm={6}
                sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}
              >
                <RHFMultiSelect
                  fullWidth
                  name="job_types"
                  checkbox
                  label={t('enums.job_type.input_label')}
                  disabled={isLoading || mode === 'view'}
                  options={
                    typeDefinitionData
                      ? typeDefinitionData.JobType.map((jobType: string) => ({
                        label: t(`enums.job_type.${jobType}`),
                        value: jobType,
                      }))
                      : []
                  }
                />

                {
                  mode === 'edit' && (
                    <RHFVisibilityToggle
                      name="job_types_visibility"
                      disabled={isLoading}
                      labelPosition="top"
                      checked={job_types_visibility}
                      onLabel={t('common.visible')}
                      offLabel={t('common.hidden')}
                      tooltip={t('profile.form.toggle_tooltip')}
                      sx={{ ml: 2 }}
                    />
                  )
                }
              </Grid>

              {/* Preferred Locations */}
              <Grid
                xs={12}
                sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}
              >
                <RHFMapsAutocomplete
                  name="preferred_locations"
                  label="Locations"
                  options={[]}
                  disabled={isLoading || mode === 'view'}
                />
                {
                  mode === 'edit' && (
                    <RHFVisibilityToggle
                      name="work_locations_visibility"
                      labelPosition="top"
                      disabled={isLoading}
                      checked={work_locations_visibility}
                      onLabel={t('common.visible')}
                      offLabel={t('common.hidden')}
                      tooltip={t('profile.form.toggle_tooltip')}
                      sx={{ ml: 2 }}
                    />
                  )
                }
              </Grid>
            </>
          )
        }
      </Grid>
      {
        mode === 'edit' && (
          <CardActions sx={{ px: 0, pt: 2, justifyContent: 'flex-end' }}>
            <LoadingButton
              variant="contained"
              color="success"
              type="submit"
              loading={isSubmitting}
              disabled={!isDirty}
            >
              {t('common.save')}
            </LoadingButton>
          </CardActions>
        )
      }
    </FormProvider>
  );

  return (
    <Card>
      <CardHeader
        title={isLoading ? <Skeleton width={200} /> : t('profile.sections.work_preferences.title')}
      />
      <CardContent>{renderForm()}</CardContent>
    </Card>
  );
}
