import { TenantType } from 'src/types/enums';

import { Account } from '../auth/auth.types';
import { Application } from '../applications/applications.types';

export enum InterviewStatus {
  InReview = 'in_review',
  ChangesRequested = 'changes_requested',
  RescheduleRequested = 'reschedule_requested',
  Sent = 'sent',
  Scheduled = 'scheduled',
  Cancelled = 'cancelled',
  Completed = 'completed',
}

export enum InterviewSubmitBehaviour {
  Schedule = 'schedule',
  RequestAvailability = 'request_availability',
}

export type ScheduledInterviewResponse = {
  id: string;

  application: Application;

  creator: Account;

  status: InterviewStatus;

  participants: ParticpantAccount[];

  available_slots: AvailabilityTimeslotResponse[];

  title: string;

  location: string;

  duration_minutes: number;

  scheduled_at: string;

  reminder_sent_at: Date;

  last_action_at: Date;

  action_reminder_sent_at: Date;

  reschedule_requested_at: Date;

  notes: string;

  link?: string;

  time_selection_type: TimeSelectionType;
};

export enum TimeSelectionType {
  SELECT = 'select',
  SCHEDULE = 'schedule',
}

export type UpdateInterviewDto = Pick<
  CreateInterviewDto,
  'available_slots' | 'participants' | 'scheduled_at'
>;

export type ParticpantAccount = Pick<Account, 'id'> & {
  first_name: string;
  last_name: string;
  type: TenantType;
};

export type AvailabilityTimeslotDto = {
  start_at: string;
  end_at: string;
};

export type AvailabilityTimeslotResponse = {
  block_start_at: string;
  block_end_at: string;
};

export type CreateInterviewDto = {
  title: string;

  link?: string;

  location?: string;

  duration_minutes: number;

  submit_behaviour: InterviewSubmitBehaviour;

  participants: ParticpantAccount[];

  available_slots?: AvailabilityTimeslotDto[];

  scheduled_at?: string;

  location_type: InterviewLocationType;
};

export enum InterviewLocationType {
  ONLINE = 'online',
  IN_PERSON = 'in_person',
}

export type ReviewInterviewDto = {
  action: InterviewReviewAction;
  notes?: string;
};

export enum InterviewReviewAction {
  Approve = 'approve',
  RequestChanges = 'request_changes',
}

export type ConfirmInterviewDto = {
  scheduled_slot: AvailabilityTimeslotDto;
};
