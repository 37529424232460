/* eslint-disable import/no-extraneous-dependencies */
import merge from 'lodash/merge';
// date fns
import { enGB as enGBAdapter } from 'date-fns/locale';

// core (MUI)
import { enUS as enUSCore } from '@mui/material/locale';
// date pickers (MUI)
import { enUS as enUSDate } from '@mui/x-date-pickers/locales';
// data grid (MUI)
import { enUS as enUSDataGrid } from '@mui/x-data-grid/locales';

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'English',
    value: 'en',
    systemValue: merge(enUSDate, enUSDataGrid, enUSCore),
    adapterLocale: enGBAdapter,
    icon: 'flagpack:gb-nir',
    numberFormat: {
      code: 'en-GB',
      currency: 'AUD',
    },
  },
];

export const defaultLang = allLangs[0]; // English

// GET MORE COUNTRY FLAGS
// https://icon-sets.iconify.design/flagpack/
// https://www.dropbox.com/sh/nec1vwswr9lqbh9/AAB9ufC8iccxvtWi3rzZvndLa?dl=0
