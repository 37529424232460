import { useMemo, ChangeEvent } from 'react';

import { useTheme } from '@mui/material/styles';
import { Stack, useMediaQuery } from '@mui/system';
import { Card, Table, TableBody, TableContainer } from '@mui/material';

import { useTranslate } from 'src/locales';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { Contact } from 'src/services/contacts/contacts.types';
import { PageOptions, PaginatedResponse } from 'src/services/api.types';

import Scrollbar from 'src/components/scrollbar';
import ContactsTableToolbar from 'src/components/custom-tables/contacts-table/contacts-table-toolbar';
import {
  useTable,
  emptyRows,
  TableNoData,
  TableSkeleton,
  TableEmptyRows,
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/table';

import { TenantType } from 'src/types/enums';

import { ContactsTableRow } from './contacts-table-row';

type Props = {
  contacts?: PaginatedResponse<Contact>;
  pageOptions: PageOptions;
  isLoading: boolean;
  onChangePage: (_event: any, pageNumber: number) => void;
  onChangeRowsPerPage: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onFilters?: (type: string, value: string) => void;
  clearFilters?: () => void;
  onAddContact?: () => void;
  onRemoveContact?: (contactId: string) => void;
};

export default function ContactsTable({
  contacts,
  pageOptions,
  isLoading = false,
  onChangePage,
  onChangeRowsPerPage,
  onFilters,
  clearFilters,
  onAddContact,
  onRemoveContact
}: Props) {

  const table = useTable();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const tenant = useOrgTenant();

  const { t } = useTranslate();

  const denseHeight = table.dense ? 56 : 56 + 20;

  const notFound = !contacts?.count;

  const isCandidate = tenant === TenantType.Candidate;

  const tableLabels = useMemo(
    () => [
      { id: 'name', label: t('contacts.table.name') },
      { id: 'email', label: t('contacts.table.email') },
      { id: 'phone', label: t('contacts.table.phone') },
      { id: 'source', label: t('contacts.table.source') },
      { id: 'type', label: t('contacts.table.type') },
      { id: 'notes', label: '' }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isCandidate]
  );

  return (
    <Card>
      {
        isLoading ? (
          <TableSkeleton />
        )
          :
          <>
            {
              (onFilters && clearFilters) && (
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <ContactsTableToolbar
                    onAddContact={onAddContact}
                    onFilters={onFilters}
                    clearFilters={clearFilters}
                  />
                </Stack>
              )
            }

            <TableContainer sx={{ overflow: 'unset' }}>
              <Scrollbar>
                <Table size={table.dense ? 'small' : 'medium'}>
                  {
                    !isMobile && (
                      <TableHeadCustom headLabel={tableLabels} />
                    )
                  }

                  <TableBody>
                    {contacts?.results?.map((row) => <ContactsTableRow key={row.id} row={row} onRemoveContact={onRemoveContact} />)}

                    <TableEmptyRows
                      height={denseHeight}
                      emptyRows={emptyRows(table.page, table.rowsPerPage, contacts?.count ?? 0)}
                    />

                    <TableNoData notFound={notFound} />
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>

            <TablePaginationCustom
              count={contacts?.count ?? 0}
              page={pageOptions.page}
              rowsPerPage={pageOptions.per_page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
              dense={table.dense}
              onChangeDense={table.onChangeDense}
            />
          </>
      }
    </Card>
  );
}
