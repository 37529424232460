import { SubscriptionStatus } from 'src/services/billing/billing.types';

export const DEFAULT_CARD_PER_PAGE = 4;

export const DEFAULT_INVOICES_PER_PAGE = 4;

export const subsciptionErrorStatuses = [
  SubscriptionStatus.INCOMPLETE,
  SubscriptionStatus.PAST_DUE,
  SubscriptionStatus.UNPAID,
];
