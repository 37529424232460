import { debounce, isNumber } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { useMemo, useState, useEffect, MouseEvent, ChangeEvent, useCallback } from 'react';

import { Card, Table, useTheme, TableBody, useMediaQuery, TableContainer } from '@mui/material';

import { useTranslate } from 'src/locales';
import { PageOptions } from 'src/services/api.types';
import { Department } from 'src/services/organisation/organisation.types';
import {
  useGetDepartmentsQuery
} from 'src/services/organisation/organisation.service';

import Scrollbar from 'src/components/scrollbar';
import {
  useTable,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/table';

import DepartmentTableRow from './department-table-row';
import DepartmentTableToolbar from './department-table-toolbar';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name' },
  { id: 'description', label: 'Description', width: 180 },
  { id: 'location', label: 'Location', width: 220 },
  { id: 'controls', width: 120 },
];

const DEFAULT_PAGE: PageOptions = {
  page: 1,
  per_page: 5,
};

type Props = {
  organizationId: string;
}

export default function DepartmentsTable({
  organizationId
}: Props) {
  const [searchParams, setSearchParams] = useSearchParams();

  const table = useTable();

  const { t } = useTranslate();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const page = searchParams.get('page');
  const per_page = searchParams.get('per_page');

  const [currentPage, setCurrentPage] = useState<PageOptions>({
    page: page && isNumber(+page) ? +page : DEFAULT_PAGE.page,
    per_page: per_page && isNumber(+per_page) ? +per_page : DEFAULT_PAGE.per_page,
  });

  const [filterQuery, setFilterQuery] = useState<string>('');

  const [totalRows, setTotalRows] = useState<number>(0);

  const { currentData: departments, isFetching, currentData } = useGetDepartmentsQuery({
    org_id: organizationId,
    page: {
      page: currentPage.page,
      per_page: currentPage.per_page,
    },
    search_query: filterQuery,
  }, {
    skip: !organizationId
  });

  useEffect(() => {
    if (isFetching) return;

    setTableData(currentData?.results ?? []);
    setTotalRows(currentData?.count ?? 0);
  }, [currentData, isFetching]);

  const denseHeight = table.dense ? 56 : 56 + 20;

  const canReset = !!filterQuery.length;

  const [tableData, setTableData] = useState<Department[]>([]);

  const notFound = (!tableData.length && canReset) || !tableData.length;

  const handleFilters = useMemo(
    () => debounce((value: string) => {
      setFilterQuery(value);
    }, 500),
    [setFilterQuery]
  );

  const onChangePage = useCallback(
    (_event: MouseEvent<HTMLButtonElement, MouseEvent>, pageNumber: number) => {
      setCurrentPage({
        ...currentPage,
        page: pageNumber + 1,
      });

      setSearchParams(
        {
          page: (pageNumber + 1).toString(),
          per_page: currentPage.per_page.toString(),
        },
        {
          replace: false,
        }
      );
    },
    [currentPage, setSearchParams]
  );

  const onChangePageRows = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const new_per_page = +(event.target as HTMLInputElement).value;
      setCurrentPage({
        ...DEFAULT_PAGE,
        per_page: new_per_page,
      });
      setSearchParams(
        {
          page: currentPage.page.toString(),
          per_page: new_per_page.toString(),
        },
        {
          replace: false,
        }
      );
    },
    [currentPage.page, setSearchParams]
  );

  return (
    <Card>
        <DepartmentTableToolbar
          onFilters={handleFilters}
        />

        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
          <Scrollbar>
            <Table size={table.dense ? 'small' : 'medium'}>
              {
                !isMobile && (
                  <TableHeadCustom
                    order={table.order}
                    headLabel={TABLE_HEAD}
                    rowCount={tableData.length}
                    numSelected={table.selected.length}
                  />
                )
              }

              <TableBody>
                {tableData.map((row) => (
                  <DepartmentTableRow key={row.id} row={row} />
                ))}

                <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                />

                <TableNoData notFound={notFound} />
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>

        <TablePaginationCustom
          count={totalRows}
          page={currentPage.page}
          rowsPerPage={currentPage.per_page}
          onPageChange={onChangePage as any}
          onRowsPerPageChange={onChangePageRows}
          dense={table.dense}
          onChangeDense={table.onChangeDense}
        />
      </Card>
  );
}
