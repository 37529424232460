import { Setting } from "src/services/settings/settings.types";

import { rootApi } from "../rootApi";
import { APIResponse, PaginatedResponse } from "../api.types";
import { IIntegration, IIntegrationLog, IIntegrationUser, IntegrationFields, UpdateSettingProps, IntegrationFieldMap, IntegrationProvider, GetIntegrationsProps, SetupIntegrationProps, UpdateFieldMappingProps, GetIntegrationLogsProps, InitiateIntegrationAuthProps } from "./integrations.types";

export const integrationsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getAvailableIntegrations: builder.query<IntegrationProvider[], void>({
      query: () => ({
        url: `/integrations/available`
      }),
      providesTags: ['AvailableIntegrations']
    }),
    getConnectedIntegrations: builder.query<PaginatedResponse<IIntegration>, GetIntegrationsProps | void>({
      query: (data?: GetIntegrationsProps) => ({
        url: `/integrations`,
        params: data,
      }),
      providesTags: ['OrgIntegrations']
    }),
    getIntegration: builder.query<IIntegration, { integrationId: string }>({
      query: ({ integrationId }) => ({
        url: `/integrations/${integrationId}`
      }),
      providesTags: ['OrgIntegrations']
    }),
    getIntegrationAccounts: builder.query<IIntegrationUser[], { integrationId: string }>({
      query: ({ integrationId }) => ({
        url: `/integrations/${integrationId}/accounts`
      })
    }),
    getIntegrationLogs: builder.query<PaginatedResponse<IIntegrationLog>, GetIntegrationLogsProps>({
      query: ({ integrationId, page, per_page }) => ({
        url: `/integrations/${integrationId}/logs`,
        params: {
          page: page || 1,
          per_page: per_page || 10
        }
      })
    }),

    // settings
    getIntegrationSettings: builder.query<Setting[], { integrationId: string }>({
      query: ({ integrationId }) => ({
        url: `/integrations/${integrationId}/settings`
      }),
      providesTags: ['IntegrationSettings']
    }),
    updateIntegrationSettings: builder.mutation<Setting[], UpdateSettingProps>({
      query: ({ integrationId, settings }) => ({
        url: `/integrations/${integrationId}/settings`,
        method: 'PUT',
        data: {
          settings
        }
      }),
      invalidatesTags: ['IntegrationSettings']
    }),

    // field mapping
    getIntegrationFields: builder.query<IntegrationFields, { integrationId: string }>({
      query: ({ integrationId }) => ({
        url: `/integrations/${integrationId}/fields`
      })
    }),
    getIntegrationFieldMap: builder.query<IntegrationFieldMap, { integrationId: string }>({
      query: ({ integrationId }) => ({
        url: `/integrations/${integrationId}/field-mapping`
      }),
      providesTags: ['IntegrationFieldMap']
    }),
    updateIntegrationFieldMap: builder.mutation<APIResponse, UpdateFieldMappingProps>({
      query: ({ integrationId, fields }) => ({
        url: `/integrations/${integrationId}/field-mapping`,
        method: 'PUT',
        data: {
          fields
        }
      }),
      invalidatesTags: ['IntegrationFieldMap']
    }),


    // auth
    initiateConnect: builder.mutation<string, InitiateIntegrationAuthProps>({
      query: (data?: InitiateIntegrationAuthProps) => ({
        url: `/integrations/${data?.provider}/auth/initiate`,
        method: 'POST',
      })
    }),
    connectIntegration: builder.mutation<IIntegration, { provider: string, code: string, state: string }>({
      query: ({ provider, code, state }) => ({
        url: `/integrations/${provider}/auth/connect`,
        method: 'POST',
        data: { code, state }
      }),
      invalidatesTags: ['OrgIntegrations']
    }),
    disconnectIntegration: builder.mutation<APIResponse, { integrationId: string }>({
      query: ({ integrationId }) => ({
        url: `/integrations/${integrationId}/auth/disconnect`,
        method: 'POST',
      }),
      invalidatesTags: ['OrgIntegrations']
    }),
    setupIntegration: builder.mutation<boolean, SetupIntegrationProps>({
      query: ({ integrationId, settings, fields }) => ({
        url: `/integrations/${integrationId}/setup`,
        method: 'POST',
        data: {
          fields,
          settings
        }
      }),
      invalidatesTags: ['OrgIntegrations', 'IntegrationFieldMap', 'IntegrationSettings']
    }),

    // ats related actions
    syncJob: builder.mutation<APIResponse, { job_id: string }>({
      query: ({ job_id }) => ({
        url: `/integrations/ats/sync-job`,
        method: 'POST',
        data: {
          job_id
        }
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'JobSummary', id: arg.job_id }, { type: 'JobIntegrations', id: arg.job_id }, { type: 'Job' }]
    }),
  })
});

export const {
  useGetConnectedIntegrationsQuery,
  useGetAvailableIntegrationsQuery,
  useGetIntegrationQuery,
  useGetIntegrationSettingsQuery,
  useUpdateIntegrationSettingsMutation,
  useGetIntegrationAccountsQuery,
  useGetIntegrationLogsQuery,

  useGetIntegrationFieldsQuery,
  useGetIntegrationFieldMapQuery,
  useUpdateIntegrationFieldMapMutation,

  useInitiateConnectMutation,
  useConnectIntegrationMutation,
  useDisconnectIntegrationMutation,
  useSetupIntegrationMutation,

  useSyncJobMutation


} = integrationsApi;