
import { Stack } from '@mui/system';
import LockIcon from '@mui/icons-material/Lock';
import {
    Box,
    Button,
    Dialog,
    useTheme,
    Typography,
    DialogTitle,
    DialogActions,
    DialogContent
} from '@mui/material';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { useTranslate } from 'src/locales';
import { useAuthContext } from 'src/auth/hooks/useAuthContext';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { UserRole } from 'src/services/organisation/organisation.enum';
import { closeFeatureAccessModal } from 'src/store/slices/billing/billingSlice';

type Props = {
    open: boolean;
};

export default function FeatureAccessModal({ open }: Props) {
    const { t } = useTranslate();
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const router = useRouter();
    const authContext = useAuthContext();

    const featureType = useAppSelector((state) => state.billing.featureAccessModal.featureType)

    const handleClose = () => {
        dispatch(closeFeatureAccessModal());
    }

    const handleUpgrade = () => {
        handleClose();

        if (authContext.account?.org_role === UserRole.Admin) {
            // Redirect to billing page
            router.push(paths.dashboard.admin.billing.root);
        }
    };

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '100%', maxWidth: 500 } }}
            open={open}
            onClose={handleClose}
        >
            <DialogTitle sx={{ textAlign: 'center' }}>
                {t('billing.feature_access_modal.feature_locked')}
            </DialogTitle>

            <DialogContent>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                    py={2}
                >
                    <LockIcon sx={{ fontSize: 60, color: theme.palette.warning.main, mb: 2 }} />
                    <Stack spacing={0} direction="column" sx={{ mt: 5 }}>
                        <Typography variant="h6">
                            {t(`billing.feature_access_modal.features.${featureType}`)}
                        </Typography>
                        {
                            authContext.account?.org_role === UserRole.Admin ?
                                <Typography variant="body2" color="textSecondary" mt={2}>
                                    {t('billing.feature_access_modal.upgrade_required')}
                                </Typography>
                                :
                                <Typography variant="body2" color="textSecondary" mt={2}>
                                    {t('billing.feature_access_modal.contact_admin')}
                                </Typography>
                        }

                    </Stack>
                </Box>
            </DialogContent>

            <DialogActions sx={{ justifyContent: 'center', pb: 3 }}>
                <Button onClick={handleClose} color="inherit">
                    {t('common.cancel')}
                </Button>

                {
                    authContext.account?.org_role === UserRole.Admin &&
                    <Button
                        onClick={handleUpgrade}
                        variant="contained"
                        color="primary"
                        startIcon={<LockIcon />}
                    >
                        {t('billing.feature_access_modal.upgrade_now')}
                    </Button>
                }

            </DialogActions>
        </Dialog>
    );
}