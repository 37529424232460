import { Trans } from "react-i18next";

import { Alert } from "@mui/material";
import { Box, Stack } from "@mui/system";

import { useTranslate } from "src/locales";
import { useOrgTenant } from "src/auth/hooks/useOrgTenant";
import { RejectionContext } from "src/services/applications/applications.types";

type Props = {
  context: RejectionContext;
};

export default function RejectionContextAlert({ context }: Props) {

  const { t } = useTranslate();

  const tenantType = useOrgTenant();

  if (!context) return null;

  return (
    <Alert severity="error" sx={{ mb: 2, width: '100%' }}>
      <Stack direction="column" spacing={1}>
        <Box>{t(`applications.detail.candidate_profile.subheader_rejected_${tenantType}`)}</Box>
        <Stack direction="column">

          <Trans>
            {t('applications.detail.candidate_profile.rejection_label', { reason: t(`applications.rejection.reasons.${context.reason}`) })}
          </Trans>

          {
            context.feedback && (
              <Box sx={{ fontStyle: 'italic' }}>
                {context.feedback}
              </Box>
            )
          }
        </Stack>
      </Stack>
    </Alert>
  )
}