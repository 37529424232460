
import { Box, Stack } from '@mui/system';
import { Report } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Card, Chip, Skeleton, useTheme, CardHeader, Typography, CardContent } from '@mui/material';

import { useTranslate } from 'src/locales';
import { useGetApplicationInsightsQuery } from 'src/services/applications/applications.service';
import { MatchStatus, ApplicationInsightType } from 'src/services/applications/applications.types';

import Iconify from 'src/components/iconify';

export function ApplicationInsightsCard({ applicationId }: { applicationId: string }) {

    const { t } = useTranslate();

    const theme = useTheme();

    const { currentData: insights, isLoading } = useGetApplicationInsightsQuery(applicationId);

    const getInsightIcon = (type: string) => {
        switch (type) {
            case ApplicationInsightType.JOB_TYPE:
                return <Iconify icon="solar:case-round-broken" />;
            case ApplicationInsightType.WORK_STYLE:
                return <Iconify icon="streamline:office-worker" />;
            case ApplicationInsightType.SALARY:
                return <Iconify icon="hugeicons:bank" />;
            default:
                return null;
        }
    };

    const getInsightLabel = (type: ApplicationInsightType) => {
        switch (type) {
            case ApplicationInsightType.JOB_TYPE:
                return t('applications.insights.types.job_type');
            case ApplicationInsightType.WORK_STYLE:
                return t('applications.insights.types.work_style');
            case ApplicationInsightType.SALARY:
                return t('applications.insights.types.salary');
            default:
                return type;
        }
    }

    const getMatchValue = (value: MatchStatus): string => {
        switch (value) {
            case MatchStatus.MATCH:
                return t('applications.insights.match_status.match');
            case MatchStatus.NO_MATCH:
                return t('applications.insights.match_status.no_match');
            case MatchStatus.NOT_ENOUGH_DATA:
                return t('applications.insights.match_status.not_enough_data');
            default:
                return value;
        }
    }

    const getMatchColor = (value: MatchStatus) => {
        switch (value) {
            case MatchStatus.MATCH:
                return 'success';
            case MatchStatus.NO_MATCH:
                return 'error';
            case MatchStatus.NOT_ENOUGH_DATA:
                return 'default';
            default:
                return 'default';
        }
    }

    const getMatchIcon = (value: MatchStatus): React.ReactElement => {
        switch (value) {
            case MatchStatus.MATCH:
                return <CheckCircleIcon sx={{ fontSize: 20, width: 18 }} />;
            case MatchStatus.NO_MATCH:
                return <CancelIcon sx={{ fontSize: 20, width: 18  }} />;
            case MatchStatus.NOT_ENOUGH_DATA:
                return <Report sx={{ fontSize: 20, width: 18  }} />;
            default:
                return <Report sx={{ fontSize: 20, width: 18  }} />;
        }
    }

    return (
        <Card>
            <CardHeader title={
                <Stack direction="row" gap={1} justifyContent="start" alignItems="center">
                    <Iconify icon="akar-icons:light-bulb" />
                    <Box>{t('applications.insights.title')}</Box>
                </Stack>
            } />

            <CardContent>
                {
                    isLoading ? (
                        <Stack direction="column" gap={1} justifyContent="center" alignItems="start" height={theme.spacing(15)}>
                            <Skeleton variant="rounded" width="100%" height={theme.spacing(5)} />
                            <Skeleton variant="rounded" width="100%" height={theme.spacing(5)} />
                            <Skeleton variant="rounded" width="100%" height={theme.spacing(5)} />
                            <Skeleton variant="rounded" width="100%" height={theme.spacing(5)} />
                        </Stack>
                    ) : (
                        <>
                            {
                                insights?.length === 0 ? (
                                    <Stack direction="row" justifyContent="center" alignItems="center" gap={1}>
                                        <Typography variant="caption">{t('applications.insights.no_insights')}</Typography>
                                    </Stack>
                                )
                                    :
                                    <Stack direction="column" justifyContent="center" alignItems="center" gap={1}>
                                        {insights?.map((insight) => (
                                            <Box
                                                key={insight.id}
                                                width="100%"
                                                sx={{
                                                    p: 2,
                                                    border: 1,
                                                    borderColor: 'divider',
                                                    borderRadius: 1,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                }}
                                            >
                                                <Stack direction="row" spacing={2} alignItems="center">
                                                    {getInsightIcon(insight.type)}
                                                    <Typography variant='body2'>{getInsightLabel(insight.type)}</Typography>
                                                </Stack>

                                                <Chip
                                                    icon={getMatchIcon(insight.match_status)}
                                                    label={getMatchValue(insight.match_status)}
                                                    color={getMatchColor(insight.match_status)}
                                                    size="small"
                                                    sx={{
                                                        fontSize: 12,
                                                        '& .MuiChip-icon': {
                                                            color: 'inherit'
                                                        }
                                                    }}
                                                />
                                            </Box>
                                        ))}
                                    </Stack>
                            }
                        </>
                    )
                }

            </CardContent>
        </Card>
    )
}
