// ----------------------------------------------------------------------

export type IPaymentCard = {
  id: string;
  cardNumber: string;
  cardType: string;
  primary?: boolean;
};

export enum RecruiterPlans {
  Free = 'recruiter_free',
  Agency = 'recruiter_agency',
  Team_Seat = 'recruiter_team_seat',
}

export enum ClientPlans {
  Free = 'client_free',
  Business = 'client_business',
}

export enum PayPeriod {
  Monthly = 'month',
  Yearly = 'year',
}
