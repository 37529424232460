import React, { useMemo, useState, useCallback } from 'react';

import { Box } from '@mui/system';
import {
  Stack, Button, Select, Tooltip,
  Popover, useTheme, MenuItem,
  TextField, IconButton, InputLabel,
  FormControl,
  useMediaQuery,
  InputAdornment
} from '@mui/material';

import { useTranslate } from 'src/locales';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { CandidateAvailabilityStatus } from 'src/services/candidates/candidates.types';

import Iconify from 'src/components/iconify';

import { TenantType } from 'src/types/enums';


export interface ToolbarOptions {
  addButton: boolean;
}

export type Props = {
  onFilters: (type: string, value: string) => void;
  clearFilters: () => void;
  onInviteCandidates?: () => void;
};

export default function CandidatesTableToolbar({
  onFilters,
  clearFilters,
  onInviteCandidates
}: Props) {

  const [searchValue, setSearchValue] = useState('');
  const [skillsQuery, setSkillsQuery] = useState('');
  const [availability, setAvailability] = useState<CandidateAvailabilityStatus | "">("");
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { t } = useTranslate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const tenantType = useOrgTenant();

  const onClearFilters = () => {
    setSearchValue('');
    setSkillsQuery('');
    setAvailability("");
    clearFilters();
  };

  const handleFilterName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onFilters('search_query', event.target.value);
      setSearchValue(event.target.value);
    },
    [onFilters]
  );

  const handleFilterSkills = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onFilters('skills', event.target.value);
      setSkillsQuery(event.target.value);
    },
    [onFilters]
  );

  const handleFilterAvailability = useCallback(
    (status: CandidateAvailabilityStatus) => {
      onFilters('availability_status', status);
      setAvailability(status);
    },
    [onFilters]
  );

  const handleOpenFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setAnchorEl(null);
  };

  const disableReset = useMemo(() => !searchValue && !availability && !skillsQuery, [searchValue, availability, skillsQuery]);

  const filterContent = (
    <Stack spacing={2} p={1} display="flex" direction={{ xs: 'column', sm: 'row' }} sx={{ justifyContent: 'space-between', alignItems: 'center', alignContent: 'center', width: '100%' }}>
      <Stack spacing={2} display="flex" direction={{ xs: 'column', sm: 'row' }} sx={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
        <TextField
          onChange={handleFilterName}
          placeholder={t('common.placeholders.search')}
          value={searchValue}
          name="search"
          sx={{ width: { sm: '200px', xs: '100%' } }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            ),
          }}
        />
        {
          tenantType === TenantType.Recruiter && (
            <FormControl sx={{ width: { sm: '200px', xs: '100%' } }}>
              <InputLabel>{t('candidates.filters.availability')}</InputLabel>
              <Select
                id="candidate-status-select"
                value={availability}
                sx={{
                  width: { sm: '200px', xs: '100%' }
                }}
                onChange={(event) => handleFilterAvailability(event.target.value as CandidateAvailabilityStatus)}
                label={t('candidates.filters.availability')}
              >
                <MenuItem value="">{t('common.none')}</MenuItem>
                {Object.values(CandidateAvailabilityStatus).map((status) => (
                  <MenuItem key={status} value={status}>
                    {t(`enums.candidate_availability_status.${status}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )
        }
        <TextField
          onChange={handleFilterSkills}
          placeholder={t('common.placeholders.search_skills')}
          value={skillsQuery}
          sx={{
            width: { sm: '200px', xs: '100%' }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            ),
          }}
        />
        {isMobile ? (
          <Button
            variant="text"
            disabled={disableReset}
            onClick={onClearFilters}
          >
            {t('common.clear')}
          </Button>
        ) :
          <FormControl>
            <Tooltip title={t('common.clear')}>
              <IconButton
                size="large"
                disabled={disableReset}
                onClick={onClearFilters}
                aria-label={t('common.clear')}
              >
                <Iconify icon="material-symbols:cancel" />
              </IconButton>
            </Tooltip>
          </FormControl>
        }
      </Stack>
    </Stack>
  );

  return (
    <Stack
      spacing={2}
      display="flex"
      direction={{ xs: 'column', sm: 'row' }}
      alignItems={{ xs: 'stretch', sm: 'center' }}
      justifyContent={{ xs: 'center', sm: 'space-between' }}
      sx={{
        p: 2,
        width: '100%',
      }}
    >
      {isMobile ? (
        <>
          <Button
            variant="outlined"
            onClick={handleOpenFilter}
            fullWidth
            startIcon={<Iconify icon="mdi:filter" />}
          >
            {t('common.filter')}
          </Button>
          {[TenantType.Recruiter].includes(tenantType) && (
            <Button
              sx={{ maxHeight: 36 }}
              size="medium"
              onClick={onInviteCandidates}
              endIcon={<Iconify icon="material-symbols:add" />}
              variant="contained"
            >
              {t('candidates.invites.invite_button_text')}
            </Button>
          )}
          <Popover
            sx={{ padding: 2 }}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleCloseFilter}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {filterContent}
          </Popover>
        </>
      ) : (
        <Stack sx={{ justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', 'width': '100%' }}>
          {filterContent}

          <Box display="flex" alignContent="center" justifyContent="center">
            {([TenantType.Recruiter].includes(tenantType) && onInviteCandidates) && (
              <Button
                size="medium"
                onClick={onInviteCandidates}
                endIcon={<Iconify icon="material-symbols:add" />}
                variant="contained"
                sx={{ textWrap: 'nowrap' }}
              >
                {t('candidates.invites.invite_button_text')}
              </Button>
            )}
          </Box>
        </Stack>
      )}
    </Stack>
  );
}