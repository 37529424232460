import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router';

import { Box, Stack } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import { Card, Grid, Button, Typography } from '@mui/material';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { useBoolean } from 'src/hooks/use-boolean';

import { useTranslate } from 'src/locales';
import { useAuthContext } from 'src/auth/hooks/useAuthContext';
import { IntegrationStatus, IntegrationProviderType } from 'src/services/integration/integrations.types';
import { useGetIntegrationQuery, useDisconnectIntegrationMutation } from 'src/services/integration/integrations.service';

import { ConfirmDialog } from 'src/components/custom-dialog';

import JobAdderSetupView from 'src/sections/admin/integrations/providers/job-adder/setup-view';
import JobAdderSettings from 'src/sections/admin/integrations/providers/job-adder/settings-view';
import JobAdderFieldMapper from 'src/sections/admin/integrations/providers/job-adder/field-mapper-view';

// ----------------------------------------------------------------------

export default function IntegrationSettingsView() {

  const disconnectControl = useBoolean();

  const { enqueueSnackbar } = useSnackbar();
  const authContext = useAuthContext();

  const { integration_id } = useParams();

  const { t } = useTranslate();

  const router = useRouter();

  const { currentData: integrationDetail, isLoading: isRetrievingIntegration, isError: isErrorRetrievingIntegration } = useGetIntegrationQuery(
    {
      integrationId: integration_id as string,
    },
    {
      skip: !integration_id
    }
  );

  const [disconnectIntegration, { isLoading: isDisconnectingIntegration }] = useDisconnectIntegrationMutation();

  useEffect(() => {
    if (isErrorRetrievingIntegration) {
      router.push(paths.dashboard.admin.integrations.root);
    }
  }, [isErrorRetrievingIntegration, router]);

  // render settings based on provider type

  const renderSettings = () => {
    if (integrationDetail?.provider_type === IntegrationProviderType.JOB_ADDER) {
      if (integrationDetail?.status === IntegrationStatus.REQUIRES_SETUP) {
        return (
          <Grid item xs={12}>
            <JobAdderSetupView integrationId={integration_id as string} />
          </Grid>
        )
      }

      return (
        <>
          <Grid item xs={12}>
            <JobAdderSettings integrationId={integration_id as string} />
          </Grid>
          <Grid item xs={12}>
            <JobAdderFieldMapper integrationId={integration_id as string} />
          </Grid>
        </>
      );
    }

    return null;
  }

  const onDisconnect = async () => {
    try {
      await disconnectIntegration({
        integrationId: integration_id as string
      }).unwrap();

      enqueueSnackbar(t('integrations.detail.configuration.danger_zone.disconnect.api.success'), { variant: 'success' });
      disconnectControl.onFalse();
      router.push(paths.dashboard.admin.integrations.root);
    }
    catch (error) {
      enqueueSnackbar(t('integrations.detail.configuration.danger_zone.disconnect.api.default_error'), { variant: 'error' });
    }
  }

  return (
    <Grid container spacing={3}>

      {renderSettings()}

      {
        integrationDetail && (
          <Grid item xs={12}>
            <Card sx={{ py: 2, px: 4 }}>
              <Box sx={{ mb: 2 }}>
                <Typography variant="h6">{t('integrations.detail.configuration.danger_zone.title')}</Typography>
                <Stack direction="row" spacing={2} sx={{ justifyContent: 'space-between', my: 4 }}>
                  <Stack direction="column">
                    <Typography variant='h6'>{t('integrations.detail.configuration.danger_zone.disconnect.label')}</Typography>
                    <Typography variant='caption'>{t('integrations.detail.configuration.danger_zone.disconnect.subtitle')}</Typography>
                  </Stack>
                  <Button color='error' onClick={disconnectControl.onTrue}>{t('integrations.detail.configuration.danger_zone.disconnect.button')}</Button>
                </Stack>
              </Box>
            </Card>

            <ConfirmDialog
              open={disconnectControl.value}
              onClose={disconnectControl.onFalse}
              title={t('integrations.detail.configuration.danger_zone.disconnect.label')}
              content={t('integrations.detail.configuration.danger_zone.disconnect.alert')}
              action={
                <LoadingButton variant="contained" color="error" onClick={onDisconnect} loading={isDisconnectingIntegration} >
                  {t('integrations.detail.configuration.danger_zone.disconnect.button')}
                </LoadingButton>
              }
            />
          </Grid>
        )
      }
    </Grid>
  );
}
