export enum TenantType {
  Candidate = 'candidate',
  Recruiter = 'recruiter',
  Client = 'client',
}

export enum TenantHierarchyLevel {
  PARENT = 'parent',
  CHILD = 'child',
}
