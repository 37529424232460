import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useMixpanel } from 'src/utils/tracking/use-mixpanel';

const PageViewTracker = () => {
    const location = useLocation();

    const { trackPageView } = useMixpanel();

    useEffect(() => {
        trackPageView({
            'path': location.pathname,
            'search': location.search,
            'hash': location.hash
        });
    }, [location, trackPageView]);

    return null;
};

export default PageViewTracker;