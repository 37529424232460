import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface IInterviewState {
  showInterviewModal: boolean;
  selectedInterviewId: string | null;
  selectedInterviewApplicationId: string | null;
  showInterviewActionModal: boolean;
}

const initialState: IInterviewState = {
  showInterviewModal: false,
  selectedInterviewId: null,
  selectedInterviewApplicationId: null,
  showInterviewActionModal: false,
};

export const interviewSlice = createSlice({
  name: 'interviews',
  initialState,
  reducers: {
    openInterviewSchedulerModal: (
      state,
      action: PayloadAction<{ applicationId: string; interviewId?: string }>
    ) => {
      state.showInterviewModal = true;
      state.selectedInterviewId = action.payload.interviewId || null;
      state.selectedInterviewApplicationId = action.payload.applicationId;
    },
    hideInterviewSchedulerModal: (state) => {
      state.showInterviewModal = false;
      state.selectedInterviewId = null;
      state.selectedInterviewApplicationId = null;
    },
    openInterviewActionModal: (state, action: PayloadAction<{ interviewId: string }>) => {
      state.showInterviewActionModal = true;
      state.selectedInterviewId = action.payload.interviewId;
    },
    hideInterviewActionModal: (state) => {
      state.showInterviewActionModal = false;
      state.selectedInterviewId = null;
    },
  },
});

export const {
  openInterviewSchedulerModal,
  hideInterviewSchedulerModal,
  openInterviewActionModal,
  hideInterviewActionModal,
} = interviewSlice.actions;
export const dataReducer = interviewSlice.reducer;
