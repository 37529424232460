import { useState, ChangeEvent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import {
  Grid,
  Chip,
  Paper,
  Select,
  Button,
  MenuItem,
  useTheme,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  SelectChangeEvent,
} from '@mui/material';

import { useTranslate } from 'src/locales';
import { PeriodType } from 'src/services/jobs/jobs.types';
import { SalaryExpectations } from 'src/services/candidates/candidates.types';

// ----------------------------------------------------------------------

export enum SalaryUnit {
  Annually = 'annually',
  Monthly = 'monthly',
  Weekly = 'weekly',
  Daily = 'daily',
}

type Props = {
  name: string;
  onAddSalary: (salary: SalaryExpectations) => void;
  onRemoveSalary: (index: number) => void;
  disabled?: boolean;
  onClick?: () => void;
};

export default function RHFSalaryPicker({
  name,
  onAddSalary,
  onRemoveSalary,
  disabled,
  onClick,
}: Props) {
  const { control } = useFormContext();

  const [chosenUnit, setChosenUnit] = useState<PeriodType>(PeriodType.YEAR);
  const [minSalary, setMinSalary] = useState<number | null>(null);
  const [maxSalary, setMaxSalary] = useState<number | null>(null);

  const { t } = useTranslate();

  const theme = useTheme();

  const handleUnitChange = (event: SelectChangeEvent) => {
    setChosenUnit(event.target.value as PeriodType);
  };

  const handleValueChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined
  ) => {
    if (!event) return;

    if (event.target.name === 'min_salary') {
      setMinSalary(+event.target.value);
    } else {
      setMaxSalary(+event.target.value);
    }
  };

  const onSubmit = () => {
    if (minSalary && maxSalary) {
      onAddSalary({
        min_amount: minSalary,
        max_amount: maxSalary,
        period: chosenUnit,
      });

      setMinSalary(0);
      setMaxSalary(0);
    }
  };

  const getChipLabel = (salaryObj: SalaryExpectations) =>
    `${Intl.NumberFormat('en-AU', {
      style: 'currency',
      currency: 'AUD',
      maximumFractionDigits: 0,
    }).format(+salaryObj.min_amount)}-${Intl.NumberFormat('en-AU', {
      style: 'currency',
      currency: 'AUD',
      maximumFractionDigits: 0,
    }).format(+salaryObj.max_amount)} ${t(`enums.rate_period_per.${salaryObj.period}`)}`;

  return (
    <Paper variant="outlined" sx={{ backgroundColor: theme.palette.background.neutral }}>
      <Grid container padding={2}>
        <Grid item xs={4}>
          <InputLabel htmlFor="min-salary-range">
            {t('profile.form.salary_range.min_label')}
          </InputLabel>
          <OutlinedInput
            id="min-salary-range"
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            placeholder={t('common.placeholders.min_salary')}
            name="min_salary"
            value={minSalary}
            type="number"
            onChange={handleValueChange}
            disabled={disabled}
            onClick={onClick}
            sx={{ backgroundColor: theme.palette.background.paper }}
          />
        </Grid>
        <Grid item xs={4}>
          <InputLabel htmlFor="max-salary-range">
            {t('profile.form.salary_range.max_label')}
          </InputLabel>
          <OutlinedInput
            id="max-salary-range"
            name="max_salary"
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            placeholder={t('common.placeholders.max_salary')}
            value={maxSalary}
            type="number"
            onChange={handleValueChange}
            disabled={disabled}
            onClick={onClick}
            sx={{ backgroundColor: theme.palette.background.paper }}
          />
        </Grid>
        <Grid item xs={4}>
          <InputLabel htmlFor="min-salary-range">
            {t('profile.form.salary_range.unit_label')}
          </InputLabel>
          <Select
            id="salary-unit-picker"
            fullWidth
            value={chosenUnit}
            onChange={handleUnitChange}
            disabled={disabled}
            onClick={onClick}
            sx={{ backgroundColor: theme.palette.background.paper }}
          >
            {Object.values(PeriodType).map((unitKey) => (
              <MenuItem key={unitKey} value={unitKey}>
                <span>{t(`enums.rate_period_per.${unitKey}`)}</span>
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="flex-end" paddingTop={1}>
          <Grid item xs={8}>
            <Controller
              name={name}
              control={control}
              render={({ field }) => (
                <Grid container spacing={2}>
                  {field.value.map((salaryRange: SalaryExpectations, index: number) => (
                    <Grid item key={`chip-${index}`}>
                      <Chip
                        label={getChipLabel(salaryRange)}
                        variant="filled"
                        disabled={disabled}
                        onDelete={() => onRemoveSalary(index)}
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
            />
          </Grid>
          <Grid item xs={4} display="flex" justifyContent="flex-end">
            <Button
              color="inherit"
              size="small"
              variant="contained"
              data-testid="select-salary-range-btn"
              disabled={!minSalary && !maxSalary}
              onClick={onSubmit}
            >
              {t('common.add')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
