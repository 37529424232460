import Lottie from 'react-lottie';

type Props = {
  json: any;
  loop: boolean;
  height?: number | string;
  width?: number | string;
};

export default function LottieContainer({
  json,
  height = '100%',
  width = '100%',
  loop = true,
}: Props) {
  return (
    <Lottie
      height={height}
      width={width}
      options={{
        loop,
        autoplay: true,
        animationData: json,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      }}
    />
  );
}
