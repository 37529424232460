
import { useState, useEffect } from 'react';
import { enqueueSnackbar } from 'notistack';

import { Stack } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import { Box, Dialog, DialogTitle, DialogActions, DialogContent } from '@mui/material';

import { useTranslate } from 'src/locales';
import { useAppDispatch } from 'src/store/store';
import { useGetTermByIdQuery, useUpdateTermMutation } from 'src/services/system/system.service';

import { MarkdownEditor } from 'src/components/markdown-editor/markdown-editor';

type Props = {
  open: boolean;
  onClose: () => void;
  termsId: string;
};

export default function EditTermsModal({ open, termsId, onClose }: Props) {

  const { t } = useTranslate();
  const dispatch = useAppDispatch();

  const [content, setContent] = useState<string>('');

  const [updateTerms] = useUpdateTermMutation();

  const { currentData: term } = useGetTermByIdQuery({
    termId: termsId
  }, {
    skip: !open || !termsId
  })

  useEffect(() => {
    if (term) {
      setContent(term.content || '');
    }
  }, [term]);

  const onTermsChange = (contentText: string) => {
    setContent(contentText);
  }

  const handleSave = async () => {
    try {
      await updateTerms({
        termId: termsId,
        content
      }).unwrap();

      enqueueSnackbar(t('system.terms.api.update.success'), { variant: 'success' });

      onClose();
    }
    catch (error) {
      enqueueSnackbar(t('system.terms.api.update.default_error'), { variant: 'error' });
    }
  }

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle sx={{ textTransform: 'capitalize' }} >
        {t('system.terms.edit_modal.title')}
      </DialogTitle>

      <DialogContent>
        <Box sx={{ mt: 2, mb: 4, px: 4 }}>
          <MarkdownEditor content={term?.content || ''} onChange={onTermsChange} />
        </Box>
      </DialogContent>

      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button onClick={onClose} color="primary" variant="text">
            {t('common.close')}
          </Button>

          <LoadingButton onClick={handleSave} color="primary" variant="contained">
            {t('common.save')}
          </LoadingButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}