import axios from 'axios';

import { localStorageGetItem } from 'src/utils/local-storage';

import { rootApi } from '../rootApi';
import { HTTPRequestType, PaginatedResponse } from '../api.types';
import { orgBasePath } from '../organisation/organisation.service';
import {
  UploadProps,
  UploadResponse,
  CreateDocumentProps,
  OrganisationDocument,
  CreateDocumentResponse,
  ConnectionDocumentsResponse,
  GetConnectionDocumentsProps,
  GetOrganisationDocumentsProps,
} from './documents.types';

const documentsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    uploadFile: builder.mutation<UploadResponse, UploadProps>({
      queryFn: async (data: UploadProps) => {
        const token = localStorageGetItem('access_token', undefined);

        // eslint-disable-next-line no-useless-catch
        try {
          const formData = new FormData();
          formData.append('file', data.file);
          formData.append('type', data.type);

          const res = await axios.post<UploadResponse>(
            `${import.meta.env.VITE_BACKEND_URL}/upload/file`,
            formData,
            {
              headers: {
                'Content-Type': `multipart/form-data;`,
                ...(token ? { Authorization: `Bearer ${token}` } : {}),
              },
            }
          );

          return res;
        } catch (e) {
          throw e;
        }
      },
    }),

    createDocument: builder.mutation<CreateDocumentResponse, CreateDocumentProps>({
      query: (data) => ({
        url: `/${orgBasePath}/${data.organization_id}/documents`,
        method: HTTPRequestType.POST,
        data: data.document,
      }),
      invalidatesTags: ['Documents'],
    }),

    getOrganisationDocuments: builder.query<PaginatedResponse<OrganisationDocument>, GetOrganisationDocumentsProps>({
      query: (data) => ({
        url: `/${orgBasePath}/${data.organization_id}/documents`,
        params: {
          search_query: data.search_query,
          type: data.type,
          page: data.page,
          per_page: data.per_page,
        }
      }),
      providesTags: ['Documents'],
    }),

    getConnectionDocuments: builder.query<ConnectionDocumentsResponse, GetConnectionDocumentsProps>({
      query: (data) => ({
        url: `/${orgBasePath}/${data.organization_id}/connections/${data.connection_id}/documents`,
      }),
    }
    ),
  }),
});

export const {
  useUploadFileMutation,
  useCreateDocumentMutation,
  useGetOrganisationDocumentsQuery,
  useLazyGetConnectionDocumentsQuery,
} = documentsApi;
