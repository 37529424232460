import * as Yup from 'yup';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Card from '@mui/material/Card';
import { Skeleton } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import LoadingButton from '@mui/lab/LoadingButton';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';

import { useTranslate } from 'src/locales';
import { VisibilityRule } from 'src/services/jobs/jobs.types';
import { useGetTypeDefinitionsQuery } from 'src/services/system/system.service';
import { useSetCandidateProfileMutation } from 'src/services/candidates/candidates.service';
import { generateCandidateProfileErrorMessage } from 'src/services/candidates/candidates.utils';
import {
  CandidateProfile,
  CandidateProfileSectionType,
} from 'src/services/candidates/candidates.types';

import FormProvider from 'src/components/hook-form';
import { RHFSelect } from 'src/components/hook-form/rhf-select';
import RHFVisibilityToggle from 'src/components/hook-form/rhf-visibility-toggle';

type Props = {
  profile?: CandidateProfile;
  isLoading: boolean;
  mode?: 'edit' | 'view';
};

export default function ProfileCurrentInformation({ profile, isLoading, mode }: Props) {
  const { data: typeDefinitionData } = useGetTypeDefinitionsQuery();

  const [updateProfile] = useSetCandidateProfileMutation();

  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslate();

  const defaultValues = {
    education_level: '',
    education_level_visibility: true,

    work_rights: '',
    work_rights_visibility: true,

    notice_period: '',
    notice_period_visibility: true,
  };

  const validationSchema = Yup.object().shape({
    work_rights: Yup.string(),
    work_rights_visibility: Yup.boolean(),

    notice_period: Yup.string(),
    notice_period_visibility: Yup.boolean(),

    education_level: Yup.string(),
    education_level_visibility: Yup.boolean(),
  });

  const formMethods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: 'all',
  });

  const {
    handleSubmit,
    formState: { isSubmitting, isValid, isDirty },
    watch,
    reset,
  } = formMethods;

  const setProfileData = () => {
    if (!profile) return;

    const workRightsVisibility = profile?.sections?.find(
      (section) => section.section_type === CandidateProfileSectionType.WORK_RIGHTS
    )?.visibility_rule;

    reset(
      {
        work_rights: profile.work_rights?.[0]?.work_right ?? '',
        work_rights_visibility: workRightsVisibility === VisibilityRule.PUBLIC,

        notice_period: profile.notice_period?.type ?? '',
        notice_period_visibility:
          profile.sections?.find(
            (section) => section.section_type === CandidateProfileSectionType.NOTICE_PERIOD
          )?.visibility_rule === VisibilityRule.PUBLIC,

        education_level: profile.education_level?.type ?? '',
        education_level_visibility:
          profile.sections?.find(
            (section) => section.section_type === CandidateProfileSectionType.EDUCATION_LEVEL
          )?.visibility_rule === VisibilityRule.PUBLIC,
      },
      { keepDirty: false }
    );
  };

  useEffect(() => {
    setProfileData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, profile]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      await updateProfile({
        id: profile?.id as string,
        data: {
          work_rights: data.work_rights ? [{ work_right: data.work_rights } as any] : undefined,
          notice_period: data.notice_period ? ({ type: data.notice_period } as any) : undefined,
          education_level: data.education_level
            ? ({ type: data.education_level } as any)
            : undefined,

          sections: [
            {
              type: CandidateProfileSectionType.WORK_RIGHTS,
              visibility_rule: data.work_rights_visibility
                ? VisibilityRule.PUBLIC
                : VisibilityRule.HIDDEN,
            },
            {
              type: CandidateProfileSectionType.NOTICE_PERIOD,
              visibility_rule: data.notice_period_visibility
                ? VisibilityRule.PUBLIC
                : VisibilityRule.HIDDEN,
            },
            {
              type: CandidateProfileSectionType.EDUCATION_LEVEL,
              visibility_rule: data.education_level_visibility
                ? VisibilityRule.PUBLIC
                : VisibilityRule.HIDDEN,
            },
          ],
        },
      }).unwrap();
    } catch (e) {
      console.log(e);

      enqueueSnackbar(generateCandidateProfileErrorMessage(e), { variant: 'error' });
    }
  });

  const education_level_visibility = watch('education_level_visibility');
  const work_rights_visibility = watch('work_rights_visibility');
  const notice_period_visibility = watch('notice_period_visibility');

  const renderForm = () => (
    <FormProvider methods={formMethods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        {
          isLoading ? (
            <Grid
              xs={12}
            >
              <Skeleton variant="rectangular" height={200} />
            </Grid>
          ) : (
            <>
              {/* Work Rights */}
              <Grid
                xs={12}
                sm={6}
                sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}
              >
                <RHFSelect
                  fullWidth
                  name="work_rights"
                  label={t('enums.work_rights.input_label')}
                  disabled={isLoading || mode === 'view'}
                >
                  <MenuItem key="default" value="">
                    {t('common.none')}
                  </MenuItem>
                  {typeDefinitionData
                    ? typeDefinitionData.WorkRightType.map((rightsType: string) => (
                      <MenuItem key={rightsType} value={rightsType}>
                        {t(`enums.work_rights.${rightsType}`)}
                      </MenuItem>
                    ))
                    : null}
                </RHFSelect>

                {
                  mode === 'edit' &&
                  <RHFVisibilityToggle
                    name="work_rights_visibility"
                    disabled={isLoading}
                    labelPosition="top"
                    checked={work_rights_visibility}
                    onLabel={t('common.visible')}
                    offLabel={t('common.hidden')}
                    tooltip={t('profile.form.toggle_tooltip')}
                    sx={{ ml: 2 }}
                  />
                }

              </Grid>

              {/* Education Level */}
              <Grid
                xs={12}
                sm={6}
                sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}
              >
                <RHFSelect
                  fullWidth
                  name="education_level"
                  label={t('enums.education_level.input_label')}
                  disabled={isLoading || mode === 'view'}
                >
                  <MenuItem key="default" value="">
                    {t('common.none')}
                  </MenuItem>
                  {typeDefinitionData
                    ? typeDefinitionData.EducationLevel.map((educationLevel: string) => (
                      <MenuItem key={educationLevel} value={educationLevel}>
                        {t(`enums.education_level.${educationLevel}`)}
                      </MenuItem>
                    ))
                    : null}
                </RHFSelect>

                {
                  mode === 'edit' &&
                  <RHFVisibilityToggle
                    name="education_level_visibility"
                    disabled={isLoading}
                    labelPosition="top"
                    checked={education_level_visibility}
                    onLabel={t('common.visible')}
                    offLabel={t('common.hidden')}
                    tooltip={t('profile.form.toggle_tooltip')}
                    sx={{ ml: 2 }}
                  />
                }
              </Grid>

              {/* Notice Period */}
              <Grid
                xs={12}
                sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}
              >
                <RHFSelect
                  fullWidth
                  name="notice_period"
                  label={t('enums.notice_period.input_label')}
                  disabled={isLoading || mode === 'view'}
                >
                  <MenuItem key="default" value="">
                    {t('common.none')}
                  </MenuItem>
                  {typeDefinitionData
                    ? typeDefinitionData.NoticePeriodType.map((period: string) => (
                      <MenuItem key={period} value={period}>
                        {t(`enums.notice_period.${period}`)}
                      </MenuItem>
                    ))
                    : null}
                </RHFSelect>

                {
                  mode === 'edit' &&
                  <RHFVisibilityToggle
                    name="notice_period_visibility"
                    disabled={isLoading}
                    labelPosition="top"
                    checked={notice_period_visibility}
                    onLabel={t('common.visible')}
                    offLabel={t('common.hidden')}
                    tooltip={t('profile.form.toggle_tooltip')}
                    sx={{ ml: 2 }}
                  />
                }
              </Grid>
            </>
          )
        }
      </Grid>

      {
        mode === 'edit' && (
          <CardActions sx={{ px: 0, pt: 2, justifyContent: 'flex-end' }}>
            <LoadingButton
              variant="contained"
              color="success"
              type="submit"
              loading={isSubmitting}
              disabled={!isValid || !isDirty}
            >
              {t('common.save')}
            </LoadingButton>
          </CardActions>
        )
      }
    </FormProvider>
  );

  return (
    <Card>
      <CardHeader
        title={isLoading ? <Skeleton width={200} /> : t('profile.sections.information.title')}
      />
      <CardContent>{renderForm()}</CardContent>
    </Card>
  );
}
