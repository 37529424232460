import React, { useMemo, useState, useCallback } from 'react';

import {
  Stack, Button, Select, Tooltip, Popover, MenuItem,
  useTheme, TextField, IconButton, InputLabel,
  FormControl, useMediaQuery, InputAdornment
} from '@mui/material';

import { useTranslate } from 'src/locales';

import Iconify from 'src/components/iconify';

import { TenantType } from 'src/types/enums';

export interface ToolbarOptions {
  addButton: boolean;
}

export type Props = {
  onAddContact?: () => void;
  onFilters: (type: string, value: string) => void;
  clearFilters: () => void;
};

export default function ContactsTableToolbar({
  onAddContact,
  onFilters,
  clearFilters,
}: Props) {
  const [selectedType, setSelectedType] = useState<string | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { t } = useTranslate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const onClearFilters = () => {
    setSearchValue('');
    setSelectedType(null);
    clearFilters();
  };

  const handleFilterName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onFilters('search_query', event.target.value);
      setSearchValue(event.target.value);
    },
    [onFilters]
  );

  const handleFilterType = useCallback(
    (type: string) => {
      onFilters('type', type);
      setSelectedType(type);
    },
    [onFilters]
  );

  const handleOpenFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setAnchorEl(null);
  };

  const disableReset = useMemo(() => !searchValue && !selectedType, [searchValue, selectedType]);

  const filterContent = (
    <Stack spacing={2} display="flex" direction={{ xs: 'column', sm: 'row' }} sx={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
      <TextField
        onChange={handleFilterName}
        placeholder={t('common.placeholders.search')}
        value={searchValue}
        name="search"
        sx={{ width: { sm: '500px', xs: '100%' } }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          ),
        }}
      />
      <FormControl sx={{ width: '100%' }}>
        <InputLabel id="contact-type-select-label">
          {t('common.type')}
        </InputLabel>
        <Select
          id="contact-type-select"
          label={t('common.placeholders.contact_type')}
          value={selectedType || ''}
          onChange={(event) => handleFilterType(event.target.value as TenantType)}
          placeholder={t('common.placeholders.contact_type')}
        >
          <MenuItem value="">{t('common.none')}</MenuItem>
          {Object.values(TenantType).map((value) => (
            <MenuItem key={value} value={value}>
              {t(`enums.tenant.${value}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {isMobile ? (
        <Button
          variant="text"
          disabled={disableReset}
          onClick={onClearFilters}
        >
          {t('common.clear')}
        </Button>
      ) :
        <FormControl>
          <Tooltip title={t('common.clear')}>
            <IconButton
              size="large"
              disabled={disableReset}
              onClick={onClearFilters}
              aria-label={t('common.clear')}
            >
              <Iconify icon="material-symbols:cancel" />
            </IconButton>
          </Tooltip>
        </FormControl>
      }
    </Stack>
  );

  return (
    <Stack
      spacing={2}
      display="flex"
      direction={{ xs: 'column', sm: 'row' }}
      alignItems={{ xs: 'stretch', sm: 'center' }}
      justifyContent={{ xs: 'center', sm: 'space-between' }}
      sx={{
        p: 2,
        width: '100%',
      }}
    >
      {isMobile ? (
        <>
          <Button
            variant="outlined"
            onClick={handleOpenFilter}
            fullWidth
            startIcon={<Iconify icon="mdi:filter" />}
          >
            {t('common.filter')}
          </Button>
          <Popover
            sx={{ padding: 2 }}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleCloseFilter}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {filterContent}
          </Popover>
        </>
      ) : (
        <Stack sx={{ justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', 'width': '100%' }}>
          {filterContent}
        </Stack>
      )}

      {onAddContact && (
        <Button
          size="medium"
          endIcon={<Iconify icon="material-symbols:add" />}
          variant="contained"
          onClick={onAddContact}
          sx={{ width: { sm: '200px', xs: '100%' } }}
        >
          {t(`contacts.add`)}
        </Button>
      )}
    </Stack>
  );
}