import { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import { Stack } from '@mui/material';
import Pagination from '@mui/material/Pagination';

import { PageOptions } from 'src/services/api.types';
import { useLazyGetThreadQuery } from 'src/services/threads/threads.service';

import PostCommentItem from './post-comment-item';

// ----------------------------------------------------------------------

type Props = {
  thread_id?: string;
  comments_per_page?: number;
};

const DEFAULT_PAGE = {
  page: 1,
  per_page: 20,
};

export default function PostCommentList({ thread_id, comments_per_page = DEFAULT_PAGE.per_page }: Props) {
  const [page, setPage] = useState<PageOptions>({
    page: DEFAULT_PAGE.page,
    per_page: comments_per_page,
  });

  const [getComments, { currentData }] = useLazyGetThreadQuery();

  useEffect(() => {
    if (!thread_id) return;

    getComments({
      thread_id,
      ...page,
    });
  }, [getComments, page, thread_id]);

  const onChangePage = (_event: any, newPage: number) => {
    setPage((prev) => ({
      ...prev,
      page: newPage,
    }));
  };

  return (
    <>
      <>
        {currentData?.results.map((comment) => {
          const {
            id,
            content,
            created_at,
            account: { first_name, last_name },
          } = comment;

          return (
            <Box key={id}>
              <PostCommentItem
                name={`${first_name} ${last_name}`}
                message={content}
                postedAt={new Date(created_at)}
              />
            </Box>
          );
        })}
      </>

      <Stack flexDirection="row" justifyContent="center">
        <Pagination
          onChange={onChangePage}
          count={
            currentData?.count && currentData.count > page.per_page
              ? Math.ceil(currentData.count / page.per_page)
              : 1
          }
          sx={{ mt: 1, mx: 'auto' }}
        />
      </Stack>
    </>
  );
}
