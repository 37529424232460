import { useSnackbar } from 'notistack';
import { useState, useCallback } from 'react';

import { LoadingButton } from '@mui/lab';
import Grid from '@mui/system/Unstable_Grid/Grid';
import {
  Button,
  Dialog,
  MenuItem,
  TextField,
  DialogTitle,
  DialogActions,
  DialogContent
} from '@mui/material';

import { useTranslate } from 'src/locales';
import { useUploadFileMutation } from 'src/services/documents/documents.service';
import { useCreateSystemDocumentMutation } from 'src/services/system/system.service';

import { Upload } from 'src/components/upload';
import UploadItemCard from 'src/components/upload/upload-item-card';

import { TenantType } from 'src/types/enums';

type Props = {
  open: boolean;
  onClose: VoidFunction;
  onUpload?: (profileDoc: any) => void;
};

export default function UploadSystemDocumentModal({ open, onClose, onUpload = (doc: any) => { } }: Props) {
  const { t } = useTranslate();

  const { enqueueSnackbar } = useSnackbar();

  const [file, setFile] = useState<File>();

  const [name, setName] = useState<string>("");
  const [tenantTypes, setTenantTypes] = useState<TenantType[]>([]);

  const [createSystemDoc, { isLoading: isCreatingSystemDoc }] = useCreateSystemDocumentMutation();

  const [uploadFile, { isLoading: isUploadingFile }] = useUploadFileMutation();

  const handleDropDocument = useCallback(async (documents: File[]) => {
    setFile(documents[0]);
  }, []);

  const handleDocumentUpload = useCallback(async () => {
    try {
      const uploadedFile = await uploadFile({
        file: file as File,
        type: 'document' as any,
      }).unwrap();

      const createSystemDocResponse = await createSystemDoc({
        name: name ?? uploadedFile.filename,
        file_id: uploadedFile?.id as string,
        tenant_types: tenantTypes
      }).unwrap();

      setFile(undefined);
      setName("");
      setTenantTypes([]);

      onUpload(createSystemDocResponse);

      enqueueSnackbar(t('system.documents.api.create.success'), { variant: 'success' });

      onClose();
    } catch (e) {
      enqueueSnackbar(t('system.documents.api.create.default_error'), { variant: 'error' });
    }
  }, [
    name,
    tenantTypes,
    onUpload,
    uploadFile,
    createSystemDoc,
    file,
    enqueueSnackbar,
    t,
    onClose,
  ]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
    >
      <DialogTitle>{t('system.documents.uploader.title')}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ pb: 0, pt: 2 }}>

          <Grid xs={12}>
            <TextField
              label={t('system.documents.uploader.name')}
              fullWidth
              value={name}
              onChange={(e: any) => setName(e.target.value)}
              placeholder="Search..."
            />
          </Grid>

          <Grid xs={12}>
            <TextField
              select
              SelectProps={{ multiple: true }}
              label={t('system.documents.uploader.tenant_types')}
              value={tenantTypes ?? []}
              onChange={(e) => setTenantTypes((e.target.value as unknown) as TenantType[])}
              fullWidth
            >
              {[TenantType.Client, TenantType.Recruiter].map((type) => (
                <MenuItem key={type} value={type}>
                  {t(`enums.tenant.${type}`)}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid xs={12}>
            <Upload
              accept={{
                'application/pdf': ['.pdf'],
                'application/msword': ['.doc'],
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
                  '.docx',
                ],
                'image/png': ['.png'],
                'image/jpeg': ['.jpeg', '.jpg'],
              }}
              onDrop={handleDropDocument}
              sx={{ mt: 1 }}
            />
          </Grid>

          <Grid xs={12}>
            {file && (
              <UploadItemCard
                title={(file as any).path}
                onDeleteFile={() => setFile(undefined)}
                uploading={isUploadingFile}
              />
            )}
          </Grid>

          <Grid xs={12}>
            <LoadingButton
              fullWidth
              variant="contained"
              loading={isUploadingFile || isCreatingSystemDoc}
              disabled={!file || isUploadingFile || isCreatingSystemDoc}
              onClick={handleDocumentUpload}
            >
              {t('common.upload')}
            </LoadingButton>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isUploadingFile || isCreatingSystemDoc}>{t('common.cancel')}</Button>
      </DialogActions>
    </Dialog>
  );
}
