import Tooltip from '@mui/material/Tooltip';

import { useTranslate } from 'src/locales';
import { JobVerificationStatus } from 'src/services/jobs/jobs.types';

import Label, { LabelProps } from 'src/components/label';

type Props = {
  status: JobVerificationStatus;
} & LabelProps;

export default function VerificationStatusLabel({ status, sx, ...other }: Props) {
  const { t } = useTranslate();

  const getLabelColor = (jobStatus: JobVerificationStatus) => {
    switch (status) {
      case JobVerificationStatus.PENDING:
        return 'info';
      case JobVerificationStatus.VERIFIED:
        return 'success';
      case JobVerificationStatus.UNVERIFIED:
        return 'error';
      default:
        return 'default';
    }
  };

  return (
    <Tooltip title={t(`enums.job_verification_status.${status}_tooltip`)}>
      <Label variant="soft" color={getLabelColor(status)}>
        {t(`enums.job_verification_status.${status}`)}
      </Label>
    </Tooltip>
  );
}
