import { useSnackbar } from 'notistack';
import { useMemo, useState, useCallback } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { Dialog, Select, MenuItem, InputLabel, DialogTitle, FormControl, DialogActions, DialogContent } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { useTranslate } from 'src/locales';
import { useAuthContext } from 'src/auth/hooks/useAuthContext';
import { DocumentType, OrganizationDocumentTypes } from 'src/services/documents/documents.types';
import {
  useUploadFileMutation,
  useCreateDocumentMutation,
} from 'src/services/documents/documents.service';

import { Upload } from 'src/components/upload';
import { useSettingsContext } from 'src/components/settings';
import UploadItemCard from 'src/components/upload/upload-item-card';


type Props = {
  open: boolean;
  onClose: VoidFunction;
  onSuccess: VoidFunction;
  onError?: VoidFunction;

};

export default function DocumentsUploader({ open, onClose, onSuccess, onError }: Props) {
  const { t } = useTranslate();

  const settings = useSettingsContext();

  const { enqueueSnackbar } = useSnackbar();

  const authContext = useAuthContext();

  const confirm = useBoolean();

  const [uploadFile, { isLoading: uploadingFile }] = useUploadFileMutation();

  const [createDocument, { isLoading: creatingDocument }] = useCreateDocumentMutation();

  const [file, setFile] = useState<File>();
  const [documentType, setDocumentType] = useState<DocumentType>();

  const [documentId, setDocumentId] = useState<string>();

  const actionsDisabled = useMemo(() => uploadingFile || creatingDocument || !documentType, [creatingDocument, uploadingFile, documentType]);

  const handleDocumentDrop = useCallback(
    async (documents: File[]) => {
      setFile(documents[0]);
    },
    [setFile]
  );

  const handleDocumentProcess = useCallback(async () => {
    confirm.onFalse();

    try {
      const uploadedFile = await uploadFile({
        file: file as File,
        type: 'document' as any,
      }).unwrap();

      const document = await createDocument({
        organization_id: authContext.organization.id,
        document: {
          file_id: uploadedFile.id,
          name: (file as File).name,
          type: documentType as DocumentType,
        },
      }).unwrap();

      enqueueSnackbar(t('documents.uploader.success'), { variant: 'success' });

      onSuccess();
    } catch (e) {
      enqueueSnackbar(t('documents.uploader.default_error'), { variant: 'error' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    authContext.organization.id,
    createDocument,
    enqueueSnackbar,
    file,
    onSuccess,
    uploadFile,
  ]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('documents.uploader.title')}</DialogTitle>
      <DialogContent>
        <Stack sx={{ my: 2, minWidth: 500 }}>
          <FormControl fullWidth>
            <InputLabel id="doc-type-label">
              {t('documents.uploader.document_type')}
            </InputLabel>
            <Select
              id="doc-type"
              labelId="doc-type-label"
              label={t('documents.uploader.document_type')}
              value={documentType}
              onChange={(e) => setDocumentType(e.target.value as DocumentType)}
              fullWidth
            >
              {OrganizationDocumentTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {t(`documents.file_type.${type}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <Stack gap={2} width="100%">
          <Box width="100%" sx={{ mb: 2 }}>
            <Upload
              accept={{ '.pdf': [], '.doc': [], '.docx': [] }}
              onDrop={handleDocumentDrop}
              disabled={actionsDisabled}
              sx={{ mt: 1 }}
            />
          </Box>

          {file && (
            <UploadItemCard
              title={(file as any).path}
              onDeleteFile={() => setFile(undefined)}
              uploading={actionsDisabled}
            />
          )}

          <LoadingButton
            disabled={!file}
            variant="contained"
            fullWidth
            loading={actionsDisabled}
            onClick={handleDocumentProcess}
          >
            {t('common.upload')}
          </LoadingButton>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('common.cancel')}</Button>
      </DialogActions>
    </Dialog>

  );
}
