// ----------------------------------------------------------------------

import { ApplicationStage } from 'src/services/applications/applications.types';

export enum CardType {
  Job = 'job',
  Application = 'application',
  Job_Invite = 'job-invite',
}


export type IKanbanAssignee = {
  id: string;
  name: string;
  role: string;
  email: string;
  status: string;
  address: string;
  avatarUrl: string;
  phoneNumber: string;
  lastActivity: Date;
};

export type IKanbanJobCard = {
  id?: string;
  index: number;
  jobTitle: string;
  employerTitle?: string;
  type: CardType;
  agencyTitle?: string;
  status?: string;
  description?: string;
  assignee?: IKanbanAssignee[];
  due?: [Date | null, Date | null];
  reporter?: {
    id: string;
    name: string;
    avatarUrl: string;
  };
  avatarUrl?: string;
  hasAttachment: boolean;
  isLinked: boolean;
  excitementLevel?: number;
};

export type IKanbanApplicationCard = {
  id?: string;
  index: number;
  type: CardType;
  candidateName: string;
  recruiterName?: string;
  positionTitle?: string;
  avatarUrl?: string;
  stage?: ApplicationStage;
  requestedStage?: ApplicationStage;
  isExternallyManaged: boolean;
  hasAttachedClient: boolean;
};

export type IKanbanColumn<T> = {
  id: string;
  name: string;
  tasks: T[];
  stage: ApplicationStage;
};

export type IKanban<T> = {
  tasks: Record<string, T>;
  columns: Record<string, IKanbanColumn<T>>;
  ordered: string[];
};
