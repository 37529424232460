import _ from 'lodash';
import { useState, useEffect, useCallback } from 'react';

import { ArrowBack } from '@mui/icons-material';
import { Alert, Paper, Stack, Button, Typography, CircularProgress } from '@mui/material';

import { paths } from 'src/routes/paths';
import { useParams, useRouter, useSearchParams } from 'src/routes/hooks';

import { useTranslate } from 'src/locales';
import { IntegrationProviderType } from 'src/services/integration/integrations.types';
import { useConnectIntegrationMutation } from 'src/services/integration/integrations.service';

import Logo from 'src/components/logo';

const validProviders: IntegrationProviderType[] = [
  IntegrationProviderType.JOB_ADDER
]

export default function CallbackManagerView() {

  const [connectIntegration] = useConnectIntegrationMutation();

  const { t } = useTranslate();

  const router = useRouter();
  const { provider } = useParams();
  const searchParams = useSearchParams();

  const [view, setView] = useState('loading');

  const handleConnect = useCallback(async (providerType: IntegrationProviderType, code: string, state: string = '') => {
    setView('connecting');

    try {
      const integrationResponse = await connectIntegration({
        provider: providerType,
        code,
        state
      }).unwrap();

      setView('success');

      setTimeout(() => {
        router.push(paths.dashboard.admin.integrations.detail.configuration(integrationResponse.id));
      }, 3000);
    }
    catch (error) {
      setView('error');
    }
  }, [connectIntegration, router]);

  useEffect(() => {
    const code = searchParams.get('code');
    const state = searchParams.get('state');
    if (!code) {
      setView('error');
      return;
    }

    if (!validProviders.includes(provider as IntegrationProviderType)) {
      setView('error');
      return;
    }

    handleConnect(provider as IntegrationProviderType, code, state ?? '');
  }, [provider, searchParams, handleConnect]);

  const handleGoBack = () => {
    router.push(paths.dashboard.admin.integrations.root);
  };

  const renderContent = () => {
    switch (view) {
      case 'loading':
      case 'connecting':
        return (
          <>
            <Typography variant="h6">
              {t('integrations.connect.linking', { provider: _.capitalize(provider) })}
            </Typography>
            <CircularProgress size={30} />
          </>
        );
      case 'success':
        return (
          <>
            <Alert severity="success">
              {t('integrations.connect.success', { provider: _.capitalize(provider) })}
            </Alert>
            <CircularProgress size={30} />
            <Typography variant='body2'>{t('integrations.connect.redirecting')}</Typography>
          </>
        );
      case 'error':
        return (
          <>
            <Alert severity="warning">
              {t('integrations.connect.error',)}
            </Alert>
            <Button startIcon={<ArrowBack />} variant="contained" color="primary" onClick={handleGoBack}>
              {t('Go Back')}
            </Button>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Stack
      spacing={4}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ backgroundColor: 'background.default' }}
    >
      <Paper sx={{ p: 4, borderRadius: 2, maxWidth: 400 }}>
        <Stack spacing={3} alignItems="center">
          <Logo />
          {renderContent()}
        </Stack>
      </Paper>
    </Stack>
  );
}