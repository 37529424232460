const isValidHttpUrl = (string: string | undefined, requireProtocol = false) => {
  let url;

  if (!string) return false;

  try {
    url = new URL(string.startsWith('http') || requireProtocol ? string : `https://${string}`);

    return !!url;
  } catch (_) {
    return false;
  }
};

export default isValidHttpUrl;
