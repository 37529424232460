import { forwardRef } from 'react';

import Link from '@mui/material/Link';
import Box, { BoxProps } from '@mui/material/Box';

import { RouterLink } from 'src/routes/components';

import { useSettingsContext } from '../settings';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const settings = useSettingsContext();

    // const PRIMARY_LIGHT = theme.palette.primary.light;

    // const PRIMARY_MAIN = theme.palette.primary.main;

    // const PRIMARY_DARK = theme.palette.primary.dark;

    // OR using local (public folder)
    // -------------------------------------------------------
    const logo = (
      <Box
        component="img"
        src={`/assets/icons/navbar/${settings.themeMode === 'dark' ? 'logo-white-text' : 'logo-transparent'
          }.png`}
        sx={{ width: 120, height: 40, cursor: 'pointer', ...sx }}
      />
    );

    return (
      <Box
        alignContent="flex-start"
        alignItems="flex-start"
        justifyContent="flex-start"
        pl={0}
        ml={0}
      >
        {
          disabledLink
            ? logo
            : (
              <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
                {logo}
              </Link>
            )
        }
      </Box>
    );
  }
);

export default Logo;
