import React from 'react';
import moment from 'moment';

import { Typography, TypographyProps } from '@mui/material';

interface DateDisplayProps extends Omit<TypographyProps, 'children'> {
    date: Date | string;
    format?: string;
}

const DateDisplay: React.FC<DateDisplayProps> = ({
    date,
    format = 'Do MMM YYYY hh:mm A',
    ...typographyProps
}) => {
    const formattedDate = moment(date).format(format);

    return (
        <Typography {...typographyProps}>
            {formattedDate}
        </Typography>
    );
};

export default DateDisplay