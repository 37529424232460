

import { useState } from 'react';

import { Card, useTheme, useMediaQuery } from '@mui/material';

import { useTranslate } from 'src/locales';
import { PageOptions } from 'src/services/api.types';
import { useGetOrganizationActivitiesQuery } from 'src/services/organisation/organisation.service';

import ActivityFeed from 'src/sections/activity/card/activity-feed';

// ----------------------------------------------------------------------

const DEFAULT_PAGE: PageOptions = {
  page: 1,
  per_page: 10
};

type Props = {
  organizationId: string;
}

export default function OrganizationActivityList({
  organizationId
}: Props) {

  const { t } = useTranslate();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [currentPage, setCurrentPage] = useState<PageOptions>({
    page: DEFAULT_PAGE.page,
    per_page: DEFAULT_PAGE.per_page,
  });

  const { currentData: activities } = useGetOrganizationActivitiesQuery({
    organizationId,
    page: currentPage.page,
    perPage: currentPage.per_page,
  }, {
    skip: !organizationId
  });

  return (
    <Card>
      <ActivityFeed
        activities={activities?.results ?? []}
        page={currentPage.page}
        totalItems={activities?.count ?? 0}
        perPage={currentPage.per_page}
        onPageChange={(e: React.ChangeEvent<unknown>, newPage: number) => setCurrentPage({
          ...currentPage,
          page: newPage
        })}
      />
    </Card>
  );
}
