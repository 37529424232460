import { paths } from 'src/routes/paths';

// API
// ----------------------------------------------------------------------

export const VERIFICATION_CODE_LENGTH = 7;

export const HOST_API = import.meta.env.VITE_HOST_API;
export const ASSETS_API = import.meta.env.VITE_ASSETS_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.dashboard.root; // as '/dashboard'
