

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Stack, useMediaQuery } from '@mui/system';
import { Card, useTheme, Typography, IconButton, CardContent } from '@mui/material';

import { useTranslate } from 'src/locales';
import { SystemDocument } from 'src/services/system/system.types';

import Iconify from 'src/components/iconify';
import DateDisplay from 'src/components/date/date-display';


type Props = {
  row: SystemDocument,
  onDelete: (document: SystemDocument) => void;
};

export function DocumentTableRow({ row, onDelete }: Props) {
  const { t } = useTranslate();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const viewDocument = () => {
    window.open(row.file.public_path, '_blank');
  }

  if (isMobile) {
    return (
      <Card sx={{ mx: 2, mb: 1 }}>
        <CardContent>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction="column" alignItems="start">
              <Typography variant="h5" color="text.secondary" sx={{ textTransform: 'capitalize' }}>
                {row.name}
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ textTransform: 'capitalize' }}>
                {row.target_types.join(', ')}
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ textTransform: 'capitalize' }}>
                <DateDisplay date={row.created_at} variant='body2' />
              </Typography>
            </Stack>

          </Stack>
        </CardContent>
      </Card>
    );
  }

  return (
    <TableRow>
      <TableCell>
        <Stack direction="column" alignItems="start">
          {row.name}
        </Stack>
      </TableCell>

      <TableCell>
        <Typography variant="body2" color="text.secondary" sx={{ textTransform: 'capitalize' }}>
          {row.target_types.join(', ')}
        </Typography>
      </TableCell>

      <TableCell>
        <DateDisplay date={row.created_at} variant='body2' />
      </TableCell>

      <TableCell>
        <Stack direction="row" alignItems="center" justifyContent="end">
          <IconButton onClick={viewDocument}>
            <Iconify icon="mdi:eye" />
          </IconButton>

          <IconButton onClick={() => onDelete(row)}>
            <Iconify icon="mdi:trash" />
          </IconButton>
        </Stack>
      </TableCell>

    </TableRow>
  );
}
