import { useSnackbar } from 'notistack';
import Cropper, { Area, Point } from 'react-easy-crop';
import { useState, useEffect, useCallback } from 'react';

import {
  Box,
  Stack,
  Dialog,
  Button,
  Slider,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { fData } from 'src/utils/format-number';

import { useTranslate } from 'src/locales';
import { useUploadFileMutation } from 'src/services/documents/documents.service';

import { UploadAvatar } from '../upload';
import getCroppedImageFile from './cropper-utils';

const RotationMarks = [
  { value: 0, label: '0°' },
  { value: 90, label: '90°' },
  { value: 180, label: '180°' },
  { value: 270, label: '270°' },
];

const ZoomMarks = [
  { value: 1, label: '1x' },
  { value: 2, label: '2x' },
  { value: 3, label: '3x' },
];

type Props = {
  onSubmit: (imageId: string) => void;
  existingUrl?: string;
  // fit?: 'contain' | 'horizontal-cover' | 'vertical-cover';
  maxImageFileSize?: number;
  showGrid?: boolean;
  warningMessage?: string;
};

export default function ImageCropper({
  onSubmit,
  existingUrl,
  maxImageFileSize = 3145728,
  showGrid = false,
  warningMessage,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslate();

  const showCropper = useBoolean();

  const [uploadFile] = useUploadFileMutation();

  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });

  const [zoom, setZoom] = useState<number>(1);

  const [rotation, setRotation] = useState<number>(0);

  const [url, setUrl] = useState<string>('');

  const [chosenCroppedArea, setChosenCroppedArea] = useState<Area>({
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  });

  useEffect(() => {
    if (existingUrl) setUrl(existingUrl);
  }, [existingUrl]);

  const onCropChange = (cropValue: Point) => {
    setCrop(cropValue);
  };

  const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
    setChosenCroppedArea(croppedAreaPixels);
  };

  const onZoomChange = (zoomValue: number) => {
    setZoom(zoomValue);
  };

  const onSaveImage = async () => {
    const file = await getCroppedImageFile(url, chosenCroppedArea, rotation);

    if (!file) return; // TODO: Throw error

    uploadEditedImage(file);

    showCropper.onFalse();
  };

  const uploadEditedImage = async (file: File) => {
    try {
      const uploadedFile = await uploadFile({
        file,
        type: 'image' as any,
      }).unwrap();

      onSubmit(uploadedFile.id);
    } catch (e) {
      enqueueSnackbar(t('upload.api.create.error'), { variant: 'error' });
    }
  };

  const handleDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      try {
        const uploadedFile = await uploadFile({
          file,
          type: 'image' as any,
        }).unwrap();

        setUrl(uploadedFile.public_path);

        if (file) {
          // onSubmit(uploadedFile.id);
          showCropper.onTrue();
        }
      } catch (e) {
        enqueueSnackbar(t('upload.api.create.error'), { variant: 'error' });
      }
    },
    [enqueueSnackbar, showCropper, t, uploadFile]
  );

  const zoomValueText = (value: number) => `${value}x`;

  return (
    <Stack direction="column" alignItems="center">
      <UploadAvatar
        file={url}
        maxSize={maxImageFileSize}
        onDrop={handleDrop}
        helperText={
          <Typography
            variant="caption"
            sx={{
              mt: 3,
              mx: 'auto',
              display: 'block',
              textAlign: 'center',
              color: 'text.disabled',
            }}
          >
            {warningMessage}
            <br /> {t('image_cropper.allowed_formats')}
            <br /> {t('image_cropper.max_size', { size: fData(maxImageFileSize) })}
          </Typography>
        }
      />

      {showCropper.value && (
        <Dialog fullWidth maxWidth="xs" open={showCropper.value} onClose={showCropper.onFalse}>
          <DialogTitle>{t('image_cropper.title')}</DialogTitle>
          <DialogContent sx={{ p: 0 }}>
            <Box sx={{ minHeight: 140, py: 6, px: 2, overflow: 'hidden', position: 'relative' }}>
              <Cropper
                image={url}
                crop={crop}
                zoom={zoom}
                minZoom={1}
                maxZoom={3}
                aspect={1}
                rotation={rotation}
                onRotationChange={(rotationValue) => setRotation(rotationValue)}
                cropShape="round"
                onCropChange={onCropChange}
                onCropComplete={onCropComplete}
                onZoomChange={onZoomChange}
                showGrid={showGrid}
              />
            </Box>
            <Box sx={{ pt: 2, px: 2 }}>
              <Stack direction="row" columnGap={2}>
                <Box sx={{ flexGrow: 1, p: 1 }}>
                  <Typography variant="body2">{t('image_cropper.zoom')}</Typography>
                  <Slider
                    value={zoom}
                    min={1}
                    max={3}
                    step={0.1}
                    marks={ZoomMarks}
                    onChange={(event: any, value: number | number[]) => setZoom(value as number)}
                    aria-label="Zoom Slider"
                    valueLabelDisplay="auto"
                    getAriaValueText={zoomValueText}
                  />
                </Box>

                <Box sx={{ flexGrow: 1, p: 1 }}>
                  <Typography variant="body2">{t('image_cropper.rotation')}</Typography>
                  <Slider
                    value={rotation}
                    onChange={(event: any, value: number | number[]) =>
                      setRotation(value as number)
                    }
                    min={0}
                    max={360}
                    step={1}
                    marks={RotationMarks}
                    aria-label="Rotation Slider"
                    valueLabelDisplay="auto"
                  />
                </Box>
              </Stack>
            </Box>
          </DialogContent>

          <DialogActions>
            <Button variant="outlined" color="inherit" onClick={showCropper.onFalse}>
              {t('common.cancel')}
            </Button>
            <Button variant="contained" color="primary" onClick={onSaveImage}>
              {t('common.save')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Stack>
  );
}
