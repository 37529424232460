import { useCallback } from 'react';
import { useSnackbar } from 'notistack';

import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Stack, { StackProps } from '@mui/material/Stack';
import { Icon, darken, Skeleton, useTheme } from '@mui/material';

import { maskEmail } from 'src/utils/text.utils';

import { useTranslate } from 'src/locales';
import LinkLogo from 'src/assets/icons/link-logo.svg';
import { PaymentMethod, PaymentMethodType } from 'src/services/billing/billing.types';
import {
  useRemovePaymentMethodMutation,
  useSetDefaultPaymentMethodMutation,
} from 'src/services/billing/billing.services';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

// ----------------------------------------------------------------------

type PaymentItemProps = StackProps & {
  card: PaymentMethod;
  onClick?: (card: PaymentMethod) => void;
  hideControls?: boolean;
};

export default function PaymentCardItem({
  card,
  sx,
  onClick,
  hideControls,
  ...other
}: PaymentItemProps) {
  const popover = usePopover();

  const theme = useTheme();

  const { t } = useTranslate();

  const { enqueueSnackbar } = useSnackbar();

  const [removeCard] = useRemovePaymentMethodMutation();

  const [setDefault] = useSetDefaultPaymentMethodMutation();

  const onRemoveCard = useCallback(async () => {
    try {
      await removeCard(card.id).unwrap();

      enqueueSnackbar(t('billing.api.card.remove.success'), { variant: 'success' });
    } catch (e) {
      console.error(e);

      enqueueSnackbar(t('billing.api.card.remove.default_error'), { variant: 'error' });
    }
    popover.onClose();
  }, [card.id, enqueueSnackbar, popover, removeCard, t]);

  const onSetDefaultCard = useCallback(async () => {
    try {
      setDefault(card.id);
    } catch (e) {
      console.error(e);
    }
    popover.onClose();
  }, [card.id, popover, setDefault]);

  return (
    <>
      <Stack
        spacing={1}
        component={Paper}
        variant="outlined"
        sx={{
          p: 2.5,
          width: 1,
          position: 'relative',
          ...{
            transition: 'all 0.3s ease-in-out',
            ':hover': onClick
              ? {
                cursor: 'pointer',
                backgroundColor: darken(theme.palette.background.default, 0.05),
              }
              : undefined,
          },
          ...sx,
        }}
        onClick={() => (onClick ? onClick(card) : undefined)}
        {...other}
      >
        {
          card.type === PaymentMethodType.CARD && (
            <>

              <Stack direction="row" alignItems="center" spacing={1}>
                <Iconify
                  icon={(card.metadata.brand === 'visa' && 'logos:visa') || 'logos:mastercard'}
                  width={36}
                />

                {card.default && <Label color="info">{t('common.default')}</Label>}
              </Stack>
              <Typography variant="subtitle2">**** **** **** {card.metadata.last_4}</Typography>
            </>
          )
        }

        {
          card.type === PaymentMethodType.LINK && (
            <>

              <Stack direction="row" alignItems="center" spacing={1}>
                <Icon sx={{ width: 48, height: 36 }} >
                  <img alt="link-logo" width="100%" height="100%" src={LinkLogo} />
                </Icon>

                {card.default && <Label color="info">{t('common.default')}</Label>}
              </Stack>
              <Typography variant="subtitle2">{maskEmail(card.metadata.email ?? '')}</Typography>
            </>
          )
        }

        {!hideControls && (
          <IconButton
            onClick={popover.onOpen}
            sx={{
              top: 8,
              right: 8,
              position: 'absolute',
            }}
          >
            <Iconify icon="eva:more-horizontal-fill" />
          </IconButton>
        )}
      </Stack >

      <CustomPopover open={popover.open} onClose={popover.onClose}>
        <MenuItem onClick={onSetDefaultCard}>
          <Iconify icon="eva:star-fill" />
          {t('billing.payment.set_primary_card')}
        </MenuItem>

        <MenuItem onClick={onRemoveCard} sx={{ color: 'error.main' }}>
          <Iconify icon="solar:trash-bin-trash-bold" />
          {t('common.delete')}
        </MenuItem>
      </CustomPopover>
    </>
  );
}

export function LoadingCardItem({ sx, ...other }: StackProps) {
  return (
    <Stack
      spacing={1}
      component={Paper}
      variant="outlined"
      sx={{
        p: 2.5,
        width: 1,
        position: 'relative',
        ...sx,
      }}
      {...other}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <Skeleton variant="rectangular" width={36} height={24} />
      </Stack>

      <Skeleton variant="text" sx={{ fontSize: '1rem', width: '70%' }} />
    </Stack>
  );
}

type NewCardItemProps = StackProps & {
  onClick: VoidFunction;
};

export function NewCardItem({ sx, onClick, ...other }: NewCardItemProps) {
  const theme = useTheme();

  return (
    <Stack
      spacing={1}
      component={Paper}
      variant="outlined"
      onClick={onClick}
      sx={{
        p: 2.5,
        width: 1,
        position: 'relative',
        backgroundColor: darken(theme.palette.background.default, 0.05),
        transition: 'all 0.3s ease-in-out',
        ':hover': {
          cursor: 'pointer',
          backgroundColor: darken(theme.palette.background.default, 0.1),
        },
        minHeight: 100,
        ...sx,
      }}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      {...other}
    >
      <Stack
        direction="row"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{ padding: 0 }}
      >
        <Stack>
          <Typography sx={{ color: theme.palette.grey[600] }}>Add Card</Typography>
        </Stack>
        <Stack>
          <Iconify sx={{ color: theme.palette.grey[600] }} icon="eva:plus-fill" />
        </Stack>
      </Stack>
    </Stack>
  );
}
