import { useSnackbar } from 'notistack';
import parse from 'autosuggest-highlight/parse';
import { Controller, useFormContext } from 'react-hook-form';
import { useRef, useMemo, useState, useEffect } from 'react';

import TextField from '@mui/material/TextField';
import { Box, Grid, debounce, Typography } from '@mui/material';
// import LocationOnIcon from '@mui/icons-material/LocationOn';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';

import { PreferredLocation } from 'src/services/candidates/candidates.types';

const isPreferredLocation = (value: PlaceType | PreferredLocation): value is PreferredLocation =>
  !(value as PlaceType).description;

const convertPlaceToLocation = (location: PlaceType | PreferredLocation): PreferredLocation => {
  if (isPreferredLocation(location)) {
    return location as PreferredLocation;
  }

  return {
    place_id: location.place_id,
    address: location.description,
    latitude: location.geometry?.lat() ?? 0,
    longitude: location.geometry?.lng() ?? 0,
  };
};

// ----------------------------------------------------------------------

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}
export interface PlaceType {
  address?: string;
  description: string;
  structured_formatting?: StructuredFormatting;
  place_id: string;
  geometry?: {
    lat: () => number;
    lng: () => number;
  };
}

interface Props<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> extends AutocompleteProps<PlaceType, Multiple, DisableClearable, FreeSolo> {
  name: string;
  label?: string;
  placeholder?: string;
  helperText?: React.ReactNode;
}

export default function RHFMapsAutocomplete<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>({
  name,
  label,
  helperText,
  placeholder,
  ...other
}: Omit<Props<Multiple, DisableClearable, FreeSolo>, 'renderInput'>) {
  const { control, setValue: setFormValue } = useFormContext();

  const [inputValue, setInputValue] = useState<string>('');

  const [options, setOptions] = useState<readonly PlaceType[]>([]);

  const loaded = useRef(false);

  const { enqueueSnackbar } = useSnackbar();

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${
          import.meta.env.VITE_MAPS_API_KEY
        }&libraries=places&loading=async`,
        document.querySelector('head'),
        'google-maps'
      );
    }

    loaded.current = true;
  }

  const fetch = useMemo(
    () =>
      debounce(
        (
          request: {
            input: string;
          },
          callback: (results?: readonly PlaceType[]) => void
        ) => {
          (autocompleteService.current as any).getPlacePredictions(request, callback);
        },
        400
      ),
    []
  );

  useEffect(() => {
    let active = true;

    if (
      !autocompleteService.current &&
      !!(window as any)?.google?.maps?.places?.AutocompleteService
    ) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService({
        types: ['(regions)'],
        fields: ['geometry'],
      });
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetch]);

  const onSelectAddress = async (places: PreferredLocation[]) => {
    const geocoder = new (window as any).google.maps.Geocoder();

    const geoCodedPlaces = await Promise.all(
      places.map(async (place) => {
        try {
          if (place.place_id) {
            const { results } = await geocoder.geocode({ placeId: place.place_id });

            return {
              ...place,
              latitude: results?.[0]?.geometry?.location?.lat(),
              longitude: results?.[0]?.geometry?.location?.lng(),
            };
          }

          return place;
        } catch (e) {
          enqueueSnackbar('Error getting Geocode data from Google.', { variant: 'error' });

          return place;
        }
      })
    );

    setFormValue(name, geoCodedPlaces.length ? geoCodedPlaces : [], { shouldDirty: true });
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Autocomplete
          {...field}
          sx={{ width: '100%' }}
          id={`autocomplete-${name}`}
          autoHighlight={false}
          options={options}
          multiple
          disabled={other.disabled}
          onChange={(_event, newValue) => {
            const locations = newValue.map((value) => convertPlaceToLocation(value));
            onSelectAddress(locations);
          }}
          onInputChange={(_event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          getOptionLabel={(option) =>
            // eslint-disable-next-line no-nested-ternary
            option?.description ?? option?.address
          }
          isOptionEqualToValue={(option, value) => option.place_id === value.place_id}
          renderOption={(props, option) => {
            const matches = option.structured_formatting.main_text_matched_substrings;

            const parts = parse(
              option.structured_formatting.main_text,
              matches.map((match: any) => [match.offset, match.offset + match.length])
            );

            return (
              <li {...props}>
                <Grid container alignItems="center">
                  <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                    {parts.map((part, index) => (
                      <Box
                        key={index}
                        component="span"
                        sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                      >
                        {part.text}
                      </Box>
                    ))}
                    <Typography variant="body2" color="text.secondary">
                      {option.structured_formatting.secondary_text}
                    </Typography>
                  </Grid>
                </Grid>
              </li>
            );
          }}
          renderInput={(params) => <TextField {...params} label="Add a location" fullWidth />}
        />
      )}
    />
  );
}
