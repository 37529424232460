import { Stack } from '@mui/system';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import { Card, Link, useTheme, Typography, CardContent, useMediaQuery } from '@mui/material';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
import { RouterLink } from 'src/routes/components';

import { useTranslate } from 'src/locales';
import { Department } from 'src/services/organisation/organisation.types';

import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

// ----------------------------------------------------------------------
type Props = {
  // onEditRow: VoidFunction;
  row: Department;
};

export default function DepartmentTableRow({
  row
}: Props) {
  
  const popover = usePopover();

  const { t } = useTranslate();

  const router = useRouter();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const viewDetail = () => {
    router.push(`${paths.dashboard.admin.organization.department}/${row.id}`);
  };

  if (isMobile) {
    return (
      <Card sx={{ mx: 2, mb: 1 }}>
        <CardContent>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography
              component={RouterLink}
              href={`${paths.dashboard.admin.organization.department}/${row.id}`}
              variant="h6"
              color="inherit"
              noWrap
              sx={{ textDecoration: 'none' }}
            >
              {row.name}
            </Typography>
            <Stack direction="row">
              <IconButton color={popover.open ? 'primary' : 'default'} onClick={popover.onOpen}>
                <Iconify icon="eva:more-horizontal-fill" />
              </IconButton>
            </Stack>
          </Stack>

          <Typography variant="body2" color="text.secondary" noWrap gutterBottom>
            {row.description}
          </Typography>

          <Typography variant="body2" color="text.secondary" noWrap>
            Location: {row.location}
          </Typography>
        </CardContent>

        <CustomPopover
          open={popover.open}
          onClose={popover.onClose}
          arrow="right-top"
          sx={{ width: 140 }}
        >
          <MenuItem
            onClick={() => {
              popover.onClose();
              viewDetail();
            }}
          >
            <Iconify icon="solar:eye-bold" />
            {t('common.view')}
          </MenuItem>
        </CustomPopover>

      </Card>
    );
  }

  return (
    <>
      <TableRow hover>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <Link
            component={RouterLink}
            noWrap
            color="inherit"
            variant="subtitle2"
            href={`${paths.dashboard.admin.organization.department}/${row.id}`}
          >
            {row.name}
          </Link>
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>{row.description}</TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>{row.location}</TableCell>

        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
            <Iconify icon="eva:more-horizontal-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            popover.onClose();
            viewDetail();
          }}
        >
          <Iconify icon="solar:eye-bold" />
          {t('common.view')}
        </MenuItem>

      </CustomPopover>
    </>
  );
}
