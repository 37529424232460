import { Alert, AlertTitle } from "@mui/material";

import { useTranslate } from "src/locales";
import { useOrgTenant } from "src/auth/hooks/useOrgTenant";
import { WithdrawalReason, WithdrawalContext } from "src/services/applications/applications.types";



type Props = {
  context: WithdrawalContext;
};

export default function WithdrawalContextAlert({ context }: Props) {

  const { t } = useTranslate();

  const tenantType = useOrgTenant();

  const getMessage = () => {

    if (!context) {
      return t(`applications.detail.withdrawal.candidate_action_${tenantType}`);
    }
    
    switch (context.reason) {
      case WithdrawalReason.APPLICATION_CONFLICT:
        return t(`applications.detail.withdrawal.application_conflict_${tenantType}`);
      case WithdrawalReason.CANDIDATE_ACTION:
        return t(`applications.detail.withdrawal.candidate_action_${tenantType}`);
      default:
        return t(`applications.detail.withdrawal.candidate_action_${tenantType}`);
    }
  }

  return (
    <Alert severity="warning" sx={{ mb: 2 }}>
      <AlertTitle>
        {t('applications.detail.withdrawal.title')}
      </AlertTitle>
      {getMessage()}
    </Alert>
  )
}