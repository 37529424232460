// eslint-disable-next-line import/no-extraneous-dependencies
import * as humanizeDuration from 'humanize-duration';

// Create a humanizer instance
const humanizer = humanizeDuration.humanizer({
  language: navigator.language.slice(0, 2),
  units: ['h', 'm'],
});

export default humanizer;
