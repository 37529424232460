import { HttpStatusCode } from 'axios';
import { debounce, isNumber } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { useSearchParams } from 'react-router-dom';
import { useMemo, useState, useEffect, MouseEvent, ChangeEvent, useCallback } from 'react';

import { Box } from '@mui/system';
import { Table, useTheme, TableBody, useMediaQuery, TableContainer } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import i18n from 'src/locales/i18n';
import { useTranslate } from 'src/locales';
import { PageOptions } from 'src/services/api.types';
import { authApi } from 'src/services/auth/auth.service';
import { CompanyDomain } from 'src/services/organisation/organisation.types';
import {
  useLazyGetCompanyDomainsQuery
} from 'src/services/organisation/organisation.service';

import Scrollbar from 'src/components/scrollbar';
import {
  useTable,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/table';

import AddDomainForm from 'src/sections/admin/organization/domains/domains-add-form';

import DomainsTableRow from '../domains-table-row';
import DomainsTableToolbar from '../domains-table-toolbar';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'domain', label: 'Domain' },
  { id: 'status', label: 'Status', width: 220 },
  // { id: 'controls', width: 120 },
];

const DEFAULT_PAGE: PageOptions = {
  page: 1,
  per_page: 5,
};

// ----------------------------------------------------------------------\

const getDomainsQueryError = (e: any) => {
  if (e.status) {
    if (+e.status === HttpStatusCode.Unauthorized || +e.status === HttpStatusCode.Forbidden) {
      return i18n.t('api.unauthorized');
    }
  }

  return i18n.t('admin-organization.domains.api.get.default_error');
};

export default function DomainsListView() {
  const [searchParams, setSearchParams] = useSearchParams();

  const table = useTable();

  const { t } = useTranslate();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const page = searchParams.get('page');
  const per_page = searchParams.get('per_page');

  const [currentPage, setCurrentPage] = useState<PageOptions>({
    page: page && isNumber(+page) ? +page : DEFAULT_PAGE.page,
    per_page: per_page && isNumber(+per_page) ? +per_page : DEFAULT_PAGE.per_page,
  });

  const [filterQuery, setFilterQuery] = useState<string>('');

  const [totalRows, setTotalRows] = useState<number>(0);

  const authResult = authApi.endpoints.getAuthContext.useQueryState();
  const { data: authData } = authResult;

  const [fetchDomains, { isFetching, currentData }] = useLazyGetCompanyDomainsQuery();

  useEffect(() => {
    if (isFetching) return;

    setTableData(currentData?.results ?? []);
    setTotalRows(currentData?.count ?? 0);
  }, [currentData, isFetching]);

  const openCreateForm = useBoolean();

  const getDomainsData = useCallback(async () => {
    if (!authData) return;

    try {
      await fetchDomains({
        page: currentPage,
        org_id: authData.organization.id,
        search_query: filterQuery,
      }).unwrap();
    } catch (e) {
      enqueueSnackbar(getDomainsQueryError(e), { variant: 'error' });
    }
  }, [currentPage, authData, fetchDomains, filterQuery]);

  useEffect(() => {
    if (!authData) return;

    getDomainsData();
  }, [authData, getDomainsData]);

  const denseHeight = table.dense ? 56 : 56 + 20;

  const canReset = !!filterQuery.length;

  const [tableData, setTableData] = useState<CompanyDomain[]>([]);

  const notFound = (!tableData.length && canReset) || !tableData.length;

  const handleFilters = useMemo(
    () => debounce((value: string) => {
      setFilterQuery(value);
      getDomainsData();
    }, 500),
    [setFilterQuery, getDomainsData]
  );

  const onChangePage = useCallback(
    (_event: MouseEvent<HTMLButtonElement, MouseEvent>, pageNumber: number) => {
      setCurrentPage({
        ...currentPage,
        page: pageNumber + 1,
      });

      setSearchParams(
        {
          page: (pageNumber + 1).toString(),
          per_page: currentPage.per_page.toString(),
        },
        {
          replace: false,
        }
      );

      getDomainsData();
    },
    [currentPage, getDomainsData, setSearchParams]
  );

  const onChangePageRows = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const new_per_page = +(event.target as HTMLInputElement).value;
      setCurrentPage({
        ...DEFAULT_PAGE,
        per_page: new_per_page,
      });
      setSearchParams(
        {
          page: currentPage.page.toString(),
          per_page: new_per_page.toString(),
        },
        {
          replace: false,
        }
      );

      getDomainsData();
    },
    [currentPage.page, getDomainsData, setSearchParams]
  );

  return (
    <Box>
      <DomainsTableToolbar
        onOpenCreateForm={openCreateForm.onTrue}
        filterQuery={filterQuery}
        onFilters={handleFilters}
      />

      <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
        <Scrollbar>
          <Table size={table.dense ? 'small' : 'medium'}>
            {
              !isMobile && (
                <TableHeadCustom
                  order={table.order}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={table.selected.length}
                />
              )
            }

            <TableBody>
              {tableData.map((row) => (
                <DomainsTableRow key={row.id} row={row} />
              ))}

              <TableEmptyRows
                height={denseHeight}
                emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
              />

              <TableNoData notFound={notFound} />
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>

      <TablePaginationCustom
        count={totalRows}
        page={currentPage.page}
        rowsPerPage={currentPage.per_page}
        onPageChange={onChangePage as any}
        onRowsPerPageChange={onChangePageRows}
        dense={table.dense}
        onChangeDense={table.onChangeDense}
      />

      <AddDomainForm open={openCreateForm.value} onClose={openCreateForm.onFalse} />
    </Box>
  );
}
