import { useMemo } from 'react';
import { Outlet } from 'react-router';
import { Helmet } from 'react-helmet-async';

import { Stack } from '@mui/material';
import Container from '@mui/material/Container';

import { paths } from 'src/routes/paths';
import { useParams } from 'src/routes/hooks';

import { useTranslate } from 'src/locales';

import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';

// ----------------------------------------------------------------------

export default function CandidatesPage() {
  const settings = useSettingsContext();

  const { t } = useTranslate();

  const { candidate_id } = useParams();

  const viewingDetails = useMemo(() => !!candidate_id, [candidate_id]);

  return (
    <>
      <Helmet>
        <title>Dashboard: Candidates</title>
      </Helmet>

      <Container
        maxWidth={settings.themeStretch ? false : 'lg'}
        sx={{
          height: 1,
        }}
      >
        <Stack spacing={2}>
          <CustomBreadcrumbs
            heading={t(`candidates.title`)}
            links={[
              { name: t('common.dashboard'), href: paths.dashboard.root },
              {
                name: t(`candidates.title`),
                href: `${paths.dashboard.candidates.root}`
              },
              ...(viewingDetails
                ? [
                  {
                    name: t('common.details'),
                  },
                ]
                : []),
            ]}
          />
          <Outlet />
        </Stack>
      </Container>
    </>
  );
}
