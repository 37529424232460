import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import TableCell from '@mui/material/TableCell';
import { useTheme, useMediaQuery } from '@mui/system';
import TableRow, { TableRowProps } from '@mui/material/TableRow';

// ----------------------------------------------------------------------

function TableRowSkeleton() {

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  return (
    <Stack spacing={3} direction="row" alignItems="center">
      <Skeleton sx={{ borderRadius: 1.5, width: 48, height: 48, flexShrink: 0 }} />
      <Skeleton sx={{ width: 200, height: 15 }} />
      <Skeleton sx={{ width: 120, height: 15 }} />

      {
        !isMobile && (
          <>
            <Skeleton sx={{ width: 120, height: 15 }} />
            <Skeleton sx={{ width: 120, height: 15 }} />

            {
              !isTablet && (
                <>
                  <Skeleton sx={{ width: 120, height: 15 }} />
                  <Skeleton sx={{ width: 240, height: 15 }} />
                </>
              )
            }
          </>
        )
      }

    </Stack>
  )
}

export default function TableSkeleton({ ...other }: TableRowProps) {

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  return (
    <TableRow {...other}>
      <TableCell colSpan={15}>
        <Stack gap={3} direction="column" alignItems="center">

          {
            Array.from({ length: 5 }).map((_, index) => (
              <TableRowSkeleton key={index} />
            ))
          }

        </Stack>
      </TableCell>
    </TableRow>
  );
}
