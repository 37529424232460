import { IJob, JobFilters } from 'src/services/jobs/jobs.types';

import { TenantType } from 'src/types/enums';

import { rootApi } from '../rootApi';
import { RestrictedAccountProfile } from '../account/account.types';
import { APIResponse, PageOptions, PaginatedResponse } from '../api.types';
import { AdminOrganization, RestrictedOrganizationSettings } from './admin.types';

const adminService = rootApi.injectEndpoints({
  endpoints: (build) => ({
    // organizations
    getAllOrganizations: build.query<PaginatedResponse<AdminOrganization>,PageOptions & { search_query?: string, tenant_type?: TenantType }>({
      query: (params) => ({
        url: '/system/organizations',
        params,
      }),
      providesTags: (_result, _error, arg) => [
        { type: 'GlobalOrganizations' },
      ]
    }),

    getOrganization: build.query<AdminOrganization,{ id: string }>({
      query: (params) => ({
        url: `/system/organizations/${params.id}`,
        method: 'GET',
      }),
      providesTags: (_result, _error, arg) => [
        { type: 'GlobalOrganizations', id: arg.id },
      ]
    }),
    updateRestrictedOrganizationSettings: build.mutation<APIResponse, { id: string, data: RestrictedOrganizationSettings }>({
      query: ({ id, data }) => ({
        url: `/system/organizations/${id}/settings`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: 'GlobalOrganizations', id: arg.id },
      ]
    }),

    // accounts
    getAllAccounts: build.query<PaginatedResponse<RestrictedAccountProfile>,PageOptions & { search_query?: string }>({
      query: (params) => ({
        url: '/system/accounts',
        params,
      }),
      providesTags: (_result, _error, arg) => [
        { type: 'GlobalAccounts' },
      ]
    }),

    getAccountsByOrganization: build.query<PaginatedResponse<RestrictedAccountProfile>,{ params: PageOptions; orgId: string }>({
      query: ({ params, orgId }) => ({
        url: `/system/organizations/${orgId}/accounts`,
        params,
      }),
      providesTags: (_result, _error, arg) => [
        { type: 'GlobalAccounts', id: arg.orgId },
      ]
    }),

    // jobs
    getAllJobs: build.query<PaginatedResponse<IJob>, PageOptions & { search_query?: string }>({
      query: (params) => ({
        url: '/system/jobs',
        params,
      })
    }),

    getJobsByOrganization: build.query<PaginatedResponse<IJob>, { params: JobFilters & PageOptions; orgId: string }>({
      query: ({ params, orgId }) => ({
        url: `/system/organizations/${orgId}/jobs`,
        params,
      })
    }),

  })
});

export const {
  useGetAllOrganizationsQuery,
  useGetOrganizationQuery,
  useUpdateRestrictedOrganizationSettingsMutation,

  useGetAllAccountsQuery,
  useGetAccountsByOrganizationQuery,

  useGetAllJobsQuery,
  useGetJobsByOrganizationQuery,
} = adminService;
