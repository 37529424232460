import {
  Card,
  Stack,
  useTheme,
  CardProps,
  Typography,
  IconButton,
  CardContent,
} from '@mui/material';

import Iconify from '../iconify';

type Props = {
  title: string;
  subtitle?: string;
  onDeleteFile: VoidFunction;
  uploading: boolean;
} & CardProps;

export default function UploadItemCard({
  title,
  uploading,
  subtitle,
  onDeleteFile,
  ...props
}: Props) {
  const theme = useTheme();

  return (
    <Card variant="outlined" {...props}>
      <CardContent>
        <Stack flexDirection="row" justifyContent="space-between">
          <Stack>
            <Typography fontSize={16} fontWeight={500}>
              {title}
            </Typography>
            <Typography variant="subtitle2">{subtitle}</Typography>
          </Stack>

          <Stack>
            {uploading ? (
              <Iconify
                icon="line-md:loading-twotone-loop"
                height="3em"
                color={theme.palette.primary.main}
              />
            ) : (
              <IconButton size="small" onClick={onDeleteFile}>
                <Iconify
                  icon="mingcute:close-circle-fill"
                  height="2em"
                  color={theme.palette.error.main}
                />
              </IconButton>
            )}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
