export const maskEmail = (email: string) => {
  if (!email) return '';

  const [username, domain] = email.split('@');

  // For very short usernames, keep first and last character only
  if (username.length <= 3) {
    const maskedUsername = `${username[0]}*${username[username.length - 1]}`;
    return `${maskedUsername}@${domain}`;
  }

  // For longer usernames, keep first 2 and last 2 characters
  const firstPart = username.slice(0, 2);
  const lastPart = username.slice(-2);
  const middlePart = '*'.repeat(Math.min(username.length - 4, 5)); // Max 5 asterisks

  return `${firstPart}${middlePart}${lastPart}@${domain}`;
};