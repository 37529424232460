import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import React, { useMemo, useState, useEffect, useCallback } from 'react';

import { Stack } from '@mui/system';
import {
  Box,
  Tab,
  Card,
  Grid,
  Tabs,
  Chip,
  Avatar,
  Typography,
  CardContent
} from '@mui/material';

import { useParams } from 'src/routes/hooks';

import { PageOptions } from 'src/services/api.types';
import { useGetCandidateProfileByOrganizationQuery } from 'src/services/candidates/candidates.service';
import {
  useGetOrganizationQuery,
  useGetAccountsByOrganizationQuery,
} from 'src/services/admin/admin.service';

import ProfileUser from 'src/sections/profile/profile-sections/profile-user';
import GlobalJobsView from 'src/sections/restricted/jobs/table/global-jobs-view';
import SettingsCard from 'src/sections/restricted/organizations/settings/SettingsCard';
import CandidateProfileEditor from 'src/sections/profile/profile-sections/profile-editor';
import SubscriptionCard from 'src/sections/restricted/organizations/billing/subscription-card';
import ConnectionsView from 'src/sections/restricted/organizations/connections/connections-view';
import { OrganizationStatusLabel } from 'src/sections/admin/organization/organization-status-label';
import InvoicesTable from 'src/sections/restricted/organizations/billing/invoices/table/invoices-table';
import DepartmentsTable from 'src/sections/restricted/organizations/departments/table/departments-table';
import OrganizationActivityList from 'src/sections/restricted/organizations/activities/organization-activity-list';

import { TenantType } from 'src/types/enums';

import GlobalAccountsTable from '../accounts/table/global-accounts-table';

const default_page: PageOptions = {
  page: 1,
  per_page: 20,
};

interface TabPanelProps {
  children?: React.ReactNode;
  identifier: string;
  value: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, identifier, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== identifier}
      id={`simple-tabpanel-${identifier}`}
      aria-labelledby={`simple-tab-${identifier}`}
      {...other}
    >
      {value === identifier && (
        <Box sx={{ py: 3, px: 1 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default function GlobalOrganizationDetail() {

  const { t } = useTranslation();
  const { org_id } = useParams();
  const [pageOptions, setPageOptions] = useState<PageOptions>(default_page);
  const [tabValue, setTabValue] = useState<string>("");

  const [searchParams, setSearchParams] = useSearchParams();

  const { data: organization } = useGetOrganizationQuery(
    {
      id: org_id as string,
    },
    { skip: !org_id }
  );

  const { data: orgAccounts } = useGetAccountsByOrganizationQuery(
    {
      orgId: org_id as string,
      params: {
        ...pageOptions,
      },
    },
    { skip: !org_id }
  );

  const { data: candidateProfile, isLoading: candidateProfileLoading } = useGetCandidateProfileByOrganizationQuery(
    org_id as string,
    { skip: !org_id || organization?.type !== TenantType.Candidate }
  );

  const isCandidate = useMemo(() => organization?.type === TenantType.Candidate, [organization]);

  useEffect(() => {
    if (!tabValue && organization) {
      const queryTab = searchParams.get('tab');
      let defaultTab = organization?.type === TenantType.Candidate ? "candidate_profile" : "accounts";
      if (queryTab) {
        defaultTab = queryTab;
      }

      setTabValue(defaultTab);

      setSearchParams(
        {
          tab: defaultTab
        },
        {
          replace: false,
        }
      )
    }
  }, [searchParams, isCandidate, setSearchParams, tabValue, organization]);

  const onChangePage = (_event: any, pageNumber: number) => {
    setPageOptions((prev) => ({ ...prev, page: pageNumber }));
  };

  const onChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPageOptions((prev) => ({ ...prev, per_page: parseInt(event.target.value, 10) }));
  };

  const handleTabChange = useCallback((_event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);

    setSearchParams(
      {
        tab: (newValue).toString()
      },
      {
        replace: false,
      }
    );

  }, [setSearchParams]);

  const avatarText = useMemo(() => {
    if (organization) {
      if (isCandidate) {
        if (organization.candidate_profile?.first_name) {
          return organization.candidate_profile?.first_name[0];
        }
      }
      else if (organization.company_profile?.company_name) {
        return organization.company_profile?.company_name[0];
      }

    }

    return '';
  }, [isCandidate, organization]);

  if (!organization) {
    return null;
  }

  const renderTabs = () => {
    if (isCandidate) {
      return (
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="organization tabs">
          <Tab label={t('restricted.organizations.detail.tabs.candidate_profile')} value="candidate_profile" key="candidate_profile" />
          <Tab label={t('restricted.organizations.detail.tabs.jobs')} value="jobs" key="jobs" />
          <Tab label={t('restricted.organizations.detail.tabs.billing')} value="billing" key="billing" />
          <Tab label={t('restricted.organizations.detail.tabs.activities')} value="activities" key="activities" />
        </Tabs>
      );
    }

    return (
      <Tabs value={tabValue} onChange={handleTabChange} aria-label="organization tabs">
        <Tab label={t('restricted.organizations.detail.tabs.accounts')} value="accounts" key="accounts" />
        <Tab label={t('restricted.organizations.detail.tabs.connections')} value="connections" key="connections" />
        <Tab label={t('restricted.organizations.detail.tabs.departments')} value="departments" key="departments" />
        <Tab label={t('restricted.organizations.detail.tabs.jobs')} value="jobs" key="jobs" />
        <Tab label={t('restricted.organizations.detail.tabs.billing')} value="billing" key="billing" />
        <Tab label={t('restricted.organizations.detail.tabs.activities')} value="activities" key="activities" />
        <Tab label={t('restricted.organizations.detail.tabs.settings')} value="settings" key="settings" />
      </Tabs>
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>

        {
          isCandidate ? (
            <ProfileUser
              profile={candidateProfile}
              isLoading={candidateProfileLoading}
              mode="view"
            />
          ) : (
            <Card>
              <CardContent>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <Avatar
                      src={organization.company_profile?.company_logo?.public_path || ''}
                      alt={organization.company_profile?.company_name || 'profile-avatar'}
                      sx={{ width: 64, height: 64 }}
                    >
                      {avatarText}
                    </Avatar>
                  </Grid>
                  <Grid item xs>
                    <Stack direction="row" spacing={1} justifyContent="start" alignItems="center">
                      <Typography variant="h5">
                        {organization.company_profile?.company_name || ''}
                      </Typography>
                      <Chip
                        label={_.capitalize(organization.type)}
                        variant="outlined"
                        color="primary"
                        size='small'
                      />
                    </Stack>
                    <Typography variant="body2" color="textSecondary">
                      {organization.id}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <OrganizationStatusLabel status={organization.status} />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )
        }

      </Grid>

      <Grid item xs={12}>

        <Box mt={2} px={3}>
          {renderTabs()}
        </Box>

        {isCandidate ? (
          <>
            <TabPanel value={tabValue} identifier="candidate_profile">

              <CandidateProfileEditor
                profile={candidateProfile}
                isLoading={candidateProfileLoading}
                mode="view"
              />

            </TabPanel>

            <TabPanel value={tabValue} identifier="jobs">

              <GlobalJobsView organizationId={org_id as string} />
            </TabPanel>

            <TabPanel value={tabValue} identifier="billing">
              <Stack spacing={4}>
                <Box>
                  <Typography variant='h5' sx={{ px: 1 }}>
                    {t('billing.title')}
                  </Typography>

                  <SubscriptionCard organizationId={org_id as string} />
                </Box>

                <Box>
                  <Typography variant='h5' sx={{ px: 1 }}>
                    {t('restricted.organizations.detail.billing.invoices')}
                  </Typography>

                  <InvoicesTable organizationId={org_id as string} />
                </Box>
              </Stack>
            </TabPanel>

            <TabPanel value={tabValue} identifier="activities">

              <OrganizationActivityList organizationId={org_id as string} />
            </TabPanel>

          </>
        ) : (
          <>

            <TabPanel value={tabValue} identifier="accounts">
              <GlobalAccountsTable
                accounts={orgAccounts}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                currentPage={pageOptions}
              />

            </TabPanel>

            <TabPanel value={tabValue} identifier="settings">

              <SettingsCard organizationId={org_id as string} />
            </TabPanel>


            <TabPanel value={tabValue} identifier="connections">
              <ConnectionsView organizationId={org_id as string} />
            </TabPanel>

            <TabPanel value={tabValue} identifier="departments">

              <DepartmentsTable organizationId={org_id as string} />

            </TabPanel>

            <TabPanel value={tabValue} identifier="jobs">

              <GlobalJobsView organizationId={org_id as string} />

            </TabPanel>

            <TabPanel value={tabValue} identifier="billing">
              <Stack spacing={4}>
                <Box>
                  <Typography variant='h5' sx={{ px: 1 }}>
                    {t('billing.title')}
                  </Typography>

                  <SubscriptionCard organizationId={org_id as string} />
                </Box>

                <Box>
                  <Typography variant='h5' sx={{ px: 1 }}>
                    {t('restricted.organizations.detail.billing.invoices')}
                  </Typography>

                  <InvoicesTable organizationId={org_id as string} />
                </Box>
              </Stack>
            </TabPanel>

            <TabPanel value={tabValue} identifier="activities">

              <OrganizationActivityList organizationId={org_id as string} />
            </TabPanel>

          </>
        )}
      </Grid>
    </Grid>
  );
}