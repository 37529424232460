import { Stack } from '@mui/system';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import { Card, useTheme, Typography, CardContent, useMediaQuery } from '@mui/material';

import { useRouter } from 'src/routes/hooks';

import { fCurrency } from 'src/utils/format-number';

import { useTranslate } from 'src/locales';
import { Invoice, InvoiceStatus } from 'src/services/billing/billing.types';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import DateDisplay from 'src/components/date/date-display';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

// ----------------------------------------------------------------------
type Props = {
  row: Invoice;
};

export default function InvoicesTableRow({
  row
}: Props) {

  const popover = usePopover();

  const { t } = useTranslate();

  const router = useRouter();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const viewDetail = () => {
    window.location.href = row.url;
  };

  if (isMobile) {
    return (
      <Card sx={{ mx: 2, mb: 1 }}>
        <CardContent>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography
              variant="body2"
              sx={{ textAlign: 'left', mr: 1, fontWeight: 'bold', textWrap: 'nowrap' }}
            >
              {
                row.amount_in_cents === 0 ? '-' : fCurrency(row.amount_in_cents / 100)
              }
            </Typography>
            <Stack direction="row">
              <IconButton color={popover.open ? 'primary' : 'default'} onClick={popover.onOpen}>
                <Iconify icon="eva:more-horizontal-fill" />
              </IconButton>
            </Stack>
          </Stack>

          <Stack sx={{ whiteSpace: 'nowrap' }} direction="column" justifyContent="start">
            <Typography
              variant="subtitle2"
              sx={{ textAlign: 'left' }}
            >
              {t(`billing.invoice.types.${row.billing_reason}`)}
            </Typography>

            <DateDisplay date={row.created_at} variant='caption' />
          </Stack>

        </CardContent>

        <CustomPopover
          open={popover.open}
          onClose={popover.onClose}
          arrow="right-top"
          sx={{ width: 140 }}
        >
          <MenuItem
            onClick={() => {
              popover.onClose();
              viewDetail();
            }}
          >
            <Iconify icon="solar:eye-bold" />
            {t('common.view')}
          </MenuItem>
        </CustomPopover>

      </Card>
    );
  }

  return (
    <>
      <TableRow hover>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <Typography
            variant="body2"
            sx={{ textAlign: 'left', mr: 1, fontWeight: 'bold', textWrap: 'nowrap' }}
          >
            {
              row.amount_in_cents === 0 ? '-' : fCurrency(row.amount_in_cents / 100)
            }
          </Typography>

        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {t(`billing.invoice.types.${row.billing_reason}`)}
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>

          <Label
            color={
              [InvoiceStatus.Closed, InvoiceStatus.Open].includes(row.status)
                ? 'error'
                : 'info'
            }
            sx={{ p: 1, width: 70 }}
          >
            {t(`billing.invoice.status.${row.status}`)}
          </Label>
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <DateDisplay date={row.created_at} variant='caption' />
        </TableCell>

        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
            <Iconify icon="eva:more-horizontal-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            popover.onClose();
            viewDetail();
          }}
        >
          <Iconify icon="solar:eye-bold" />
          {t('common.view')}
        </MenuItem>

      </CustomPopover>
    </>
  );
}
