import { t } from 'i18next';

import { paths } from 'src/routes/paths';

const CandidateSteps = [
  {
    target: '#activity-nav-menu-item',
    content: t('joyride.activity.content'),
    data: {
      path: paths.dashboard.root,
      navMenu: true,
    },
    disableBeacon: true,
  },
  {
    target: '#create-job-button',
    content: t('joyride.create_job_button.content'),
    data: {
      path: paths.dashboard.root,
      navMenu: false,
    },
    disableBeacon: true,
  },
  {
    target: '#import-job-button',
    content: t('joyride.import_job_button.content'),
    data: {
      path: paths.dashboard.root,
      navMenu: false,
    },
    disableBeacon: true,
  },
  {
    target: '#extension-button',
    content: t('joyride.extension_btn.content'),
    data: {
      path: paths.dashboard.root,
      navMenu: false,
    },
    disableBeacon: true,
  },
  {
    target: '#account_popover',
    content: t('joyride.account_popover.content'),
    data: {
      path: paths.dashboard.root,
      navMenu: false,
    },
    disableBeacon: true,
  },
  {
    target: '#job-task-0',
    content: t('joyride.sample_job.content'),
    data: {
      path: paths.dashboard.root,
      navMenu: false,
    },
    disableBeacon: true,
  },
  {
    target: '#jobs-nav-menu-item',
    content: t('joyride.jobs_nav_item.content'),
    data: {
      path: paths.dashboard.root,
      navMenu: true,
    },
    disableBeacon: true,
  },
  {
    target: '#clients-nav-menu-item',
    content: t('joyride.client_nav_item.content'),
    data: {
      path: paths.dashboard.root,
      navMenu: true,
    },
    disableBeacon: true,
  },
  {
    target: '#recruiters-nav-menu-item',
    content: t('joyride.recruiter_nav_item.content'),
    data: {
      path: paths.dashboard.root,
      navMenu: true,
    },
    disableBeacon: true,
  },
  {
    target: '#profile-nav-menu-item',
    content: t('joyride.profile_nav_item.content'),
    data: {
      path: paths.dashboard.root,
      navMenu: true,
    },
    disableBeacon: true,
  },
];

export default CandidateSteps;
