import Pusher from 'pusher-js';

import { getSessionToken } from 'src/auth/utils';

const socketService = () => {

  const clearSocketSetup = (client: Pusher) => {
    const channels = client.allChannels();
    channels.forEach((channel) => {
      channel.unbind_all();
    });

    client.disconnect();
  };

  const usePusherClient = () => {
    try {
      console.log('socket manager initializing..');

      let pusherClient = null;

      const access_token = getSessionToken();
      pusherClient = new Pusher(import.meta.env.VITE_PUSHER_KEY, {
        cluster: import.meta.env.VITE_PUSHER_CLUSTER,
        authEndpoint: `${import.meta.env.VITE_BACKEND_URL}/realtime/auth`,
        auth: {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        },
        enableStats: true,
      });

      console.log('socket manager ready');

      return pusherClient;
    } catch (error) {
      console.log('failed to initialize socket manager', error);
      throw error;
    }
  };

  return {
    clearSocketSetup,
    usePusherClient
  };
};

export default socketService;
