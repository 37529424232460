
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { useMemo, useState, useEffect, useCallback } from 'react';

import Alert from '@mui/material/Alert';
import { Box, Stack } from '@mui/system';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { MenuItem, TextField, Typography, ListItemText, CircularProgress } from '@mui/material';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import i18n from 'src/locales/i18n';
import { useTranslate } from 'src/locales';
import { rootApi } from 'src/services/rootApi';
import { useAppDispatch } from 'src/store/store';
import { authApi } from 'src/services/auth/auth.service';
import { useAuthContext } from 'src/auth/hooks/useAuthContext';
import { useGetMyAccountsQuery, useSwitchAccountMutation } from 'src/services/account/account.service';

import Label from 'src/components/label';

import { BusinessErrorCodes } from 'src/types/business-errors';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
};

const switchAccountErrorMessage = (e: any) => {
  if (e?.data.error_code) {
    if (e.data.error_code === BusinessErrorCodes.SubTenantSuspended) {
      return i18n.t('account.switch_modal.api.organization_suspended');
    }
  }

  return i18n.t('account.switch_modal.api.default_error');
}

export default function AccountSwitcherModal({ open, onClose }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslate();

  const dispatch = useAppDispatch();

  const authContext = useAuthContext();

  const router = useRouter();

  const [accountToSwitch, setAccountToSwitch] = useState<string | null>(null);
  const [switchError, setSwitchError] = useState<string | null>(null);

  const authResult = authApi.endpoints.getAuthContext.useQueryState();
  const { data: authData } = authResult;

  const { currentData: myAccounts, isLoading: isFetchingAccounts } = useGetMyAccountsQuery();

  const [switchAccount, { isLoading: isSwitchingAccount }] = useSwitchAccountMutation();

  const handleCloseModal = useCallback(() => {
    onClose();
  }, [onClose]);

  useEffect(() => {
    // set the other account as the default account to switch to
    if (myAccounts && myAccounts.results.length > 0) {
      const otherAccount = myAccounts.results.find((account) => account.id !== authContext.account.id);
      setAccountToSwitch(otherAccount?.id || null);
    }

  }, [authData, myAccounts, authContext]);

  const handleSwitchAccount = useCallback(async () => {
    if (!authData || !accountToSwitch) return;

    setSwitchError(null);

    try {
      await switchAccount({ account_id: accountToSwitch }).unwrap();

      enqueueSnackbar(t('account.switch_modal.api.success'), { variant: 'success' });

      dispatch(rootApi.util.resetApiState());

      router.push(paths.dashboard.root);

      handleCloseModal();

    } catch (error) {

      const errorMessage = switchAccountErrorMessage(error);
      setSwitchError(errorMessage);
      enqueueSnackbar(errorMessage, { variant: 'error' });

    }
  }, [authData, accountToSwitch, switchAccount, enqueueSnackbar, t, handleCloseModal, dispatch, router, setSwitchError]);

  const currentAccount = useMemo(() => {
    if (!authData || !myAccounts) return null;

    return myAccounts.results.find((account) => account.id === authData.account.id);

  }, [authData, myAccounts]);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleCloseModal}
    >

      <DialogTitle>{t('account.switch_modal.title')}</DialogTitle>

      <DialogContent>

        <Alert variant="outlined" severity="info" sx={{ mb: 3 }}>
          {t('account.switch_modal.alert')}
        </Alert>

        {
          isFetchingAccounts ? (
            <Stack justifyContent="center" alignItems="center" minHeight={100}>
              <CircularProgress />
            </Stack>
          )
            : (
              <Box mb={3}>

                {
                  currentAccount && (
                    <ListItemText

                      primary={t('account.switch_modal.current_account')}
                      secondary={(
                        <Stack direction="row" spacing={1}>
                          <Box>{`${currentAccount?.organization.company_name} (${_.capitalize(currentAccount?.organization.company_type)})`}</Box>
                        </Stack>
                      )}
                      sx={{ ml: 1 }}
                    />
                  )
                }

                <Box my={3}>
                  <TextField
                    select
                    label={t('account.switch_modal.select_account')}
                    value={accountToSwitch}
                    onChange={(e) => setAccountToSwitch((e.target.value))}
                    fullWidth
                  >
                    {myAccounts?.results.map((account) => (
                      <MenuItem key={account.id} value={account.id} disabled={account.id === authContext.account.id}>
                        <Stack direction="row" spacing={1}>
                          <Typography variant='body2'>
                            {account.organization.company_name} ({_.capitalize(account.organization.company_type)})
                          </Typography>
                          <Label color="info">{t(`enums.hierarchy_level.${account.organization.hierarchy_level}`)}</Label>
                        </Stack>
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </Box>

            )
        }

        {switchError && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {switchError}
          </Alert>
        )}

      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          {t('common.cancel')}
        </Button>

        <LoadingButton
          type="submit"
          onClick={handleSwitchAccount}
          variant="contained"
          loading={isSwitchingAccount}
          disabled={!authData || isFetchingAccounts || isSwitchingAccount}
        >
          {t('account.switch_modal.switch')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}