import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { debounce, isString } from 'lodash';
import { useState, useCallback } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

import Box from '@mui/system/Box';
import Alert from '@mui/material/Alert';
import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/system/Unstable_Grid/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Link,
  Stack,
  Avatar,
  Button,
  Checkbox,
  MenuItem,
  TextField,
  Typography,
  Autocomplete,
  MobileStepper,
  CircularProgress,
} from '@mui/material';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import i18n from 'src/locales/i18n';
import { useTranslate } from 'src/locales';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { hideJobLinkActionModal } from 'src/store/slices/jobs/jobsSlice';
import { useMarkAllAsReadMutation } from 'src/services/notifications/notifications.service';
import {
  generateAcceptJobLinkRequestError,
  generateRejectJobLinkRequestError,
} from 'src/services/jobs/jobs.utils';
import {
  IJob,
  FeeType,
  LinkBehaviour,
  FeeTypeDisplay,
  LinkFeeSettingsDto,
  FeeSettingsSourceType,
  JobVerificationStatus,
} from 'src/services/jobs/jobs.types';
import {
  useGetOrganisationJobsQuery,
  useGetJobLinkRequestByIdQuery,
  useAcceptJobLinkRequestMutation,
  useRejectJobLinkRequestMutation,
} from 'src/services/jobs/jobs.service';

import Iconify from 'src/components/iconify';
import FormProvider, { RHFSelect } from 'src/components/hook-form';
import RHFUnitInput from 'src/components/hook-form/rhf-unit-input';
import SelectableCard from 'src/components/selectable-card/selectable-card';

import JobCard from 'src/sections/jobs/cards/job-card';

import { TenantType } from 'src/types/enums';
import { BusinessErrorCodes } from 'src/types/business-errors';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type Props = {
  open: boolean;
};

const generateJobLinkActionError = (e: any): string => {
  if (isString(e?.data?.error_code)) {
    if (e?.data.error_code === BusinessErrorCodes.UnableToActionInvite) {
      return i18n.t('jobs.invite_modal.api.accept.error.invalid');
    }

    if (e?.data.error_code === BusinessErrorCodes.CandidateAlreadyApplied) {
      return i18n.t('jobs.invite_modal.api.accept.error.exists');
    }
  }

  return i18n.t('jobs.invite_modal.api.accept.error.default');
};

export default function JobLinkActionModal({ open }: Props) {
  const { t } = useTranslate();

  const tenantType = useOrgTenant();

  const { enqueueSnackbar } = useSnackbar();

  const router = useRouter();

  const [currentPage, setCurrentPage] = useState<number>(0);

  const [errorMsg, setErrorMsg] = useState<string>('');

  const [linkBehaviour, setLinkBehaviour] = useState<LinkBehaviour>(
    tenantType === TenantType.Recruiter ? LinkBehaviour.CLONE_SOURCE : LinkBehaviour.LINK_EXISTING
  );

  const [markAsRead] = useMarkAllAsReadMutation();

  const [selectedJob, onSelectJob] = useState<IJob>();

  const requestId = useAppSelector((state) => state.jobs.jobLinkRequestId as string);
  const jobLinkRequestNotificationId = useAppSelector(
    (state) => state.jobs.jobLinkRequestNotificationId
  );

  const dispatch = useAppDispatch();

  const [jobSearchQuery, setJobSearchQuery] = useState('');

  const AcceptRequestSchema = Yup.object().shape({
    fee_source_type: Yup.mixed<FeeSettingsSourceType>()
      .oneOf(Object.values(FeeSettingsSourceType), t('validation.type_error'))
      .required(t('validation.required')),
    custom_fee_type: Yup.mixed<FeeType>()
      .oneOf(Object.values(FeeType), t('validation.type_error'))
      .nullable()
      .when(['fee_source_type'], ([fee_source_type], schema) => {
        if (fee_source_type === FeeSettingsSourceType.CUSTOM) {
          return schema.required(t('validation.required'));
        }
        return schema;
      }),
    custom_fee_value: Yup.number()
      .default(0)
      .when(
        ['fee_source_type', 'custom_fee_type'],
        ([fee_source_type, custom_fee_type], schema) => {
          if (
            fee_source_type === FeeSettingsSourceType.CUSTOM &&
            custom_fee_type === FeeType.PERCENTAGE
          ) {
            return schema
              .required(t('validation.required'))
              .min(1, t('validation.min_integer', { min: 1 }))
              .max(100, t('validation.max_percentage'));
          }
          if (
            fee_source_type === FeeSettingsSourceType.CUSTOM &&
            custom_fee_type === FeeType.FIXED
          ) {
            return schema
              .required(t('validation.required'))
              .min(1, t('validation.min_integer', { min: 1 }));
          }
          return schema;
        }
      ),
  });

  const defaultValues = {
    fee_source_type: FeeSettingsSourceType.DEFAULT,
    custom_fee_value: 0,
    custom_fee_type: FeeType.PERCENTAGE,
  };

  const searchMethods = useForm({
    resolver: yupResolver(AcceptRequestSchema),
    defaultValues,
    mode: 'all',
  });

  const { setValue, watch, trigger, getValues } = searchMethods;

  const feeSourceType = watch('fee_source_type');
  const customFeeValue = watch('custom_fee_value');
  const customFeeType = watch('custom_fee_type');

  const { currentData: jobsData } = useGetOrganisationJobsQuery({
    search_query: jobSearchQuery,
    page: 1,
    per_page: 5,
    verification_status:
      tenantType === TenantType.Recruiter ? JobVerificationStatus.UNVERIFIED : undefined,
  });

  const { currentData: requestData, isFetching } = useGetJobLinkRequestByIdQuery(requestId, {
    skip: !requestId,
  });

  const [acceptRequest, { isLoading: isAccepting }] = useAcceptJobLinkRequestMutation();

  const [rejectRequest, { isLoading: isRejecting }] = useRejectJobLinkRequestMutation();

  const onClose = useCallback(() => {
    setCurrentPage(0);
    setErrorMsg('');
    dispatch(hideJobLinkActionModal());
  }, [dispatch]);

  const onNextPage = () => {
    setCurrentPage(1);
  };

  const onNavigateToConnectionSettings = useCallback(() => {
    router.push(paths.dashboard.admin.connection_management.root);
    onClose();
  }, [onClose, router]);

  const onNavigateToCreateJob = useCallback(() => {
    router.push(paths.dashboard.jobs.create_link(requestId));
    onClose();
  }, [onClose, requestId, router]);

  const onRejectRequest = async () => {
    setErrorMsg('');
    try {
      await rejectRequest({
        requestId,
      }).unwrap();

      if (jobLinkRequestNotificationId)
        markAsRead({ notifications: [jobLinkRequestNotificationId] });

      onClose();
    } catch (e) {
      const errorText = generateRejectJobLinkRequestError(e);
      setErrorMsg(errorText);

      enqueueSnackbar(errorText, { variant: 'error' });
    }
  };

  const onAcceptRequest = async () => {
    setErrorMsg('');
    try {
      let feeSettings: LinkFeeSettingsDto | null = null;
      if (tenantType === TenantType.Client) {
        const valid = await trigger();
        if (!valid) return;

        const data = getValues();

        if (tenantType === TenantType.Client) {
          feeSettings = {
            source_type: data.fee_source_type,
            custom_fees:
              data.fee_source_type === FeeSettingsSourceType.CUSTOM
                ? {
                  fee_value: (data.custom_fee_value as number) ?? 0,
                  fee_type: data.custom_fee_type as FeeType,
                }
                : undefined,
          };
        }
      }

      await acceptRequest({
        requestId,
        data: {
          link_settings: {
            job_id:
              selectedJob && linkBehaviour === LinkBehaviour.LINK_EXISTING
                ? selectedJob.id
                : undefined,
            link_behaviour: linkBehaviour,
          },
          fee_settings: feeSettings ?? undefined,
        },
      }).unwrap();

      enqueueSnackbar(
        t('jobs.action.api.success', {
          client: requestData?.source_organization.company_name as string,
        }),
        { variant: 'success' }
      );

      if (jobLinkRequestNotificationId)
        markAsRead({ notifications: [jobLinkRequestNotificationId] });

      onClose();
    } catch (e) {
      const errorText = generateAcceptJobLinkRequestError(e);
      setErrorMsg(errorText);

      enqueueSnackbar(t(errorText), { variant: 'error' });
    }
  };

  const handleChangeUnit = useCallback(
    (value: FeeType) => {
      setValue('custom_fee_type', value, { shouldValidate: true });
    },
    [setValue]
  );

  const onSearchJobs = debounce((input: string) => {
    setJobSearchQuery(input);
  }, 500);

  const renderRequest = (
    <Stack direction="column" gap={2}>
      <Alert variant="outlined" severity="info" sx={{ mb: 3 }}>
        {t(`jobs.action.alert_${tenantType}`, {
          company: requestData?.source_organization?.company_name,
        })}
      </Alert>

      <Grid container rowGap={2}>
        <Grid xs={11} sx={{ px: 2 }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction="row" justifyContent="flex-start" alignItems="center">
              <Stack direction="column" justifyContent="center" sx={{ mr: 2 }}>
                <Avatar src={requestData?.source_organization?.company_logo?.public_path} />
              </Stack>
              <Stack>
                <Typography variant="h6">{t('jobs.action.info.company_name')}</Typography>
                <Typography variant="body2">
                  {requestData?.source_organization.company_name}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Grid>

        <JobCard job={requestData?.source_job} />

        <Grid xs={12} sm={6} sx={{ px: 2, pt: 2 }}>
          <LoadingButton
            loading={isAccepting}
            fullWidth
            variant="contained"
            color="error"
            onClick={onRejectRequest}
          >
            {t('common.deny')}
          </LoadingButton>
        </Grid>
        <Grid xs={12} sm={6} sx={{ px: 2, pt: 2 }}>
          <LoadingButton onClick={onNextPage} loading={isAccepting} fullWidth variant="contained">
            {t('common.link')}
          </LoadingButton>
        </Grid>
      </Grid>
    </Stack>
  );

  const renderCloner = (
    <Stack direction="column" spacing={2}>
      <Alert variant="outlined" severity="info">
        {t(`jobs.action.alert_clone_${tenantType}`)}
      </Alert>

      <Stack direction="row" justifyContent="flex-start">
        <Button
          variant="text"
          onClick={() => setCurrentPage(0)}
          startIcon={<Iconify icon="eva:arrow-ios-back-fill" width={16} />}
        >
          {t('common.back')}
        </Button>
      </Stack>

      <Grid container gap={2}>
        {tenantType === TenantType.Recruiter ? (
          <>
            <Grid xs={12}>
              <SelectableCard
                title={t('jobs.action.select_job.clone')}
                subtitle={t('jobs.action.select_job.clone_info')}
                selected={linkBehaviour === LinkBehaviour.CLONE_SOURCE}
                onClick={() => setLinkBehaviour(LinkBehaviour.CLONE_SOURCE)}
                flexDirection="row"
              />
            </Grid>
            <Grid xs={12}>
              <SelectableCard
                title={t('jobs.action.select_job.select')}
                subtitle={t('jobs.action.select_job.select_info')}
                selected={linkBehaviour === LinkBehaviour.LINK_EXISTING}
                onClick={() => setLinkBehaviour(LinkBehaviour.LINK_EXISTING)}
                flexDirection={linkBehaviour === LinkBehaviour.LINK_EXISTING ? 'column' : 'row'}
              >
                {linkBehaviour === LinkBehaviour.LINK_EXISTING && (
                  <Stack sx={{ pt: 2 }}>
                    <Autocomplete
                      id="job-select"
                      onChange={(_e: any, value) => value && onSelectJob(value)}
                      options={jobsData?.results ?? []}
                      isOptionEqualToValue={(option: IJob, value: IJob) => option.id === value.id}
                      getOptionLabel={(option) => option.title}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                            disabled={
                              option.verification_status !== JobVerificationStatus.UNVERIFIED
                            }
                          />
                          {option.title}
                        </li>
                      )}
                      style={{ width: 500 }}
                      renderInput={(params) => (
                        <TextField {...params} label={t('common.jobs')} placeholder="Favorites" />
                      )}
                      onInputChange={(_e: any, value: string) => onSearchJobs(value)}
                    />
                  </Stack>
                )}
              </SelectableCard>
            </Grid>
          </>
        ) : (
          <>
            <Grid xs={12}>
              <SelectableCard
                title={t('jobs.action.select_job.select')}
                subtitle={t('jobs.action.select_job.select_info')}
                selected={linkBehaviour === LinkBehaviour.LINK_EXISTING}
                onClick={() => setLinkBehaviour(LinkBehaviour.LINK_EXISTING)}
                flexDirection={linkBehaviour === LinkBehaviour.LINK_EXISTING ? 'column' : 'row'}
              />
            </Grid>
            <Grid xs={12}>
              <SelectableCard
                title={t('jobs.action.select_job.clone_and_edit')}
                subtitle={t('jobs.action.select_job.clone_and_edit_info')}
                selected={linkBehaviour === LinkBehaviour.CREATE_AND_LINK}
                onClick={() => setLinkBehaviour(LinkBehaviour.CREATE_AND_LINK)}
                flexDirection="row"
              />
            </Grid>
          </>
        )}
      </Grid>
    </Stack>
  );

  const renderLinker = (
    <Grid container spacing={2} py={1}>
      <Grid xs={12}>
        <Stack direction="row" justifyContent="flex-start">
          <Button
            variant="text"
            onClick={() => setCurrentPage(1)}
            startIcon={<Iconify icon="eva:arrow-ios-back-fill" width={16} />}
          >
            {t('common.back')}
          </Button>
        </Stack>
      </Grid>
      <Grid xs={12} sx={{ pt: 0, width: '100%' }}>
        <Autocomplete
          sx={{ width: '100%' }}
          id="job-select"
          value={selectedJob}
          onChange={(_e: any, value) => value && onSelectJob(value)}
          options={jobsData?.results ?? []}
          isOptionEqualToValue={(option: IJob, value: IJob) => option.id === value.id}
          getOptionLabel={(option) => option.title}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.title}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label={`${t('common.select')} ${t('common.job')}`}
              placeholder="Select job"
            />
          )}
          onInputChange={(_e: any, value: string) => onSearchJobs(value)}
        />
      </Grid>

      {tenantType === TenantType.Client && (
        <Grid xs={12}>
          <FormProvider methods={searchMethods}>
            <Stack direction="column" gap={2}>
              <RHFSelect
                name="fee_source_type"
                label={t('jobs.link_modal.fee_settings.label')}
                placeholder={t('jobs.link_modal.source_types.placeholder')}
              >
                <MenuItem key={FeeSettingsSourceType.DEFAULT} value={FeeSettingsSourceType.DEFAULT}>
                  {t('jobs.link_modal.fee_settings.fee_source_types.default')}
                </MenuItem>
                <MenuItem key={FeeSettingsSourceType.CUSTOM} value={FeeSettingsSourceType.CUSTOM}>
                  {t('jobs.link_modal.fee_settings.fee_source_types.custom')}
                </MenuItem>
              </RHFSelect>

              {feeSourceType === FeeSettingsSourceType.CUSTOM && (
                <Stack direction="column" alignItems="start" justifyContent="start" gap={2}>
                  <RHFUnitInput
                    fullWidth
                    sx={{ width: '100%' }}
                    name="custom_fee_value"
                    label={t('jobs.link_modal.fee_settings.custom_fee')}
                    type="number"
                    value={customFeeValue}
                    unit={customFeeType}
                    onChangeUnit={handleChangeUnit}
                    options={[
                      { value: FeeType.FIXED, label: FeeTypeDisplay.Fixed },
                      { value: FeeType.PERCENTAGE, label: FeeTypeDisplay.Percentage },
                    ]}
                  />
                </Stack>
              )}
            </Stack>
          </FormProvider>
        </Grid>
      )}

      <Grid xs={12}>
        <Stack direction="row" justifyContent="center" sx={{ mt: 2 }}>
          <Typography variant="body1">
            {t('jobs.action.nav_to_connections')}{' '}
            <Link href={paths.dashboard.admin.connection_management.root}>{t('common.here')}</Link>
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );

  const renderActionButton = () => {
    if (currentPage === 0) return null;

    if (
      (currentPage === 1 && tenantType === TenantType.Recruiter) ||
      (currentPage === 2 && tenantType === TenantType.Client)
    ) {
      return (
        <LoadingButton onClick={onAcceptRequest} variant="contained">
          {t('jobs.action.link_job')}
        </LoadingButton>
      );
    }

    if (tenantType === TenantType.Client) {
      if (linkBehaviour === LinkBehaviour.LINK_EXISTING) {
        return (
          <LoadingButton onClick={() => setCurrentPage(2)} variant="contained">
            {t('common.continue')}
          </LoadingButton>
        );
      }
      return (
        <LoadingButton onClick={onNavigateToCreateJob} variant="contained">
          {t('common.continue')}
        </LoadingButton>
      );
    }

    return null;
  };

  const renderCurrentPage = () => {
    if (currentPage === 0) {
      return renderRequest;
    }

    if (currentPage === 1 || tenantType === TenantType.Recruiter) {
      return renderCloner;
    }

    return renderLinker;
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle sx={{ textTransform: 'capitalize' }}>{t(`jobs.action.title`)}</DialogTitle>

      <DialogContent>
        {isFetching ? (
          <Box
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 150 }}
          >
            <CircularProgress />
          </Box>
        ) : (
          renderCurrentPage()
        )}

        {errorMsg && (
          <Stack sx={{ mt: 2 }}>
            <Alert severity="error">{errorMsg}</Alert>
          </Stack>
        )}

        <Stack paddingTop={2}>
          <MobileStepper
            steps={3}
            position="static"
            activeStep={currentPage}
            nextButton={undefined}
            backButton={undefined}
            sx={{ justifyContent: 'center' }}
          />
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{t('common.cancel')}</Button>
        {renderActionButton()}
      </DialogActions>
    </Dialog>
  );
}
