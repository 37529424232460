import { AuthContext } from 'src/services/auth/auth.types';
import { getOrgTenant } from 'src/services/auth/auth.utils';
import { useGetAuthContextQuery } from 'src/services/auth/auth.service';

import { TenantType } from 'src/types/enums';

export const useOrgTenant = (): TenantType => {
  const { currentData: authContext } = useGetAuthContextQuery();

  return getOrgTenant(authContext as AuthContext);
};
