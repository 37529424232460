

import * as Yup from 'yup';
import { t } from 'i18next';
import { isString } from 'lodash';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState, useEffect, SyntheticEvent } from 'react';

import { LoadingButton } from '@mui/lab';
import { Box, Stack } from '@mui/system';
import { Card, Grid, Skeleton, Typography } from '@mui/material';

import { useAuthContext } from 'src/auth/hooks/useAuthContext';
import { IIntegrationUser, IntegrationSetting } from 'src/services/integration/integrations.types';
import { useGetIntegrationQuery, useGetIntegrationAccountsQuery, useGetIntegrationSettingsQuery, useUpdateIntegrationSettingsMutation } from 'src/services/integration/integrations.service';

import { RHFAutocomplete } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';

// ----------------------------------------------------------------------

type JobAdderSettingsProps = {
  integrationId: string;
};

export default function JobAdderSettings({
  integrationId
}: JobAdderSettingsProps) {

  const { enqueueSnackbar } = useSnackbar();
  const authContext = useAuthContext();

  const { integration_id } = useParams();

  const [searchQuery, setsearchQuery] = useState<string>('');

  const { currentData: integrationDetail, isLoading: isRetrievingIntegration } = useGetIntegrationQuery(
    {
      integrationId: integration_id as string,
    },
    {
      skip: !integration_id
    }
  );

  const { currentData: integrationsettings, isLoading: isRetrievingSettings } = useGetIntegrationSettingsQuery(
    {
      integrationId: integration_id as string,
    },
    {
      skip: !integration_id
    }
  );

  const [updateSettings, { isLoading: isSubmitting }] = useUpdateIntegrationSettingsMutation();

  const { currentData: accounts } = useGetIntegrationAccountsQuery(
    {
      integrationId: integration_id as string,
    },
    { skip: !integration_id }
  );

  const JobAdderSettingsSchema = Yup.object().shape({
    administrator: Yup.object().shape({
      id: Yup.string().required(t('validation.required')),
      first_name: Yup.string().required(t('validation.required')),
      last_name: Yup.string().required(t('validation.required')),
      email: Yup.string().required(t('validation.required')),
    }).required(t('validation.required'))
  });

  const methods = useForm({
    resolver: yupResolver(JobAdderSettingsSchema),
    defaultValues: {
      administrator: {},
    }
  });

  const {
    setValue,
    handleSubmit,
    reset,
    formState: { isValid },
  } = methods;

  useEffect(() => {
    if (!integrationsettings || !accounts) return;

    const administrator = accounts.find((account) => {
      const adminSetting = integrationsettings.find((setting) => setting.setting_type === IntegrationSetting.ADMINISTRATOR);

      // eslint-disable-next-line
      return adminSetting ? account.id == adminSetting.setting_value : false
    });

    if (administrator) {
      setValue('administrator', {
        id: administrator.id as string,
        first_name: administrator.first_name as string,
        last_name: administrator.last_name as string,
        email: administrator.email as string,
      });
    }

  }, [integrationsettings, setValue, accounts]);

  const buildSettingsDto = (data: any) => {
    if (!integrationsettings) return [];

    const settings = [];

    const adminSetting = integrationsettings.find((setting) => setting.setting_type === IntegrationSetting.ADMINISTRATOR);

    if (adminSetting) {
      settings.push({
        id: adminSetting.id as string,
        value: data.administrator.id,
      });
    }

    return settings;
  }

  const onSubmit = handleSubmit(async (data: any) => {
    try {

      const settings = buildSettingsDto(data);

      await updateSettings({
        integrationId: integration_id as string,
        settings
      }).unwrap();

      enqueueSnackbar(t('admin-organization.settings.update.success'), { variant: 'success' });
    }
    catch (e) {
      enqueueSnackbar(t('admin-organization.settings.update.default_error'), { variant: 'error' });
    };
  })

  const onSearch = (_event: SyntheticEvent, value: string) => setsearchQuery(value);

  // render settings based on provider type

  return (
    <Card sx={{ py: 2, px: 4 }}>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h6">{t('integrations.detail.configuration.settings.title')}</Typography>
      </Box>

      {
        (isRetrievingIntegration || isRetrievingSettings) ? (
          <Grid container gap={2} sx={{ px: 2, mt: 2 }}>
            <Grid
              xs={12}
            >
              <Skeleton variant="rectangular" height={20} width="50%" />
            </Grid>
            <Grid
              xs={12}
            >
              <Skeleton variant="rectangular" height={200} />
            </Grid>
          </Grid>
        ) :
          (
            <FormProvider methods={methods}>
              <Stack spacing={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <RHFAutocomplete
                      name="administrator"
                      label={t('integrations.detail.configuration.settings.administrator.label')}
                      placeholder={t(
                        'integrations.detail.configuration.settings.administrator.placeholder'
                      )}
                      helperText={t(`integrations.detail.configuration.settings.administrator.helper_text`)}
                      options={accounts || []}
                      onInputChange={onSearch}
                      filterOptions={(x) => x}
                      renderOption={(props, option: IIntegrationUser) => (
                        <li {...props}>
                          <Stack direction="column">
                            <Typography variant="body1">
                              {`${option.first_name} ${option.last_name}`}
                            </Typography>
                            <Typography variant="body2">{option.email}</Typography>
                          </Stack>
                        </li>
                      )}
                      getOptionLabel={(option) =>
                        isString(option) ? option : `${option.first_name} ${option.last_name} (${option.email})`
                      }
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      getOptionKey={(option) => (option as IIntegrationUser).id as string}
                    />
                  </Grid>
                </Grid>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                  <LoadingButton
                    type="submit"
                    onClick={onSubmit}
                    variant="contained"
                    loading={isSubmitting}
                    disabled={!isValid}
                  >
                    {t('common.update')}
                  </LoadingButton>
                </Box>
              </Stack>
            </FormProvider>
          )
      }
    </Card>
  );
}
