import { useMemo, useState } from 'react';

import { Tab, Card, Tabs, alpha, Typography } from '@mui/material';

import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';

import CandidatesTable from 'src/sections/candidates/table/candidate-table';

import { TenantType } from 'src/types/enums';

import InvitesTable from '../invites/table/invites-table';

export default function CandidatesView() {

  const tenantType = useOrgTenant();

  const [selectedTab, setSelectedTab] = useState<'candidates' | 'invites'>('candidates');

  const tableTabOptions = useMemo(() => {
    switch (tenantType) {
      case TenantType.Client:
        return ['candidates'];
      case TenantType.Recruiter:
        return ['candidates', 'invites'];
      default:
        return [];
    }
  }, [tenantType]);

  return (
    <Card>
      <Tabs
        value={selectedTab}
        onChange={(_event, tab) => setSelectedTab(tab)}
        sx={{
          px: 2.5,
          boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
        }}
      >
        {tableTabOptions.map((tab) => (
          <Tab
            key={tab}
            iconPosition="end"
            value={tab}
            label={
              <Typography
                variant="subtitle2"
                sx={{ textTransform: 'capitalize', fontWeight: 'bold' }}
              >
                {tab}
              </Typography>
            }
          />
        ))}
      </Tabs>

      {
        selectedTab === 'candidates' &&
        <CandidatesTable />
      }

      {
        selectedTab === 'invites' &&
        <InvitesTable />
      }


    </Card>
  );
}
