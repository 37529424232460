
import { useState } from 'react';

import {
  Tab,
  Card,
  Tabs,
  useTheme,
  Typography,
  useMediaQuery
} from '@mui/material';

import { useTranslate } from 'src/locales';

import ConnectionsTable from 'src/sections/restricted/organizations/connections/active/connections-table';
import ConnectionRequestsTable from 'src/sections/restricted/organizations/connections/requests/connection-requests-table';


type Props = {
  organizationId: string;
};

export default function ConnectionsView({ organizationId }: Props) {
  const { t } = useTranslate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [selectedTab, setSelectedTab] = useState<"active" | "requests">('active');

  return (
    <Card>
      <Tabs
        value={selectedTab}
        onChange={(e, value) => setSelectedTab(value)}
        sx={{
          px: 2.5,
          mb: { xs: 1, sm: 'unset' }
        }}
      >
        <Tab
          iconPosition="end"
          value="active"
          label={
            <Typography
              variant="subtitle2"
              sx={{ textTransform: 'capitalize', fontWeight: 'bold' }}
            >
              {t('restricted.organizations.detail.connections.tabs.active')}
            </Typography>
          }
        />
        <Tab
          iconPosition="end"
          value="requests"
          label={
            <Typography
              variant="subtitle2"
              sx={{ textTransform: 'capitalize', fontWeight: 'bold' }}
            >
              {t('restricted.organizations.detail.connections.tabs.requests')}
            </Typography>
          }
        />
      </Tabs>

      {
        selectedTab === 'requests' && (
          <ConnectionRequestsTable organizationId={organizationId} />
        )
      }

      {
        selectedTab === 'active' && (
          <ConnectionsTable organizationId={organizationId} />
        )
      }

    </Card>
  );
}