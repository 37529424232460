import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { useMemo, useState, ChangeEvent, useCallback } from 'react';

import { useMediaQuery } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import { Card, Table, TableBody, TableContainer } from '@mui/material';

import { useTranslate } from 'src/locales';
import { PageOptions } from 'src/services/api.types';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { SystemDocument } from 'src/services/system/system.types';
import { useGetSystemDocumentsQuery, useDeleteSystemDocumentMutation } from 'src/services/system/system.service';

import Scrollbar from 'src/components/scrollbar';
import {
  useTable,
  emptyRows,
  TableNoData,
  TableSkeleton,
  TableEmptyRows,
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/table';

import { DocumentTableRow } from './documents-table-row';

const DEFAULT_PAGE: PageOptions = {
  page: 1,
  per_page: 5,
};

export default function SystemDocumentsTable() {

  const table = useTable();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const tenant = useOrgTenant();

  const { t } = useTranslate();

  const denseHeight = table.dense ? 56 : 56 + 20;

  const [pageOptions, setPageOptions] = useState<PageOptions>({
    page: DEFAULT_PAGE.page,
    per_page: DEFAULT_PAGE.per_page,
  });

  const { currentData: documents, isLoading } = useGetSystemDocumentsQuery({
    ...pageOptions
  })

  const [deleteDocument, { isLoading: isDeletingDocument }] = useDeleteSystemDocumentMutation();

  const notFound = !documents?.count;

  const tableLabels = useMemo(
    () => [
      { id: 'name', label: t('system.documents.table.name') },
      { id: 'tenant_type', label: t('system.documents.table.tenant_types') },
      { id: 'created_at', label: t('system.documents.table.created_at') },
      { id: 'actions', label: '' }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onChangePage = useCallback((_event: any, pageNumber: number) => {
    setPageOptions((prev) => ({
      ...prev,
      page: pageNumber + 1,
    }));
  }, []);

  const onChangeRowsPerPage = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setPageOptions((prev) => ({
        ...prev,
        per_page: +event.target.value,
      }));
    },
    []
  );

  const handleDeleteDocument = useCallback(async (document: SystemDocument) => {

    const toastId = enqueueSnackbar(t('system.documents.api.delete.loading'), {
      variant: 'loading',
      persist: true,
    });

    try {

      await deleteDocument({
        documentId: document.id
      }).unwrap();

      closeSnackbar(toastId);
      enqueueSnackbar(t('system.documents.api.delete.success'), { variant: 'success' });
    }
    catch (e) {

      closeSnackbar(toastId);
      enqueueSnackbar(t('system.documents.api.delete.default_error'), { variant: 'error' });
    }
  }, [t, deleteDocument]);

  return (
    <Card>
      {
        isLoading ? (
          <TableSkeleton />
        )
          :
          <>
            <TableContainer sx={{ overflow: 'unset' }}>
              <Scrollbar>
                <Table size={table.dense ? 'small' : 'medium'}>

                  {
                    !isMobile && (
                      <TableHeadCustom headLabel={tableLabels} />
                    )
                  }

                  <TableBody>
                    {documents?.results?.map((row) => <DocumentTableRow key={row.id} row={row} onDelete={handleDeleteDocument} />)}

                    <TableEmptyRows
                      height={denseHeight}
                      emptyRows={emptyRows(table.page, table.rowsPerPage, documents?.count ?? 0)}
                    />

                    <TableNoData notFound={notFound} />
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>

            <TablePaginationCustom
              count={documents?.count ?? 0}
              page={pageOptions.page}
              rowsPerPage={pageOptions.per_page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
              dense={table.dense}
              onChangeDense={table.onChangeDense}
            />
          </>
      }

    </Card>
  );
}
