import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router';

import { Card } from '@mui/material';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { useAuthContext } from 'src/auth/hooks/useAuthContext';
import { useGetIntegrationQuery } from 'src/services/integration/integrations.service';

import IntegrationActivityTable from 'src/sections/admin/integrations/table/activity-table';

// ----------------------------------------------------------------------

export default function IntegrationActivityView() {

  const { enqueueSnackbar } = useSnackbar();
  const authContext = useAuthContext();

  const { integration_id } = useParams();

  const router = useRouter();

  const { currentData: integrationDetail, isLoading: isRetrievingIntegration, isError: isErrorRetrievingIntegration } = useGetIntegrationQuery(
    {
      integrationId: integration_id as string,
    },
    {
      skip: !integration_id
    }
  );

  useEffect(() => {
    if (isErrorRetrievingIntegration) {
      router.push(paths.dashboard.admin.integrations.root);
    }
  }, [isErrorRetrievingIntegration, router]);

  return (
    <Card >
      <IntegrationActivityTable integrationId={integration_id as string} />
    </Card>
  );
}
