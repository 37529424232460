import { debounce } from 'lodash';
import { useMemo, useState, useEffect, MouseEvent, ChangeEvent, useCallback } from 'react';

import { Card, Table, useTheme, TableBody, useMediaQuery, TableContainer } from '@mui/material';

import i18n from 'src/locales/i18n';
import { useTranslate } from 'src/locales';
import { PageOptions } from 'src/services/api.types';
import { Connection } from 'src/services/connections/connections.types';
import { useGetOrgConnectionsQuery } from 'src/services/connections/connections.service';

import Scrollbar from 'src/components/scrollbar';
import {
  useTable,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/table';

import ConnectionsTableToolbar from 'src/sections/restricted/organizations/connections/active/connections-table-toolbar';

import ConnectionsTableRow from './connections-table-row';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'company_name', label: i18n.t('admin-connections.table.company_name') },
  { id: 'status', label: i18n.t('admin-connections.table.status') },
  { id: 'approved_specializations', label: i18n.t('admin-connections.table.approved_specializations') },
  { id: 'job_limit', label: i18n.t('admin-connections.table.job_limit', { limit: '' }) },
  { id: '' },
];

const DEFAULT_PAGE: PageOptions = {
  page: 1,
  per_page: 5,
};

type Props = {
  organizationId: string;
}

export default function ConnectionsTable({
  organizationId
}: Props) {

  const table = useTable();

  const { t } = useTranslate();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [currentPage, setCurrentPage] = useState<PageOptions>({
    page: DEFAULT_PAGE.page,
    per_page: DEFAULT_PAGE.per_page,
  });

  const [filterQuery, setFilterQuery] = useState<string>('');

  const [totalRows, setTotalRows] = useState<number>(0);

  const { currentData: connections, isFetching } = useGetOrgConnectionsQuery({
    organization_id: organizationId,
    page: currentPage.page,
    per_page: currentPage.per_page,
    search_query: filterQuery
  }, {
    skip: !organizationId
  });

  useEffect(() => {
    if (isFetching) return;

    setTableData(connections?.results ?? []);
    setTotalRows(connections?.count ?? 0);
  }, [connections, isFetching]);

  const denseHeight = table.dense ? 56 : 56 + 20;

  const canReset = !!filterQuery.length;

  const [tableData, setTableData] = useState<Connection[]>([]);

  const notFound = (!tableData.length && canReset) || !tableData.length;

  const handleFilters = useMemo(
    () => debounce((value: string) => {
      setFilterQuery(value);
    }, 500),
    [setFilterQuery]
  );

  const onChangePage = useCallback(
    (_event: MouseEvent<HTMLButtonElement, MouseEvent>, pageNumber: number) => {
      setCurrentPage({
        ...currentPage,
        page: pageNumber + 1,
      });
    },
    [currentPage]
  );

  const onChangePageRows = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const new_per_page = +(event.target as HTMLInputElement).value;
      setCurrentPage({
        ...currentPage,
        per_page: new_per_page,
      });
    },
    [currentPage]
  );

  return (
    <Card>
      <ConnectionsTableToolbar
        onFilters={handleFilters}
      />

      <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
        <Scrollbar>
          <Table size={table.dense ? 'small' : 'medium'}>
            {
              !isMobile && (
                <TableHeadCustom
                  order={table.order}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={table.selected.length}
                />
              )
            }

            <TableBody>
              {tableData.map((row) => (
                <ConnectionsTableRow key={row.id} row={row} />
              ))}

              <TableEmptyRows
                height={denseHeight}
                emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
              />

              <TableNoData notFound={notFound} />
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>

      <TablePaginationCustom
        count={totalRows}
        page={currentPage.page}
        rowsPerPage={currentPage.per_page}
        onPageChange={onChangePage as any}
        onRowsPerPageChange={onChangePageRows}
        dense={table.dense}
        onChangeDense={table.onChangeDense}
      />
    </Card>
  );
}
