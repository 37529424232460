import { forwardRef } from 'react';

import Link from '@mui/material/Link';
import Box, { BoxProps } from '@mui/material/Box';

import { RouterLink } from 'src/routes/components';

import { useSettingsContext } from '../settings';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const LogoSmall = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const settings = useSettingsContext();

    // const PRIMARY_LIGHT = theme.palette.primary.light;

    // const PRIMARY_MAIN = theme.palette.primary.main;

    // const PRIMARY_DARK = theme.palette.primary.dark;

    // OR using local (public folder)
    // -------------------------------------------------------
    const logo = (
      <Box
        component="img"
        src="/assets/logo/logo-icon.svg"
        sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
      />
    );

    return (
      <Box alignContent="center" alignItems="center" textAlign="center" pl={0} ml={0}>
        <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
          {logo}
        </Link>
      </Box>
    );
  }
);

export default LogoSmall;
