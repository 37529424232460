import * as Yup from 'yup';
import { debounce, isString } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useFieldArray } from 'react-hook-form';
import { useMemo, useState, useEffect, SyntheticEvent } from 'react';

import { Grid, Stack, Badge, Button, Divider, Typography, IconButton } from '@mui/material';

import { EmailRegex } from 'src/utils/data/regex';

import { useTranslate } from 'src/locales';
import { PageOptions } from 'src/services/api.types';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { useLazySearchActiveOrganisationsQuery } from 'src/services/search/search.service';
import { CompanyProfile, ConnectionTargetData } from 'src/services/connections/connections.types';

import Iconify from 'src/components/iconify';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';
import RHFAutocomplete from 'src/components/hook-form/rhf-autocomplete';

import { TenantType } from 'src/types/enums';

const DEFAULT_PAGE = {
  page: 1,
  per_page: 5,
};

export type Props = {
  searchEnabled?: boolean;
  onNextPage: (data: ConnectionTargetData) => void;
};

export default function InviteSearchForm({ searchEnabled, onNextPage }: Props) {
  const { t } = useTranslate();

  const tenant = useOrgTenant();

  const [pageOptions] = useState<PageOptions>(DEFAULT_PAGE);

  const [searchQuery, setSearchQuery] = useState('');

  const [searchOrganisations, { currentData: searchOptions }] =
    useLazySearchActiveOrganisationsQuery();

  const onSearch = (_event: SyntheticEvent, value: string) => setSearchQuery(value);

  const debounceSearchOrgs = useMemo(
    () =>
      debounce((input: string) => {
        searchOrganisations({
          tenant: tenant === TenantType.Client ? 'recruiters' : 'clients',
          searchQuery: input,
          excludeConnections: true,
          ...pageOptions,
        });
      }),
    [pageOptions, searchOrganisations, tenant]
  );

  const ConnectionSearchSchema = Yup.object().shape({
    searchOption: Yup.object()
      .shape({
        id: Yup.string(),
      })
      .nullable()
      .notRequired(),
    // Org
    target_id: Yup.string().nullable().notRequired(),

    company_name: Yup.string().required(t('admin-connections.create_modal.company_name.required')),

    name: Yup.string().when('target_id', ([target_id], schema) =>
      target_id === '' ? Yup.string().required(t('validation.required')) : schema
    ),

    email: Yup.string()
      .nullable()
      .when('target_id', ([target_id], schema) =>
        target_id === ''
          ? Yup.string()
              .required(t('admin-connections.create_modal.contact_email.required'))
              .matches(EmailRegex, t('auth.validation.email.valid'))
          : schema
      ),
    additional_invites: Yup.array()
      .of(
        Yup.object().shape({
          email: Yup.string()
            .required(t('validation.required'))
            .matches(EmailRegex, t('validation.email_format')),
        })
      )
      .unique(t('validation.email_unique'), (value: any) => value.email),
  });

  const defaultValues = {
    searchOption: null,

    target_id: '',
    company_name: '',
    name: '',
    email: '',
    additional_invites: [] as { email: string }[],
  };

  const searchMethods = useForm({
    resolver: yupResolver(ConnectionSearchSchema),
    defaultValues,
    mode: 'all',
  });

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { isValid },
  } = searchMethods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'additional_invites',
  });

  useEffect(() => {
    debounceSearchOrgs(searchQuery);
  }, [debounceSearchOrgs, searchQuery]);

  const watchTargetId = watch('target_id');

  const onSelectTargetCompany = (_event: SyntheticEvent, value: CompanyProfile | null) => {
    setValue('searchOption', value, { shouldValidate: false });
    setValue('target_id', value?.organization?.id ?? '', { shouldValidate: true });
    setValue('company_name', value?.company_name ?? '', { shouldValidate: true });
  };

  const submitForm = handleSubmit((data) => {
    onNextPage({
      target_id: data.target_id ?? undefined,
      company_name: data.company_name,
      email: data.email ?? undefined,
      name: data.name ?? undefined,
      additional_invites: data.additional_invites ?? [],
    });
  });

  return (
    <FormProvider methods={searchMethods} onSubmit={submitForm}>
      <Grid container spacing={1} justifyContent="center">
        {searchEnabled && (
          <>
            <Grid item xs={12}>
              <Typography sx={{ color: 'subtitle1', mb: 1 }}>
                {t('admin-connections.create_modal.search')}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <RHFAutocomplete
                name="searchOption"
                label={t('admin-connections.create_modal.search')}
                placeholder={t('admin-connections.create_modal.search_placeholder')}
                onInputChange={onSearch}
                options={searchOptions?.results ?? []}
                filterOptions={(x) => x}
                renderOption={({ key, ...rest }, option: CompanyProfile) => (
                  <li key={key} {...rest}>
                    <Stack direction="column">
                      <Typography variant="body1">{option.company_name}</Typography>
                    </Stack>
                  </li>
                )}
                getOptionLabel={(option: CompanyProfile | string) =>
                  isString(option) ? option : option.company_name
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionKey={(option) => (option as CompanyProfile).id as string}
                onChange={onSelectTargetCompany as any}
              />
            </Grid>

            <Grid item xs={8} flexDirection="row" textAlign="center" justifyContent="center">
              <Divider sx={{ my: 2 }}>
                <Typography sx={{ color: 'text.secondary', mb: 0.5 }}>{t('common.or')}</Typography>
              </Divider>
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Typography sx={{ color: 'subtitle1', mb: 1 }}>
            {t('admin-connections.create_modal.invite')}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <RHFTextField
            name="company_name"
            label={t('admin-connections.create_modal.company_name.label')}
            disabled={!!watchTargetId}
          />
        </Grid>

        {!watchTargetId && (
          <Grid item xs={12}>
            <RHFTextField
              name="email"
              label={t('admin-connections.create_modal.contact_email.label')}
              disabled={!!watchTargetId}
            />
          </Grid>
        )}

        {!watchTargetId && (
          <Grid item xs={12}>
            <RHFTextField
              name="name"
              label={t('admin-connections.create_modal.name.label')}
              disabled={!!watchTargetId}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <Stack direction="row" gap={1} justifyContent="space-between" alignItems="center">
            <Stack direction="row" gap={2} alignItems="center">
              <Typography sx={{ color: 'subtitle1', my: 2 }}>
                {t('admin-connections.create_modal.additional_emails')}
              </Typography>

              {fields?.length > 0 && <Badge badgeContent={fields?.length} color="primary" />}
            </Stack>

            <IconButton onClick={() => append({ email: '' })}>
              <Iconify icon="formkit:add" />
            </IconButton>
          </Stack>

          {fields?.length > 0 && (
            <Stack direction="column" gap={1}>
              {fields?.map((field, index) => (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="start"
                  gap={2}
                  key={field.email}
                >
                  <RHFTextField
                    name={`additional_invites[${index}].email`}
                    label={t('admin-connections.create_modal.peer_invite.label')}
                  />
                  <IconButton
                    onClick={() => remove(index)}
                    color="error"
                    sx={{ width: 50, height: 50 }}
                  >
                    <Iconify icon="bi:trash" />
                  </IconButton>
                </Stack>
              ))}
            </Stack>
          )}

          {fields?.length === 0 && (
            <Stack direction="column" gap={1} justifyContent="center" alignItems="center">
              <Typography sx={{ color: 'text.secondary', mt: 1 }} variant="caption">
                {t('admin-connections.create_modal.peer_invite.none_added')}
              </Typography>
            </Stack>
          )}
        </Grid>

        <Stack sx={{ py: 2, width: '100%' }} flexDirection="row" justifyContent="flex-end">
          <Button type="submit" variant="contained" disabled={!isValid}>
            {t('common.next')}
          </Button>
        </Stack>
      </Grid>
    </FormProvider>
  );
}
