import React, { useState } from 'react';
import { useCSVReader, formatFileSize } from 'react-papaparse';

import { CloudUpload, CheckCircle } from '@mui/icons-material';
import {
  Box,
  Paper,
  styled,
  Typography,
  LinearProgress,
} from '@mui/material';

import { useTranslate } from 'src/locales';

const DropZone = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(4),
  border: `2px dashed ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  cursor: 'pointer',
  transition: theme.transitions.create(['border-color', 'background-color']),
  '&:hover': {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.action.hover,
  },
}));

const FileInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: theme.spacing(2),
}));

export interface CSVReaderResult {
  data: any[];
  errors: any[];
  meta: any[];
}

export interface CSVReaderProps {
  onRead: (results: CSVReaderResult) => void;
}

export default function CSVReader({ onRead }: CSVReaderProps) {
  const { CSVReader: CSVParser } = useCSVReader();
  const [hovering, setHovering] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const { t } = useTranslate();

  const onUpload = (results: CSVReaderResult) => {
    setHovering(false);
    setUploadComplete(true);
    onRead(results);
  };

  return (
    <CSVParser onUploadAccepted={onUpload}>
      {({
        getRootProps,
        acceptedFile,
        ProgressBar,
        getRemoveFileProps,
      }: any) => (
        <DropZone
          {...getRootProps()}
          elevation={hovering ? 3 : 1}
          onDragEnter={() => setHovering(true)}
          onDragLeave={() => setHovering(false)}
        >
          {acceptedFile ? (
            <FileInfo>
              <Box sx={{ width: '100%', mb: 2 }}>
                {uploadComplete ? (
                  <Box display="flex" justifyContent="center" alignItems="center">
                    <CheckCircle color="success" sx={{ fontSize: 40 }} />
                  </Box>
                ) : (
                  <LinearProgress variant="determinate" value={ProgressBar} />
                )}
              </Box>
              <Typography variant="h6" gutterBottom>
                {acceptedFile.name}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {formatFileSize(acceptedFile.size)}
              </Typography>
            </FileInfo>
          ) : (
            <>
              <CloudUpload sx={{ fontSize: 48, mb: 2, color: 'primary.main' }} />
              <Typography variant="h6">{t('common.click_to_upload')}</Typography>
              <Typography variant="body2" color="textSecondary">
                {t('common.drag_and_drop')}
              </Typography>
            </>
          )}
        </DropZone>
      )}
    </CSVParser>
  );
}