import { Suspense, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet, useLocation } from 'react-router';

import { Stack, useTheme, Container } from '@mui/material';

import { paths } from 'src/routes/paths';

import { useTranslate } from 'src/locales';
import { useAuthContext } from 'src/auth/hooks/useAuthContext';

import { useSettingsContext } from 'src/components/settings';
import { LoadingScreen } from 'src/components/loading-screen';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';

export default function OrganizationDocumentsPage() {
  const [value, setValue] = useState(0);

  const settings = useSettingsContext();
  const authContext = useAuthContext();

  const theme = useTheme();

  const location = useLocation();

  const path = location.pathname;

  const { t } = useTranslate();

  return (
    <>
      <Helmet>
        <title>Admin: Documents</title>
      </Helmet>

      <Container
        maxWidth={settings.themeStretch ? false : 'lg'}
      >
        <Stack>
          <CustomBreadcrumbs
            heading={t('documents.title')}
            links={[
              { name: t('common.dashboard'), href: paths.dashboard.root },
              { name: t('documents.title'), href: paths.dashboard.admin.documents.root },
            ]}
            sx={{
              mb: { xs: 3, md: 5 },
            }}
          />
          <Stack >
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </Stack>
        </Stack>
      </Container>
    </>
  );

}