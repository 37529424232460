import React, { useRef, useMemo, useState, useCallback } from 'react';

import { Box } from '@mui/system';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { Button, Popover, Tooltip, useTheme, IconButton, FormControl, useMediaQuery } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { useTranslate } from 'src/locales';

import Iconify from 'src/components/iconify';

type Props = {
  onFilters: (type: string, value: string | boolean) => void;
  clearFilters: () => void;
};

export default function IntegrationsToolbar({
  onFilters,
  clearFilters
}: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const showFilterPopover = useBoolean();

  const anchorRef = useRef<HTMLButtonElement | null>(null);

  const { t } = useTranslate();

  const [connectedOnly, setConnectedOnly] = useState<boolean | null>(null);
  const [searchValue, setSearchValue] = useState('');

  const onClearFilters = useCallback(() => {
    setSearchValue('');
    setConnectedOnly(null);
    clearFilters();
  }, [clearFilters]);

  const handleFilterType = useCallback(
    (type: boolean) => {
      setConnectedOnly(type);
      onFilters('connected_only', type);
    },
    [onFilters]
  );

  const handleFilterName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(event.target.value);
      onFilters('search_query', event.target.value);
    },
    [onFilters]
  );

  const disableReset = useMemo(() => !searchValue && !connectedOnly, [searchValue, connectedOnly]);

  const renderFilterContent = useMemo(() => (
    <>
      <TextField
        onChange={handleFilterName}
        placeholder="Search..."
        value={searchValue}
        sx={{ width: '50%' }}
        name="description"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          ),
        }}
      />

      {isMobile ? (
        <Button
          variant="text"
          disabled={disableReset}
          onClick={onClearFilters}
        >
          {t('common.clear')}
        </Button>
      ) : (
        <FormControl sx={{ width: 100 }}>
          <Tooltip title={t('common.clear')}>
            <IconButton
              size="large"
              disabled={disableReset}
              onClick={onClearFilters}
              aria-label={t('common.clear')}
            >
              <Iconify icon="material-symbols:cancel" />
            </IconButton>
          </Tooltip>
        </FormControl>
      )}
    </>
  ), [disableReset, handleFilterName, onClearFilters, searchValue, t, isMobile]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        p: 2,
        width: '100%',
      }}
    >
      {isMobile ? (
        <Box sx={{ position: 'relative' }}>
          <Button
            variant="text"
            startIcon={<Iconify icon="mdi:filter" />}
            onClick={showFilterPopover.onTrue}
            ref={anchorRef}
          >
            {t('common.filter')}
          </Button>
          <Popover
            open={showFilterPopover.value}
            onClose={showFilterPopover.onFalse}
            anchorEl={anchorRef.current}
          >
            <Stack sx={{ p: 2, width: 280 }}>
              {renderFilterContent}
            </Stack>
          </Popover>
        </Box>
      ) : (
        <Stack direction="row" spacing={2} alignItems="center" sx={{ flexGrow: 1, maxWidth: "75%" }}>
          {renderFilterContent}
        </Stack>
      )}
    </Stack>
  );
}