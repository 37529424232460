
import { Grid } from '@mui/material';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import { useTranslate } from 'src/locales';
import { User } from 'src/services/organisation/organisation.types';

import { RHFTextField } from 'src/components/hook-form';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
  user?: User;
};

export default function AddDomainForm({ open, onClose, user }: Props) {

  const { t } = useTranslate();

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <DialogTitle>{t('admin-organization.domains.add.title')}</DialogTitle>

      <DialogContent>
        <Alert variant="outlined" severity="info" sx={{ mb: 3 }}>
          {t('admin-organization.domains.add.alert')}
        </Alert>

        <Grid spacing={2} container>
          <Grid item xs={12}>
            <RHFTextField name="domain" label={t('admin-organization.domains.add.domain')} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog >
  );
}
