import React from 'react';
import { format } from 'date-fns';

import {
  Box,
  List,
  Avatar,
  ListItem,
  Pagination,
  Typography,
  ListItemText,
  ListItemAvatar,
} from '@mui/material';

import { useTranslate } from 'src/locales';

import EmptyContent from 'src/components/empty-content/empty-content';

interface ActivityFeedProps {
  activities: IActivity[];
  onPageChange: (event: React.ChangeEvent<unknown>, page: number) => void;
  page: number;
  perPage: number;
  totalItems: number;
}

const ActivityFeed: React.FC<ActivityFeedProps> = ({
  activities,
  onPageChange,
  page,
  perPage,
  totalItems,
}) => {
  const { t } = useTranslate();

  const formatTimestamp = (timestamp: string) => {
    const date = new Date(parseInt(timestamp, 10) * 1000);
    return format(date, 'MMM d, yyyy HH:mm:ss');
  };

  const getInitials = (name: string) => {
    const words = name.split(' ');
    return words[0][0].toUpperCase();
  }

  return (
    <Box>
      <List>
        {
          activities.length === 0 &&
          <ListItem sx={{ justifyContent: 'center' }}>
            <EmptyContent title={t('activities.feed.no_data')} />
          </ListItem>
        }

        {activities.length > 0 && activities.map((activity, index) => (
          <ListItem key={index} sx={{ borderBottom: "1px solid #f3f3f3", justifyContent: 'center', py: 1 }}>
            <ListItemAvatar>
              <Avatar src={activity.actor.profile_image} alt={getInitials(activity.actor.first_name)}>
                {!activity.actor.profile_image && getInitials(activity.actor.first_name)}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={activity.descriptor}
              secondary={
                <Typography
                  component="span"
                  variant="caption"
                  color="text.primary"
                >
                  {formatTimestamp(activity.timestamp)}
                </Typography>
              }
            />
          </ListItem>
        ))}
      </List>
      <Box display="flex" justifyContent="center" my={2}>
        {
          totalItems > 0 &&
          <Pagination
            count={Math.ceil((totalItems || 1) / perPage)}
            page={page}
            onChange={onPageChange}
            color="primary"
          />
        }
      </Box>
    </Box>
  );
};

export default ActivityFeed;