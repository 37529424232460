import { t } from 'i18next';
import { useRef, useState, ChangeEvent } from 'react';

import { LoadingButton } from '@mui/lab';
import { Button, Popover, MenuItem, TextField } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { useAuthContext } from 'src/auth/hooks/useAuthContext';
import { useGetCountriesQuery } from 'src/services/utils/utils.service';
import { useGetCompanyProfileQuery } from 'src/services/organisation/organisation.service';

export type HolidayDate = {
  countries: string[] | null;
  countryCode: string;
  date: string;
  fixed: boolean;
  global: boolean;
  launchYear: number | null;
  localName: string;
  name: string;
  types: string[];
}

type Props = {
  onSelect: (data: HolidayDate[]) => void;
};

const HolidayPopover = ({
  onSelect
}: Props) => {

  const authContext = useAuthContext();

  const anchorRef = useRef<HTMLButtonElement | null>(null);

  const { currentData: companyProfile } = useGetCompanyProfileQuery(authContext.organization?.id, { skip: !authContext?.organization?.id });
  const { data: countries } = useGetCountriesQuery();

  const popoverControl = useBoolean();
  const [selectedCountry, setSelectedCountry] = useState('');
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    popoverControl.onTrue()
  };

  const handleClose = () => {
    popoverControl.onFalse()
  };

  const handleCountryChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSelectedCountry(event.target.value);
  };

  const fetchHolidays = async () => {
    setLoading(true);
    try {
      const currentYear = new Date().getFullYear();
      const response = await fetch(`https://date.nager.at/api/v3/PublicHolidays/${currentYear}/${selectedCountry}`);
      const data = await response.json();

      if (Array.isArray(data) && data.length > 0) {
        onSelect(data);
      }
      else {
        onSelect([]);
      }
    } catch (error) {
      console.error('Failed to fetch holidays:', error);
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  return (
    <div>
      <Button aria-describedby="holiday-popover" variant="outlined" onClick={handleClick} ref={anchorRef}>
        {t('admin-organization.settings.business_hours_selector.set_public_holidays')}
      </Button>
      <Popover
        id="holiday-popover"
        open={popoverControl.value}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div style={{ padding: '20px', maxWidth: '300px' }}>
          <TextField
            select
            fullWidth
            label={t('admin-organization.settings.business_hours_selector.select_country')}
            value={selectedCountry}
            onChange={handleCountryChange}
            variant="outlined"
            style={{ marginBottom: '20px' }}
          >
            {countries?.map((country) => (
              <MenuItem key={country.code} value={country.code}>
                {country.name}
              </MenuItem>
            ))}
          </TextField>
          <LoadingButton
            variant="contained"
            color="primary"
            loading={loading}
            onClick={fetchHolidays}
            disabled={!selectedCountry}
            fullWidth
          >
            {t('admin-organization.settings.business_hours_selector.select_public_holidays')}
          </LoadingButton>
        </div>
      </Popover>
    </div>
  );
};

export default HolidayPopover;