
import { Card, CardHeader, CardContent } from '@mui/material';

import { useTranslate } from 'src/locales';

import TermsTable from 'src/sections/restricted/settings/sections/terms-list/table/terms-table';

export default function TermsView() {

  const { t } = useTranslate();


  return (
    <Card sx={{ margin: 'auto', mt: 4, boxShadow: 0 }}>
      <CardHeader title={t('system.terms.title')} />
      <CardContent>
        <TermsTable />
      </CardContent>

    </Card>
  );
}