import * as Yup from 'yup';
import { isString } from 'lodash';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { enqueueSnackbar } from 'notistack';
import { m, AnimatePresence } from 'framer-motion';
import { yupResolver } from '@hookform/resolvers/yup';

import { Stack } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, Alert, Typography } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { useTranslate } from 'src/locales';
import { useAppDispatch } from 'src/store/store';
import doc_scan from 'src/assets/lottie/doc-scan.json';
import { ImportWorkflowType } from 'src/services/jobs/jobs.types';
import { hideImportJobModal } from 'src/store/slices/jobs/jobsSlice';
import { useImportJobMutation } from 'src/services/jobs/jobs.service';
import { generateCreateJobError } from 'src/services/jobs/jobs.utils';

import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';
import LottieContainer from 'src/components/lottie/lottie-container';

import { BusinessErrorCodes } from 'src/types/business-errors';

type Props = {
  open: boolean;
};

export default function ImportJobModal({ open }: Props) {
  const { t } = useTranslate();

  const router = useRouter();

  const dispatch = useAppDispatch();

  const [importJob] = useImportJobMutation();

  const ImportJobSchema = Yup.object().shape({
    workflow_type: Yup.string().required(t('validation.required')),
    url: Yup.string()
      .nullable()
      .when('workflow_type', ([workflowType], schema) =>
        workflowType === ImportWorkflowType.LINK
          ? Yup.string().url(t('validation.url')).required(t('validation.required'))
          : schema.nullable()
      ),
    content: Yup.string()
      .nullable()
      .when('workflow_type', ([workflowType], schema) =>
        workflowType === ImportWorkflowType.CONTENT
          ? Yup.string().required(t('validation.required'))
          : schema.nullable()
      ),
  });

  const importJobMethods = useForm({
    resolver: yupResolver(ImportJobSchema),
    defaultValues: {
      workflow_type: ImportWorkflowType.LINK,
      content: '',
      url: '',
    },
  });

  const {
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting },
  } = importJobMethods;

  const onClose = useCallback(() => {
    importJobMethods.reset();
    dispatch(hideImportJobModal());
  }, [dispatch, importJobMethods]);

  const handleJobImport = handleSubmit(async (data) => {
    try {
      const importResult = await importJob({
        data: {
          workflow_type: data.workflow_type as ImportWorkflowType,
          url: data.url,
          content: data.content,
        },
      }).unwrap();

      enqueueSnackbar(t('jobs.api.create.success'));
      onClose();
      router.push(`${paths.dashboard.jobs.root}/${importResult.job_id}`);
    } catch (e) {
      console.error(e);

      if (isString(e?.data?.error_code) && e.data.error_code === BusinessErrorCodes.WebsiteScrapeFailed) {
        setError('url', { type: 'custom', message: t('jobs.api.create.scrape_failed_alert') });
      }

      enqueueSnackbar(generateCreateJobError(e), { variant: 'error' });
    }
  });

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle sx={{ textTransform: 'capitalize' }}>{t('jobs.import_modal.title')}</DialogTitle>

      <DialogContent>
        <FormProvider methods={importJobMethods} onSubmit={handleJobImport}>
          <Grid container spacing={2} justifyContent="center" pt={1}>
            <Grid item xs={12}>
              <Alert variant="outlined" severity="info">
                {t(`jobs.import_modal.alert`)}
              </Alert>
            </Grid>

            <AnimatePresence>
              {isSubmitting && (
                <m.div
                  style={{ overflow: 'hidden' }}
                  initial={{ height: 0 }}
                  animate={{ height: 'auto' }}
                  transition={{ duration: 0.5 }}
                  exit={{ height: 0 }}
                  key="container"
                >
                  <LottieContainer json={doc_scan} height={250} loop />
                  <Typography variant="subtitle2" color="text.disabled">
                    {t(`jobs.import_modal.time_warning`)}
                  </Typography>
                </m.div>
              )}
            </AnimatePresence>

            <Grid item xs={12}>
              <RHFTextField
                id="outlined-multiline-static"
                name="url"
                label={t('jobs.import_modal.url.label')}
                placeholder={t('jobs.import_modal.url.placeholder')}
              />
            </Grid>

            <Stack sx={{ py: 2, width: '100%' }} flexDirection="row" justifyContent="flex-end">
              <LoadingButton
                loading={isSubmitting}
                type="submit"
                variant="contained"
                disabled={!isValid}
              >
                {t('common.import')}
              </LoadingButton>
            </Stack>
          </Grid>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
