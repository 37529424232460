import { TenantType } from 'src/types/enums';

import { AuthContext } from './auth.types';
import { UserRole } from '../organisation/organisation.enum';

export const Business_Tenants = [TenantType.Client, TenantType.Recruiter];

export const getOrgTenant = (authContext: AuthContext) => authContext.organization.type;

export const isOrganisationTenant = (tenant: TenantType) => Business_Tenants.includes(tenant);

export const isAdmin = (authContext: AuthContext) =>
  [UserRole.Op_Manager, UserRole.Admin].includes(authContext.account.org_role);
