import { Link } from 'react-router-dom';

import { Tab } from '@mui/material';

export function samePageLinkNavigation(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
  if (
    event.defaultPrevented ||
    event.button !== 0 || // ignore everything but left-click
    event.metaKey ||
    event.ctrlKey ||
    event.altKey ||
    event.shiftKey
  ) {
    return false;
  }
  return true;
}

interface LinkTabProps {
  label?: string;
  href: string;
  selected?: boolean;
}

export default function LinkTab({ selected, href, ...other }: LinkTabProps) {
  return <Tab to={href} component={Link} aria-current={selected && 'page'} {...other} />;
}
