import { t } from 'i18next';

import { paths } from 'src/routes/paths';

const ClientSteps = [
  {
    target: '#activity-nav-menu-item',
    content: t('joyride.activity.content'),
    data: {
      path: paths.dashboard.root,
      navMenu: true,
    },
    disableBeacon: true,
  },
  {
    target: '#create-job-button',
    content: t('joyride.create_job_button.content'),
    data: {
      path: paths.dashboard.root,
      navMenu: false,
    },
    disableBeacon: true,
  },
  {
    target: '#incoming-job-requests',
    content: t('joyride.incoming_job_requests.content'),
    data: {
      path: paths.dashboard.root,
      navMenu: false,
    },
    disableBeacon: true,
  },
  {
    target: '#job-approval-requests',
    content: t('joyride.job_approval_requests.content'),
    data: {
      path: paths.dashboard.root,
      navMenu: false,
    },
    disableBeacon: true,
  },
  {
    target: '#candidate-invite-requests',
    content: t('joyride.candidate_invite_requests.content'),
    data: {
      path: paths.dashboard.root,
      navMenu: false,
    },
    disableBeacon: true,
  },
  {
    target: '#account_popover',
    content: t('joyride.account_popover.content'),
    data: {
      path: paths.dashboard.root,
      navMenu: false,
    },
    disableBeacon: true,
  },
  {
    target: '#jobs-nav-menu-item',
    content: t('joyride.jobs_nav_item.content'),
    data: {
      path: paths.dashboard.root,
      navMenu: true,
    },
    disableBeacon: true,
  },
  {
    target: '#clients-nav-menu-item',
    content: t('joyride.recruiter_nav_item.content'),
    data: {
      path: paths.dashboard.root,
      navMenu: true,
    },
    disableBeacon: true,
  },
  {
    target: '#candidates-nav-menu-item',
    content: t('joyride.candidates_nav_item.content'),
    data: {
      path: paths.dashboard.root,
      navMenu: true,
    },
    disableBeacon: true,
  },
  {
    target: '#contacts-nav-menu-item',
    content: t('joyride.contacts_nav_item.content'),
    data: {
      path: paths.dashboard.root,
      navMenu: true,
    },
    disableBeacon: true,
  },
];

export default ClientSteps;
