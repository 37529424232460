import { useState, MouseEvent, ChangeEvent, useCallback } from 'react';

import { Box } from '@mui/system';
import { Table, useTheme, TableBody, useMediaQuery, TableContainer } from '@mui/material';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import i18n from 'src/locales/i18n';
import { useTranslate } from 'src/locales';
import { PageOptions } from 'src/services/api.types';
import { useGetIntegrationLogsQuery } from 'src/services/integration/integrations.service';
import {
  IIntegrationLog,
  IntegrationActivityType,
} from 'src/services/integration/integrations.types';

import Scrollbar from 'src/components/scrollbar';
import {
  useTable,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/table';

import ActivityTableRow from 'src/sections/admin/integrations/table/activity-table-row';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'event_type', label: i18n.t('integrations.activity.table.event_type') },
  { id: 'status', label: i18n.t('integrations.activity.table.status'), width: 320 },
  { id: 'activity_at', label: i18n.t('integrations.activity.table.created_at') },
  { id: 'controls', width: 120 },
];

const DEFAULT_PAGE: PageOptions = {
  page: 1,
  per_page: 10,
};

// ----------------------------------------------------------------------\

export default function IntegrationActivityTable({ integrationId }: { integrationId: string }) {
  const table = useTable();

  const { t } = useTranslate();

  const theme = useTheme();

  const router = useRouter();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [currentPage, setCurrentPage] = useState<PageOptions>({
    page: DEFAULT_PAGE.page,
    per_page: DEFAULT_PAGE.per_page,
  });

  const { currentData: integrationLogs, isLoading: isRetrievingLogs } = useGetIntegrationLogsQuery(
    {
      integrationId: integrationId as string,
      page: currentPage.page,
      per_page: currentPage.per_page,
    },
    {
      skip: !integrationId,
    }
  );

  const denseHeight = table.dense ? 56 : 56 + 20;

  const notFound = !integrationLogs?.count;

  const onChangePage = useCallback(
    (_event: MouseEvent<HTMLButtonElement, MouseEvent>, pageNumber: number) => {
      setCurrentPage({
        ...currentPage,
        page: pageNumber + 1,
      });
    },
    [currentPage]
  );

  const onChangePageRows = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const new_per_page = +(event.target as HTMLInputElement).value;
      setCurrentPage({
        ...currentPage,
        per_page: new_per_page,
      });
    },
    [currentPage]
  );

  const onViewEntity = useCallback(
    (row: IIntegrationLog) => {
      switch (row.activity_type) {
        case IntegrationActivityType.JOB_SYNC:
          router.push(paths.dashboard.jobs.detail(row.metadata.job_id as string));
          break;
        case IntegrationActivityType.CANDIDATE_SYNC:
          router.push(paths.dashboard.candidates.root);
          break;
        case IntegrationActivityType.APPLICATION_SYNC:
          router.push(
            paths.dashboard.jobs.application(
              row.metadata.job_id as string,
              row.metadata.application_id as string
            )
          );
          break;
        case IntegrationActivityType.COMPANY_SYNC:
          router.push(
            paths.dashboard.admin.connection_management.detail(row.metadata.connection_id as string)
          );
          break;
        default:
          break;
      }
    },
    [router]
  );

  return (
    <Box>
      <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
        <Scrollbar>
          <Table size={table.dense ? 'small' : 'medium'}>
            {!isMobile && (
              <TableHeadCustom
                order={table.order}
                headLabel={TABLE_HEAD}
                rowCount={integrationLogs?.count ?? 0}
                numSelected={table.selected.length}
              />
            )}

            <TableBody>
              {integrationLogs?.results.map((row) => (
                <ActivityTableRow key={row.id} row={row} onViewEntity={onViewEntity} />
              ))}

              <TableEmptyRows
                height={denseHeight}
                emptyRows={emptyRows(table.page, table.rowsPerPage, integrationLogs?.count ?? 0)}
              />

              <TableNoData notFound={notFound} />
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>

      <TablePaginationCustom
        count={integrationLogs?.count ?? 0}
        page={currentPage.page}
        rowsPerPage={currentPage.per_page}
        onPageChange={onChangePage as any}
        onRowsPerPageChange={onChangePageRows}
        dense={table.dense}
        onChangeDense={table.onChangeDense}
      />
    </Box>
  );
}
