import { Account } from 'src/services/auth/auth.types';
import { Setting } from 'src/services/settings/settings.types';

import { rootApi } from '../rootApi';
import { PreferredLocation } from '../candidates/candidates.types';
import { APIResponse, HTTPRequestType, PaginatedResponse } from '../api.types';
import {
  AccountProfile,
  UpdateSettingProps,
  CreateAccountProfile,
  UpdateAccountPasswordProps,
} from './account.types';

const accountService = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getMyAccountProfile: builder.query<AccountProfile, void>({
      query: () => ({
        url: '/account/profile',
      }),
      providesTags: ['Account'],
    }),

    updateMyAccountProfile: builder.mutation<APIResponse, Partial<CreateAccountProfile>>({
      query: (data) => ({
        url: '/account/profile',
        method: HTTPRequestType.PUT,
        data,
      }),
      onQueryStarted: async (_arg, { dispatch }) => {
        setTimeout(() => {
          dispatch(rootApi.util.invalidateTags(['Profile', 'Account']));
        }, 10000);
      },
      invalidatesTags: ['Account', 'Profile'],
    }),

    updateMyAccountSpecializations: builder.mutation<APIResponse, { specializations: string[] }>({
      query: (data) => ({
        url: '/account/profile/specializations',
        method: HTTPRequestType.PUT,
        data,
      }),
      invalidatesTags: ['Account'],
    }),

    updateMyAccountLocations: builder.mutation<APIResponse, { locations: PreferredLocation[] }>({
      query: (data) => ({
        url: '/account/profile/locations',
        method: HTTPRequestType.PUT,
        data,
      }),
      invalidatesTags: ['Account'],
    }),

    updateAccountPassword: builder.mutation<APIResponse, UpdateAccountPasswordProps>({
      query: (data) => ({
        url: '/account/password',
        method: HTTPRequestType.PUT,
        data,
      }),
    }),

    getAccountSettings: builder.query<Setting[], string>({
      query: (accountId: string) => ({
        url: `/accounts/${accountId}/settings`,
      }),
      providesTags: ['AccountSettings'],
    }),

    updateAccountSettings: builder.mutation<APIResponse, UpdateSettingProps>({
      query: (data) => ({
        url: `/accounts/${data.accountId}/settings`,
        method: HTTPRequestType.POST,
        data: {
          settings: data.settings,
        },
      }),
      invalidatesTags: ['AccountSettings'],
    }),

    updateOnboardingContext: builder.mutation<APIResponse, { [key: string]: boolean }>({
      query: (data) => ({
        url: '/account/onboarding-context',
        data,
        method: HTTPRequestType.PUT,
      }),
      invalidatesTags: ['AuthContext'],
    }),
    updateAlertContext: builder.mutation<APIResponse, { [key: string]: boolean }>({
      query: (data) => ({
        url: '/account/alert-context',
        data,
        method: HTTPRequestType.PUT,
      }),
      invalidatesTags: ['AuthContext'],
    }),

    getMyAccounts: builder.query<PaginatedResponse<Account>, void>({
      query: () => ({
        method: HTTPRequestType.GET,
        url: '/accounts/list',
      }),
      providesTags: ['Accounts'],
    }),
    switchAccount: builder.mutation<APIResponse, { account_id: string }>({
      query: (data) => ({
        url: '/accounts/switch',
        method: HTTPRequestType.POST,
        data,
      })
    }),
  }),
});

export const {
  useGetMyAccountProfileQuery,
  useUpdateMyAccountProfileMutation,
  useUpdateMyAccountSpecializationsMutation,
  useUpdateMyAccountLocationsMutation,
  useUpdateAccountPasswordMutation,
  useGetAccountSettingsQuery,
  useUpdateAccountSettingsMutation,

  useUpdateOnboardingContextMutation,
  useUpdateAlertContextMutation,

  useGetMyAccountsQuery,
  useSwitchAccountMutation,
} = accountService;
