import { enqueueSnackbar } from 'notistack';
import { useMemo, useState, ChangeEvent, useCallback } from 'react';

import { useMediaQuery } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import { Card, Table, TableBody, TableContainer } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { useTranslate } from 'src/locales';
import { PageOptions } from 'src/services/api.types';
import {
  useGetSystemTermsQuery,
  useSetTermAsActiveMutation,
} from 'src/services/system/system.service';

import Scrollbar from 'src/components/scrollbar';
import {
  useTable,
  emptyRows,
  TableNoData,
  TableSkeleton,
  TableEmptyRows,
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/table';

import EditTermsModal from 'src/sections/restricted/settings/sections/terms-list/modal/edit-terms-modal';

import { TermsTableRow } from './terms-table-row';

const DEFAULT_PAGE: PageOptions = {
  page: 1,
  per_page: 5,
};

export default function TermsTable() {
  const table = useTable();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { t } = useTranslate();

  const editTermControl = useBoolean();
  const [currentTermId, setCurrentTermId] = useState<string | null>(null);

  const denseHeight = table.dense ? 56 : 56 + 20;

  const [pageOptions, setPageOptions] = useState<PageOptions>({
    page: DEFAULT_PAGE.page,
    per_page: DEFAULT_PAGE.per_page,
  });

  const [activateTerm] = useSetTermAsActiveMutation();

  const { currentData: termsData, isLoading } = useGetSystemTermsQuery({
    ...pageOptions,
  });

  const notFound = !termsData?.count;

  const tableLabels = useMemo(
    () => [
      { id: 'name', label: t('system.terms.table.type') },
      { id: 'tenant_type', label: t('system.terms.table.tenant_type') },
      { id: 'region', label: t('system.terms.table.region') },
      { id: 'status', label: t('system.terms.table.status') },
      { id: 'actions', label: '' },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onEditTerm = (termsId: string) => {
    setCurrentTermId(termsId);
    editTermControl.onTrue();
  };

  const handleTermsModalClose = () => {
    setCurrentTermId(null);
    editTermControl.onFalse();
  };

  const onChangePage = useCallback((_event: any, pageNumber: number) => {
    setPageOptions((prev) => ({
      ...prev,
      page: pageNumber + 1,
    }));
  }, []);

  const onChangeRowsPerPage = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setPageOptions((prev) => ({
        ...prev,
        per_page: +event.target.value,
      }));
    },
    []
  );

  const handleActivateTerm = (termsId: string) => {
    try {
      activateTerm({
        termId: termsId,
      }).unwrap();

      enqueueSnackbar(t('system.terms.api.set_active.success'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t('system.terms.api.set_active.default_error'), { variant: 'error' });
    }
  };

  return (
    <Card>
      {isLoading ? (
        <TableSkeleton />
      ) : (
        <>
          <TableContainer sx={{ overflow: 'unset' }}>
            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'}>
                {!isMobile && <TableHeadCustom headLabel={tableLabels} />}

                <TableBody>
                  {termsData?.results?.map((row) => (
                    <TermsTableRow
                      key={row.id}
                      row={row}
                      onEditTerm={onEditTerm}
                      onActivateTerm={handleActivateTerm}
                    />
                  ))}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(table.page, table.rowsPerPage, termsData?.count ?? 0)}
                  />

                  <TableNoData notFound={notFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={termsData?.count ?? 0}
            page={pageOptions.page}
            rowsPerPage={pageOptions.per_page}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            dense={table.dense}
            onChangeDense={table.onChangeDense}
          />
        </>
      )}

      <EditTermsModal
        termsId={currentTermId as string}
        open={editTermControl.value}
        onClose={handleTermsModalClose}
      />
    </Card>
  );
}
