import { t } from "i18next";

import { Box } from "@mui/system";

import TermsView from "src/sections/restricted/settings/sections/terms-list/terms-view";
import SystemDocumentsView from "src/sections/restricted/settings/sections/documents/documents-view";

import MaintenanceModeForm from "./sections/maintenance-mode-form";

export default function GlobalSettings() {


    return (
        <Box>
            <h2>
                {t('app.settings.title')}
            </h2>

            <MaintenanceModeForm />
            <TermsView />
            <SystemDocumentsView />
        </Box>
    )
}
