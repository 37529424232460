import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';

import { useTranslate } from 'src/locales';
import { usePostCommentMutation } from 'src/services/threads/threads.service';

import FormProvider, { RHFTextField } from 'src/components/hook-form';

// ----------------------------------------------------------------------

type Props = {
  thread_id?: string;
  disabled?: boolean;
};

export default function PostCommentForm({ thread_id, disabled }: Props) {
  const { t } = useTranslate();

  const { enqueueSnackbar } = useSnackbar();

  const [postComment] = usePostCommentMutation();

  const CommentSchema = Yup.object().shape({
    comment: Yup.string().required('Comment is required'),
  });

  const defaultValues = {
    comment: '',
  };

  const methods = useForm({
    resolver: yupResolver(CommentSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await postComment({
        thread_id: thread_id as string,
        comment: data.comment,
      }).unwrap();

      reset();
    } catch (error) {
      console.error(error);

      enqueueSnackbar(t('threads.api.post.default_error'), { variant: 'error' });
    }
  });

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Stack spacing={3}>
        <RHFTextField
          name="comment"
          placeholder={t('threads.form.comment.placeholder')}
          multiline
          disabled={disabled}
          rows={4}
        />

        <Stack direction="row" justifyContent="flex-end">
          {/* TODO: Use these controls for rich media in comments when enabled on backend. */}

          {/* <Stack direction="row" alignItems="center" flexGrow={1}>
            <IconButton>
              <Iconify icon="solar:gallery-add-bold" />
            </IconButton>

            <IconButton>
              <Iconify icon="eva:attach-2-fill" />
            </IconButton>

            <IconButton>
              <Iconify icon="eva:smiling-face-fill" />
            </IconButton>
          </Stack> */}

          <LoadingButton
            type="submit"
            variant="contained"
            disabled={!thread_id || !isValid || disabled}
            loading={isSubmitting}
          >
            {t('threads.form.submit')}
          </LoadingButton>
        </Stack>
      </Stack>
    </FormProvider>
  );
}
