import { Stack } from '@mui/system';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import { Card, Button, useTheme, Typography, CardContent, useMediaQuery } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { useTranslate } from 'src/locales';
import { OutgoingCandidateInvite } from 'src/services/candidates/candidates.types';

import Iconify from 'src/components/iconify';
import DateDisplay from 'src/components/date/date-display';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

// ----------------------------------------------------------------------

type Props = {
  row: OutgoingCandidateInvite;
  onDeleteRow?: (id: string) => void;
};

export default function InvitesTableRow({
  row,
  onDeleteRow,
}: Props) {
  const confirm = useBoolean();

  const popover = usePopover();

  const { t } = useTranslate();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) {
    return (
      <Card sx={{ mx: 2, mb: 1 }}>
        <CardContent>
          <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h6" noWrap>
              {row.first_name} {row.last_name}
            </Typography>
            <IconButton color={popover.open ? 'primary' : 'default'} onClick={popover.onOpen}>
              <Iconify icon="eva:more-horizontal-fill" />
            </IconButton>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h6" noWrap>
              {row.email}
            </Typography>
            <IconButton color={popover.open ? 'primary' : 'default'} onClick={popover.onOpen}>
              <Iconify icon="eva:more-horizontal-fill" />
            </IconButton>
          </Stack>

          <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="body2" color="text.secondary">
              <DateDisplay
                date={row.created_at}
                variant='inherit'
                sx={{ whiteSpace: 'nowrap' }}
                format='Do MMM YYYY hh:mm A'
              />
            </Typography>
          </Stack>
        </CardContent>

        <CustomPopover
          open={popover.open}
          onClose={popover.onClose}
          arrow="right-top"
          sx={{ width: 140 }}
        >
          <MenuItem
            onClick={() => {
              confirm.onTrue();
              popover.onClose();
            }}
            sx={{ color: 'error.main' }}
          >
            <Iconify icon="solar:trash-bin-trash-bold" />
            {t('common.delete')}
          </MenuItem>
        </CustomPopover>

        <ConfirmDialog
          open={confirm.value}
          onClose={confirm.onFalse}
          title={t('common.delete')}
          content={t('admin-organization.invites.delete.confirm')}
          action={
            <Button
              variant="contained"
              color="error"
              onClick={() => (onDeleteRow ? onDeleteRow(row.id as string) : undefined)}
            >
              {t('common.delete')}
            </Button>
          }
        />
      </Card>
    )
  }

  return (
    <>
      <TableRow hover>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{row.first_name} {row.last_name}</TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>{row.email}</TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <DateDisplay date={row.created_at} variant='inherit' sx={{ whiteSpace: 'nowrap' }} format='Do MMM YYYY hh:mm A' />
        </TableCell>

        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
            <Iconify icon="eva:more-horizontal-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          {t('common.delete')}
        </MenuItem>

      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title={t('common.delete')}
        content={t('admin-organization.invites.delete.confirm')}
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => (onDeleteRow ? onDeleteRow(row.id as string) : undefined)}
          >
            {t('common.delete')}
          </Button>
        }
      />
    </>
  );
}
