import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';

import Card from '@mui/material/Card';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { Typography, CardContent, useMediaQuery } from '@mui/material';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { useBoolean } from 'src/hooks/use-boolean';

import { useTranslate } from 'src/locales';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { useAuthContext } from 'src/auth/hooks/useAuthContext';
import { useUnlinkConnectionMutation } from 'src/services/connections/connections.service';

import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import CategoryDisplay from 'src/components/category-display/category-display';

// eslint-disable-next-line import/no-cycle

import { Connection } from 'src/services/connections/connections.types';

import { ConnectionStatusLabel } from 'src/sections/admin/connections/table/connections-table-view';


type Props = {
  row: Connection;
};

function ConnectionTableRow({ row }: Props) {
  const popover = usePopover();

  const { t } = useTranslate();

  const tenant = useOrgTenant();

  const router = useRouter();

  const { enqueueSnackbar } = useSnackbar();

  const [unlinkConnection] = useUnlinkConnectionMutation();

  const authContext = useAuthContext();

  const showEditModal = useBoolean();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const viewDetail = () => {
    router.push(`${paths.dashboard.admin.connection_management.root}/${row.id}`);
  };

  if (isMobile) {
    return (
      <Card sx={{ mx: 2, mb: 1 }}>
        <CardContent>
          <Typography
            variant="h6"
            component={Link}
            to={`${paths.dashboard.admin.connection_management.root}/${row.id}`}
            gutterBottom
            color={theme.palette.primary.main}
          >
            {row.connected_organization.name}
          </Typography>

          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            {t('admin-connections.table.job_limit', { limit: row.job_limit ?? t('common.none') })}
          </Typography>
        </CardContent>

        <IconButton
          sx={{ position: 'absolute', top: 8, right: 8, zIndex: 50 }}
          color={popover.open ? 'primary' : 'default'}
          onClick={popover.onOpen}
        >
          <Iconify icon="eva:more-horizontal-fill" />
        </IconButton>

        <CustomPopover
          open={popover.open}
          onClose={popover.onClose}
          arrow="right-top"
          sx={{ width: 200 }}
        >

          <MenuItem
            onClick={() => {
              popover.onClose();
              viewDetail();
            }}
          >
            <Iconify icon="solar:eye-bold" />
            {t('common.view')}
          </MenuItem>

        </CustomPopover>

      </Card>
    );
  }

  return (
    <>
      <TableRow>
        <TableCell>
          <Link
            to={`${paths.dashboard.admin.connection_management.root}/${row.id}`}
            color={theme.palette.primary.main}
          >
            {row.connected_organization.name}
          </Link>
        </TableCell>

        <TableCell>
          <ConnectionStatusLabel status={row.status} />
        </TableCell>

        <TableCell>
          <CategoryDisplay categories={row?.approved_specializations} />
        </TableCell>

        <TableCell>{!row.job_limit ? t('common.unlimited') : row.job_limit}</TableCell>

        <TableCell align="right" sx={{ pr: 1 }}>
          <IconButton
            color={popover.open ? 'inherit' : 'default'}
            onClick={popover.onOpen}
          >
            <Iconify icon="eva:more-horizontal-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 200 }}
      >
        <MenuItem
          onClick={() => {
            popover.onClose();
            viewDetail();
          }}
        >
          <Iconify icon="solar:eye-bold" />
          {t('common.view')}
        </MenuItem>

      </CustomPopover>
    </>
  );
}

export default ConnectionTableRow;