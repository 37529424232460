import _ from 'lodash';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Stack, useMediaQuery } from '@mui/system';
import { Card, useTheme, Typography, IconButton, CardContent } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { useTranslate } from 'src/locales';
import { UsageTerm, TermStatus } from 'src/services/system/system.types';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';

type Props = {
  row: UsageTerm;
  onEditTerm: (termsId: string) => void;
  onActivateTerm: (termsId: string) => void;
};

export function TermsTableRow({ row, onEditTerm, onActivateTerm }: Props) {
  const { t } = useTranslate();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const showNotesModal = useBoolean();

  if (isMobile) {
    return (
      <Card sx={{ mx: 2, mb: 1 }}>
        <CardContent>
          <Stack direction="column" alignItems="start" mb={1}>
            <TermsTypeLabel type={row.document_type} />
          </Stack>

          <Typography
            variant="body2"
            color="text.secondary"
            gutterBottom
            sx={{ textTransform: 'capitalize' }}
          >
            {row.target_type ?? '-'}
          </Typography>

          <Stack direction="row" justifyContent="space-between" alignItems="center" mt={2} mb={2}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Label variant="outlined" color="primary">
                {row.status}
              </Label>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <IconButton onClick={() => onEditTerm(row.id)} size="small">
                <Iconify icon="mdi:edit" />
              </IconButton>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    );
  }

  return (
    <TableRow>
      <TableCell>
        <Stack direction="column" alignItems="start">
          <TermsTypeLabel type={row.document_type} />
        </Stack>
      </TableCell>

      <TableCell>
        <Typography variant="body2" color="text.secondary" sx={{ textTransform: 'capitalize' }}>
          {row.target_type ?? '-'}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography
          variant="body2"
          color="text.secondary"
          gutterBottom
          sx={{ textTransform: 'capitalize' }}
        >
          {row.region ?? '-'}
        </Typography>
      </TableCell>

      <TableCell>
        <Label variant="outlined" color={row.status === TermStatus.ACTIVE ? 'primary' : 'default'}>
          {row.status}
        </Label>
      </TableCell>

      <TableCell>
        <IconButton
          onClick={() => onActivateTerm(row.id)}
          size="small"
          disabled={row.status === TermStatus.ACTIVE}
          color="primary"
        >
          <Iconify icon="formkit:start" />
        </IconButton>
        <IconButton onClick={() => onEditTerm(row.id)} size="small">
          <Iconify icon="mdi:edit" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

const TermsTypeLabel = ({ type }: { type: string }) => (
  <Label variant="soft" color="primary">
    {_.snakeCase(type).replace(/_/g, ' ')}
  </Label>
);
