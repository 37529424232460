import { useEffect } from 'react';

import Box from '@mui/material/Box';

import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';

import ModalContainer from 'src/utils/modal-container';

import { useAuthContext } from 'src/auth/hooks/useAuthContext';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { setMaintenanceMode } from 'src/store/slices/app/appSlice';
import { SystemAttributeKey } from 'src/services/system/system.types';
import { SystemRole } from 'src/services/organisation/organisation.enum';
import { useGetSystemSettingsQuery } from 'src/services/system/system.service';

import { useSettingsContext } from 'src/components/settings';

import Main from './main';
import Header from './header';
import NavMini from './nav-mini';
import NavVertical from './nav-vertical';
import NavHorizontal from './nav-horizontal';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function DashboardLayout({ children }: Props) {
  const settings = useSettingsContext();

  const dispatch = useAppDispatch();

  const lgUp = useResponsive('up', 'lg');

  const nav = useBoolean();

  const showNavMenu = useAppSelector((state) => state.joyride.showNavMenu);

  const authContext = useAuthContext();

  const isHorizontal = settings.themeLayout === 'horizontal';

  const isMini = settings.themeLayout === 'mini';

  const renderNavMini = <NavMini />;

  const renderHorizontal = <NavHorizontal />;

  const renderNavVertical = (
    <NavVertical openNav={nav.value || showNavMenu} onCloseNav={nav.onFalse} />
  );

  const modeState = useAppSelector(
    (state) => state.app.maintenanceMode
  );

  const { currentData: systemAttributes } = useGetSystemSettingsQuery(undefined, {
    pollingInterval: modeState === true ? 5000 : 0 // Poll every minute if in maintenance mode
  });

  useEffect(() => {

    // Check if the system is in maintenance mode
    // Sync system attributes with the store
    if (systemAttributes && authContext) {
      const modeSetting = systemAttributes.find((setting) => setting.key === SystemAttributeKey.MAINTENANCE_MODE);
      const modeMsgSetting = systemAttributes.find((setting) => setting.key === SystemAttributeKey.MAINTENANCE_MESSAGE);
      if (!authContext.user?.system_role || authContext.user?.system_role !== SystemRole.SUPER_ADMIN) {
        if (modeSetting?.value === true) {
          dispatch(setMaintenanceMode({ mode_state: true, message: modeMsgSetting?.value }));
        }
        else {
          dispatch(setMaintenanceMode({ mode_state: false, message: undefined }));
        }
      }
    }
  }, [systemAttributes, dispatch, authContext]);

  if (isHorizontal) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        {lgUp ? renderHorizontal : renderNavVertical}

        <Main>{children}</Main>
      </>
    );
  }

  if (isMini) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        <Box
          sx={{
            minHeight: 1,
            display: 'flex',
            flexDirection: { xs: 'column', lg: 'row' },
          }}
        >
          {lgUp ? renderNavMini : renderNavVertical}

          <Main>{children}</Main>
        </Box>
      </>
    );
  }

  return (
    <>
      <Header onOpenNav={nav.onTrue} />

      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
        }}
      >
        {renderNavVertical}

        <ModalContainer />

        <Main>{children}</Main>
      </Box>
    </>
  );
}
