import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { FeatureType } from 'src/types/subscription.types';

import { ShowFeatureAccessModalProps } from './billing-actions.types';

interface FeatureAccessModalState {
  open: boolean;
  featureType?: FeatureType;
}

export interface IBillingState {
  featureAccessModal: FeatureAccessModalState;
}

const initialState: IBillingState = {
  featureAccessModal: {
    open: false,
    featureType: undefined
  }
};

export const billingSlice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    openFeatureAccessModal: (state, action: PayloadAction<ShowFeatureAccessModalProps>) => {
      state.featureAccessModal.open = true;
      state.featureAccessModal.featureType = action.payload.featureType;
    },
    closeFeatureAccessModal: (state) => {
      state.featureAccessModal.open = false;
      state.featureAccessModal.featureType = undefined;
    }
  }
});

export const billingReducer = billingSlice.reducer;

export const {
  openFeatureAccessModal,
  closeFeatureAccessModal
} = billingSlice.actions;
