import React, { useState, ReactElement } from 'react';

import { Stack } from '@mui/system';
import { KeyboardArrowRight } from '@mui/icons-material';
import { Menu, Button, MenuItem, IconButton, ListItemText } from '@mui/material';

interface MenuItemType {
  label: string;
  icon?: ReactElement;
  onClick?: Function;
  children?: MenuItemType[];
  color?: string;
}

interface NestedMenuItemProps extends MenuItemType {
  children?: MenuItemType[];
  onClose: () => void;
}

const NestedMenuItem: React.FC<NestedMenuItemProps> = ({ label, children, icon, onClick, color, onClose }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isSubMenu = Array.isArray(children);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {

    if (isSubMenu) {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
    } else {
      if (onClick) onClick();
      onClose();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <MenuItem onClick={handleClick} sx={{ color }}>
        <Stack direction="row" spacing={2}>
          {icon}
          <ListItemText >{label}</ListItemText>
        </Stack>
        {isSubMenu && <KeyboardArrowRight />}
      </MenuItem>
      {isSubMenu && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
          {children.map((child, index) => (
            <NestedMenuItem key={index} {...child} onClose={onClose} />
          ))}
        </Menu>
      )}
    </>
  );
};


type TriggerElementProps = {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

type ButtonTriggerProps = TriggerElementProps & {
  buttonText: string;
}

const ButtonTrigger: React.FC<ButtonTriggerProps> = ({ onClick, buttonText }) => (
  <Button onClick={onClick}>{buttonText}</Button>
);

type IconTriggerProps = TriggerElementProps & {
  icon: ReactElement;
}

const IconTrigger: React.FC<IconTriggerProps> = ({ onClick, icon }) => (
  <IconButton onClick={onClick}>{icon}</IconButton>
);

interface NestedMenuProps {
  menuItems: MenuItemType[];
  buttonText?: string;
}

type TriggerType =
  | { type: 'button'; buttonText: string }
  | { type: 'icon'; icon: ReactElement };

interface NestedMenuProps {
  menuItems: MenuItemType[];
  trigger: TriggerType;
}

const NestedMenu: React.FC<NestedMenuProps> = ({ menuItems, trigger }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderTrigger = () => {
    switch (trigger.type) {
      case 'button':
        return <ButtonTrigger onClick={handleClick} buttonText={trigger.buttonText} />;
      case 'icon':
        return <IconTrigger onClick={handleClick} icon={trigger.icon} />;
      default:
        return null;
    }
  };

  return (
    <>
      {renderTrigger()}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuItems.map((item, index) => (
          <NestedMenuItem key={index} {...item} onClose={handleClose} />
        ))}
      </Menu>
    </>
  );
};

export default NestedMenu;