import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import FormHelperText from '@mui/material/FormHelperText';

import Label from 'src/components/label';

import Editor, { EditorProps } from '../editor';

// ----------------------------------------------------------------------

interface Props extends EditorProps {
  name: string;
  label?: string | undefined;
  disabled?: boolean;
}

export default function RHFEditor({ name, label, helperText, disabled, ...other }: Props) {
  const {
    control,
    watch,
    setValue,
    formState: { isSubmitSuccessful },
  } = useFormContext();

  const values = watch();

  useEffect(() => {
    if (values[name] === '<p><br></p>') {
      setValue(name, '', {
        shouldValidate: !isSubmitSuccessful,
      });
    }
  }, [isSubmitSuccessful, name, setValue, values]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div style={{ position: 'relative' }}>
          {label && (
            <Label
              style={{
                position: 'absolute',
                background: 'white',
                zIndex: 20,
                top: -12,
                left: 8,
                fontWeight: 600,
              }}
            >
              {label}
            </Label>
          )}
          <Editor
            id={name}
            value={field.value}
            disabled={disabled}
            onChange={field.onChange}
            error={!!error}
            helperText={
              (!!error || helperText) && (
                <FormHelperText error={!!error} sx={{ px: 2 }}>
                  {error ? error?.message : helperText}
                </FormHelperText>
              )
            }
            {...other}
          />
        </div>
      )}
    />
  );
}
