import { useCallback } from 'react';

import { Stack, alpha, Button, Typography } from '@mui/material';

import { useTranslate } from 'src/locales';
import { useAppDispatch } from 'src/store/store';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { toggleCreateConnectionModal } from 'src/store/slices/connections/connectionsSlice';

import Iconify from 'src/components/iconify';

type Props = {
  filterQuery: string;
  onFilters: (value: string) => void;
};

export default function ConnectionsTableToolbar({ filterQuery, onFilters }: Props) {
  const { t } = useTranslate();

  const tenant = useOrgTenant();

  const dispatch = useAppDispatch();

  const onInviteTenant = useCallback(() => {
    dispatch(toggleCreateConnectionModal());
  }, [dispatch]);

  return (
    <Stack
      spacing={2}
      alignItems={{ xs: 'flex-end', md: 'center' }}
      direction={{
        xs: 'column',
        md: 'row',
      }}
      sx={{
        boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
        p: 2.5,
        pr: { xs: 2.5, md: 1 },
        width: '100%',
      }}
    >
      <Stack
        sx={{ width: '100%' }}
        direction="row"
        justifyContent="space-between"
        spacing={2}
        flexGrow={1}
      >
        <Stack>
          <Typography variant="subtitle1" fontSize={18}>
            {t('admin-connections.title.connections')}
          </Typography>
        </Stack>
        <Stack display="flex" flexDirection="row">
          <Button
            size="medium"
            endIcon={<Iconify icon="material-symbols:add" />}
            variant="contained"
            sx={{ maxHeight: 36 }}
            onClick={onInviteTenant}
          >
            {t(`admin-connections.invite.${tenant}`)}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}
