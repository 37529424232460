import { ReactNode } from 'react';

import {
  Card,
  Chip,
  Stack,
  useTheme,
  CardProps,
  Typography,
  CardContent,
  CardActionArea,
} from '@mui/material';

import { useTranslate } from 'src/locales';

type Props = {
  title: string;
  subtitle: string;
  selected: boolean;
  flexDirection?: 'row' | 'column';
  children?: ReactNode | ReactNode[];
  disabled?: boolean;
} & CardProps;

export default function SelectableCard({
  selected,
  title,
  subtitle,
  flexDirection = 'row',
  children,
  disabled,
  onClick,
  ...props
}: Props) {
  const theme = useTheme();

  const { t } = useTranslate();

  return (
    <Card
      sx={{
        borderColor: selected ? theme.palette.primary.main : undefined,
        backgroundColor: disabled ? theme.palette.grey[200] : undefined,
      }}
      variant="outlined"
      onClick={!disabled ? onClick : undefined}
      {...props}
    >
      <CardActionArea disableRipple={selected} disabled={disabled}>
        <CardContent>
          <Stack
            flexDirection={flexDirection}
            justifyContent={flexDirection === 'row' ? 'space-between' : 'flex-start'}
          >
            <Stack>
              <Typography variant="h5">{title}</Typography>
              <Typography variant="subtitle2">{subtitle}</Typography>
            </Stack>

            <Stack>{children}</Stack>
            {disabled && (
              <Chip
                variant="soft"
                label={t('admin-connections.create_modal.terms_of_engagement.disabled')}
                color="info"
              />
            )}
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
