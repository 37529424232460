import { isString } from 'lodash';

import i18n from 'src/locales/i18n';

import { TenantType } from 'src/types/enums';
import { BusinessErrorCodes } from 'src/types/business-errors';

import { jobsApi } from './jobs.service';
import { JobFilters } from './jobs.types';
import { ApplicationStage } from '../applications/applications.types';

export const generateAcceptJobLinkRequestError = (e: any) => {
  if (isString(e?.data?.error_code)) {
    if (e?.data.error_code === BusinessErrorCodes.ResourceNotFound) {
      return i18n.t('jobs.action.api.not_found');
    }
    if (e?.data.error_code === BusinessErrorCodes.InvalidLinkRequestState) {
      return i18n.t('jobs.action.api.invalid_state');
    }

    if (e?.data.error_code === BusinessErrorCodes.JobAlreadyLinked) {
      return i18n.t('jobs.action.api.link_exists');
    }

    if (e?.data.error_code === BusinessErrorCodes.RecruitmentTypeRestriction) {
      return i18n.t('jobs.action.api.recruitment_type_restriction');
    }

    if (e?.data.error_code === BusinessErrorCodes.CannotLinkInternalJob) {
      return i18n.t('jobs.action.api.cannot_link_internal');
    }

    if (e?.data.error_code === BusinessErrorCodes.JobIndustryNotApproved) {
      return i18n.t('jobs.action.api.specialization_restriction');
    }

    if (e?.data.error_code === BusinessErrorCodes.DefaultFeeSettingsNotConfigured) {
      return i18n.t('jobs.action.api.missing_default_fee');
    }
  }
  return i18n.t('jobs.action.api.default_error');
};

export const generateRejectJobLinkRequestError = (e: any) => {
  if (isString(e?.data?.error_code)) {
    if (e?.data.error_code === BusinessErrorCodes.ResourceNotFound) {
      return i18n.t('jobs.action.api.not_found');
    }

    if (e?.data.error_code === BusinessErrorCodes.InvalidLinkRequestState) {
      return i18n.t('jobs.action.api.invalid_state');
    }
  }
  return i18n.t('jobs.action.api.default_error');
};

export const updateJobApplicationStatus = (
  jobId: string,
  destinationColumnId: string,
  filters: JobFilters
) =>
  jobsApi.util.updateQueryData(
    'getMyActiveJobs',
    Object.values(filters).every((value) => !value) ? undefined : filters,
    (jobsResponse) => ({
      ...jobsResponse,
      results: jobsResponse.results.map((job) =>
        job.id === jobId
          ? { ...job, latest_application_status: destinationColumnId as ApplicationStage }
          : job
      ),
    }),
    true
  );

export const generateJobApplicationStatusUpdateError = (e: any) => {
  if (isString(e?.data?.error_code)) {
    if (e?.data.error_code === BusinessErrorCodes.JobHasActiveApplication) {
      return i18n.t('jobs.api.update_stage.is_linked');
    }
  }

  return i18n.t('jobs.api.update_stage.default_error');
};

export const generateArchiveJobError = (e: any, tenant: TenantType) => {
  if (isString(e?.data?.error_code)) {
    if (e?.data.error_code === BusinessErrorCodes.JobHasActiveApplication) {
      return tenant === TenantType.Candidate
        ? i18n.t('jobs.api.archive.linked')
        : i18n.t('jobs.api.archive.linked_org');
    }
  }
  return i18n.t('jobs.api.archive.default_error');
};

export const generateCreateJobError = (e: any) => {
  if (isString(e?.data?.error_code)) {
    if (e?.data.error_code === BusinessErrorCodes.WebsiteScrapeFailed) {
      return i18n.t('jobs.api.create.scrape_failed');
    }
  }
  return i18n.t('jobs.api.create.default_error');
};

export const generateCandidateInviteError = (e: any) => {
  if (isString(e?.data?.error_code)) {
    if (e?.data.error_code === BusinessErrorCodes.CandidateAlreadyApplied) {
      return i18n.t('jobs.api.invite.already_applied');
    }

    if (e?.data.error_code === BusinessErrorCodes.JobInviteExists) {
      return i18n.t('jobs.api.invite.already_invited');
    }
  }
  return i18n.t('jobs.api.invite.default_error');
};
