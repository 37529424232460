import i18n from 'src/locales/i18n';
import { Notification, NotificationType } from 'src/services/notifications/notifications.types';

export const getConnectionNotificationMessage = (notification: Notification) => {
  const notificationMessage = i18n.t(`notifications.connection_request.${notification.type}`);
  let targetName;

  switch (notification.type) {
    case NotificationType.INITIATE_CONNECTION_REQUEST:
      targetName = notification.metadata?.variables?.initiator_name;
      break;
    case NotificationType.CONNECTION_REQUEST_ACCEPTED:
      targetName = notification.metadata?.variables?.target_name;
      break;
    case NotificationType.CONNECTION_REQUEST_REJECTED:
      targetName = notification.metadata?.variables?.target_name;
      break;
    case NotificationType.CONNECTION_DISCONNECT:
      targetName = notification.metadata?.variables?.company_name;
      break;
    default:
      targetName = '';
  }

  return `${targetName} ${notificationMessage}`;
};

export const getJobLinkRequestNotificationMessage = (notification: Notification) => {
  switch (notification.type) {
    case NotificationType.JOB_CLIENT_VERIFIED:
      return i18n.t('notifications.job_link_request.JOB_CLIENT_VERIFIED', {
        role: notification.metadata.variables?.job_title ?? '',
      });

    default:
      return notification.metadata.body;
  }
};

export const getJobInviteNotificationMessage = (notification: Notification) => {
  switch (notification.type) {
    case NotificationType.JOB_INVITE_INITIATED:
      return i18n.t('notifications.job_invite.JOB_INVITE_INITIATED', {
        role:
          notification.metadata.variables?.job_title ??
          i18n.t('notifications.job_invite.default_title'),
        company:
          notification.metadata.variables?.initiator_name ??
          i18n.t('notifications.job_invite.default_target'),
      });
    case NotificationType.JOB_INVITE_ACCEPTED:
      return i18n.t('notifications.job_invite.JOB_INVITE_ACCEPTED', {
        role:
          notification.metadata.variables?.job_title ??
          i18n.t('notifications.job_invite.default_title'),
      });
    case NotificationType.JOB_INVITE_REJECTED:
      return i18n.t('notifications.job_invite.JOB_INVITE_ACCEPTED', {
        role:
          notification.metadata.variables?.job_title ??
          i18n.t('notifications.job_invite.default_title'),
      });
    default:
      return notification.metadata.body;
  }
};

export const getApplicationNotificationMessage = (notification: Notification) => {
  switch (notification.type) {
    case NotificationType.APPLICATION_STAGE_CHANGE_REQUEST_INITIATED:
      return i18n.t('notification_types.APPLICATION_STAGE_CHANGE_REQUEST_INITIATED.description', {
        role: notification.metadata.variables?.job_title ?? '',
        stage: notification.metadata.variables?.target_stage ?? '',
      });

    default:
      return notification.metadata.variables?.job_title ?? notification.metadata.body;
  }
};

export const getInterviewReviewNotificationMessage = (notification: Notification) => {
  switch (notification.type) {
    case NotificationType.APPLICATION_STAGE_CHANGE_REQUEST_INITIATED:
      return i18n.t('notification_types.INTERVIEW_IN_REVIEW.description');

    default:
      return notification.metadata.variables?.job_title ?? notification.metadata.body;
  }
};

export const getInterviewNotificationMessage = (notification: Notification) => {
  switch (notification.type) {
    case NotificationType.INTERVIEW_SCHEDULED:
      return i18n.t('notification_types.INTERVIEW_SCHEDULED.description', {
        role: notification.metadata.variables?.job_title ?? '',
      });
    case NotificationType.INTERVIEW_CANCELLED:
      return i18n.t('notification_types.INTERVIEW_CANCELLED.description', {
        role: notification.metadata.variables?.job_title ?? '',
      });
    case NotificationType.INTERVIEW_REMINDER:
      return i18n.t('notification_types.INTERVIEW_REMINDER.description', {
        role: notification.metadata.variables?.job_title ?? '',
      });
    case NotificationType.INTERVIEW_IN_REVIEW:
      return i18n.t('notification_types.INTERVIEW_IN_REVIEW.description', {
        role: notification.metadata.variables?.job_title ?? '',
      });

    default:
      return notification.metadata.variables?.job_title ?? notification.metadata.body;
  }
};
