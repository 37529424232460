import { useState } from 'react';
import { debounce } from 'lodash';

import { Card } from '@mui/material';

import { PageOptions } from 'src/services/api.types';
import { useGetAllAccountsQuery } from 'src/services/admin/admin.service';

import { TableSkeleton } from 'src/components/table';

import GlobalAccountsTable from './global-accounts-table';

const default_page: PageOptions = {
  page: 1,
  per_page: 20
};

export default function GlobalAccountsTableView() {
  const [pageOptions, setPageOptions] = useState<PageOptions>(default_page);

  const [searchQuery, setSearchQuery] = useState('');

  const { currentData, isLoading } = useGetAllAccountsQuery({
    ...pageOptions,
    search_query: searchQuery,
  });

  const onChangePage = (_event: any, pageNumber: number) => {
    setPageOptions((prev) => ({ ...prev, page: pageNumber + 1 }));
  };

  const onChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPageOptions((prev) => ({ ...prev, per_page: parseInt(event.target.value, 10) }));
  };

  const debounceSearch = debounce((value: string) => {
    setSearchQuery(value);
  }, 500);

  return (
    <Card>
      {isLoading ? (
        <TableSkeleton />
      ) : (
        <GlobalAccountsTable
          accounts={currentData}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          currentPage={pageOptions}
          onFilter={debounceSearch}
        />
      )
      }
    </Card>
  );
}