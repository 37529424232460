import { DebouncedFunc } from 'lodash';
import { Link } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { useMemo, useCallback } from 'react';

import {
  Card,
  Chip,
  Stack,
  Table,
  Button,
  Tooltip,
  MenuItem,
  TableRow,
  TableBody,
  TableCell,
  TextField,
  IconButton,
  Typography,
  InputAdornment,
  TableContainer,
} from '@mui/material';

import { paths } from 'src/routes/paths';

import { useBoolean } from 'src/hooks/use-boolean';

import { useTranslate } from 'src/locales';
import { rootApi } from 'src/services/rootApi';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { PageOptions, PaginatedResponse } from 'src/services/api.types';
import { isAccountOnline } from 'src/store/slices/accounts/accountsSlice';
import { AccountStatus, RestrictedAccountProfile } from 'src/services/account/account.types';
import { useUpdateAccountStatusMutation } from 'src/services/organisation/organisation.service';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import DateDisplay from 'src/components/date/date-display';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import {
  useTable,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/table';

import { BusinessErrorCodes } from 'src/types/business-errors';

type Props = {
  accounts?: PaginatedResponse<RestrictedAccountProfile>;
  currentPage: PageOptions;
  onChangePage: (_event: any, pageNumber: number) => void;
  onChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onFilter?: DebouncedFunc<(value: string) => void>;
};

export default function GlobalAccountsTable({
  accounts,
  onChangePage,
  onChangeRowsPerPage,
  onFilter,
  currentPage,
}: Props) {

  const table = useTable();

  const { t } = useTranslate();

  const dispatch = useAppDispatch();

  const denseHeight = table.dense ? 56 : 56 + 20;

  const [updateAccountStatus, { isLoading: updateStatusLoading }] = useUpdateAccountStatusMutation();

  const tableLabels = useMemo(
    () => [
      { id: 'account', label: 'Account' },
      { id: 'org_role', label: 'Organization' },
      { id: 'status', label: 'Account Status' },
      { id: 'timezone', label: 'Timezone' },
      { id: 'last_login', label: 'Last Login' },
      { id: 'controls', label: '', width: 120 }
    ],
    []
  );

  const notFound = !accounts?.count;

  const handleStatusUpdate = useCallback(
    async (id: string, status: AccountStatus) => {
      try {

        const organization = accounts?.results?.find((account) => account.id === id)?.organization;

        if (!organization) {
          return;
        }

        await updateAccountStatus({
          account_id: id,
          org_id: organization?.id as string,
          status,
        }).unwrap();

        dispatch(rootApi.util.invalidateTags(['GlobalAccounts']));

        enqueueSnackbar(
          status === AccountStatus.ACTIVE
            ? t('account.status.api.unlock.success')
            : t('account.status.api.lock.success'),
          { variant: 'success' }
        );
      }
      catch (e) {
        if (e.data?.error_code === BusinessErrorCodes.CannotLockOwnAccount) {
          enqueueSnackbar(t('account.status.api.cannot_lock_self'), { variant: 'error' });
        }
        else if (e.data?.error_code === BusinessErrorCodes.OrganizationAdminRequired) {
          enqueueSnackbar(t('account.status.api.must_have_one_admin'), { variant: 'error' });
        }
        else if (status === AccountStatus.ACTIVE) {
          enqueueSnackbar(t('account.status.api.unlock.default_error'), { variant: 'error' });
        }
        else {
          enqueueSnackbar(t('account.status.api.lock.default_error'), { variant: 'error' });
        }
      }
    },
    [t, updateAccountStatus, accounts, dispatch]
  );

  return (
    <Card sx={{ pt: { xs: 2, sm: 'unset' } }}>
      {onFilter && (
        <Stack
          sx={{
            p: 2.5,
            pr: { xs: 2.5, md: 1 },
          }}
        >
          <TextField
            onChange={(e) => onFilter(e.target.value)}
            label="Search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              ),
            }}
          />
        </Stack>
      )}

      <TableContainer sx={{ overflow: 'unset' }}>
        <Scrollbar>
          <Table sx={{ minWidth: 680 }} size={table.dense ? 'small' : 'medium'}>
            <TableHeadCustom headLabel={tableLabels} />

            <TableBody>
              {accounts?.results?.map((row) => <AccountsTableRow key={row.id} row={row} onStatusChange={handleStatusUpdate} />)}

              <TableEmptyRows
                height={denseHeight}
                emptyRows={emptyRows(table.page, table.rowsPerPage, accounts?.count ?? 0)}
              />

              <TableNoData notFound={notFound} />
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>

      <TablePaginationCustom
        count={accounts?.count ?? 0}
        page={currentPage.page}
        rowsPerPage={currentPage.per_page}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
        dense={table.dense}
        onChangeDense={table.onChangeDense}
      />
    </Card>
  );
}

const AccountsTableRow = ({ row, onStatusChange }: { row: RestrictedAccountProfile, onStatusChange?: (id: string, status: AccountStatus) => void; }) => {

  const { t } = useTranslate();

  const lockControl = useBoolean();

  const popover = usePopover();

  const isOnline = useAppSelector((state) => isAccountOnline(state, row.id));

  const handleUpdateStatus = () => {
    lockControl.onFalse();
    if (onStatusChange) {
      if (row.status === AccountStatus.LOCKED) {
        onStatusChange(row.id as string, AccountStatus.ACTIVE);
      } else {
        onStatusChange(row.id as string, AccountStatus.LOCKED);
      }
    }
  }

  return (
    <>
      <TableRow>
        <TableCell>
          <Stack direction="row" alignItems="center">
            {
              isOnline ? (
                <Tooltip title={t('account.online_status.online')} placement="top" arrow>
                  <Iconify icon="icon-park-outline:dot" color="green" width={25} />
                </Tooltip>
              ) : (
                <Tooltip title={t('account.online_status.offline')} placement="top" arrow>
                  <Iconify icon="icon-park-outline:dot" color="gray" width={25} />
                </Tooltip>

              )
            }
            <Typography variant='subtitle2'>
              {`${row.first_name} ${row.last_name}`}
            </Typography>

          </Stack>
          <Typography variant='body2'>
            {row.email}
          </Typography>
        </TableCell>
        <TableCell>
          <Stack direction='column' spacing={1} alignItems="start">
            <Link to={`${paths.dashboard.restricted.tenants.root}/${row.organization.id}`}>
              {row.organization?.company_name ?? 'View Candidate'}
            </Link>
            <Chip label={t(`enums.org_role.${row.org_role}`)} color="default" size='small' variant='outlined' />
          </Stack>
        </TableCell>
        <TableCell>
          <Chip label={t(`enums.account_status.${row.status}`)} color="default" size='small' />
        </TableCell>
        <TableCell>
          <Typography variant='body2'>
            {row.timezone}
          </Typography>
        </TableCell>
        <TableCell>
          <DateDisplay date={row.last_login_at as Date} variant='body2' />
        </TableCell>
        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <Stack direction="row" justifyContent="flex-end" alignItems="center">

            {
              row.status === AccountStatus.LOCKED && (
                <Tooltip title={t('account.status.locked_tooltip')} placement="top" arrow>
                  <Iconify icon="uim:lock" color="default" />
                </Tooltip>
              )
            }

            <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
              <Iconify icon="eva:more-horizontal-fill" />
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 160 }}
      >
        {
          row.status === AccountStatus.LOCKED ?
            <MenuItem
              onClick={() => {
                popover.onClose();
                lockControl.onTrue();
              }}
            >
              <Iconify icon="uim:unlock" />
              {t('account.status.unlock_account')}
            </MenuItem>
            :
            <MenuItem
              onClick={() => {
                popover.onClose();
                lockControl.onTrue();
              }}
            >
              <Iconify icon="uim:lock" />
              {t('account.status.lock_account')}
            </MenuItem>
        }

      </CustomPopover >

      <ConfirmDialog
        open={lockControl.value}
        onClose={lockControl.onFalse}
        title={row.status === AccountStatus.LOCKED ? t('account.status.confirm_title_unlock') : t('account.status.confirm_title_lock')}
        content={
          <Typography variant="body2">
            {row.status === AccountStatus.LOCKED ? t('account.status.unlock_account_confirm') : t('account.status.lock_account_confirm')}
          </Typography>
        }
        action={
          <Button variant="contained" color="primary" onClick={handleUpdateStatus}>
            {row.status === AccountStatus.LOCKED ? t('account.status.unlock_account') : t('account.status.lock_account')}
          </Button>
        }
      />

    </>
  );
};
