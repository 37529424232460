

import * as Yup from 'yup';
import { t } from 'i18next';
import { isString } from 'lodash';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { useMemo, useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

import { LoadingButton } from '@mui/lab';
import { Box, Stack } from '@mui/system';
import { Card, Grid, Alert, Button, Select, MenuItem, Skeleton, TextField, IconButton, Typography } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { useAuthContext } from 'src/auth/hooks/useAuthContext';
import { FieldOption, RemoteField, InternalField, IIntegrationUser, InternalFieldType, IntegrationSetting, IntegrationFieldMapItem } from 'src/services/integration/integrations.types';
import { useGetIntegrationQuery, useSetupIntegrationMutation, useGetIntegrationFieldsQuery, useGetIntegrationAccountsQuery, useGetIntegrationFieldMapQuery, useGetIntegrationSettingsQuery } from 'src/services/integration/integrations.service';

import Logo from 'src/components/logo';
import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import { RHFAutocomplete } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';

import ValueMapperModal from 'src/sections/admin/integrations/providers/job-adder/value-mapper-modal';

// ----------------------------------------------------------------------

type JobAdderSettingsProps = {
  integrationId: string;
};

enum TAB_OPTION {
  SETTINGS = 'settings',
  FIELD_MAPPER = 'field_mapper',
}

export default function JobAdderSetupView({
  integrationId
}: JobAdderSettingsProps) {

  const { enqueueSnackbar } = useSnackbar();
  const authContext = useAuthContext();

  const valueMapperControl = useBoolean();

  const [currentFieldMapItem, setSelectedFieldMapItem] = useState<any>(null);

  const [errorMsg, setErrorMsg] = useState<any>(null);

  const { integration_id } = useParams();

  const [tab, setTab] = useState(TAB_OPTION.SETTINGS);
  const [fieldMap, setFieldMap] = useState<{ internal: InternalField, remote: RemoteField, value_mapping?: { internal: FieldOption, remote: FieldOption }[] }[]>([]);

  const { currentData: integrationDetail, isLoading: isRetrievingIntegration } = useGetIntegrationQuery(
    {
      integrationId: integration_id as string,
    },
    {
      skip: !integration_id
    }
  );

  const { currentData: integrationsettings, isLoading: isRetrievingSettings } = useGetIntegrationSettingsQuery(
    {
      integrationId: integration_id as string,
    },
    {
      skip: !integration_id
    }
  );

  const { currentData: integrationFields, isLoading: isRetrievingFields } = useGetIntegrationFieldsQuery(
    {
      integrationId: integration_id as string,
    },
    {
      skip: !integration_id
    }
  );

  const { currentData: integrationFieldMap, isLoading: isRetrievingFieldMap } = useGetIntegrationFieldMapQuery(
    {
      integrationId: integration_id as string,
    },
    {
      skip: !integration_id
    }
  );

  const [setupIntegration, { isLoading: isSettingUpIntegration }] = useSetupIntegrationMutation();

  const { currentData: accounts } = useGetIntegrationAccountsQuery(
    {
      integrationId: integration_id as string,
    },
    { skip: !integration_id }
  );

  const JobAdderSettingsSchema = Yup.object().shape({
    administrator: Yup.object().shape({
      id: Yup.string().required(t('validation.required')),
      first_name: Yup.string().required(t('validation.required')),
      last_name: Yup.string().required(t('validation.required')),
      email: Yup.string().required(t('validation.required')),
    }).required(t('validation.required'))
  });

  const methods = useForm({
    resolver: yupResolver(JobAdderSettingsSchema)
  });

  const {
    setValue,
    handleSubmit,
    reset,
    formState: { isSubmitting, isValid },
  } = methods;

  useEffect(() => {
    if (integrationFieldMap) {
      setFieldMap(integrationFieldMap.map((map) => ({ internal: map.internal, remote: map.remote, value_mapping: map.value_mapping })));
    }
  }, [integrationFieldMap]);


  const buildSettingsDto = (data: any) => {
    if (!integrationsettings) return [];

    const settings = [];

    const adminSetting = integrationsettings.find((setting) => setting.setting_type === IntegrationSetting.ADMINISTRATOR);

    if (adminSetting) {
      settings.push({
        id: adminSetting.id as string,
        value: data.administrator.id.toString(),
      });
    }

    return settings;
  }

  const fieldsToSetup = useMemo(() => {
    if (!integrationFields) return [];
    return integrationFields?.internal.filter((field) => [InternalFieldType.JOB_APPLICATION_STAGE, InternalFieldType.JOB_EMPLOYMENT_TYPE].includes(field.key as InternalFieldType));
  }, [integrationFields]);

  const openValueMapper = (internalKey: string) => {

    const selectedFieldMapItem = fieldMap.find((map) => map.internal.key === internalKey);

    if (!selectedFieldMapItem) return;

    setSelectedFieldMapItem(selectedFieldMapItem);
    valueMapperControl.onTrue();
  }

  const closeValueMapper = () => {
    setSelectedFieldMapItem(null);
    valueMapperControl.onFalse();
  }

  const getOptionsForFieldType = (entity: string, field: InternalField) => {
    if (!integrationFields) return [];

    return integrationFields?.remote.filter((remoteField) => remoteField.entity === entity);
  }

  const getSelectedRemoteField = (internalField: InternalField) => {
    if (!integrationFieldMap || !fieldMap) return '--';

    const mapping = fieldMap?.find((map) => map.internal.key === internalField.key);
    return mapping?.remote?.key || '--';
  }

  const onUpdateFieldMap = (fieldKey: string, remoteField: string) => {

    const updatedFieldMap = fieldMap.map((map) => {
      if (map.internal.key === fieldKey) {
        const remoteFieldObj = integrationFields?.remote.find((field) => field.key === remoteField);
        return { ...map, remote: remoteFieldObj };
      }
      return map;
    });

    // what if its a new field?
    if (!updatedFieldMap.find((map) => map.internal.key === fieldKey)) {
      const remoteFieldObj = integrationFields?.remote.find((fieldItem) => fieldItem.key === remoteField);
      const internalFieldObj = integrationFields?.internal.find((fieldItem) => fieldItem.key === fieldKey);

      if (!remoteFieldObj || !internalFieldObj) return;
      updatedFieldMap.push({ internal: internalFieldObj, remote: remoteFieldObj });
    }

    setFieldMap(updatedFieldMap as any);
  }

  const onValueMapUpdated = (fieldMapItem: IntegrationFieldMapItem, valueMapping: { internal: FieldOption, remote: FieldOption }[]) => {
    const updatedFieldMap = fieldMap.map((map) => {
      if (map.internal.key === fieldMapItem.internal.key) {
        return { ...map, value_mapping: valueMapping };
      }
      return map;
    });

    setFieldMap(updatedFieldMap);
  }

  const onSettingsSelected = handleSubmit(async (data: any) => {
    setTab(TAB_OPTION.FIELD_MAPPER);
  })

  const onPreviousStep = () => {
    setTab(TAB_OPTION.SETTINGS);
  }

  const handleSetupIntegration = async () => {
    setErrorMsg(null);

    if (!integration_id) return;

    const settings = buildSettingsDto(methods.getValues());

    // check if all values for each field are mapped (value_mapping)
    const fieldsToCheck = fieldMap.filter((map) => fieldsToSetup.find((fieldToSetup) => fieldToSetup.key === map.internal.key));

    if (fieldsToCheck.some((field) => !field.value_mapping)) {
      setErrorMsg(t('integrations.detail.configuration.setup.errors.missing_mappings'));
      return;
    }

    const fields = fieldMap.map((map) => ({ internal: map.internal.key, remote: map.remote.id as string, value_mapping: map.value_mapping }));

    try {

      await setupIntegration({
        integrationId: integration_id as string,
        settings,
        fields
      }).unwrap();

      enqueueSnackbar(t('integrations.detail.configuration.setup.success'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t('integrations.detail.configuration.setup.errors.default'), { variant: 'error' });
    }
  }

  return (
    <Stack spacing={1} direction="column">

      <Alert variant="outlined" severity="info" sx={{ mb: 2 }}>
        {t('integrations.detail.configuration.setup.alert')}
      </Alert>

      <Card sx={{ py: 2, px: 4 }}>

        {
          tab === TAB_OPTION.SETTINGS && (
            <Box sx={{ mb: 2 }}>
              <Typography variant="h5">
                {t('integrations.detail.configuration.setup.select_user')}
              </Typography>
            </Box>
          )
        }

        {
          tab === TAB_OPTION.FIELD_MAPPER && (
            <Box sx={{ mb: 2 }}>
              <Typography variant="h5">
                {t('integrations.detail.configuration.setup.field_mapping')}
              </Typography>
            </Box>
          )
        }

        {
          (isRetrievingIntegration || isRetrievingSettings) ? (
            <Grid container gap={2} sx={{ px: 2, mt: 2 }}>
              <Grid
                xs={12}
              >
                <Skeleton variant="rectangular" height={20} width="50%" />
              </Grid>
              <Grid
                xs={12}
              >
                <Skeleton variant="rectangular" height={200} />
              </Grid>
            </Grid>
          ) :
            (
              <>
                {
                  tab === TAB_OPTION.SETTINGS && (
                    <FormProvider methods={methods}>
                      <Stack spacing={2}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12}>
                            <RHFAutocomplete
                              name="administrator"
                              label={t('integrations.detail.configuration.settings.administrator.label')}
                              placeholder={t(
                                'integrations.detail.configuration.settings.administrator.placeholder'
                              )}
                              helperText={t(`integrations.detail.configuration.settings.administrator.helper_text`)}
                              options={accounts || []}
                              filterOptions={(x) => x}
                              renderOption={(props, option: IIntegrationUser) => (
                                <li {...props}>
                                  <Stack direction="column">
                                    <Typography variant="body1">
                                      {`${option.first_name} ${option.last_name}`}
                                    </Typography>
                                    <Typography variant="body2">{option.email}</Typography>
                                  </Stack>
                                </li>
                              )}
                              getOptionLabel={(option) =>
                                isString(option) ? option : `${option.first_name} ${option.last_name} (${option.email})`
                              }
                              isOptionEqualToValue={(option, value) => option.id === value.id}
                              getOptionKey={(option) => (option as IIntegrationUser).id as string}
                            />
                          </Grid>
                        </Grid>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                          <LoadingButton
                            type="submit"
                            onClick={onSettingsSelected}
                            variant="contained"
                            disabled={!isValid}
                          >
                            {t('common.next')}
                          </LoadingButton>
                        </Box>
                      </Stack>
                    </FormProvider>
                  )
                }

                {
                  tab === TAB_OPTION.FIELD_MAPPER && (
                    <Box>
                      <Grid container columns={12} spacing={2} sx={{ px: 2, mt: 2 }}>
                        <Grid item xs={5}>
                          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <Logo sx={{ height: '1.5em', objectFit: 'contain' }} disabledLink />
                          </Box>
                        </Grid>

                        <Grid item sm={1} sx={{
                          display: { xs: "none", lg: "block" }
                        }} />

                        <Grid item xs={5} >
                          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <Image src={integrationDetail?.provider.logo} sx={{ height: '1em' }} />
                          </Box>
                        </Grid>

                        <Grid item xs={1} />

                        {
                          fieldsToSetup.map((field) => (
                            <>
                              <Grid item xs={5}>
                                <TextField
                                  inputProps={{ sx: { px: 2, py: 1, borderRadius: 10 } }}
                                  fullWidth
                                  variant="filled"
                                  value={field.name}
                                  disabled
                                />
                              </Grid>
                              <Grid item sm={1} sx={{
                                display: { xs: "none", lg: "block" }
                              }}>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                  <Iconify icon="material-symbols:sync-alt" color="GrayText" />
                                </Box>
                              </Grid>
                              <Grid item xs={5}>
                                <Select
                                  fullWidth
                                  size="small"
                                  disabled={!field.mapping}
                                  value={getSelectedRemoteField(field)}
                                  onChange={(event) => onUpdateFieldMap(field.key, event.target.value)}
                                >
                                  <MenuItem value="--">--</MenuItem>
                                  {
                                    getOptionsForFieldType(field.entity, field).map((option) => (
                                      <MenuItem value={option.key}>{option.name}</MenuItem>
                                    ))
                                  }
                                </Select>
                              </Grid>

                              <Grid item xs={1} >
                                {
                                  field.value_mapping && (
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                      <Button
                                        variant="text"
                                        size="small"
                                        sx={{ textWrap: 'nowrap', display: { xs: "none", lg: "block" } }}
                                        onClick={() => openValueMapper(field.key)}
                                        disabled={!field.value_mapping}
                                      >
                                        {t('integrations.detail.configuration.field_mapping.configure')}
                                      </Button>

                                      <IconButton
                                        size="small"
                                        sx={{
                                          display: { xs: "block", lg: "none" }
                                        }}
                                        onClick={() => openValueMapper(field.key)}
                                      >
                                        <Iconify icon="pepicons-print:gear" />
                                      </IconButton>
                                    </Box>
                                  )
                                }

                              </Grid>
                            </>
                          ))
                        }
                      </Grid>

                      {
                        valueMapperControl.value &&
                        <ValueMapperModal open={valueMapperControl.value} onClose={closeValueMapper} fieldMapItem={currentFieldMapItem} onUpdate={onValueMapUpdated} />
                      }

                      {
                        errorMsg && (
                          <Alert variant="outlined" severity="error" sx={{ mt: 2 }}>
                            {errorMsg}
                          </Alert>
                        )
                      }

                      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4, gap: 1 }}>
                        <LoadingButton
                          onClick={onPreviousStep}
                          disabled={!isValid}
                        >
                          {t('common.back')}
                        </LoadingButton>
                        <LoadingButton
                          type="submit"
                          loading={isSettingUpIntegration}
                          disabled={isSettingUpIntegration}
                          onClick={handleSetupIntegration}
                          variant="contained"
                        >
                          {t('integrations.detail.configuration.setup.complete_setup')}
                        </LoadingButton>
                      </Box>
                    </Box>

                  )
                }
              </>
            )

        }
      </Card>
    </Stack>
  );
}
