
import { Chip } from '@mui/material';

import { useTranslate } from 'src/locales';
import { OrganizationStatus } from 'src/services/organisation/organisation.types';

export function OrganizationStatusLabel({ status }: { status: OrganizationStatus }) {

  const { t } = useTranslate();

  return (
    <Chip label={t(`enums.organization_status.${status}`)} color="default" size='small' />
  )
}
