import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';

import Page403 from 'src/pages/403';
import Page500 from 'src/pages/500';
import CompactLayout from 'src/layouts/compact';
import MaintenancePage from 'src/pages/maintenance';
import SentryErrorPage from 'src/pages/SentryErrorPage';

import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const Page404 = lazy(() => import('src/pages/404'));

// ----------------------------------------------------------------------

export const mainRoutes = [
  {
    element: (
      <ErrorBoundary fallback={<SentryErrorPage />}>
        <CompactLayout>
          <Suspense fallback={<SplashScreen />}>
            <Outlet />
          </Suspense>
        </CompactLayout>
      </ErrorBoundary>
    ),
    children: [
      { path: 'maintenance', element: <MaintenancePage /> },
      { path: '500', element: <Page500 /> },
      { path: '404', element: <Page404 /> },
      { path: '403', element: <Page403 /> },
    ],
  },
];
