import { useSnackbar } from 'notistack';

import Card from '@mui/material/Card';
import TableRow from '@mui/material/TableRow';
import { useTheme } from '@mui/material/styles';
import { TableCell, Typography, CardContent, useMediaQuery } from '@mui/material';

import { useRouter } from 'src/routes/hooks';

import { useBoolean } from 'src/hooks/use-boolean';

import { useTranslate } from 'src/locales';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { useAuthContext } from 'src/auth/hooks/useAuthContext';
import { useUnlinkConnectionMutation } from 'src/services/connections/connections.service';

import { usePopover } from 'src/components/custom-popover';

// eslint-disable-next-line import/no-cycle

import { useMemo } from 'react';

import { ConnectionRequest } from 'src/services/connections/connections.types';

import DateDisplay from 'src/components/date/date-display';

import { TenantType } from 'src/types/enums';


type Props = {
  row: ConnectionRequest;
};

function ConnectionRequestTableRow({ row }: Props) {
  const popover = usePopover();

  const { t } = useTranslate();

  const tenant = useOrgTenant();

  const router = useRouter();

  const { enqueueSnackbar } = useSnackbar();

  const [unlinkConnection] = useUnlinkConnectionMutation();

  const authContext = useAuthContext();

  const showEditModal = useBoolean();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const recieverCompany = useMemo(() => {
    const initiatorType = row.initiator_type;

    if (initiatorType === TenantType.Client) {
      return row.recruiter.company_name;
    }

    return row.client.company_name;

  }, [row]);

  if (isMobile) {
    return (
      <Card sx={{ mx: 2, mb: 1 }}>
        <CardContent>
          <Typography variant="h6" color="text.secondary" noWrap gutterBottom>
            {recieverCompany}
          </Typography>

          <Typography variant="body2" color="textSecondary" sx={{ textTransform: 'capitalize' }}>
            {row.status}
          </Typography>

          <DateDisplay date={row.created_at} variant='caption' />
        </CardContent>

      </Card>
    );
  }

  return (
    <TableRow>

      <TableCell>
        {recieverCompany}
      </TableCell>

      <TableCell>
        <Typography variant="body2" color="textSecondary" sx={{ textTransform: 'capitalize' }}>
          {row.status}
        </Typography>
      </TableCell>

      <TableCell>
        <DateDisplay date={row.created_at} variant='body2' />
      </TableCell>

      <TableCell>
        <Typography variant="body2" color="textSecondary" sx={{ textTransform: 'capitalize' }}>
          {row.initiator_account.first_name} {row.initiator_account.last_name}
        </Typography>
      </TableCell>

    </TableRow>
  );
}

export default ConnectionRequestTableRow;