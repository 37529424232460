import { useMemo, ReactNode } from 'react';
import { m, AnimatePresence } from 'framer-motion';

import { Box, Link, useTheme, Typography } from '@mui/material';

import { paths } from 'src/routes/paths';

import { useTranslate } from 'src/locales';
import { useGetMySubscriptionQuery } from 'src/services/billing/billing.services';

import { subsciptionErrorStatuses } from 'src/sections/admin/billing/billing.constants';

type Props = {
  children: ReactNode | ReactNode[];
};

export default function UsageGuard({ children }: Props) {
  const { currentData: subscriptionData } = useGetMySubscriptionQuery();

  const theme = useTheme();

  const { t } = useTranslate();

  const showBanner = useMemo(() => {
    if (!subscriptionData) return;

    // eslint-disable-next-line consistent-return
    return subsciptionErrorStatuses.includes(subscriptionData.status);
  }, [subscriptionData]);

  return (
    <>
      <AnimatePresence>
        {showBanner && (
          <m.div
            key="usage-banner"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Box
              sx={{
                height: 24,
                width: '100%',
                backgroundColor: theme.palette.error.main,
                position: 'fixed',
                top: 0,
                left: 'auto',
                right: 0,
                'z-index': 2001,
                fontSize: 13,
                color: '#fff',
              }}
              textAlign="center"
              display="flex"
              flexDirection="row"
              justifyContent="center"
            >
              <Typography variant='caption' lineHeight={2}>
                {t('usage.past_due')}{' '}
                <Link
                  sx={{
                    color: '#fff',
                    textDecoration: 'underline',
                    fontWeight: 'bold',
                  }}

                  href={paths.dashboard.admin.billing.root}
                >
                  {`${t('usage.complete_payment')} `}
                </Link>{' '}
                {t('usage.lock_out')}
              </Typography>
            </Box>
          </m.div>
        )}
      </AnimatePresence>
      {children}
    </>
  );
}
