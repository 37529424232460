import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IApplicationsState {
  showActionStageChangeRequest: boolean;
  selectedRequestId: string | null;
  showJobStageChangeRequestsModal: boolean;
  selectedJobId: string | null;
  showOfferModal: boolean;
  selectedApplicationId: string | null;
  selectedOfferId: string | null;
  showCandidateOfferModal: boolean;
}

const initialState: IApplicationsState = {
  showActionStageChangeRequest: false,
  selectedRequestId: null,
  showJobStageChangeRequestsModal: false,
  selectedJobId: null,
  showOfferModal: false,
  selectedApplicationId: null,
  selectedOfferId: null,
  showCandidateOfferModal: false,
};

export const applicationsSlice = createSlice({
  name: 'applications',
  initialState,
  reducers: {
    setShowActionStageRequestModal: (state, action: PayloadAction<string>) => {
      state.showActionStageChangeRequest = true;
      state.selectedRequestId = action.payload;
    },
    setHideActionStageRequestModal: (state) => {
      state.showActionStageChangeRequest = false;
      state.selectedRequestId = null;
    },
    setShowJobStageChangeRequestsModal: (state, action: PayloadAction<string>) => {
      state.showJobStageChangeRequestsModal = true;
      state.selectedJobId = action.payload;
    },
    hideJobStageChangeRequestsModal: (state) => {
      state.showJobStageChangeRequestsModal = false;
      state.selectedJobId = null;
    },
    setShowOfferModal: (
      state,
      action: PayloadAction<{ applicationId: string; offerId?: string }>
    ) => {
      state.selectedApplicationId = action.payload.applicationId;
      state.selectedOfferId = action.payload.offerId || null;
      state.showOfferModal = true;
    },
    hideOfferModal: (state) => {
      state.selectedApplicationId = null;
      state.showOfferModal = false;
      state.selectedOfferId = null;
    },
    showCandidateOfferModal: (state, action: PayloadAction<string>) => {
      state.showCandidateOfferModal = true;
      state.selectedOfferId = action.payload;
    },
    hideCandidateOfferModal: (state) => {
      state.showCandidateOfferModal = false;
      state.selectedOfferId = null;
    },
  },
});

export const {
  setShowActionStageRequestModal,
  setHideActionStageRequestModal,
  setShowJobStageChangeRequestsModal,
  hideJobStageChangeRequestsModal,
  setShowOfferModal,
  hideOfferModal,
  showCandidateOfferModal,
  hideCandidateOfferModal,
} = applicationsSlice.actions;
export default applicationsSlice.reducer;
