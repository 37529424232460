import { Fragment, useState, useCallback } from 'react';

import Stack from '@mui/system/Stack';
import List from '@mui/material/List';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import { Box, Button } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import Pagination from '@mui/material/Pagination';
import DialogTitle from '@mui/material/DialogTitle';
import ListItemText from '@mui/material/ListItemText';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { useTranslate } from 'src/locales';
import { PageOptions } from 'src/services/api.types';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { StageChangeRequest } from 'src/services/applications/applications.types';
import { useGetAllJobStageChangeRequestsQuery } from 'src/services/applications/applications.service';
import {
  setShowActionStageRequestModal,
  hideJobStageChangeRequestsModal,
} from 'src/store/slices/applications/applicationsSlice';

import EmptyContent from 'src/components/empty-content';

import { TenantType } from 'src/types/enums';

type Props = {
  open: boolean;
};

const DEFAULT_PAGE = {
  page: 1,
  per_page: 5,
};

export default function JobStageChangeRequestsModal({ open }: Props) {
  const { t } = useTranslate();

  const dispatch = useAppDispatch();

  const tenant = useOrgTenant();

  const onClose = useCallback(() => {
    dispatch(hideJobStageChangeRequestsModal());
  }, [dispatch]);

  const [pageOptions, setPageOptions] = useState<PageOptions>(DEFAULT_PAGE);

  const selectedJobId = useAppSelector((state) => state.applications.selectedJobId);

  const changePage = useCallback((value: number) => {
    setPageOptions((prev) => ({
      ...prev,
      page: value,
    }));
  }, []);

  const { currentData: requestData } = useGetAllJobStageChangeRequestsQuery(
    {
      jobId: selectedJobId as string,
      params: {
        ...pageOptions,
      },
    },
    { skip: tenant !== TenantType.Recruiter || !selectedJobId }
  );

  const onManageRequest = useCallback(
    (request: StageChangeRequest) => {
      dispatch(setShowActionStageRequestModal(request.id));

      onClose();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const renderIncomingButtons = (request: StageChangeRequest) => (
    <Button
      variant="contained"
      sx={{ mr: 1 }}
      aria-label="manage"
      onClick={() => onManageRequest(request)}
    >
      {t('common.manage')}
    </Button>
  );

  const renderRequest = (request: StageChangeRequest, index: number) => {
    const candidateName = `${request.application.attached_profile.first_name} ${request.application.attached_profile.last_name}`;
    const targetStage = request.target_stage;

    return (
      <Fragment key={`request-${index}`}>
        <ListItem sx={{ mb: 1 }} secondaryAction={renderIncomingButtons(request)}>
          {/* <ListItemAvatar>
            <Avatar>{companyName?.charAt(0)}</Avatar>
          </ListItemAvatar> */}
          <ListItemText
            secondaryTypographyProps={{
              paddingRight: 14,
            }}
            primary={candidateName}
            secondary={t(`applications.job_stage_change_request_modal.requested_stage`, {
              stage: t(`enums.application_status.${targetStage}`),
            })}
          />
        </ListItem>
        <Divider variant="middle" component="li" />
      </Fragment>
    );
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle sx={{ textTransform: 'capitalize' }}>
        {t(`applications.job_stage_change_request_modal.title`, {
          target: t('common.incoming'),
        })}
      </DialogTitle>

      <DialogContent>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <Stack direction="column">
            <Alert variant="outlined" severity="info" sx={{ mb: 3 }}>
              {t(`applications.job_stage_change_request_modal.alert`)}
            </Alert>

            {requestData?.count ? (
              <List dense>
                {requestData?.results?.map((requestObj, requestIndex) =>
                  renderRequest(requestObj, requestIndex)
                )}
              </List>
            ) : (
              <EmptyContent
                filled
                title="No Data"
                sx={{
                  py: 10,
                }}
              />
            )}

            <Stack flexDirection="row" justifyContent="center" sx={{ pt: 2 }}>
              <Pagination
                page={pageOptions.page || 1}
                count={Math.ceil((requestData?.count || 1) / pageOptions.per_page)}
                onChange={(_e, value) => changePage(value)}
              />
            </Stack>
          </Stack>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{t('common.close')}</Button>
      </DialogActions>
    </Dialog>
  );
}
