import { useMemo, useState, useEffect, useCallback } from 'react';

import { useTheme } from '@mui/material/styles';
import { Card, Table, CardProps, TableBody, useMediaQuery } from '@mui/material';

import { useTranslate } from 'src/locales';
import { PageOptions } from 'src/services/api.types';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { JobFilters, JobsResponse } from 'src/services/jobs/jobs.types';
import { useGetJobsByOrganizationQuery } from 'src/services/admin/admin.service';

import Scrollbar from 'src/components/scrollbar';
import {
  useTable,
  TableNoData,
  TableSkeleton,
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/table';

import JobsTableToolbar from 'src/sections/restricted/jobs/table/global-jobs-table-toolbar';

import { TenantType } from 'src/types/enums';

import JobTableRow from './global-jobs-table-row';

interface Props extends CardProps {
  organizationId?: string | null;
}

const DEFAULT_PAGE_OPTIONS: PageOptions = {
  page: 1,
  per_page: 5,
};

export default function GlobalJobsView({ organizationId, ...other }: Props) {

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const table = useTable();

  const tenant = useOrgTenant();

  const { t } = useTranslate();

  const [jobFilters, setJobFilters] = useState<JobFilters>({
    search_query: undefined,
    job_status: undefined,
    assigned_account: undefined,
  });

  const [pageOptions, setPageOptions] = useState<PageOptions>({
    page: jobFilters.page ?? DEFAULT_PAGE_OPTIONS.page,
    per_page: jobFilters.per_page ?? DEFAULT_PAGE_OPTIONS.per_page,
  });

  const [jobsData, setJobsData] = useState<JobsResponse | null>(null);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data: orgJobs, isLoading: orgJobsLoading } = useGetJobsByOrganizationQuery(
    {
      orgId: organizationId as string,
      params: { ...jobFilters, ...pageOptions }
    },
    { skip: !organizationId }
  );

  useEffect(() => {

    if (orgJobs) {
      setJobsData(orgJobs);
    }

  }, [orgJobs]);

  const isCandidate = tenant === TenantType.Candidate;

  const tableLabels = useMemo(
    () => [
      { id: 'title', label: t('jobs.table.title') },
      { id: 'client_company_name', label: t('jobs.table.client_company_name') },
      { id: 'department', label: t('jobs.table.department') },
      { id: 'vacancies', label: t('jobs.table.vacancies') },
      { id: 'status', label: t('jobs.table.verification_status') },
      { id: 'stage', label: t(`jobs.table.stage`) },
      { id: 'created_at', label: t('jobs.table.created_at') },
      { id: '' },
    ],
    [t]
  );

  const onChangeFilters = useCallback((name: keyof JobFilters, value: any) => {
    setJobFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  const onClearFilters = useCallback(() => {
    setJobFilters({
      search_query: undefined,
      job_status: undefined,
      assigned_account: undefined,
    });
  }, []);

  return (
    <Card {...other} sx={{ pt: { xs: 2, sm: 'unset' } }}>
      {orgJobsLoading ? (
        <TableSkeleton />
      ) : (
        <>

          <JobsTableToolbar
            showImport={false}
            showCreate={false}
            onFilters={onChangeFilters}
            clearFilters={onClearFilters}
            filters={jobFilters}
          />

          <Scrollbar>

            <Table size={table.dense ? 'small' : 'medium'}>
              {!isMobile && <TableHeadCustom headLabel={tableLabels} />}

              <TableBody>
                {jobsData?.results.map((row) => <JobTableRow key={row.id} row={row} />)}
                {!jobsData?.count && <TableNoData notFound />}
              </TableBody>
            </Table>
          </Scrollbar>
          <TablePaginationCustom
            count={jobsData?.count || 0}
            page={pageOptions.page}
            rowsPerPage={pageOptions.per_page}
            onPageChange={(e, newPage) => setPageOptions({ ...pageOptions, page: newPage + 1 })}
            onRowsPerPageChange={(e) =>
              setPageOptions({ page: 1, per_page: parseInt(e.target.value, 10) })
            }
            dense={table.dense}
            onChangeDense={table.onChangeDense}
          />
        </>
      )}
    </Card>
  );
}
