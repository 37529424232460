import { isString } from 'lodash';

import i18n from 'src/locales/i18n';

import { BusinessErrorCodes } from 'src/types/business-errors';

export const generateUnlinkConnectionErrorMessage = (e: any) => {
  if (isString(e?.data?.error_code)) {
    if (e?.data.error_code === BusinessErrorCodes.InvalidConnectionStatus) {
      return i18n.t('connections.api.unlink.invalid');
    }
  }
  return i18n.t('connections.api.unlink.default_error');
};

export const generateConnectionCreateErrorMessage = (e: any) => {

  if (e?.data?.feature_type) {
    return i18n.t(`admin-connections.api.errors.features.${e.data.feature_type}`);
  }

  if (isString(e?.data?.error_code)) {
    if ([BusinessErrorCodes.ConnectionRequestExists, BusinessErrorCodes.ActiveConnectionExists].includes(e?.data.error_code)) {
      return i18n.t('admin-connections.api.initiate.already_exists');
    }

    if ([BusinessErrorCodes.InvalidConnectionTarget].includes(e?.data.error_code)) {
      return i18n.t('admin-connections.api.initiate.invalid_target');
    }

    if (e?.data.error_code === BusinessErrorCodes.BadTenantOperation) {
      return i18n.t('admin-connections.api.initiate.company_email');
    }
  }

  return i18n.t('admin-connections.api.initiate.default_error');
};

