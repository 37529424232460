import { Helmet } from 'react-helmet-async';

import CallbackManagerView from 'src/sections/auth/callback-manager/callback-manager-view';

export default function CallbackManagerPage() {
    return (
        <>
            <Helmet>
                <title>JT: Complete Connection</title>
            </Helmet>

            <CallbackManagerView />
        </>
    );
}
