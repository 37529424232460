import { Button } from "@mui/material";

import { useTranslate } from "src/locales";
import { useLazyGetKnowledgeBaseLinkQuery } from 'src/services/system/system.service';

import Iconify from "src/components/iconify";

export const KnowledgeBaseButton = () => {

  const [getKnowledgeBaseLink] = useLazyGetKnowledgeBaseLinkQuery();

  const { t } = useTranslate();

  const handleClick = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const ssoOptions = await getKnowledgeBaseLink();
    window.open(ssoOptions.data, '_blank');
  };

  return (
    <Button
      variant="outlined"
      color="primary"
      startIcon={<Iconify icon="bx:help-circle" width={25} height={25} />}
      onClick={handleClick}
    >
      {t('common.help')}
    </Button>
  );
}