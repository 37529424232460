import { t } from 'i18next';
import moment from 'moment';
import { enqueueSnackbar } from 'notistack';
import { useState, useEffect } from 'react';

import { LoadingButton } from '@mui/lab';
import { Box, Stack } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import { RemoveCircle } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { Grid, Paper, Button, Checkbox, Skeleton, IconButton, Typography } from '@mui/material';

import { useAuthContext } from 'src/auth/hooks/useAuthContext';
import { WeekDaySetting, CustomDateSetting } from 'src/services/organisation/organisation.types';
import { useGetOrganizationDateSettingsQuery, useUpdateOrganizationDateSettingsMutation } from 'src/services/organisation/organisation.service';

import TruncatedTextField from 'src/components/truncate-text/truncated-text-field';

import HolidayPopover, { HolidayDate } from 'src/sections/admin/organization/settings/components/holiday-popover';

interface WorkingHours {
  [key: string]: { start: Date | null; end: Date | null };
}

const daysOfWeek = [{
  key: 1,
  value: t('admin-organization.settings.business_hours_selector.days.monday')
}, {
  key: 2,
  value: t('admin-organization.settings.business_hours_selector.days.tuesday')
}, {
  key: 3,
  value: t('admin-organization.settings.business_hours_selector.days.wednesday')
}, {
  key: 4,
  value: t('admin-organization.settings.business_hours_selector.days.thursday')
}, {
  key: 5,
  value: t('admin-organization.settings.business_hours_selector.days.friday')
}, {
  key: 6,
  value: t('admin-organization.settings.business_hours_selector.days.saturday')
}, {
  key: 7,
  value: t('admin-organization.settings.business_hours_selector.days.sunday')
}];

type CustomDateInfo = {
  date: Date;
  name: string;
}

const WorkingHoursComponent = () => {

  const authContext = useAuthContext();

  const { currentData: currentDateSettings, isFetching: isRetrieving } = useGetOrganizationDateSettingsQuery(authContext?.organization?.id, {
    skip: !authContext?.organization?.id,
  });

  const [updateDateSettings, { isLoading: isUpdating }] = useUpdateOrganizationDateSettingsMutation();

  const [selectedDays, setSelectedDays] = useState<number[]>([]);
  const [workingHours, setWorkingHours] = useState<WorkingHours>({});
  const [customDates, setCustomDates] = useState<CustomDateInfo[]>([]);
  const [dayErrors, setDayErrors] = useState<any>([]);
  const [generalError, setGeneralError] = useState<string | null>(null);

  useEffect(() => {
    if (currentDateSettings) {
      const workingHoursObject = currentDateSettings.weekday_settings.reduce((acc, day) => ({
        ...acc,
        [day.day]: {
          start: new Date(0, 0, 0, day.start, 0),
          end: new Date(0, 0, 0, day.end, 0)
        }
      }), {});

      setSelectedDays(currentDateSettings.weekday_settings.map((day) => day.day));
      setWorkingHours(workingHoursObject);
      setCustomDates(currentDateSettings.custom_date_settings.map((customDate) => ({
        date: new Date(customDate.date),
        name: customDate.name
      })));
    }
  }, [currentDateSettings]);

  const handleDaySelection = (day: number) => {
    setSelectedDays((prevDays) =>
      prevDays.includes(day)
        ? prevDays.filter((d) => d !== day)
        : [...prevDays, day]
    );

    if (!selectedDays.includes(day)) {
      setWorkingHours((prev) => ({
        ...prev,
        [day]: { start: new Date(0, 0, 0, 9, 0), end: new Date(0, 0, 0, 17, 0) }
      }));
    }
    else {
      setWorkingHours((prev) => {
        const { [day]: _, ...rest } = prev;
        return rest;
      });
    }
  };

  const handleStandardHours = () => {
    const weekdays = [1, 2, 3, 4, 5];
    setSelectedDays(weekdays);
    setWorkingHours((prev) => {
      const standardHours = { start: new Date(0, 0, 0, 9, 0), end: new Date(0, 0, 0, 17, 0) };
      return weekdays.reduce((acc, day) => ({ ...acc, [day]: standardHours }), prev);
    });
  }

  const handleTimeChange = (day: number, type: 'start' | 'end', value: Date | null) => {
    setWorkingHours((prev) => ({
      ...prev,
      [day]: { ...prev[day], [type]: value },
    }));
  };

  const handleAddHoliday = (date: Date | null) => {
    if (date) {
      setCustomDates((prev) => [...prev, {
        date,
        name: 'Custom Holiday'
      }]);
    }
  };

  const handleRemoveHoliday = (date: Date) => {
    setCustomDates((prev) => prev.filter((d) => d.date.getTime() !== date.getTime()));
  };

  const onClearDays = () => {
    setSelectedDays([]);
    setWorkingHours({});
  }

  const handleFormSubmit = async () => {
    setDayErrors({});
    setGeneralError(null);

    if (isRetrieving || isUpdating) return;

    // validate if all days have working hours
    // create object with key as day if there is an error
    const errors = selectedDays.reduce((acc, day) => {
      if (workingHours && workingHours[day]) {
        if (!workingHours[day].start) {
          acc = {
            ...acc,
            [day]: t('admin-organization.settings.business_hours_selector.validation.start_required')
          }
        }
        else if (!workingHours[day].end) {
          acc = {
            ...acc,
            [day]: t('admin-organization.settings.business_hours_selector.validation.end_required')
          }
        }
        // @ts-ignore
        else if (workingHours[day].start && workingHours[day].end && workingHours[day].start >= workingHours[day].end) {
          acc = {
            ...acc,
            [day]: t('admin-organization.settings.business_hours_selector.validation.start_before_end')
          }
        }
      }
      return acc;
    }, {})

    setDayErrors(errors);
    if (Object.keys(errors).length > 0) return;

    if (selectedDays.length === 0 || Object.keys(workingHours).length === 0) {
      setGeneralError(t('admin-organization.settings.business_hours_selector.validation.select_days'));
      return;
    }

    // put it into a single array of objects of day and start/end hours
    const workingHoursArray = Object.entries(workingHours).filter(([day, hours]) => day && hours.start && hours.end).map(([day, hours]) => ({
      day: parseInt(day, 10),
      start: hours.start?.getHours(),
      end: hours.end?.getHours()
    }));

    // put holidays into array of date and name
    const customDateArray = customDates.map((customDate) => ({
      date: customDate.date,
      name: customDate.name
    }));

    try {
      await updateDateSettings({
        organizationId: authContext.organization.id,
        weekday_settings: workingHoursArray as WeekDaySetting[],
        custom_date_settings: customDateArray as CustomDateSetting[]
      }).unwrap();

      enqueueSnackbar(t('admin-organization.settings.business_hours_selector.api.success'), { variant: 'success' });
    }
    catch (error) {
      enqueueSnackbar(t('admin-organization.settings.business_hours_selector.api.default_error'), { variant: 'error' });
    }
  }

  const handleHolidaySelection = (dates: HolidayDate[]) => {
    setCustomDates(dates.map((dateInfo) => ({
      date: new Date(dateInfo.date),
      name: dateInfo.localName
    })));
  }

  return (
    <Box sx={{ width: '100%', px: 2, py: 2 }}>
      <Typography variant="h6" gutterBottom>
        {t('admin-organization.settings.business_hours_selector.title')}
      </Typography>

      {
        isRetrieving ?
          <Stack gap={2}>
            <Skeleton variant="rounded" height={50} />
            <Skeleton variant="rounded" height={50} />
            <Skeleton variant="rounded" height={50} />
            <Skeleton variant="rounded" height={50} />
          </Stack>
          :
          <>
            <Paper sx={{ mb: 3, px: 2 }}>
              <Stack direction="row" spacing={2} sx={{ py: 1 }} justifyContent="space-between" alignItems="center">
                <Stack direction="column" sx={{ mb: 2 }}  >
                  <Typography variant="h6">
                    {t('admin-organization.settings.business_hours_selector.select_working_days_title')}
                  </Typography>
                  <Typography variant="caption">
                    {t('admin-organization.settings.business_hours_selector.select_working_days_subtitle')}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={2} sx={{ py: 1 }} alignItems="center">
                  <Button
                    variant="outlined"
                    onClick={handleStandardHours}
                  >
                    {t('admin-organization.settings.business_hours_selector.set_standard_hours')}
                  </Button>
                  {
                    selectedDays.length > 0 && (
                      <Button
                        variant="contained"
                        onClick={onClearDays}
                      >
                        {t('admin-organization.settings.business_hours_selector.clear_days')}
                      </Button>
                    )
                  }

                </Stack>
              </Stack>
              <Grid container spacing={2} sx={{ px: 2 }}>
                {daysOfWeek.map(({ key, value }) => (
                  <Grid item xs={12} sm={12} key={key}>
                    <Stack direction="row" justifyContent="start" alignItems="center" spacing={1} sx={{ mb: 1 }}>
                      <Checkbox
                        checked={selectedDays.includes(key)}
                        onChange={() => handleDaySelection(key)}
                        color="primary"
                      />
                      <Typography variant="subtitle1">{value}</Typography>
                      <Typography variant="caption">
                        {selectedDays.includes(key) ? t('admin-organization.settings.business_hours_selector.active_day') : t('admin-organization.settings.business_hours_selector.day_off')}
                      </Typography>
                    </Stack>

                    {selectedDays.includes(key) && (
                      <Grid container spacing={2} sx={{ px: 2 }} gap={1}>
                        <Grid item sx={{ display: 'flex', gap: 2, mt: 2, px: 1, justifyContent: 'start', alignItems: 'center' }} xs={12} md={6}>
                          <TimePicker
                            label="Start"
                            sx={{ width: '100%' }}
                            value={workingHours[key]?.start || null}
                            onChange={(newValue) => handleTimeChange(key, 'start', newValue)}
                          />
                          <TimePicker
                            label="End"
                            sx={{ width: '100%' }}
                            value={workingHours[key]?.end || null}
                            onChange={(newValue) => handleTimeChange(key, 'end', newValue)}
                          />
                        </Grid>
                        <Grid item sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }} xs={12} md={4}>
                          {
                            dayErrors[key] && <Typography variant="caption" color="error" sx={{ mt: 1 }}>{dayErrors[key]}</Typography>
                          }
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                ))}
              </Grid>
            </Paper>

            <Paper sx={{ py: 1, px: 2 }}>
              <Stack direction="row" sx={{ mb: 2 }} justifyContent="space-between" alignItems="center">
                <Stack direction="column" sx={{ mb: 2 }} >
                  <Typography variant="h6">
                    {t('admin-organization.settings.business_hours_selector.holiday_selection_title')}
                  </Typography>
                  <Typography variant="caption">
                    {t('admin-organization.settings.business_hours_selector.holiday_selection_subtitle')}
                  </Typography>
                </Stack>
                <Stack direction="row" sx={{ mb: 2 }} gap={2} alignItems="center">
                  <HolidayPopover onSelect={handleHolidaySelection} />
                  {
                    customDates.length > 0 && (
                      <Button variant='contained' onClick={() => setCustomDates([])}>
                        {t('admin-organization.settings.business_hours_selector.clear_holidays')}
                      </Button>
                    )
                  }

                </Stack>
              </Stack>

              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, px: 2 }}>
                <DatePicker
                  label={t('admin-organization.settings.business_hours_selector.add_holiday')}
                  value={null}
                  sx={{ width: '100%' }}
                  onChange={handleAddHoliday}
                />
                <IconButton color="primary" onClick={() => handleAddHoliday(new Date())}>
                  <AddIcon />
                </IconButton>
              </Box>
              <Stack direction="row" spacing={1} flexWrap="wrap" sx={{ px: 2 }}>
                {customDates.sort((a, b) => a.date.getTime() - b.date.getTime()).map((dateInfo) => (
                  <Stack direction="row" sx={{ border: 1, borderRadius: 1, pl: 2, py: 1, borderColor: 'lightgray' }}>
                    <Stack direction="column" flexWrap="wrap">
                      <Typography variant="body2">{moment(dateInfo.date).format('Do of MMM YYYY')}</Typography>
                      <TruncatedTextField mode="characters" limit={30} text={dateInfo.name} variant='caption' showMoreEnabled={false} />
                    </Stack>
                    <IconButton onClick={() => handleRemoveHoliday(dateInfo.date)} >
                      <RemoveCircle color='error' />
                    </IconButton>
                  </Stack>
                ))}
              </Stack>
            </Paper>
          </>
      }

      <Box sx={{ mt: 3, textAlign: 'right', display: 'flex', gap: 2, justifyContent: 'end', px: 2 }}>
        {generalError && <Typography variant="body2" color="error" sx={{ mt: 1 }}>{generalError}</Typography>}
        <LoadingButton
          type="submit"
          variant="contained"
          loading={isUpdating}
          disabled={isRetrieving || isUpdating}
          onClick={handleFormSubmit}
        >
          {t('common.update')}
        </LoadingButton>
      </Box>
    </Box>
  );
}

export default WorkingHoursComponent;