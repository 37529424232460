export enum HTTPRequestType {
  POST = 'POST',
  GET = 'GET',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export interface APIResponse {
  message: string;
  errors?: {
    [key: string]: string[];
  };
  timestamp?: string;
}

export interface AuthResponse {
  access_token: string;
}

export interface PaginatedResponse<T> {
  count: number;
  results: Array<T>;
}

export interface PageOptions {
  page: number;
  per_page: number;
}

export interface ErrorValidationResponse<T extends Record<string, string>> {
  data: {
    errors: {
      [key in keyof T]: string[];
    };
  };
  message: string;
  timestamp: number;
}
