import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface ICandidateState {
  showAvailabilityManagerModal: boolean;
}

const initialState: ICandidateState = {
  showAvailabilityManagerModal: false
};

export const candidateSlice = createSlice({
  name: 'candidate',
  initialState,
  reducers: {
    setAvailabilityManagerModalVisibility: (state, action: PayloadAction<{ status: boolean }>) => {
      state.showAvailabilityManagerModal = action.payload.status;
    },
  },
});

export const {
  setAvailabilityManagerModalVisibility
} = candidateSlice.actions;

export const candidateeducer = candidateSlice.reducer;
