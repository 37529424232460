import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface IAppState {
  maintenanceMode: boolean;
  maintenanceMessage?: string;
}

const initialState: IAppState = {
  maintenanceMode: false,
  maintenanceMessage: undefined,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setMaintenanceMode: (state, action: PayloadAction<{ mode_state: boolean, message?: string }>) => {
      state.maintenanceMode = action.payload.mode_state;
      state.maintenanceMessage = action.payload.message;
    }
  },
});

export const { setMaintenanceMode } = appSlice.actions;
export const appReducer = appSlice.reducer;
