import { lazy, Suspense } from 'react';
import { ErrorBoundary } from '@sentry/react';

import SentryErrorPage from 'src/pages/SentryErrorPage';
import AuthModernCompactLayout from 'src/layouts/auth/modern-compact';

import { SplashScreen } from 'src/components/loading-screen';

const ExperienceVerificationPage = lazy(
  () => import('src/pages/external/experience-verification-page')
);

export const externalRoutes = [
  {
    path: 'experience-verification/:request_id',
    element: (
      <ErrorBoundary fallback={<SentryErrorPage />}>
        <Suspense fallback={<SplashScreen />}>
          <AuthModernCompactLayout>
            <ExperienceVerificationPage />
          </AuthModernCompactLayout>
        </Suspense>
      </ErrorBoundary>
    ),
  },
];
