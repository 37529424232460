import { Helmet } from 'react-helmet-async';

import PasswordResetView from 'src/sections/auth/password-reset/password-reset-view';

// ----------------------------------------------------------------------

export default function PasswordResetPage() {
  return (
    <>
      <Helmet>
        <title>JT: Reset Password</title>
      </Helmet>

      <PasswordResetView />
    </>
  );
}
