
import Tooltip from '@mui/material/Tooltip';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Stack, useMediaQuery } from '@mui/system';
import { Card, Button, useTheme, Typography, IconButton, CardContent } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { useTranslate } from 'src/locales';
import { Contact, ContactSource } from 'src/services/contacts/contacts.types';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';

import ContactsNotesModal from './contacts-notes-modal';

type Props = {
  row: Contact
  onRemoveContact?: (contactId: string) => void;
};

export function ContactsTableRow({ row, onRemoveContact }: Props) {
  const { t } = useTranslate();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const showNotesModal = useBoolean();

  const confirm = useBoolean();

  const handleRemoveContact = () => {
    if (!onRemoveContact) return;

    confirm.onFalse();
    onRemoveContact(row.id);
  }

  if (isMobile) {
    return (
      <Card sx={{ mx: 2, mb: 1 }}>
        <CardContent>
          <Stack direction="column" alignItems="start" mb={1}>
            <Typography variant="h6">
              {`${row.first_name} ${row.last_name ?? ''}`}
            </Typography>
            {
              (row.account && row.account.organization) && (
                <Typography variant='caption' sx={{ color: theme.palette.text.primary }}>
                  {row.account.organization.company_name}
                </Typography>
              )
            }
          </Stack>

          <Typography variant="body2" color="text.secondary" gutterBottom>
            {row.email ?? '-'}
          </Typography>

          {
            (row.phone) && (
              <Typography variant="body2" color="text.secondary" gutterBottom>
                {row.phone ?? '-'}
              </Typography>
            )
          }

          <Stack direction="row" justifyContent="space-between" alignItems="center" mt={2} mb={2}>
            <Tooltip
              title={
                row?.source === ContactSource.JOB_INTERACTION
                  ? t('contacts.source.tooltip_job_interaction')
                  : t('contacts.source.tooltip_manual')
              }
            >
              <Label
                variant="soft"
                color={row?.source === ContactSource.JOB_INTERACTION ? 'success' : 'info'}
              >
                {t(`enums.contact_source.${row?.source}`)} ({row.type ?? '-'})
              </Label>
            </Tooltip>

            <Stack direction="row" spacing={1} alignItems="center">
              <IconButton onClick={showNotesModal.onTrue} size="small">
                <Iconify icon="mdi:comments" />
              </IconButton>
              {onRemoveContact && (
                <IconButton onClick={confirm.onTrue} size="small">
                  <Iconify color="error" icon="eva:trash-2-outline" />
                </IconButton>
              )}

            </Stack>

          </Stack>
        </CardContent>

        <ContactsNotesModal
          threadId={row.thread.id}
          open={showNotesModal.value}
          onClose={showNotesModal.onFalse}
        />

        {onRemoveContact && (
          <ConfirmDialog
            open={confirm.value}
            onClose={confirm.onFalse}
            title={t('common.delete')}
            content={
              <Typography variant="body2">
                {t('contacts.delete_modal.confirm_message')}
              </Typography>
            }
            action={
              <Button variant="contained" color="error" onClick={handleRemoveContact}>
                {t('common.delete')}
              </Button>
            }
          />
        )}
      </Card>
    );
  }

  return (
    <>
      <TableRow>
        <TableCell>
          <Stack direction="column" alignItems="start">
            <Typography variant='body2' sx={{ color: theme.palette.text.primary, fontWeight: 600 }}>
              {`${row.first_name} ${row.last_name ?? ''}`}
            </Typography>
            {
              (row.account && row.account.organization) && (
                <Typography variant='caption' sx={{ color: theme.palette.text.primary }}>
                  {row.account.organization.company_name}
                </Typography>
              )
            }
          </Stack>
        </TableCell>

        <TableCell>
          <Typography variant="body2" color="text.secondary">
            {row.email ?? '-'}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography variant="body2" color="text.secondary">
            {row.phone ?? '-'}
          </Typography>
        </TableCell>

        <TableCell>
          <Tooltip
            title={
              row?.source === ContactSource.JOB_INTERACTION
                ? t('contacts.source.tooltip_job_interaction')
                : t('contacts.source.tooltip_manual')
            }
          >
            <Label
              variant="soft"
              color={row?.source === ContactSource.JOB_INTERACTION ? 'success' : 'info'}
            >
              {t(`enums.contact_source.${row?.source}`)}
            </Label>
          </Tooltip>
        </TableCell>

        <TableCell sx={{ textTransform: 'capitalize' }}>{row.type ?? '-'}</TableCell>

        <TableCell align="right" sx={{ px: 4 }}>
          <Stack direction="row" justifyContent="end" spacing={1}>
            <Button variant="contained" size="small" onClick={showNotesModal.onTrue}>
              View Notes
            </Button>
            {
              onRemoveContact && (
                <IconButton onClick={confirm.onTrue}>
                  <Iconify color="red" icon="eva:trash-2-outline" />
                </IconButton>
              )
            }
          </Stack>
        </TableCell>

      </TableRow>

      <ContactsNotesModal
        threadId={row.thread.id}
        open={showNotesModal.value}
        onClose={showNotesModal.onFalse}
      />

      {
        onRemoveContact && (
          <ConfirmDialog
            open={confirm.value}
            onClose={confirm.onFalse}
            title={t('common.delete')}
            content={
              <Typography variant="body2">
                {t('contacts.delete_modal.confirm_message')}
              </Typography>
            }
            action={
              <Button variant="contained" color="error" onClick={handleRemoveContact}>
                {t('common.delete')}
              </Button>
            }
          />
        )
      }
    </>
  );
}
