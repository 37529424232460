import { Helmet } from 'react-helmet-async';

import { AcceptInviteView } from 'src/sections/auth';

export default function LoginPage() {
  return (
    <>
      <Helmet>
        <title>JT: Sign Up</title>
      </Helmet>

      <AcceptInviteView />
    </>
  );
}
