import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState, useEffect, SyntheticEvent } from 'react';

import Card from '@mui/material/Card';
import { Skeleton } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import LoadingButton from '@mui/lab/LoadingButton';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';

import { useTranslate } from 'src/locales';
import { VisibilityRule } from 'src/services/jobs/jobs.types';
import { useGetSkillsQuery } from 'src/services/utils/utils.service';
import { useSetCandidateProfileMutation } from 'src/services/candidates/candidates.service';
import { generateCandidateProfileErrorMessage } from 'src/services/candidates/candidates.utils';
import {
  CandidateProfile,
  CandidateProfileSectionType,
} from 'src/services/candidates/candidates.types';

import FormProvider from 'src/components/hook-form';
import RHFAutocomplete from 'src/components/hook-form/rhf-autocomplete';
import RHFVisibilityToggle from 'src/components/hook-form/rhf-visibility-toggle';

type Props = {
  profile?: CandidateProfile;
  isLoading: boolean;
  mode?: 'edit' | 'view';
};

export default function ProfileSkillsEditor({ profile, isLoading, mode = 'edit' }: Props) {
  const [updateProfile] = useSetCandidateProfileMutation();

  const { t } = useTranslate();

  const { enqueueSnackbar } = useSnackbar();

  const [skillName, setSkillName] = useState('');

  const { currentData: skillsData } = useGetSkillsQuery({
    page: 1,
    per_page: 5,
    name: skillName,
  });

  const defaultValues = {
    skills: [],
    skills_visibility: true,
  };

  const validationSchema = Yup.object().shape({
    skills: Yup.array().of(Yup.string().defined()),
    skills_visibility: Yup.boolean(),
  });

  const formMethods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: 'all',
  });

  const {
    handleSubmit,
    formState: { isSubmitting, isValid, isDirty },
    watch,
    reset,
  } = formMethods;

  const setProfileData = () => {
    if (!profile || isLoading) return;

    reset(
      {
        skills: profile?.skills?.map((skill) => skill.name) ?? [],
        skills_visibility:
          profile.sections?.find(
            (section) => section.section_type === CandidateProfileSectionType.SKILLS
          )?.visibility_rule === VisibilityRule.PUBLIC,
      },
      { keepDirty: false }
    );
  };

  useEffect(() => {
    setProfileData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, profile]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      await updateProfile({
        id: profile?.id as string,
        data: {
          skills: data.skills ? data.skills?.map((skill) => ({ name: skill })) : undefined,
          sections: [
            {
              type: CandidateProfileSectionType.SKILLS,
              visibility_rule: data.skills_visibility
                ? VisibilityRule.PUBLIC
                : VisibilityRule.HIDDEN,
            },
          ],
        },
      }).unwrap();
    } catch (e) {
      console.log(e);

      enqueueSnackbar(generateCandidateProfileErrorMessage(e), { variant: 'error' });
    }

    reset({}, { keepDirty: false });
  });

  const skills_visibility = watch('skills_visibility');

  const onSearchSkill = (_event: SyntheticEvent, value: string) => setSkillName(value);

  const renderForm = () => (
    <>
      {
        isLoading ? (
          <Grid
            xs={12}
          >
            <Skeleton variant="rectangular" height={100} />
          </Grid>
        ) : (
          <FormProvider methods={formMethods} onSubmit={onSubmit}>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <RHFAutocomplete
                  fullWidth
                  freeSolo
                  multiple
                  onInputChange={onSearchSkill}
                  name="skills"
                  label={t('jobs.create.form.skills.label')}
                  placeholder={t('jobs.create.form.skills.placeholder')}
                  options={skillsData?.results?.map((option) => option.name) ?? []}
                  disabled={isLoading || mode === 'view'}
                />
              </Grid>
            </Grid>
            {
              mode === 'edit' && (
                <CardActions sx={{ px: 0, pt: 2, justifyContent: 'flex-end' }}>
                  <RHFVisibilityToggle
                    name="skills_visibility"
                    labelPosition="left"
                    checked={skills_visibility}
                    onLabel={t('common.visible')}
                    offLabel={t('common.hidden')}
                    tooltip={t('profile.form.toggle_tooltip')}
                  />

                  <LoadingButton
                    variant="contained"
                    color="success"
                    type="submit"
                    loading={isSubmitting}
                    disabled={!isValid || !isDirty}
                  >
                    {t('common.save')}
                  </LoadingButton>
                </CardActions>
              )
            }
          </FormProvider>
        )
      }
    </>
  );

  return (
    <Card>
      <CardHeader
        title={isLoading ? <Skeleton width={200} /> : t('profile.sections.skills.title')}
      />
      <CardContent>{renderForm()}</CardContent>
    </Card>
  );
}
