import { useState } from 'react';

import Card from '@mui/material/Card';
import Grid from '@mui/system/Unstable_Grid';
import { Stack, Button } from '@mui/material';
import CardContent from '@mui/material/CardContent';

import { useBoolean } from 'src/hooks/use-boolean';

import { useTranslate } from 'src/locales';
import { useGetProfileDocumentsQuery } from 'src/services/candidates/candidates.service';

import { useTable } from 'src/components/table';
import FileManagerTable from 'src/components/documents/file-manager-table';

import UploadCandidateDocumentModal from '../modals/upload-document-modal';

const DEFAULT_PAGE = {
  page: 1,
  per_page: 10,
};

type Props = {
  profileId?: string;
};

export default function CandidateProfileDocuments({ profileId }: Props) {
  const [page, setPage] = useState(DEFAULT_PAGE);

  const showUploadModal = useBoolean();

  const { t } = useTranslate();

  const { currentData: myDocuments, isLoading } = useGetProfileDocumentsQuery(
    {
      profileId: profileId as string,
      params: page,
    },
    { skip: !profileId }
  );

  const table = useTable();

  const notFound = !myDocuments?.count && !isLoading;

  return (
    <Grid container spacing={3}>
      <Grid xs={12}>
        <Stack direction="row" justifyContent="flex-end">
          <Button variant="contained" disabled={!profileId} onClick={showUploadModal.onTrue}>
            {t('profile.documents.upload')}
          </Button>
        </Stack>
      </Grid>
      <Grid xs={12}>
        <Card>
          <CardContent>
            <FileManagerTable
              table={table}
              dataFiltered={myDocuments?.results ?? []}
              count={myDocuments?.count ?? 0}
              // onDeleteRow={handleDeleteItem}
              notFound={notFound}
              documentType="candidate"
              onChangePage={(_e, pageNumber) => setPage({ ...page, page: pageNumber + 1 })}
              onChangePerPage={(e) => setPage({ ...page, per_page: Number(e.target.value) })}
              pageOptions={page}
            />
          </CardContent>
        </Card>
      </Grid>

      {profileId && (
        <UploadCandidateDocumentModal
          open={showUploadModal.value}
          onClose={showUploadModal.onFalse}
          profileId={profileId}
        />
      )}
    </Grid>
  );
}
