import { useMemo } from 'react';

import { alpha } from '@mui/system';
import { useTheme } from '@mui/material';

import { KanbanColorMap } from 'src/utils/jobs-kanban';

import { useTranslate } from 'src/locales';
import { ApplicationStage } from 'src/services/applications/applications.types';

import Label, { LabelProps } from 'src/components/label';

export function ApplicationStatusLabel(props: LabelProps & { status: ApplicationStage }) {
  const { status } = props;

  const { t } = useTranslate();

  const theme = useTheme();

  const colorCode = useMemo(() => KanbanColorMap[status], [status]);

  return (
    <Label
      variant="soft"
      sx={{
        backgroundColor: alpha(colorCode, 1),
        color: theme.palette.primary.contrastText,
        fontSize: '0.6rem',
      }}
    >
      {t(`enums.application_status.${status}`)}
    </Label>
  );
}
