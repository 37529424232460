import { useState, Fragment, useCallback } from 'react';

import {
  List,
  Stack,
  Dialog,
  Button,
  Divider,
  ListItem,
  Pagination,
  DialogTitle,
  ListItemText,
  DialogContent,
  DialogActions,
} from '@mui/material';

import { fDateTime } from 'src/utils/format-time';

import { useTranslate } from 'src/locales';
import { useAppDispatch } from 'src/store/store';
import { PageOptions } from 'src/services/api.types';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { openInterviewActionModal } from 'src/store/slices/interviews/interviewSlice';
import { useGetApplicationInterviewsQuery } from 'src/services/interviews/interviews.service';
import {
  InterviewStatus,
  ScheduledInterviewResponse,
} from 'src/services/interviews/interview.types';

import Label from 'src/components/label';
import EmptyContent from 'src/components/empty-content';

import { TenantType } from 'src/types/enums';

type Props = {
  open: boolean;
  applicationId: string;
  onClose: VoidFunction;
};

export default function InterviewListModal({ open, applicationId, onClose }: Props) {
  const { t } = useTranslate();

  const tenant = useOrgTenant();

  const dispatch = useAppDispatch();

  const [pageOptions, setPageOptions] = useState<PageOptions>({
    page: 1,
    per_page: 5,
  });

  const { currentData: interviews } = useGetApplicationInterviewsQuery({
    applicationId,
    params: pageOptions,
  });

  const onChangePage = useCallback((value: number) => {
    setPageOptions((prev) => ({
      ...prev,
      page: value,
    }));
  }, []);

  const getStatusLabelText = (interview: ScheduledInterviewResponse) => {
    switch (interview.status) {
      case InterviewStatus.Sent:
        return t(
          `enums.interview_status.sent_${tenant === TenantType.Candidate ? 'candidate' : 'org'}`
        );
      case InterviewStatus.ChangesRequested:
        return t('enums.interview_status.changes_requested');
      case InterviewStatus.RescheduleRequested:
        return t('enums.interview_status.reschedule_requested');
      case InterviewStatus.Scheduled:
        return t('enums.interview_status.scheduled');
      case InterviewStatus.Cancelled:
        return t('enums.interview_status.cancelled');
      case InterviewStatus.Completed:
        return t('enums.interview_status.completed');
      case InterviewStatus.InReview:
        return tenant === TenantType.Client
          ? t('enums.interview_status.in_review_client')
          : t('enums.interview_status.in_review_agency');
      default:
        return '';
    }
  };

  const getSubtitle = (interview: ScheduledInterviewResponse) => {
    if (interview.status === InterviewStatus.Scheduled) {
      return fDateTime(interview.scheduled_at);
    }

    if (interview.status === InterviewStatus.Cancelled) {
      return t('common.cancelled');
    }

    return t('applications.interview_list_modal.not_scheduled');
  };

  const renderActionButton = (interview: ScheduledInterviewResponse) => {
    if (interview.status === InterviewStatus.InReview) {
      return (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => dispatch(openInterviewActionModal({ interviewId: interview.id }))}
        >
          {t('common.review')}
        </Button>
      );
    }

    return null;
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{t('applications.interview_list_modal.title')}</DialogTitle>
      <DialogContent>
        <Stack
          direction="column"
          sx={{ minHeight: 300, height: '100%', typography: 'body1' }}
          justifyContent="space-between"
        >
          {interviews?.count === 0 ? (
            <EmptyContent
              filled
              title={t('applications.interview_list_modal.no_interviews')}
              sx={{
                py: 10,
              }}
            />
          ) : (
            <List sx={{ paddingBottom: 2, maxWidth: '100%' }}>
              {interviews?.results.map((interview) => (
                <Fragment key={interview.id}>
                  <ListItem
                    secondaryAction={
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Label color="info">{getStatusLabelText(interview)}</Label>
                        {renderActionButton(interview)}
                      </Stack>
                    }
                  >
                    <ListItemText primary={interview.title} secondary={getSubtitle(interview)} />
                  </ListItem>
                  <Divider />
                </Fragment>
              ))}
            </List>
          )}

          <Stack flexDirection="row" justifyContent="center" sx={{ py: 2 }}>
            <Pagination
              page={pageOptions.page || 1}
              count={Math.ceil((interviews?.count || 1) / pageOptions.per_page)}
              onChange={(_e, value) => onChangePage(value)}
            />
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{t('common.close')}</Button>
      </DialogActions>
    </Dialog>
  );
}
