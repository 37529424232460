import { UpdateSetting } from "src/services/settings/settings.types";

export enum IntegrationProviderType {
  JOB_ADDER = 'jobadder'
}

export enum IntegrationCategory {
  ATS = 'ats',
  ACCOUNTING = 'accounting'
}

export enum IntegrationStatus {
  ACTIVE = 'active',
  DISABLED = 'disabled',
  DISCONNECTED = 'disconnected',
  EXPIRED = 'expired',
  REQUIRES_SETUP = 'requires_setup',
}

export interface IIntegration {
  id: string;
  provider_type: IntegrationProviderType;
  provider: IntegrationProvider;
  category: IntegrationCategory;
  status: IntegrationStatus;
  name: string;
}

export enum IntegrationActivityType {
  WEBHOOK = 'webhook',
  JOB_SYNC = 'job_sync',
  CANDIDATE_SYNC = 'candidate_sync',
  COMPANY_SYNC = 'company_sync',
  CONTACT_SYNC = 'contact_sync',
  APPLICATION_SYNC = 'application_sync',
  PLACEMENT_SYNC = 'placement_sync'
}

export enum IntegrationActivityStatus {
  SUCCESS = 'success',
  ERROR = 'error'
}

export interface IResource {
  provider_type: IntegrationProviderType;
  last_synced_at: Date;
}

export interface IIntegrationLog {
  id: string;
  activity_type: IntegrationActivityType;
  status: IntegrationActivityStatus;
  message?: string;
  metadata: {
    job_id?: string;
    candidate_id?: string;
    connection_id?: string;
    organization_id?: string;
    contact_id?: string;
    application_id?: string;
    placement_id?: string;
  };
  created_at: Date;
}

export type GetIntegrationsProps = {
  search_query?: string;
  page?: number;
  per_page?: number;
};

export type GetIntegrationLogsProps = {
  integrationId: string;
  search_query?: string;
  page?: number;
  per_page?: number;
};

export type InitiateIntegrationAuthProps = {
  provider: IntegrationProviderType;
};

export type IntegrationProvider = {
  type: IntegrationProviderType;
  name: string;
  description: string;
  enabled: boolean;
  logo: string;
  link: string;
  privacy_policy: string;
  terms_of_service: string;
  tags: string[];
  category: IntegrationCategory;
};

export type UpdateSettingProps = {
  settings: UpdateSetting[];
  integrationId: string;
};

export enum FieldType {
  STRING = 'string',
  NUMBER = 'number',
  OBJECT = 'object',
  URL = 'url',
  DATE = 'date',
  ARRAY = 'array'
}

export interface IIntegrationUser {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
}

export type InternalField = {
  key: string;
  type: FieldType;
  name: string;
  entity: string;
  mapping?: boolean;
  value_mapping?: boolean;
  values?: Record<string, any>;
};

export type RemoteField = {
  id?: string;
  key: string;
  type: FieldType;
  name: string;
  entity: string;
  custom?: boolean;
  metadata?: Record<string, any>;
};

export type IntegrationFields = {
  internal: InternalField[];
  remote: RemoteField[];
};

export type FieldOption = {
  key: string;
  value: string;
}

export type IntegrationFieldMapItem = {
  internal: InternalField;
  remote: RemoteField;
  entity_type: string;
  value_mapping?: { internal: FieldOption, remote: FieldOption }[]
}

export type IntegrationFieldMap = IntegrationFieldMapItem[];

export type UpdateFieldMappingProps = {
  fields: {
    internal: string;
    remote: string;
    value_mapping?: { internal: FieldOption, remote: FieldOption }[]
  }[];
  integrationId: string;
};

export type SetupIntegrationProps = {
  integrationId: string;
  fields: {
    internal: string;
    remote: string;
    value_mapping?: { internal: FieldOption, remote: FieldOption }[]
  }[];
  settings: UpdateSetting[];
};

export enum IntegrationSetting {
  ADMINISTRATOR = 'administrator',
}

export type IntegrationResource = {
  integration: IIntegration;
  last_synced_at: Date;
}

export enum InternalFieldType {

  // jobs
  JOB_TITLE = 'job_title',
  JOB_DESCRIPTION = 'job_description',
  JOB_CURRENCY = 'job_currency',
  JOB_URL = 'job_url',
  JOB_RECRUITMENT_TYPE = 'job_recruitment_type',
  JOB_WORK_STYLE = 'job_work_style',
  JOB_EMPLOYMENT_TYPE = 'job_employment_type',
  JOB_TYPE = 'job_type',
  JOB_VACANCIES = 'job_vacancies',
  JOB_SHORTLIST_DEADLINE = 'job_shortlist_deadline',
  JOB_REFERENCE = 'job_reference',
  JOB_START_DATE = 'job_start_date',
  JOB_DURATION_PERIOD = 'job_duration_period',
  JOB_DURATION_VALUE = 'job_duration_value',
  JOB_MINIMUM_SALARY = 'job_minimum_salary',
  JOB_MAXIMUM_SALARY = 'job_maximum_salary',
  JOB_RATE_PERIOD = 'job_rate_period',
  JOB_PAY_RATE = 'job_pay_rate',
  JOB_CHARGE_RATE = 'job_charge_rate',
  JOB_FEE_TYPE = 'job_fee_type',
  JOB_FEE_VALUE = 'job_fee_value',
  JOB_FEE_CYCLE = 'job_fee_cycle',
  JOB_FEE_PERCENTAGE = 'job_fee_percentage',
  JOB_STATUS = 'job_status',
  JOB_INDUSTRY = 'job_industry',
  JOB_SKILLS = 'job_skills',

  JOB_APPLICATION_STAGE = 'application_stage',

  // company profiles
  COMPANY_NAME = 'company_name',
  COMPANY_INFORMATION = 'company_information',

  // contacts
  CONTACT_NAME = 'contact_name',
  CONTACT_EMAIL = 'contact_email',
  CONTACT_PHONE = 'contact_phone',
  CONTACT_COMPANY = 'contact_company',

  CANDIDATE_FIRST_NAME = 'candidate_first_name',
  CANDIDATE_LAST_NAME = 'candidate_last_name',
  CANDIDATE_EMAIL = 'candidate_email',
  CANDIDATE_PHONE = 'candidate_phone',
  CANDIDATE_AVAILABILITY_STATUS = 'candidate_availability_status',
  CANDIDATE_SKILLS = 'candidate_skills',


}
