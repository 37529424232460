import { Outlet } from 'react-router';
import { Helmet } from 'react-helmet-async';

import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';

import { paths } from 'src/routes/paths';

import { useTranslate } from 'src/locales';

import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';

export default function GlobalSettingsPage() {
    const { t } = useTranslate();

    return (
        <>
            <Helmet>
                <title>Admin: Global Settings</title>
            </Helmet>

            <Container
                maxWidth="lg"
                sx={{
                    height: 1,
                }}
            >
                <Stack spacing={2}>
                    <CustomBreadcrumbs
                        heading="Admin: Settings"
                        links={[
                            { name: t('common.dashboard'), href: paths.dashboard.root },
                            {
                                name: t('common.settings'),
                                href: undefined,
                            },
                        ]}
                    />
                    <Outlet />
                </Stack>
            </Container>
        </>
    );
}
