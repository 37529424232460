import { useState, useEffect, useCallback } from 'react';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { useMixpanel } from 'src/utils/tracking/use-mixpanel';

import { useAppDispatch } from 'src/store/store';
import { authApi, useGetAuthContextQuery } from 'src/services/auth/auth.service';
import { OrganizationStatus } from 'src/services/organisation/organisation.types';

import { SplashScreen } from 'src/components/loading-screen';

import { TenantType } from 'src/types/enums';

import { logOut } from '../utils';
// ----------------------------------------------------------------------

export const AccessTokenKey = 'access_token';

type Props = {
  children: React.ReactNode;
};

export default function AuthGuard({ children }: Props) {
  const { isLoading } = useGetAuthContextQuery(undefined);

  return <>{isLoading ? <SplashScreen /> : <Container>{children}</Container>}</>;
}

// ----------------------------------------------------------------------

function Container({ children }: Props) {
  const router = useRouter();

  const dispatch = useAppDispatch();

  const { identify } = useMixpanel();

  const { data, isLoading, isError } = useGetAuthContextQuery(undefined, {
    refetchOnFocus: true,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: 30,
  });

  useEffect(() => {
    if (isError) {
      dispatch(authApi.util.invalidateTags(['AuthContext']));
    }
  }, [dispatch, isError]);

  const [checked, setChecked] = useState(false);

  const check = useCallback(async () => {
    if (isLoading) return;

    if (!data) {
      const searchParams = new URLSearchParams({
        returnTo: window.location.pathname,
      }).toString();

      const loginPath = paths.auth.login;

      const href = `${loginPath}?${searchParams}`;

      router.replace(href);
    }

    if (data && isError) {
      const searchParams = new URLSearchParams({
        returnTo: window.location.pathname,
      }).toString();

      dispatch(authApi.util.invalidateTags(['AuthContext']));
      await dispatch(authApi.util.resetApiState());
      logOut();

      const loginPath = paths.auth.login;

      const href = `${loginPath}?${searchParams}`;

      router.replace(href);
    } else if (data) {
      const { user, account, organization } = data;

      if (!user.email_verified) {
        router.replace(paths.auth.register.verify);
      } else if (
        account?.type !== TenantType.Candidate &&
        organization?.status === OrganizationStatus.INACTIVE
      ) {
        router.replace(paths.auth.register.organisation);
      }
    }

    identify(data?.account.id, {
      $first_name: data?.user.first_name,
      $last_name: data?.user.last_name,
      $name: data?.user.name,
      $email: data?.user.email,
      $phone: data?.user.phone,
      $role: data?.account.org_role,
      type: data?.account.type,
    });

    setChecked(true);
  }, [isLoading, data, isError, dispatch, router, identify]);

  useEffect(() => {
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isError]);

  if (!checked) {
    return null;
  }

  return <>{children}</>;
}
