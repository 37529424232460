import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { useTheme } from '@mui/material/styles';
import { Stack, useMediaQuery } from '@mui/system';
import { Card, Tooltip, Typography, CardContent } from '@mui/material';

import { useRouter } from 'src/routes/hooks';

import { useBoolean } from 'src/hooks/use-boolean';

import { useTranslate } from 'src/locales';
import { CompanyDomain } from 'src/services/organisation/organisation.types';

import Iconify from 'src/components/iconify';
import { usePopover } from 'src/components/custom-popover';



// ----------------------------------------------------------------------
// TODO: Edit and Delete
type Props = {
  // onEditRow: VoidFunction;
  row: CompanyDomain;
  // onDeleteRow: (id: string) => void;
};

export default function DomainsTableRow({
  row,
  // onEditRow,
  // onDeleteRow,
}: Props) {
  const confirm = useBoolean();

  const quickEdit = useBoolean();

  const popover = usePopover();

  const { t } = useTranslate();

  const router = useRouter();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) {
    return (
      <Card sx={{ mx: 2, mb: 1 }}>
        <CardContent>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h6" noWrap>
              {row.domain}
            </Typography>
            <Tooltip title={row.verified ? "Verified" : "Not Verified"} placement="top" arrow>
              {row.verified ? (
                <Iconify
                  icon="akar-icons:check-box-fill"
                  color={theme.palette.success.main}
                  sx={{ fontSize: 24 }}
                />
              ) : (
                <Iconify
                  icon="akar-icons:circle-minus-fill"
                  color={theme.palette.grey[500]}
                  sx={{ fontSize: 24 }}
                />
              )}
            </Tooltip>
          </Stack>
        </CardContent>
      </Card>
    )
  }

  return (
    <TableRow hover>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        {row.domain}
      </TableCell>
      <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
        {row.verified ? (
          <Iconify icon="akar-icons:check-box-fill" color={theme.palette.success.main} />
        ) : (
          <Iconify icon="akar-icons:circle-minus-fill" color={theme.palette.grey[500]} />
        )}
      </TableCell>

    </TableRow>
  );
}
