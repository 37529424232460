import React, { useCallback } from 'react';

import { LoadingButton } from '@mui/lab';
import { Stack, Button } from '@mui/material';

import { useTranslate } from 'src/locales';
import { ConnectionSettings } from 'src/services/connections/connections.types';

import { SettingsFormData, ConnectionSettingsForm } from 'src/sections/connections/modal/create-connection-modal/components/connection-settings-form';

export type Props = {
  onNextPage: (data: ConnectionSettings) => void;
  onBack: VoidFunction;
  submitting: boolean;
};

export default function ConnectionSettingsStep({ onNextPage, onBack, submitting = false }: Props) {

  const { t } = useTranslate();

  const [connectionSettings, setConnectionSettings] = React.useState<SettingsFormData>();

  const handleSubmit = useCallback(() => {
    if (!connectionSettings) return;

    onNextPage({
      job_limit_applied: connectionSettings.job_limit_applied,
      jobs_limit: connectionSettings.jobs_limit,
      default_fee_type: connectionSettings.default_fee_type,
      default_fee_value: connectionSettings.default_fee_value,
      specializations: connectionSettings.specializations.map((item) => item.id),
    });
  }, [connectionSettings, onNextPage]);

  return (
    <Stack>
      <ConnectionSettingsForm
        onValuesChange={setConnectionSettings}
      />

      <Stack sx={{ pt: 3, width: '100%' }} flexDirection="row" justifyContent="flex-end">
        <Button variant="outlined" onClick={onBack}>
          {t('common.back')}
        </Button>
        <LoadingButton
          loading={submitting}
          onClick={handleSubmit}
          variant="contained"
          disabled={!connectionSettings || submitting}
          sx={{ ml: 1 }}
        >
          {t('common.submit')}
        </LoadingButton>
      </Stack>
    </Stack>
  );
}
