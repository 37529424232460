import { useEffect } from 'react';

import { useAppDispatch } from 'src/store/store';
import { useAuthContext } from 'src/auth/hooks/useAuthContext';
import { ConnectionStatus } from 'src/services/connections/connections.types';
import { useGetAccountSettingsQuery } from 'src/services/account/account.service';
import { useGetOrgConnectionsQuery } from 'src/services/connections/connections.service';
import { setOrgUsers, setOrgConnections, setOrgDepartments } from 'src/store/slices/data/dataSlice';
import {
  useGetAccountsQuery,
  useGetDepartmentsQuery,
} from 'src/services/organisation/organisation.service';

export default function DataFetcher() {
  const dispatch = useAppDispatch();
  const authContext = useAuthContext();

  const { currentData: users } = useGetAccountsQuery(
    {
      page: {
        page: 1,
        per_page: 999,
      },
      search_query: '',
      org_id: authContext?.organization?.id,
    },
    { skip: !authContext?.organization?.id }
  );

  const { currentData: departments } = useGetDepartmentsQuery({
    page: {
      page: 1,
      per_page: 999,
    },
    search_query: '',
    org_id: authContext?.organization?.id,
  });

  const { currentData: connections } = useGetOrgConnectionsQuery({
    search_query: '',
    organization_id: authContext?.organization?.id,
    page: 1,
    per_page: 999,
    status: ConnectionStatus.CONNECTED,
  });

  useGetAccountSettingsQuery(authContext.account.id);

  useEffect(() => {
    if (!connections) return;

    dispatch(setOrgConnections(connections.results));
  }, [connections, dispatch, users]);

  useEffect(() => {
    if (!users) return;

    dispatch(setOrgUsers(users.results));
  }, [dispatch, users]);

  useEffect(() => {
    if (!departments) return;

    dispatch(setOrgDepartments(departments.results));
  }, [departments, dispatch]);

  return <div />;
}
