import { m } from 'framer-motion';

import { Button } from '@mui/material';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Theme, SxProps } from '@mui/material/styles';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { authApi } from 'src/services/auth/auth.service';
import { AuthContext } from 'src/services/auth/auth.types';
import { ForbiddenIllustration } from 'src/assets/illustrations';
import { SystemRole } from 'src/services/organisation/organisation.enum';

import Iconify from 'src/components/iconify';
import { varBounce, MotionContainer } from 'src/components/animate';

// ----------------------------------------------------------------------

type SystemRoleGuardProp = {
  roles?: SystemRole[];
  children: React.ReactNode;
  sx?: SxProps<Theme>;
};

export default function SystemRoleGuard({ roles = [], children, sx }: SystemRoleGuardProp) {
  // Logic here to get current user system role
  const authResult = authApi.endpoints.getAuthContext.useQueryState();
  const { currentData: authData } = authResult;
  const { user } = authData as AuthContext;

  const router = useRouter();

  const currentRole = user?.system_role

  if (!currentRole || (currentRole && (roles.length > 0 && !roles.includes(currentRole)))) {
    return (
      <Container component={MotionContainer} sx={{ textAlign: 'center', ...sx, height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <m.div variants={varBounce().in}>
          <Typography variant="h3">
            Permission Denied
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>
            You do not have permission to access this page
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <ForbiddenIllustration
            sx={{
              height: 260,
              my: { xs: 5, sm: 10 },
            }}
          />
        </m.div>

        <m.div variants={varBounce().in}>
          <Button variant="contained" startIcon={<Iconify icon="mdi:arrow-left" />} onClick={() => router.push(paths.dashboard.root)}>Back Home</Button>
        </m.div>
      </Container>
    );
  }

  return <> {children} </>;
}