
import { Card, Button, CardHeader, CardContent } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { useTranslate } from 'src/locales';

import SystemDocumentsTable from 'src/sections/restricted/settings/sections/documents/table/documents-table';
import UploadSystemDocumentModal from 'src/sections/restricted/settings/sections/documents/upload-document-modal';


export default function SystemDocumentsView() {

  const { t } = useTranslate();

  const createTermControl = useBoolean();




  return (
    <Card sx={{ margin: 'auto', mt: 4, boxShadow: 0 }}>
      <CardHeader title={t('system.documents.title')} action={<Button onClick={() => createTermControl.onTrue()}>Add Document</Button>} />
      <CardContent>
        <SystemDocumentsTable />
      </CardContent>

      <UploadSystemDocumentModal open={createTermControl.value} onClose={createTermControl.onFalse} />
    </Card>
  );
}