import { useSnackbar } from 'notistack';
import React, { useMemo, useState, useEffect, useCallback } from 'react';

import { Box } from '@mui/system';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Card, Grid, Stack, Avatar, Button, Divider, Tooltip, useTheme, Typography, CardContent } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { useTranslate } from 'src/locales';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { useAuthContext } from 'src/auth/hooks/useAuthContext';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { hideConnectionModal } from 'src/store/slices/connections/connectionsSlice';
import { useMarkAllAsReadMutation } from 'src/services/notifications/notifications.service';
import {
  ConnectionSettings,
  ConnectionTermsType,
  ConnectionActionOption,
} from 'src/services/connections/connections.types';
import {
  useActionConnectionRequestMutation,
  useLazyGetConnectionRequestByIdQuery,
} from 'src/services/connections/connections.service';

import ViewTermsModal from 'src/sections/terms/view-terms-modal';
import { SettingsFormData, ConnectionSettingsForm } from 'src/sections/connections/modal/create-connection-modal/components/connection-settings-form';

import { TenantType } from 'src/types/enums';


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
};

const InfoSection = ({ title, content }: { title?: string, content?: string }) => (
  <Grid item xs={12}>
    <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
      {title}
    </Typography>
    <Typography variant="body2" color="text.secondary">
      {content}
    </Typography>
  </Grid>
);

export default function ConnectionActionModal({ open }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const tenantType = useOrgTenant();

  const authContext = useAuthContext();

  const { t } = useTranslate();

  const termsModalControl = useBoolean();

  const theme = useTheme();

  const dispatch = useAppDispatch();

  const requestId = useAppSelector((state) => state.connectionsState.connectionRequestId);
  const requestNotificationId = useAppSelector(
    (state) => state.connectionsState.requestNotificationId
  );

  const [fetchConnectionRequest, { isLoading: requestLoading, currentData: connectionData }] =
    useLazyGetConnectionRequestByIdQuery();

  const [actionConnectionRequest, { isLoading: isSubmitting }] =
    useActionConnectionRequestMutation();

  const [markAsRead] = useMarkAllAsReadMutation();

  const [connectionError, setConnectionError] = useState<any>(null);

  const [connectionSettings, setConnectionSettings] = React.useState<SettingsFormData>();

  const [termsData, setTermsData] = useState<any[]>([]);

  const defaultConnectionSettings = useMemo(() => {

    if (!connectionData || isSubmitting) return undefined;

    return {
      default_fee_type: connectionData?.settings_data?.default_fee_type,
      default_fee_value: connectionData?.settings_data?.default_fee_value,
      job_limit_applied: connectionData?.settings_data?.job_limit_applied,
      jobs_limit: connectionData?.settings_data?.jobs_limit,
      specializations: connectionData?.settings_data?.specializations,
    } as SettingsFormData;


  }, [connectionData, isSubmitting]);

  useEffect(() => {
    if (requestId) {
      fetchConnectionRequest({
        organization_id: authContext.organization.id,
        request_id: requestId,
      });
    }
  }, [authContext.organization.id, fetchConnectionRequest, requestId]);

  const onClose = useCallback(() => {
    setConnectionError(null);
    dispatch(hideConnectionModal());
  }, [dispatch]);

  const onActionConnectionRequest = useCallback(
    async (action: ConnectionActionOption) => {

      const connectionSettingsDto: ConnectionSettings | undefined =
        (action === ConnectionActionOption.Accept && tenantType === TenantType.Client)
          ? {
            job_limit_applied: connectionSettings?.job_limit_applied,
            jobs_limit: connectionSettings?.jobs_limit,
            specializations: connectionSettings?.specializations?.map((item) => item.id),
            default_fee_type: connectionSettings?.default_fee_type,
            default_fee_value: connectionSettings?.default_fee_value,
          } as ConnectionSettings
          : undefined;

      try {
        await actionConnectionRequest({
          connectionId: requestId as string,
          action: {
            action,
            connection_settings: connectionSettingsDto,
          },
        }).unwrap();

        onClose();
        markAsRead({ notifications: [requestNotificationId as string] });
        enqueueSnackbar(t(`admin-connections.api.action.success-${action}`), {
          variant: 'success',
        });
      } catch (e) {
        if (e.data) {
          setConnectionError(e.data);
        }

        enqueueSnackbar(t(`admin-connections.api.action.default_error`), { variant: 'error' });
      }
    },
    [
      tenantType,
      actionConnectionRequest,
      connectionSettings,
      enqueueSnackbar,
      markAsRead,
      onClose,
      requestNotificationId,
      requestId,
      t
    ]
  );

  const openTermsModal = () => {

    const termConfig: { type: "document" | "usage_term"; id: string; public_path?: string; content?: string }[] = [];
    switch (connectionData?.terms_type) {
      case ConnectionTermsType.Template:
      case ConnectionTermsType.Custom:
        connectionData.documents.forEach((doc) => {
          termConfig.push({
            type: "document",
            id: doc.id,
            public_path: doc.file.public_path
          });
        });

        break;
      case ConnectionTermsType.Terms_Of_Engagement:

        connectionData.requested_terms.forEach((term) => {
          termConfig.push({
            type: "usage_term",
            id: term.id,
            content: term.content
          });
        });

        break;
      default:
        break;
    }

    setTermsData(termConfig);
    termsModalControl.onTrue();
  };

  const closeTermsModal = () => {
    termsModalControl.onFalse();
    setTermsData([]);
  }

  const renderConnectionDetails = () => {
    const requestingTenant =
      tenantType === TenantType.Client ? connectionData?.recruiter : connectionData?.client;

    return (
      <Card elevation={3} sx={{ mb: 4 }}>
        <CardContent>
          <Grid container spacing={3}>
            <InfoSection
              title={t('admin-connections.action_modal.company_name')}
              content={requestingTenant?.company_name}
            />

            <InfoSection
              title={t('admin-connections.action_modal.company_desc')}
              content={requestingTenant?.company_information}
            />

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="subtitle1" fontWeight="bold">
                  {t('admin-connections.action_modal.terms')}
                </Typography>
                <Button
                  onClick={openTermsModal}
                  variant="outlined"
                  color="primary"
                  size="small"
                >
                  {t('admin-connections.action_modal.view_terms')}
                </Button>
              </Stack>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                {t('admin-connections.action_modal.view_terms_desc')}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
                <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                  {t('admin-connections.action_modal.initiated_by')}
                </Typography>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Avatar
                    alt={connectionData?.initiator_account.first_name}
                    sx={{ width: 32, height: 32 }}
                  />
                  <Typography variant="body2">
                    {`${connectionData?.initiator_account.first_name} ${connectionData?.initiator_account.last_name}`}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>

            {
              tenantType === TenantType.Recruiter && (
                <Grid item xs={12}>
                  <Divider />
                  <Stack
                    direction="row"
                    spacing={4}
                    alignItems="center"
                    sx={{ pt: 2 }}
                  >
                    {/* Job Limit */}
                    <Tooltip
                      title={
                        <Box sx={{ p: 1 }}>
                          <Typography variant="subtitle2" fontWeight="bold">
                            {t('admin-connections.connection_settings.job_limit')}
                          </Typography>
                          <Typography variant="body2">
                            {connectionData?.settings_data?.jobs_limit ?? 'N/A'}
                          </Typography>
                        </Box>
                      }
                    >
                      <Stack direction="row" spacing={1} alignItems="center">
                        <WorkOutlineIcon color="action" />
                        <Typography variant="body2">
                          {connectionData?.settings_data?.jobs_limit ?? 'N/A'}
                        </Typography>
                      </Stack>
                    </Tooltip>

                    {/* Specializations */}
                    <Tooltip
                      title={
                        <Box sx={{ p: 1 }}>
                          <Typography variant="subtitle2" fontWeight="bold">
                            {t('admin-connections.connection_settings.specialisations')}
                          </Typography>
                          <Typography variant="body2">
                            {connectionData?.settings_data?.specializations?.length
                              ? `${connectionData?.settings_data?.specializations?.length} ${t('common.specialisations')}`
                              : 'N/A'}
                          </Typography>
                        </Box>
                      }
                    >
                      <Stack direction="row" spacing={1} alignItems="center">
                        <LocalOfferOutlinedIcon color="action" />
                        <Typography variant="body2">
                          {connectionData?.settings_data?.specializations?.length
                            ? `${connectionData?.settings_data?.specializations?.length} ${t('common.specialisations')}`
                            : 'N/A'}
                        </Typography>
                      </Stack>
                    </Tooltip>

                    {/* Fee */}
                    <Tooltip
                      title={
                        <Box sx={{ p: 1 }}>
                          <Typography variant="subtitle2" fontWeight="bold">
                            {t('admin-connections.connection_settings.fee_settings')}
                          </Typography>
                          {
                            (connectionData?.settings_data?.default_fee_type && connectionData?.settings_data?.default_fee_value) ? (
                              <Typography variant="body2">
                                {connectionData?.settings_data?.default_fee_type === 'percentage'
                                  ? `${connectionData?.settings_data?.default_fee_value}%`
                                  : `$${connectionData?.settings_data?.default_fee_value}`}
                              </Typography>
                            )
                              :
                              <Typography variant="body2">N/A</Typography>
                          }
                        </Box>
                      }
                    >
                      <Stack direction="row" spacing={1} alignItems="center">
                        <PaidOutlinedIcon color="action" />
                        {
                          (connectionData?.settings_data?.default_fee_type && connectionData?.settings_data?.default_fee_value) ? (
                            <Typography variant="body2">
                              {connectionData?.settings_data?.default_fee_type === 'percentage'
                                ? `${connectionData?.settings_data?.default_fee_value}%`
                                : `$${connectionData?.settings_data?.default_fee_value}`}
                            </Typography>
                          )
                            :
                            <Typography variant="body2">N/A</Typography>
                        }
                      </Stack>
                    </Tooltip>
                  </Stack>
                </Grid>
              )
            }

          </Grid>
        </CardContent>

        <ViewTermsModal
          title={t('documents.terms_modal.terms_of_business')}
          open={termsModalControl.value} onClose={closeTermsModal}
          termsData={termsData}
          termsType={connectionData?.terms_type as ConnectionTermsType}
        />

      </Card>
    );
  };

  const renderClientForm = (
    <Grid
      container
      spacing={2}
      sx={{
        backgroundColor: theme.palette.grey[100],
        borderRadius: 2,
        padding: theme.spacing(2)
      }}
    >
      <ConnectionSettingsForm
        defaultValues={defaultConnectionSettings}
        onValuesChange={setConnectionSettings}
      />

    </Grid>
  );

  const renderInfoAlert = () => (
    <Alert variant="outlined" severity="info" sx={{ mb: 3 }}>
      {t(`admin-connections.action_modal.alert_${tenantType}`)}
    </Alert>
  );

  const renderErrorAlert = () => {
    if (connectionError) {
      let message = ``;

      if (connectionError?.feature_type) {
        message = t(`admin-connections.api.errors.features.${connectionError.feature_type}`);
      }

      if (!message) return null;

      return (
        <Alert variant="outlined" severity="error" sx={{ mb: 3, width: '100%' }}>
          {message}
        </Alert>
      );
    }

    return null;
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>

      <DialogTitle>{t('admin-connections.action_modal.title')}</DialogTitle>

      <DialogContent>
        {renderInfoAlert()}

        {renderErrorAlert()}

        {renderConnectionDetails()}

        {tenantType === TenantType.Client && renderClientForm}
      </DialogContent>

      <DialogActions>
        <LoadingButton
          variant="outlined"
          onClick={() => onActionConnectionRequest(ConnectionActionOption.Reject)}
          disabled={requestLoading}
          loading={isSubmitting}
        >
          {t('common.decline')}
        </LoadingButton>

        <LoadingButton
          variant="contained"
          loading={isSubmitting}
          onClick={() => onActionConnectionRequest(ConnectionActionOption.Accept)}
          disabled={requestLoading}
        >
          {t('common.accept')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
