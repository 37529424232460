import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import { useTranslate } from 'src/locales';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { isOrganisationTenant } from 'src/services/auth/auth.utils';
import PostCommentForm from 'src/layouts/common/threads/post-comment-form';
import PostCommentList from 'src/layouts/common/threads/post-comment-list';

type Props = {
  open: boolean;
  onClose: () => void;
  threadId: string;
};

export default function ContactsNotesModal({ threadId, open, onClose }: Props) {
  const { t } = useTranslate();

  const tenant = useOrgTenant();

  return (
    <Dialog open={open} maxWidth="lg" onClose={onClose} sx={{ pb: 2 }}>
      <DialogTitle>{t('contacts.notes_modal.title')}</DialogTitle>

      <DialogContent sx={{ pb: 2, minWidth: 490 }}>
        <Alert variant="outlined" severity="info" sx={{ mb: 2 }}>
          {isOrganisationTenant(tenant)
            ? t('contacts.notes_modal.alert')
            : t('contacts.notes_modal.alert_candidate')}
        </Alert>
        <PostCommentForm thread_id={threadId} />
        <PostCommentList thread_id={threadId} comments_per_page={3}/>
      </DialogContent>
    </Dialog>
  );
}
