import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { debounce, isString } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useMemo, useEffect, useCallback, SyntheticEvent } from 'react';

import Grid from '@mui/system/Unstable_Grid';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Box, Stack, Checkbox, useTheme, Typography } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import { useTranslate } from 'src/locales';
import { Category } from 'src/services/utils/utils.types';
import { FeeType, FeeTypeDisplay } from 'src/services/jobs/jobs.types';
import { useLazySearchCategoriesQuery } from 'src/services/utils/utils.service';

import RHFSwitch from 'src/components/hook-form/rhf-switch';
import FormProvider from 'src/components/hook-form/form-provider';
import RHFUnitInput from 'src/components/hook-form/rhf-unit-input';
import QuantityInput from 'src/components/number-input/quantity-input';
import RHFAutocomplete from 'src/components/hook-form/rhf-autocomplete';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export type SettingsFormData = {
  default_fee_type: FeeType;
  default_fee_value: number;
  job_limit_applied: boolean;
  jobs_limit: number | null;
  specializations: Category[];
};

type Props = {
  defaultValues?: Partial<SettingsFormData>;
  onValuesChange?: (values: SettingsFormData) => void;
};

const defaultFormValues: SettingsFormData = {
  default_fee_type: FeeType.PERCENTAGE,
  default_fee_value: 0,
  job_limit_applied: false,
  jobs_limit: 0,
  specializations: []
}

export const ConnectionSettingsForm = ({
  defaultValues = defaultFormValues,
  onValuesChange
}: Props) => {
  const { t } = useTranslate();
  const theme = useTheme();
  const [searchQuery, setSearchQuery] = React.useState('');
  const [jobsLimit, setJobsLimit] = React.useState(defaultValues.jobs_limit || 1);

  const [searchCategories, { currentData: categoriesData }] = useLazySearchCategoriesQuery();

  const ConnectionSearchSchema = Yup.object().shape({
    default_fee_type: Yup.mixed<FeeType>().oneOf([FeeType.FIXED, FeeType.PERCENTAGE]).required(t('validation.required')),
    default_fee_value: Yup.number()
      .min(1, t('validation.min_zero'))
      .required(t('validation.required'))
      .when('default_fee_type', ([default_fee_type], schema) => {
        if (default_fee_type === FeeType.PERCENTAGE) {
          return schema.max(100, t('admin-connections.create_modal.template.rate.max_percentage'));
        }
        return schema;
      }),
    job_limit_applied: Yup.boolean().default(false),
    jobs_limit: Yup.number().integer(),
    specializations: Yup.array().of(Yup.object())
  });

  const methods = useForm({
    resolver: yupResolver(ConnectionSearchSchema) as any,
    defaultValues: {
      default_fee_type: defaultValues.default_fee_type ?? defaultFormValues.default_fee_type,
      default_fee_value: defaultValues.default_fee_value ?? defaultFormValues.default_fee_value,
      job_limit_applied: defaultValues.job_limit_applied ?? defaultFormValues.job_limit_applied,
      jobs_limit: defaultValues.jobs_limit ?? defaultFormValues.jobs_limit,
      specializations: defaultValues.specializations ?? defaultFormValues.specializations
    },
    mode: 'all',
  });

  const { setValue, watch, formState: { isDirty, errors }, getValues, trigger } = methods;

  const feeType = watch('default_fee_type');
  const feeValue = watch('default_fee_value');
  const jobLimitApplied = watch('job_limit_applied');
  const specializations = watch('specializations');

  useEffect(() => {

    const validateAndSubmit = async () => {
      const result = await trigger();

      if (result) {
        onValuesChange?.({
          default_fee_type: feeType,
          default_fee_value: feeValue,
          job_limit_applied: jobLimitApplied,
          jobs_limit: jobLimitApplied ? jobsLimit : null,
          specializations,
        } as SettingsFormData);
      }
    }

    validateAndSubmit();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onValuesChange, feeType, feeValue, jobLimitApplied, jobsLimit, specializations, trigger]);

  const debounceSearchCategories = useMemo(
    () =>
      debounce((input: string) => {
        searchCategories({
          name: input,
          page: 1,
          per_page: 5,
        });
      }, 300),
    [searchCategories]
  );

  useEffect(() => {
    if (!searchQuery) return;
    debounceSearchCategories(searchQuery);
  }, [debounceSearchCategories, searchQuery]);

  const onSearch = (_event: SyntheticEvent, value: string) => setSearchQuery(value);

  const onSelectSpecialization = (_event: SyntheticEvent, value: Category[]) => {
    if (!value) return;
    setValue('specializations', [...value]);
  };

  const handleChangeUnit = useCallback(
    (value: FeeType) => {
      setValue('default_fee_type', value, { shouldValidate: true });
    },
    [setValue]
  );

  const onChangeJobLimit = (event: FocusEvent | PointerEvent | KeyboardEvent, value: number) => {
    event.preventDefault();
    setJobsLimit(value);
    setValue('jobs_limit', value);
  };

  return (
    <FormProvider methods={methods}>
      <Grid
        container
        sx={{ backgroundColor: theme.palette.grey[100], borderRadius: 2, width: '100%' }}
        mt={1}
        columns={12}
        gap={4}
        padding={2}
      >
        <Grid xs={12}>
          <Stack direction="column" alignItems="start" justifyContent="start" gap={2}>
            <Typography variant="subtitle2">
              {t('admin-connections.connection_settings.fee_settings')}
            </Typography>
            <RHFUnitInput
              fullWidth
              sx={{ width: '100%' }}
              name="default_fee_value"
              value={feeValue}
              label={t('jobs.create.form.fee_value.label')}
              type="number"
              unit={feeType}
              onChangeUnit={handleChangeUnit}
              options={[
                { value: FeeType.FIXED, label: FeeTypeDisplay.Fixed },
                { value: FeeType.PERCENTAGE, label: FeeTypeDisplay.Percentage },
              ]}
            />
          </Stack>
        </Grid>

        <Grid xs={12}>
          <Stack direction="column" alignItems="start" justifyContent="start" gap={1}>
            <Typography variant="subtitle2">
              {t('admin-connections.connection_settings.specialisations')}
            </Typography>
            <RHFAutocomplete
              multiple
              fullWidth
              onInputChange={onSearch}
              disableCloseOnSelect
              name="specializations"
              placeholder="Start typing to search specialisations"
              options={categoriesData?.results ?? []}
              onChange={onSelectSpecialization as any}
              filterOptions={(x) => x}
              renderOption={(props, option: Category, { selected }) => (
                <li {...props} key={option.id}>
                  <Stack direction="row">
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    <Typography variant="body1">{option.name}</Typography>
                  </Stack>
                </li>
              )}
              getOptionLabel={(option: Category | string) =>
                isString(option) ? option : option.name
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionKey={(option) => (option as Category).id as string}
            />
          </Stack>
        </Grid>

        <Grid xs={12}>
          <Stack direction="column" alignItems="start" justifyContent="start" gap={1}>
            <Typography variant="subtitle2">
              {t('admin-connections.connection_settings.job_limit')}
            </Typography>
            <Stack direction="row" alignItems="center" justifyContent="start" gap={3}>
              <Box>
                <RHFSwitch
                  label={t('admin-connections.connection_settings.limit_label')}
                  name="job_limit_applied"
                />
              </Box>
              {jobLimitApplied && (
                <Box>
                  <QuantityInput
                    min={1}
                    max={99}
                    value={jobsLimit}
                    onChange={onChangeJobLimit as any}
                  />
                </Box>
              )}
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </FormProvider>
  );
};

export default ConnectionSettingsForm;