import { useSnackbar } from 'notistack';
import { useState, useCallback } from 'react';

import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { Grid, Stack, MobileStepper } from '@mui/material';

import { useTranslate } from 'src/locales';
import { useAppDispatch } from 'src/store/store';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { useAuthContext } from 'src/auth/hooks/useAuthContext';
import { toggleCreateConnectionModal } from 'src/store/slices/connections/connectionsSlice';
import { generateConnectionCreateErrorMessage } from 'src/services/connections/connection.utils';
import { useInitiateConnectionRequestMutation } from 'src/services/connections/connections.service';
import {
  ConnectionSettings,
  ConnectionTargetData,
  ConnectionRequestData,
  ConnectionTermsSettings,
} from 'src/services/connections/connections.types';

import { TenantType } from 'src/types/enums';

import InviteSearchForm from './components/invite-search-form';
import BusinessTermsPicker from './components/business-terms-picker';
import ConnectionSettingsStep from './components/connection-settings';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
};

// ----------------------------------------------------------------------

export default function CreateConnectionModal({ open }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const tenantType = useOrgTenant();

  const { t } = useTranslate();

  const authContext = useAuthContext();

  const dispatch = useAppDispatch();

  const [requestConnection, { isLoading }] = useInitiateConnectionRequestMutation();

  const [activeStep, setActiveStep] = useState(0);

  const [connectionError, setConnectionError] = useState<any>(null);

  const [targetData, setTargetData] = useState<ConnectionTargetData | null>(null);

  const [connectionTerms, setConnectionTerms] = useState<ConnectionTermsSettings | null>(null);

  const handleSearchNextStep = (data: ConnectionTargetData) => {
    setActiveStep(1);
    setTargetData(data);
  };

  const handleTermsNextStep = async (data: ConnectionTermsSettings) => {
    setConnectionTerms(data);

    if (tenantType === TenantType.Client) {
      setActiveStep(2);
    } else {
      onSubmit({
        connectionTerms: data,
      });
    }
  };

  const handleSettingsNextStep = async (data: ConnectionSettings) => {
    await onSubmit({ connectionSettings: data });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onClose = useCallback(() => {
    setConnectionError(null);
    setActiveStep(0);
    dispatch(toggleCreateConnectionModal());
  }, [dispatch]);

  const onSubmit = async (data?: {
    connectionTerms?: ConnectionTermsSettings;
    connectionSettings?: ConnectionSettings;
  }) => {
    if (!targetData) return;

    const requestData: ConnectionRequestData = {
      target: targetData,
      terms_settings: data?.connectionTerms ?? (connectionTerms as ConnectionTermsSettings),
      connection_settings: tenantType === TenantType.Client ? (data?.connectionSettings as ConnectionSettings) : undefined
    };

    try {
      await requestConnection({
        organization_id: authContext.organization.id,
        request: requestData,
      }).unwrap();

      enqueueSnackbar(t('admin-connections.api.initiate.success'), { variant: 'success' });

      setActiveStep(0);
      setConnectionError(null);
      onClose();
    } catch (e) {
      if (e) {
        setConnectionError(e);
      }

      enqueueSnackbar(generateConnectionCreateErrorMessage(e), { variant: 'error' });
    }
  };

  const renderInfoAlert = () => {
    if (activeStep === 0) {
      return (
        <Alert variant="outlined" severity="info" sx={{ mb: 3 }}>
          {t(`admin-connections.create_modal.step_1_alert`)}
        </Alert>
      );
    }
    if (activeStep === 1) {
      return (
        <Alert variant="outlined" severity="info" sx={{ mb: 3 }}>
          {t(`admin-connections.create_modal.step_2_alert`)}
        </Alert>
      );
    }
    if (activeStep === 2) {
      return (
        <Alert variant="outlined" severity="info" sx={{ mb: 3 }}>
          {t(`admin-connections.create_modal.step_3_alert`)}
        </Alert>
      );
    }

    return null;
  };

  const renderErrorAlert = () => {
    if (connectionError) {
      const message = generateConnectionCreateErrorMessage(connectionError);

      if (!message) return null;

      return (
        <Alert variant="outlined" severity="error" sx={{ mb: 3, width: '100%' }}>
          {message}
        </Alert>
      );
    }

    return null;
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle>{t('admin-connections.action_modal.title')}</DialogTitle>

      <DialogContent>
        <Stack flexDirection="column" className="w-100">
          <Grid
            container
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignContent="center"
            spacing={2}
            sx={{ py: 2, px: 2 }}
          >
            {renderInfoAlert()}

            {renderErrorAlert()}

            {activeStep === 0 && <InviteSearchForm onNextPage={handleSearchNextStep} searchEnabled={tenantType === TenantType.Client} />}

            {activeStep === 1 && (
              <BusinessTermsPicker
                onNextPage={handleTermsNextStep}
                onBack={handleBack}
                submitting={isLoading}
              />
            )}

            {activeStep === 2 && (
              <ConnectionSettingsStep
                onNextPage={handleSettingsNextStep}
                onBack={handleBack}
                submitting={isLoading}
              />
            )}

            <Grid item xs={12}>
              <MobileStepper
                steps={tenantType === TenantType.Client ? 3 : 2}
                position="static"
                activeStep={activeStep}
                nextButton={undefined}
                backButton={undefined}
                sx={{ justifyContent: 'center' }}
              />
            </Grid>
          </Grid>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
