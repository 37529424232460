import {
  useGetOrgFeaturesQuery,
  useGetFeatureUsageQuery,
} from 'src/services/billing/billing.services';

export const useUsageState = () => {
  const { currentData: orgFeatures } = useGetOrgFeaturesQuery();
  const { currentData: featureUsage } = useGetFeatureUsageQuery();

  return { orgFeatures, featureUsage };
};

export default useUsageState;
