import { Card } from '@stripe/stripe-js';

import { rootApi } from '../rootApi';
import { APIResponse, PageOptions, HTTPRequestType, PaginatedResponse } from '../api.types';
import {
  Invoice,
  Product,
  OrgFeature,
  FeatureUsage,
  Subscription,
  PaymentMethod,
  ActivateSubscriptionProps,
} from './billing.types';

export const billingApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    /* Products ------------------------------------------------------------ */

    getProducts: builder.query<Product[], void>({
      query: () => ({
        url: '/billing/system/products',
      }),
    }),

    /* Subscription -------------------------------------------------------- */

    getMySubscription: builder.query<Subscription, void>({
      query: () => ({
        url: '/billing/organizations/my/subscription',
      }),
      providesTags: ['Subscription'],
      transformErrorResponse: (response: { status: number }) => {
        if (response.status === 404) {
          return { kind: 'NO_SUBSCRIPTION' } as const;
        }
        return { kind: 'OTHER_ERROR' } as const;
      },
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
        } catch (error) {
          if (error.error.kind === 'NO_SUBSCRIPTION') {
            // Clear the cache when there's no subscription
            dispatch(billingApi.util.updateQueryData('getMySubscription', undefined, () => undefined));
          }
        }
      },
    }),

    getOrganizationSubscription: builder.query<Subscription, { organization_id: string; }>({
      query: (data) => ({
        url: `/billing/organizations/${data.organization_id}/subscription`,
      })
    }),

    activateSubscription: builder.mutation<APIResponse, ActivateSubscriptionProps>({
      query: (data) => ({
        url: '/billing/organizations/my/subscription',
        method: HTTPRequestType.POST,
        data,
      }),
      invalidatesTags: ['Invoices', 'Subscription'],
    }),

    upgradeSubscription: builder.mutation<APIResponse, ActivateSubscriptionProps>({
      query: (data) => ({
        url: '/billing/organizations/my/subscription',
        method: HTTPRequestType.PUT,
        data,
      }),
      invalidatesTags: ['Invoices', 'Subscription'],
    }),

    cancelSubscription: builder.mutation<APIResponse, { cancel_immediately: boolean }>({
      query: (data) => ({
        url: '/billing/organizations/my/subscription/cancel',
        method: HTTPRequestType.POST,
        data,
      }),
      invalidatesTags: ['Subscription'],
    }),

    /* Payments ------------------------------------------------------------ */

    getMyPaymentMethods: builder.query<PaginatedResponse<PaymentMethod>, PageOptions>({
      query: (data) => ({
        url: `/billing/organizations/my/payment-methods?page=${data.page}&per_page=${data.per_page}`,
      }),
      providesTags: ['Cards'],
    }),

    initiatePaymentMethodSetup: builder.mutation<{ client_secret: string }, void>({
      query: () => ({
        url: '/billing/organizations/my/payment-methods/setup/request',
        method: HTTPRequestType.POST,
      }),
      invalidatesTags: ['Cards'],
    }),

    createPaymentMethod: builder.mutation<Card, { payment_method: string }>({
      query: ({ payment_method }) => ({
        url: '/billing/organizations/my/payment-methods',
        method: HTTPRequestType.POST,
        data: {
          type: 'card',
          service_id: payment_method,
          set_default: true,
        },
      }),
      invalidatesTags: ['Cards'],
    }),

    removePaymentMethod: builder.mutation<APIResponse, string>({
      query: (paymentMethodId) => ({
        url: `/billing/organizations/my/payment-methods/${paymentMethodId}`,
        method: HTTPRequestType.DELETE,
      }),
      invalidatesTags: ['Cards'],
    }),

    setDefaultPaymentMethod: builder.mutation<APIResponse, string>({
      query: (paymentMethodId) => ({
        url: `/billing/organizations/my/payment-methods/${paymentMethodId}/mark-as-default`,
        method: HTTPRequestType.POST,
      }),
      invalidatesTags: ['Cards'],
    }),

    /* Invoices ------------------------------------------------------------ */

    getMyInvoices: builder.query<PaginatedResponse<Invoice>, PageOptions>({
      query: (data) => ({
        url: `/billing/organizations/my/invoices?page=${data.page}&per_page=${data.per_page}`,
      }),
      providesTags: ['Invoices'],
    }),

    getOrganizationInvoices: builder.query<PaginatedResponse<Invoice>, { organization_id: string; page_options: PageOptions }>({
      query: (data) => ({
        url: `/billing/organizations/${data.organization_id}/invoices?page=${data.page_options.page}&per_page=${data.page_options.per_page}`,
      })
    }),

    payInvoice: builder.mutation<APIResponse, string>({
      query: (invoiceId) => ({
        url: `/billing/organizations/my/invoices/${invoiceId}/pay`,
        method: HTTPRequestType.POST,
      }),
      invalidatesTags: ['Invoices', 'Subscription'],
    }),

    /* Usage --------------------------------------------------------------- */

    getFeatureUsage: builder.query<FeatureUsage[], void>({
      query: () => ({
        url: `/billing/organizations/my/usage`,
      }),
      providesTags: ['Subscription'],
    }),

    getOrgFeatures: builder.query<OrgFeature[], void>({
      query: () => ({
        url: `/billing/organizations/my/features`,
      }),
      providesTags: ['Subscription'],
    }),
  }),
});

export const {
  useGetProductsQuery,
  useGetMySubscriptionQuery,
  useGetOrganizationSubscriptionQuery,
  useGetMyPaymentMethodsQuery,
  useInitiatePaymentMethodSetupMutation,
  useCreatePaymentMethodMutation,
  useRemovePaymentMethodMutation,
  useLazyGetMyPaymentMethodsQuery,
  useActivateSubscriptionMutation,
  useUpgradeSubscriptionMutation,
  useCancelSubscriptionMutation,
  useGetMyInvoicesQuery,
  useLazyGetMyInvoicesQuery,
  useGetOrganizationInvoicesQuery,
  usePayInvoiceMutation,
  useSetDefaultPaymentMethodMutation,
  useGetFeatureUsageQuery,
  useGetOrgFeaturesQuery,
} = billingApi;
