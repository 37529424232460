import { Fragment, useState, useCallback } from 'react';

import Stack from '@mui/system/Stack';
import List from '@mui/material/List';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import { Box, Button } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import Pagination from '@mui/material/Pagination';
import DialogTitle from '@mui/material/DialogTitle';
import ListItemText from '@mui/material/ListItemText';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import { useTranslate } from 'src/locales';
import { useAppDispatch } from 'src/store/store';
import { PageOptions } from 'src/services/api.types';
import { JobInvite } from 'src/services/jobs/jobs.types';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { useGetCandidateInvitesQuery } from 'src/services/jobs/jobs.service';
import {
  hideCandidateJobRequestsModal,
  showCandidateJobInviteActionModal,
} from 'src/store/slices/jobs/jobsSlice';

import EmptyContent from 'src/components/empty-content';

import { TenantType } from 'src/types/enums';

type Props = {
  open: boolean;
};

const DEFAULT_PAGE = {
  page: 1,
  per_page: 5,
};

export default function CandidateJobRequestsModal({ open }: Props) {
  const { t } = useTranslate();

  const dispatch = useAppDispatch();

  const tenant = useOrgTenant();

  const onClose = useCallback(() => {
    dispatch(hideCandidateJobRequestsModal());
  }, [dispatch]);

  const [pageOptions, setPageOptions] = useState<PageOptions>(DEFAULT_PAGE);

  const changePage = useCallback((value: number) => {
    setPageOptions((prev) => ({
      ...prev,
      page: value,
    }));
  }, []);

  const { currentData: requestData } = useGetCandidateInvitesQuery(pageOptions, {
    skip: tenant !== TenantType.Candidate,
  });

  const onManageRequest = useCallback(
    (invite: JobInvite) => {
      dispatch(showCandidateJobInviteActionModal(invite));

      onClose();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const renderIncomingButtons = (invite: JobInvite) => (
    <Button
      variant="contained"
      sx={{ mr: 1 }}
      aria-label="manage"
      onClick={() => onManageRequest(invite)}
    >
      {t('common.manage')}
    </Button>
  );

  const renderRequest = (request: JobInvite, index: number) => {
    const jobTitle = request.job?.title;
    const companyName = request.initiator?.company_name;

    return (
      <Fragment key={`request-${index}`}>
        <ListItem sx={{ mb: 1 }} secondaryAction={renderIncomingButtons(request)}>
          <ListItemAvatar>
            <Avatar>{companyName?.charAt(0)}</Avatar>
          </ListItemAvatar>
          <ListItemText
            secondaryTypographyProps={{
              paddingRight: 14,
            }}
            primary={jobTitle}
            secondary={companyName}
          />
        </ListItem>
        <Divider variant="middle" component="li" />
      </Fragment>
    );
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle sx={{ textTransform: 'capitalize' }}>
        {t(`jobs.job_invites_modal.title`, {
          target: t('common.incoming'),
        })}
      </DialogTitle>

      <DialogContent>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <Stack direction="column">
            <Alert variant="outlined" severity="info" sx={{ mb: 3 }}>
              {t(`jobs.job_invites_modal.incoming.alert`)}
            </Alert>

            {requestData?.count ? (
              <List dense>
                {requestData?.results?.map((requestObj, requestIndex) =>
                  renderRequest(requestObj, requestIndex)
                )}
              </List>
            ) : (
              <EmptyContent
                filled
                title="No Data"
                sx={{
                  py: 10,
                }}
              />
            )}

            <Stack flexDirection="row" justifyContent="center" sx={{ pt: 2 }}>
              <Pagination
                page={pageOptions.page || 1}
                count={Math.ceil((requestData?.count || 1) / pageOptions.per_page)}
                onChange={(_e, value) => changePage(value)}
              />
            </Stack>
          </Stack>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{t('common.close')}</Button>
      </DialogActions>
    </Dialog>
  );
}
