import { enqueueSnackbar } from 'notistack';
import React, { useState, useEffect } from 'react';

import { LoadingButton } from '@mui/lab';
import { Card, Checkbox, TextField, CardHeader, CardActions, CardContent, FormControlLabel } from '@mui/material';

import { useTranslate } from 'src/locales';
import { SystemAttributeKey } from 'src/services/system/system.types';
import { useGetSystemSettingsQuery, useUpdateSystemSettingsMutation } from 'src/services/system/system.service';

export default function MaintenanceModeForm() {

  const { t } = useTranslate();

  const { data: settingsData } = useGetSystemSettingsQuery();
  const [updateSettings, { isLoading: isSubmitting }] = useUpdateSystemSettingsMutation();

  const [settings, setSettings] = useState<Record<SystemAttributeKey, any>>({
    [SystemAttributeKey.MAINTENANCE_MODE]: false,
    [SystemAttributeKey.MAINTENANCE_MESSAGE]: '',
  });

  useEffect(() => {
    if (settingsData) {
      const data = settingsData.reduce((acc, setting) => {
        acc[setting.key] = setting.value;
        return acc;
      }, {} as Record<SystemAttributeKey, any>);
      setSettings(data);
    }
  }, [settingsData]);

  const handleModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSettings(prev => ({ ...prev, MAINTENANCE_MODE: event.target.checked }));
  };

  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSettings(prev => ({ ...prev, MAINTENANCE_MESSAGE: event.target.value ?? '' }));
  };

  const handleSave = async () => {
    try {
      await updateSettings([{
        key: SystemAttributeKey.MAINTENANCE_MODE,
        value: settings[SystemAttributeKey.MAINTENANCE_MODE],
      }, {
        key: SystemAttributeKey.MAINTENANCE_MESSAGE,
        value: settings[SystemAttributeKey.MAINTENANCE_MESSAGE] ?? ''
      }]);

      enqueueSnackbar(t('app.settings.api.update.success'), { variant: 'success' });
    } catch (error) {
      console.error('Failed to save settings:', error);
      enqueueSnackbar(t('app.settings.api.update.default_error'), { variant: 'error' });
    }
  }

  return (
    <Card sx={{ margin: 'auto', mt: 4, boxShadow: 0 }}>
      <CardHeader title="Maintenance Mode" />
      <CardContent>
        <FormControlLabel
          control={
            <Checkbox
              checked={settings.MAINTENANCE_MODE}
              onChange={handleModeChange}
              name={SystemAttributeKey.MAINTENANCE_MODE}
            />
          }
          label={t('app.settings.labels.maintenance_mode')}
        />

        {settings.MAINTENANCE_MODE && (
          <TextField
            fullWidth
            margin="normal"
            id={SystemAttributeKey.MAINTENANCE_MESSAGE}
            name={SystemAttributeKey.MAINTENANCE_MESSAGE}
            label={t('app.settings.labels.maintenance_message')}
            value={settings.MAINTENANCE_MESSAGE}
            onChange={handleMessageChange}
            multiline
            rows={4}
          />
        )}

        <CardActions sx={{ px: 0, pt: 2, justifyContent: 'flex-end' }}>
          <LoadingButton
            variant="contained"
            color="success"
            type="submit"
            onClick={handleSave}
            loading={isSubmitting}
            disabled={false}
          >
            {t('common.save')}
          </LoadingButton>
        </CardActions>
      </CardContent>

    </Card>
  );
}