import { PageOptions } from '../api.types';

export interface UploadProps {
  file: File;
  type: 'document';
}

export interface UploadResponse {
  id: string;
  type: DocumentType;
  filename: string;
  public_path: string;
}

export enum DocumentType {
  RESUME = 'resume',
  COVER_LETTER = 'cover_letter',
  CERTIFICATE = 'certificate',
  VISA = 'visa',
  DRIVING_LICENSE = 'driving_license',
  OTHER_LICENSE = 'other_license',
  INSURANCE_DOCUMENT = 'insurance_document',
  TAX_DOCUMENT = 'tax_document',
  PASSPORT = 'passport',
  TERMS_OF_BUSINESS = 'terms_of_business',
  OTHER = 'other',
}

export const CandidateDocumentTypes = [
  DocumentType.RESUME,
  DocumentType.COVER_LETTER,
  DocumentType.CERTIFICATE,
  DocumentType.VISA,
  DocumentType.DRIVING_LICENSE,
  DocumentType.OTHER_LICENSE,
  DocumentType.INSURANCE_DOCUMENT,
  DocumentType.TAX_DOCUMENT,
  DocumentType.PASSPORT,
  DocumentType.OTHER,
];

export const OrganizationDocumentTypes = [DocumentType.TERMS_OF_BUSINESS];

export const OfferDocumentTypes = [
  DocumentType.RESUME,
  DocumentType.COVER_LETTER,
  DocumentType.CERTIFICATE,
  DocumentType.VISA,
  DocumentType.DRIVING_LICENSE,
  DocumentType.OTHER_LICENSE,
  DocumentType.INSURANCE_DOCUMENT,
  DocumentType.TAX_DOCUMENT,
  DocumentType.PASSPORT,
  DocumentType.OTHER,
];

export interface CreateDocumentProps {
  organization_id: string;
  document: CreateDocumentData;
}

export interface CreateDocumentData {
  file_id: string;
  name: string;
  type: DocumentType;
}

export interface CreateDocumentResponse {
  id: string;
  name: string;
  type: DocumentType;
  file: DocumentFile;
  created_at: string;
}

export type GetOrganisationDocumentsProps = {
  organization_id: string;
  search_query?: string | undefined;
  type?: DocumentType | undefined;
} & PageOptions;

export interface OrganisationDocument {
  id: string;
  name: string;
  type: DocumentType;
  custom_type_name?: string;
  file: DocumentFile;
}

export interface DocumentFile {
  id: string;
  filename: string;
  public_path: string;
  type: DocumentType;
  mime: string;
  created_at: string;
  name?: string;
  custom_type_name?: string;
}

export interface GetConnectionDocumentsProps {
  organization_id: string;
  connection_id: string;
}

export type ConnectionDocumentsResponse = OrganisationDocument[];
