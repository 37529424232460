import { Helmet } from 'react-helmet-async';

import ExtensionAuthView from 'src/sections/auth/extension-auth/extension-auth-view';

// ----------------------------------------------------------------------

export default function ExtensionAuthPage() {
  return (
    <>
      <Helmet>
        <title>JT: Verify Email</title>
      </Helmet>

      <ExtensionAuthView />
    </>
  );
}
