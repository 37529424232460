import 'src/utils/sentry/instrument';
// eslint-disable-next-line perfectionist/sort-imports
import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import App from './App';

// ----------------------------------------------------------------------

const container = document.getElementById('root') as HTMLElement;

const root = createRoot(container, {
  // TODO: Add error boundaries here upon release of React v19.
});

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <App />
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>
);
