
import { useMemo, MouseEventHandler } from 'react';

import { Tooltip } from '@mui/material';

import { useTranslate } from 'src/locales';
import { CandidateAvailabilityStatus } from 'src/services/candidates/candidates.types';

import Label, { LabelColor } from 'src/components/label';

// ----------------------------------------------------------------------

type Props = {
  status?: CandidateAvailabilityStatus;
  enableTooltip?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement> | undefined;
};

export default function AvailabilityStatusLabel({
  status,
  enableTooltip = true,
  onClick
}: Props) {

  const { t } = useTranslate();

  const styles = useMemo(() => {
    switch (status) {
      case CandidateAvailabilityStatus.AVAILABLE_NOW:
        return {
          color: 'success',
          label: t(`enums.candidate_availability_status.${status}`),
          tooltip: t(`candidates.availability_status_tooltips.${status}`)
        };
      case CandidateAvailabilityStatus.AVAILABLE_SOON:
        return {
          color: 'warning',
          label: t(`enums.candidate_availability_status.${status}`),
          tooltip: t(`candidates.availability_status_tooltips.${status}`)
        };
      case CandidateAvailabilityStatus.INTERESTED:
        return {
          color: 'info',
          label: t(`enums.candidate_availability_status.${status}`),
          tooltip: t(`candidates.availability_status_tooltips.${status}`)
        };
      case CandidateAvailabilityStatus.UNAVAILABLE:
        return {
          color: 'error',
          label: t(`enums.candidate_availability_status.${status}`),
          tooltip: t(`candidates.availability_status_tooltips.${status}`)
        };
      default:
        return {
          color: 'default',
          label: t(`enums.candidate_availability_status.private`),
          tooltip: t(`candidates.availability_status_tooltips.private`)
        };
    }
  }, [status, t]);

  return (
    <Tooltip title={enableTooltip ? styles.tooltip : ''} sx={{ width: '100%', cursor: onClick ? 'pointer' : 'default' }} onClick={onClick} >
      <Label
        variant="soft"
        color={styles.color as LabelColor}
      >
        {styles.label}
      </Label>
    </Tooltip >
  );
}
