import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { AuthGuard } from 'src/auth/guard';
import { AcceptInvitePage } from 'src/pages/auth';
import PasswordResetPage from 'src/pages/auth/password-reset-page';
import ExtensionAuthPage from 'src/pages/auth/extension-auth-page';
import AuthModernCompactLayout from 'src/layouts/auth/modern-compact';
import CallbackManagerPage from 'src/pages/auth/callbacks/callback-page';

import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// JWT
const LoginPage = lazy(() => import('src/pages/auth/login'));
const RegisterRootPage = lazy(() => import('src/pages/auth/register-root'));
const ConfirmAuthorityPage = lazy(() => import('src/pages/auth/organisation-confirm-authority'));
const AccountRegisterPage = lazy(() => import('src/pages/auth/register'));
const VerifyEmailPage = lazy(() => import('src/pages/auth/verify-email-page'));
const RegisterOrganisationPage = lazy(() => import('src/pages/auth/register-organisation-page'));
const ConnectionRequestPage = lazy(
  () => import('src/pages/auth/connection-request/connection-request-page')
);
const CandidateProfileView = lazy(
  () => import('src/sections/auth/candidate-profile/candidate-profile-view')
);
const RegisterInviteView = lazy(
  () => import('src/sections/auth/job-invites/register-invites-view')
);
const RegisterOrgInviteView = lazy(
  () => import('src/sections/auth/organisation-invites/company-invites-view')
);

// ----------------------------------------------------------------------

const children = [
  {
    element: (
      <AuthModernCompactLayout>
        <Outlet />
      </AuthModernCompactLayout>
    ),
    children: [
      {
        path: 'login',
        element: <LoginPage />,
      },
      {
        path: 'register',
        element: <RegisterRootPage />,
      },
      {
        path: 'register/confirm',
        element: <ConfirmAuthorityPage />,
      },
      {
        path: 'register/account',
        element: <AccountRegisterPage />,
      },
      {
        path: 'register/account/verify',
        element: (
          <AuthGuard>
            <VerifyEmailPage />
          </AuthGuard>
        ),
      },
      {
        path: 'register/organisation',
        element: (
          <AuthGuard>
            <RegisterOrganisationPage />
          </AuthGuard>
        ),
      },
      {
        path: 'register/candidate',
        element: (
          <AuthGuard>
            <CandidateProfileView />
          </AuthGuard>
        ),
      },
      {
        path: 'register/invites',
        element: (
          <AuthGuard>
            <RegisterInviteView />
          </AuthGuard>
        ),
      },
      {
        path: 'register/organisation-invites',
        element: (
          <AuthGuard>
            <RegisterOrgInviteView />
          </AuthGuard>
        ),
      },
      {
        path: 'password-reset',
        element: <PasswordResetPage />,
      },
      {
        path: 'accept-invite',
        element: <AcceptInvitePage />,
      },
      {
        path: 'connection-requests',
        element: <ConnectionRequestPage />,
      },
      {
        path: 'callbacks/:provider',
        element: <CallbackManagerPage />,
      },
    ],
  },
  {
    path: 'extension',
    element: (
      <Suspense fallback={<SplashScreen />}>
        <AuthGuard>
          <AuthModernCompactLayout>
            <ExtensionAuthPage />
          </AuthModernCompactLayout>
        </AuthGuard>
      </Suspense>
    ),
  },
];

export const authRoutes = [
  {
    path: 'auth',
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),
    children,
  },
];
