import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface JoyrideState {
  showWelcomeJoyride: boolean;
  showNavMenu: boolean;
}

const initialState: JoyrideState = {
  showWelcomeJoyride: false,
  showNavMenu: false,
};

export const joyrideSlice = createSlice({
  name: 'joyride',
  initialState,
  reducers: {
    setShowJoyride: (state, action: PayloadAction<boolean>) => {
      state.showWelcomeJoyride = action.payload;
    },
    setShowNavMenu: (state, action: PayloadAction<boolean>) => {
      state.showNavMenu = action.payload;
    },
  },
});

export const { setShowJoyride, setShowNavMenu } = joyrideSlice.actions;

export const joyrideStateReducer = joyrideSlice.reducer;
