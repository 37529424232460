import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useState, useCallback } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

import { LoadingButton } from '@mui/lab';
import { Grid, Stack, Alert, Dialog, Button, DialogTitle, DialogContent } from '@mui/material';

import { useTranslate } from 'src/locales';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { useRequestJobChangesMutation } from 'src/services/jobs/jobs.service';
import { onHideRequestJobChangesModal } from 'src/store/slices/jobs/jobsSlice';

import FormProvider, { RHFTextField } from 'src/components/hook-form';

type Props = {
  open: boolean;
};

export default function RequestJobChangesModal({ open }: Props) {
  const { t } = useTranslate();

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useAppDispatch();

  const [error, setError] = useState<string | null>(null);

  const [requestChanges] = useRequestJobChangesMutation();

  const selectedJobId = useAppSelector((state) => state.jobs.currentJobId);

  const onCloseDialog = useCallback(() => {
    dispatch(onHideRequestJobChangesModal());
  }, [dispatch]);

  const defaultValues = {
    notes: '',
  };

  const schema = Yup.object().shape({
    notes: Yup.string().required(t('validation.required')),
  });

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { handleSubmit } = methods;

  const onSubmit = handleSubmit(async (data: any) => {
    try {
      await requestChanges({
        job_id: selectedJobId as string,
        notes: data.notes,
      }).unwrap;

      enqueueSnackbar(t('jobs.request_changes_modal.api.success'), { variant: 'success' });

      onCloseDialog();
    } catch (e) {
      enqueueSnackbar(t('jobs.request_changes_modal.api.error'), { variant: 'error' });
      setError(t('jobs.request_changes_modal.api.error'));
    }
  });

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onCloseDialog}>
      <DialogTitle>{t(`jobs.request_changes_modal.title`)}</DialogTitle>
      <DialogContent>
        {!!error && <Alert severity="error">{error}</Alert>}

        <FormProvider methods={methods} onSubmit={onSubmit}>
          <Grid container spacing={2} justifyContent="center" pt={1}>
            <Grid item xs={12}>
              <Alert variant="outlined" severity="info">
                {t(`jobs.request_changes_modal.alert`)}
              </Alert>
            </Grid>

            <Grid item xs={12} mb={5}>
              <RHFTextField
                name="notes"
                multiline
                rows={2}
                placeholder={t(`jobs.request_changes_modal.alert_placeholder`)}
              />
            </Grid>

            <Stack
              sx={{ pb: 2, width: '100%' }}
              gap={1}
              flexDirection="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button variant="text" onClick={onCloseDialog}>
                {t('common.cancel')}
              </Button>

              <LoadingButton
                type="submit"
                variant="contained"
                // onClick={handleInviteCandidate}
                // disabled={isSubmitting || !selectedJob || !orgCandidate}
                // loading={isSubmitting}
              >
                {t('candidates.invites.add_to_job_modal.add')}
              </LoadingButton>
            </Stack>
          </Grid>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
