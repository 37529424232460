import { Suspense, useState, useEffect } from 'react';
import { Outlet, useParams, useLocation } from 'react-router';

import { Box } from '@mui/system';
import { Tabs, Stack, Alert, Button, useTheme, Container, Typography } from '@mui/material';

import { paths } from 'src/routes/paths';

import { useTranslate } from 'src/locales';
import { useAuthContext } from 'src/auth/hooks/useAuthContext';
import { IntegrationStatus } from 'src/services/integration/integrations.types';
import { useGetIntegrationQuery, useInitiateConnectMutation } from 'src/services/integration/integrations.service';

import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import { LoadingScreen } from 'src/components/loading-screen';
import LinkTab, { samePageLinkNavigation } from 'src/components/link-tab/link-tab';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';

export default function IntegrationsContainer() {

  const [value, setValue] = useState(0);

  const settings = useSettingsContext();
  const authContext = useAuthContext();

  const { integration_id } = useParams();

  const { currentData: integrationDetail, isLoading: isRetrievingIntegration } = useGetIntegrationQuery(
    {
      integrationId: integration_id as string,
    },
    {
      skip: !integration_id
    }
  );

  const [initiateConnect] = useInitiateConnectMutation();

  const theme = useTheme();

  const location = useLocation();

  const path = location.pathname;

  const { t } = useTranslate();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    // event.type can be equal to focus with selectionFollowsFocus.
    if (
      event.type !== 'click' ||
      (event.type === 'click' &&
        samePageLinkNavigation(event as React.MouseEvent<HTMLAnchorElement, MouseEvent>))
    ) {
      setValue(newValue);
    }
  };

  useEffect(() => {
    if (path.includes(paths.dashboard.admin.integrations.detail.configuration(integration_id as string))) {
      setValue(0);
    }

    if (path.includes(paths.dashboard.admin.integrations.detail.activity(integration_id as string))) {
      setValue(1);
    }
  }, [path, integration_id]);

  const handleReconnect = async () => {
    if (!integrationDetail) return;

    const connectionUrl = await initiateConnect({
      provider: integrationDetail.provider_type
    }).unwrap();

    if (connectionUrl) {
      // navigate to connection url
      window.location.replace(connectionUrl);
    }
  }

  return (
    <Container
      maxWidth={settings.themeStretch ? false : 'lg'}
    >
      <Stack>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          {
            isRetrievingIntegration ?
              <Box>{t('common.loading')}</Box>
              : (
                <Stack direction="row" justifyContent="start" alignItems="space-between" width="100%">
                  <Stack direction="column" >
                    <Stack direction="row" justifyContent="start" alignItems="center" width="100%" gap={.5}>
                      <Typography variant="h5">{integrationDetail?.provider.name}</Typography>
                      {
                        integrationDetail?.status === IntegrationStatus.REQUIRES_SETUP &&
                        <Iconify icon="ph:warning-duotone" color="orange" />
                      }
                    </Stack>
                    <Typography variant="caption">{integrationDetail?.provider.description}</Typography>
                  </Stack>
                  <Box />
                </Stack>
              )
          }
        </Box>
        <CustomBreadcrumbs
          links={[
            { name: t('common.dashboard'), href: paths.dashboard.root },
            { name: t('integrations.title'), href: paths.dashboard.admin.integrations.root },

          ]}
          sx={{
            pt: 2,
            pb: 2
          }}
        />

        {
          integrationDetail?.status === IntegrationStatus.EXPIRED && (
            <Alert sx={{ px: 4, width: '100%' }} variant='outlined' severity='error' action={
              <Button onClick={handleReconnect} > {t('integrations.reconnect.button')}</Button>
            }>
              <Stack direction="column">
                <Typography variant="h6">{t('integrations.detail.expired.title')}</Typography>
                <Typography variant="caption">{t('integrations.detail.expired.subtitle')}</Typography>
              </Stack>
            </Alert>
          )
        }

        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Integration navigation tabs"
          role="navigation"
        >
          <LinkTab label={t('integrations.detail.tabs.configuration')} href={paths.dashboard.admin.integrations.detail.configuration(integration_id as string)} />
          <LinkTab label={t('integrations.detail.tabs.activity')} href={paths.dashboard.admin.integrations.detail.activity(integration_id as string)} />
        </Tabs>
        <Stack sx={{ marginTop: theme.spacing(4) }}>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </Stack>
      </Stack >
    </Container >
  );
}