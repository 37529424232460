import { useMemo } from 'react';

import Stack from '@mui/system/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Switch, TextFieldProps, FormControlLabel } from '@mui/material';

import RHFSwitch from './rhf-switch';

type Props = TextFieldProps & {
  name: string;
  onLabel: string;
  offLabel: string;
  labelPosition: 'top' | 'bottom' | 'left' | 'right';
  tooltip?: string;
  checked?: boolean;
  disabled?: boolean;
};

export default function RHFVisibilityToggle({
  name,
  onLabel,
  offLabel,
  labelPosition,
  tooltip,
  checked,
  disabled,
  sx,
  ...other
}: Props) {
  const direction = useMemo(() => {
    switch (labelPosition) {
      case 'top':
        return 'column';
      case 'bottom':
        return 'column-reverse';
      case 'left':
        return 'row';
      case 'right':
        return 'row-reverse';
      default:
        return 'column';
    }
  }, [labelPosition]);

  return (
    <Tooltip title={tooltip}>
      <Stack
        direction={direction}
        gap={direction === 'row' || direction === 'row-reverse' ? 1 : 0}
        alignItems="center"
      >
        <Typography fontSize={12} fontWeight="bold">
          {checked ? onLabel : offLabel}
        </Typography>
        {disabled ? (
          <FormControlLabel
            sx={{ ...sx, mr: 0 }}
            label=""
            control={<Switch disabled checked={checked} />}
          />
        ) : (
          <RHFSwitch sx={{ ...sx, mr: 0 }} name={name} />
        )}
      </Stack>
    </Tooltip>
  );
}
