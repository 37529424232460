import { useState } from 'react';
import Markdown from 'react-markdown';

import { Stack } from '@mui/system';
import Button from '@mui/material/Button';
import { DownloadOutlined } from '@mui/icons-material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  Box,
  Dialog,
  Slider,
  IconButton,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@mui/material';

import { useTranslate } from 'src/locales';
import { ConnectionTermsType } from 'src/services/connections/connections.types';

import DocumentViewer from 'src/components/documents/document-viewer';

type Props = {
  open: boolean;
  title: string;
  termsType: ConnectionTermsType;
  onClose: () => void;
  termsData: { type: 'document' | 'usage_term'; public_path?: string; content?: string }[];
};

export default function ViewTermsModal({ title, open, termsType, termsData, onClose }: Props) {
  const { t } = useTranslate();

  const [currentIndex, setCurrentIndex] = useState(0);

  const [pageNumber, setPageNumber] = useState(1);

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setCurrentIndex(newValue as number);
    setPageNumber(1); // Reset to first page when changing documents
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => Math.max(0, prevIndex - 1));
    setPageNumber(1); // Reset to first page when changing documents
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => Math.min(termsData.length - 1, prevIndex + 1));
    setPageNumber(1); // Reset to first page when changing documents
  };

  const renderCurrentTerm = () => {
    const term = termsData[currentIndex];
    if (!term) return null;

    switch (termsType) {
      case ConnectionTermsType.Terms_Of_Engagement:
        return (
          <Box sx={{ mb: 2 }}>
            <Markdown>{term.content || ''}</Markdown>
          </Box>
        );
      case ConnectionTermsType.Template:
      case ConnectionTermsType.Custom:
        return (
          <Box sx={{ m: 0 }}>
            <DocumentViewer fileUrl={term.public_path as string} />
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle sx={{ textTransform: 'capitalize' }}>{title}</DialogTitle>

      <DialogContent>
        <Box sx={{ mt: 2, mb: 4, px: 4 }}>{renderCurrentTerm()}</Box>
        {termsData.length > 1 && (
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <IconButton onClick={handlePrevious} disabled={currentIndex === 0}>
              <ArrowBackIosNewIcon />
            </IconButton>
            <Slider
              value={currentIndex}
              onChange={handleSliderChange}
              step={1}
              marks
              min={0}
              max={termsData.length - 1}
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => `Term ${value + 1}`}
              sx={{ mx: 2, flexGrow: 1 }}
            />
            <IconButton onClick={handleNext} disabled={currentIndex === termsData.length - 1}>
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        <Stack direction="row" spacing={2}>
          {termsData[currentIndex] &&
            [ConnectionTermsType.Custom, ConnectionTermsType.Template].includes(termsType) && (
              <Button
                startIcon={<DownloadOutlined />}
                href={termsData[currentIndex].public_path as string}
                download="Terms of business.pdf"
              >
                {t('documents.terms_modal.download_document')}
              </Button>
            )}

          <Button onClick={onClose} color="primary" variant="contained">
            {t('common.close')}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
