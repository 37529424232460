import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { ShowConnectionModalProps } from './connection-actions.types';

export interface IConnectionState {
  showConnectionModal: boolean;
  connectionRequestId?: string;
  requestNotificationId?: string;
  showCreateConnectionModal: boolean;
}

const initialState: IConnectionState = {
  showConnectionModal: false,
  requestNotificationId: undefined,
  connectionRequestId: undefined,
  showCreateConnectionModal: false,
};

export const connectionsSlice = createSlice({
  name: 'connectionsState',
  initialState,
  reducers: {
    showConnectionActionModal: (state, action: PayloadAction<ShowConnectionModalProps>) => {
      const { notificationId, requestId } = action.payload;
      state.showConnectionModal = true;
      state.connectionRequestId = requestId;
      state.requestNotificationId = notificationId;
    },
    hideConnectionModal: (state) => {
      state.showConnectionModal = false;
      state.connectionRequestId = undefined;
      state.requestNotificationId = undefined;
    },
    toggleCreateConnectionModal: (state) => {
      state.showCreateConnectionModal = !state.showCreateConnectionModal;
    },
  },
});

export const { showConnectionActionModal, hideConnectionModal, toggleCreateConnectionModal } =
  connectionsSlice.actions;
export const connectionsStateReducer = connectionsSlice.reducer;
