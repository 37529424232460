import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import { useMemo, useState, useEffect, useCallback } from 'react';

import { Stack } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Unstable_Grid2';
import { StaticDatePicker } from '@mui/x-date-pickers';
import {
  Box,
  Chip,
  Link,
  Alert,
  Paper,
  Button,
  Dialog,
  Divider,
  TextField,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
  CircularProgress,
} from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import humanizer from 'src/utils/humanizer';
import isValidHttpUrl from 'src/utils/isValidUrl';
import { fTime, fDateTime } from 'src/utils/format-time';

import { useTranslate } from 'src/locales';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { hideInterviewActionModal } from 'src/store/slices/interviews/interviewSlice';
import {
  InterviewStatus,
  InterviewReviewAction,
  AvailabilityTimeslotResponse,
} from 'src/services/interviews/interview.types';
import {
  useGetInterviewByIdQuery,
  useReviewInterviewMutation,
  useConfirmInterviewMutation,
  useRequestRescheduleMutation,
} from 'src/services/interviews/interviews.service';

import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';

import { TenantType } from 'src/types/enums';

type Props = {
  open: boolean;
};

export default function ActionInterviewModal({ open }: Props) {
  const dispatch = useAppDispatch();

  const { t } = useTranslate();

  const tenant = useOrgTenant();

  const showConfirm = useBoolean();

  const showRequestReschedule = useBoolean();

  const showConfirmInterview = useBoolean();

  const { enqueueSnackbar } = useSnackbar();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const applicationId = useAppSelector((state) => state.applications.selectedApplicationId);

  const interviewId = useAppSelector((state) => state.interviews.selectedInterviewId);

  const [reviewNotes, setReviewNotes] = useState<string>('');

  const [selectedTimeSlot, setSelectedTimeSlot] = useState<AvailabilityTimeslotResponse | null>(
    null
  );

  const { currentData: interview, isLoading: isLoadingInterview } = useGetInterviewByIdQuery(
    { interviewId: interviewId as string },
    { skip: !interviewId }
  );

  const [reviewInterview, { isLoading: isReviewing }] = useReviewInterviewMutation();

  const [requestReschedule, { isLoading: isRequestingReschedule }] = useRequestRescheduleMutation();

  const [confirmInterview, { isLoading: isConfirming }] = useConfirmInterviewMutation();

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const interviewStatus = useMemo(() => interview?.status, [interview]);

  const shouldDisableActions = useMemo(() => {
    if (
      !interviewStatus ||
      [InterviewStatus.Scheduled, InterviewStatus.Cancelled].includes(interviewStatus)
    )
      return true;

    if (tenant === TenantType.Recruiter) {
      return [InterviewStatus.ChangesRequested, InterviewStatus.Sent].includes(interviewStatus);
    }

    if (tenant === TenantType.Candidate) {
      return interviewStatus !== InterviewStatus.Sent;
    }

    if (tenant === TenantType.Client) {
      return interviewStatus === InterviewStatus.InReview;
    }

    return false;
  }, [interviewStatus, tenant]);

  const currentDayTimeslots = useMemo(() => {
    if (!interview?.available_slots) return [];

    return interview.available_slots
      ?.filter((timeslot: AvailabilityTimeslotResponse) =>
        moment.tz(timeslot.block_start_at, moment.tz.guess()).isSame(selectedDate, 'day')
      )
      .sort((a, b) => moment(a.block_start_at).diff(moment(b.block_start_at)));
  }, [interview?.available_slots, selectedDate]);

  useEffect(() => {
    if (isLoadingInterview || !interview) return;

    if (interview.scheduled_at) {
      setSelectedDate(new Date(interview.scheduled_at));

      return;
    }

    setSelectedDate(new Date(interview.available_slots[0].block_start_at));
  }, [interview, isLoadingInterview]);

  const onClose = useCallback(() => {
    dispatch(hideInterviewActionModal());
  }, [dispatch]);

  const onApprove = useCallback(async () => {
    setErrorMessage(null);
    try {
      await reviewInterview({
        interviewId: interviewId as string,
        data: {
          action: InterviewReviewAction.Approve,
        },
      }).unwrap();

      enqueueSnackbar(t('applications.action_interview_modal.api.approve.success'), {
        variant: 'success',
      });

      onClose();
    } catch (e) {
      console.log(e);

      setErrorMessage(t('applications.action_interview_modal.api.approve.default_error'));

      enqueueSnackbar(t('applications.action_interview_modal.api.approve.default_error'), {
        variant: 'success',
      });
    }
  }, [enqueueSnackbar, interviewId, onClose, reviewInterview, t]);

  const shouldDisableDate = (date: Date) => {
    if (!interview?.available_slots) return false;

    return !interview.available_slots.some(
      (timeslot: AvailabilityTimeslotResponse) =>
        new Date(timeslot.block_start_at).toDateString() === date.toDateString()
    );
  };

  const onRequestChanges = () => {
    showConfirm.onFalse();

    setErrorMessage(null);

    try {
      reviewInterview({
        interviewId: interviewId as string,
        data: {
          action: InterviewReviewAction.RequestChanges,
          notes: reviewNotes,
        },
      }).unwrap();

      enqueueSnackbar(t('applications.action_interview_modal.api.request_changes.success'), {
        variant: 'success',
      });

      onClose();
    } catch (e) {
      console.log(e);

      setErrorMessage(t('applications.action_interview_modal.api.request_changes.default_error'));

      enqueueSnackbar(t('applications.action_interview_modal.api.request_changes.default_error'), {
        variant: 'error',
      });
    }
  };

  const onRequestReschedule = () => {
    showRequestReschedule.onFalse();

    setErrorMessage(null);

    try {
      requestReschedule({
        interviewId: interviewId as string,
        data: {
          notes: reviewNotes,
        },
      }).unwrap();

      enqueueSnackbar(t('applications.action_interview_modal.api.request_reschedule.success'), {
        variant: 'success',
      });

      onClose();
    } catch (e) {
      console.log(e);

      setErrorMessage(
        t('applications.action_interview_modal.api.request_reschedule.default_error')
      );

      enqueueSnackbar(
        t('applications.action_interview_modal.api.request_reschedule.default_error'),
        {
          variant: 'error',
        }
      );
    }
  };

  const onConfirmInterview = () => {
    setErrorMessage(null);
    try {
      confirmInterview({
        interviewId: interviewId as string,
        data: {
          scheduled_slot: {
            start_at: selectedTimeSlot?.block_start_at as string,
            end_at: selectedTimeSlot?.block_end_at as string,
          },
        },
      }).unwrap();

      enqueueSnackbar(t('applications.action_interview_modal.api.confirm.success'), {
        variant: 'success',
      });

      onClose();
    } catch (e) {
      console.log(e);

      setErrorMessage(t('applications.action_interview_modal.api.confirm.default_error'));

      enqueueSnackbar(t('applications.action_interview_modal.api.confirm.default_error'), {
        variant: 'error',
      });
    }
  };

  const getLinkText = () => {
    if (interview?.link) {
      if (isValidHttpUrl(interview.link, false)) {
        return (
          <Button
            LinkComponent={Link}
            href={interview.link}
            target="_blank"
            variant="text"
            color="primary"
            endIcon={<Iconify icon="solar:link-round-angle-bold-duotone" width={16} />}
          >
            {t('common.open')}
          </Button>
        );
      }

      return interview?.link;
    }

    if (interview?.location) return interview.location;

    return '-';
  };

  // eslint-disable-next-line arrow-body-style
  const renderOptions = () => {
    // comment
    return (
      <Grid container spacing={2} overflow="hidden">
        {interview?.status !== InterviewStatus.Scheduled && (
          <Grid xs={12} sm={5}>
            <Stack direction="column" spacing={2}>
              {/* Calender */}
              <StaticDatePicker
                sx={{ marginLeft: 0 }}
                value={selectedDate}
                onChange={(value) => setSelectedDate(value)}
                slotProps={{
                  actionBar: { actions: [] },
                }}
                shouldDisableDate={shouldDisableDate}
              />
            </Stack>
          </Grid>
        )}

        <Grid xs={12} sm={interview?.status !== InterviewStatus.Scheduled ? 7 : 12}>
          {/* Time */}
          <Stack component={Paper} spacing={2}>
            {interview?.status && ![InterviewStatus.Scheduled].includes(interview.status) && (
              <Box>
                <Typography variant="h6">
                  {t('applications.action_interview_modal.selection.select_time')}
                </Typography>
                <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.grey[600] }}>
                  {t(
                    `applications.action_interview_modal.selection.select_time_subtitle.${tenant}`
                  )}
                </Typography>
              </Box>
            )}

            {!selectedTimeSlot &&
            !interview?.scheduled_at &&
            interview?.status !== InterviewStatus.Scheduled ? (
              <Stack
                spacing={2}
                sx={{
                  overflowY: 'auto',
                  minHeight: 350,
                  maxHeight: '350px',
                  borderRadius: 1,
                  padding: 2,
                }}
                direction="column"
                display="flex"
              >
                {currentDayTimeslots.map(
                  (timeslot: AvailabilityTimeslotResponse, index: number) => (
                    <Button
                      size="small"
                      key={`timeslot-${index}`}
                      variant={!Boolean(index % 2) ? 'contained' : 'outlined'}
                      color="primary"
                      fullWidth={false}
                      disabled={
                        shouldDisableActions ||
                        moment.tz(timeslot.block_start_at, moment.tz.guess()).isBefore(moment())
                      }
                      onClick={() => setSelectedTimeSlot(timeslot)}
                    >
                      {fTime(timeslot.block_start_at)}
                    </Button>
                  )
                )}
              </Stack>
            ) : (
              <Box>
                {interview?.status !== InterviewStatus.Scheduled && !interview?.scheduled_at && (
                  <Button
                    startIcon={<Iconify icon="eva:arrow-ios-back-fill" width={16} />}
                    variant="text"
                    onClick={() => setSelectedTimeSlot(null)}
                  >
                    {t('common.back')}
                  </Button>
                )}
                <Typography variant="h6">
                  {t('applications.action_interview_modal.selection.title')}
                </Typography>

                <Divider />
                <Grid container spacing={1} justifyContent="space-between" paddingTop={2}>
                  <Grid xs={12} sm={6}>
                    <Typography variant="body1" fontWeight="bold">
                      {t('common.title')}
                    </Typography>
                    <Typography variant="body2">{interview?.title ?? '-'}</Typography>
                  </Grid>
                  <Grid xs={12} sm={6}>
                    <Typography variant="body1" fontWeight="bold">
                      {t(
                        `applications.detail.candidate_profile.upcoming_interviews.${
                          interview?.location ? 'location' : 'link'
                        }`
                      )}
                    </Typography>
                    <Typography variant="body2">{getLinkText()}</Typography>
                  </Grid>
                  <Grid xs={12} sm={6}>
                    <Typography variant="body1" fontWeight="bold">
                      {t('applications.detail.candidate_profile.upcoming_interviews.duration')}
                    </Typography>
                    <Typography variant="body2">
                      {interview?.duration_minutes
                        ? humanizer(interview.duration_minutes * 60 * 1000)
                        : '-'}
                    </Typography>
                  </Grid>
                  <Grid xs={12} sm={6}>
                    <Typography variant="body1" fontWeight="bold">
                      {t(
                        `applications.detail.candidate_profile.upcoming_interviews.${
                          selectedTimeSlot ? 'selected_timeslot' : 'scheduled_at'
                        }`
                      )}
                    </Typography>
                    <Typography variant="body2">
                      {interview?.scheduled_at
                        ? fDateTime(interview.scheduled_at)
                        : fDateTime(selectedTimeSlot?.block_start_at)}
                    </Typography>
                  </Grid>
                  <Grid xs={12}>
                    <Typography variant="body1" fontWeight="bold">
                      {t('applications.detail.candidate_profile.upcoming_interviews.participants')}
                    </Typography>
                    <Stack direction="row" flexWrap="wrap" spacing={1} paddingTop={1}>
                      {interview?.participants.map((participant) => (
                        <Chip
                          variant="outlined"
                          key={participant.id}
                          label={`${participant.first_name} ${participant.last_name}`}
                        />
                      ))}
                    </Stack>
                  </Grid>
                  <Grid xs={12} paddingTop={2}>
                    <LoadingButton
                      disabled={
                        !selectedTimeSlot ||
                        moment.tz().isBefore(moment.tz(selectedTimeSlot.block_start_at)) ||
                        shouldDisableActions
                      }
                      variant="contained"
                      loading={isConfirming}
                      onClick={showConfirmInterview.onTrue}
                      color="success"
                      fullWidth
                    >
                      {t('applications.action_interview_modal.selection.schedule_interview')}
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Stack>
        </Grid>
      </Grid>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth={interview?.status !== InterviewStatus.Scheduled ? 'md' : 'sm'}
    >
      <DialogTitle sx={{ pb: 1 }}>{t(`applications.action_interview_modal.title`)}</DialogTitle>

      <DialogContent sx={{ overflowY: 'hidden' }}>
        {errorMessage && (
          <Alert variant="outlined" severity="error" sx={{ mb: 3 }}>
            {errorMessage}
          </Alert>
        )}
        {isLoadingInterview ? (
          <Stack
            width="100%"
            minHeight={400}
            minWidth={400}
            justifyContent="center"
            alignItems="center"
            paddingTop={4}
          >
            <CircularProgress />
          </Stack>
        ) : (
          <Box>{renderOptions()}</Box>
        )}

        {interview?.status === InterviewStatus.InReview && tenant === TenantType.Recruiter && (
          <Stack paddingTop={2} spacing={2}>
            <Button variant="contained" onClick={onApprove} disabled={shouldDisableActions}>
              {t('common.approve')}
            </Button>
            <Button variant="outlined" onClick={showConfirm.onTrue} disabled={shouldDisableActions}>
              {t('applications.action_interview_modal.approval.request_changes')}
            </Button>
          </Stack>
        )}

        {tenant !== TenantType.Client && (
          <Stack paddingTop={2} spacing={2}>
            {tenant === TenantType.Candidate && (
              <Button
                variant="outlined"
                onClick={showRequestReschedule.onTrue}
                disabled={shouldDisableActions}
              >
                {t('applications.action_interview_modal.request_reschedule.request_reschedule')}
              </Button>
            )}
          </Stack>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{t('common.close')}</Button>
      </DialogActions>

      <ConfirmDialog
        open={showConfirm.value}
        onClose={showConfirm.onFalse}
        title={t('applications.action_interview_modal.approval.request_changes')}
        content={
          <Stack spacing={2}>
            <Alert severity="info">{t('applications.action_interview_modal.approval.alert')}</Alert>
            <TextField
              label={t('common.comment')}
              fullWidth
              multiline
              rows={2}
              value={reviewNotes}
              onChange={(event) => setReviewNotes(event.target.value)}
            />
          </Stack>
        }
        action={
          <Button disabled={!reviewNotes} onClick={onRequestChanges}>
            {t('common.confirm')}
          </Button>
        }
      />

      <ConfirmDialog
        open={showRequestReschedule.value}
        onClose={showRequestReschedule.onFalse}
        title={t('applications.action_interview_modal.request_reschedule.request_reschedule')}
        content={
          <Stack spacing={2}>
            <Alert severity="info" sx={{ pb: 2 }}>
              {t('applications.action_interview_modal.request_reschedule.alert')}
            </Alert>
            <TextField
              label={t('common.comment')}
              fullWidth
              multiline
              rows={2}
              value={reviewNotes}
              onChange={(event) => setReviewNotes(event.target.value)}
            />
          </Stack>
        }
        action={
          <Button disabled={!reviewNotes} onClick={onRequestReschedule}>
            {t('common.confirm')}
          </Button>
        }
      />

      <ConfirmDialog
        open={showConfirmInterview.value}
        onClose={showConfirmInterview.onFalse}
        title={t('applications.action_interview_modal.confirm.title')}
        content={t('applications.action_interview_modal.confirm.alert')}
        action={<Button onClick={onConfirmInterview}>{t('common.confirm')}</Button>}
      />
    </Dialog>
  );
}
