import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { useTheme } from '@mui/material/styles';
import { Stack, useMediaQuery } from '@mui/system';
import { Card, Button, Tooltip, Typography, CardContent } from '@mui/material';

import { useRouter } from 'src/routes/hooks';

import { useBoolean } from 'src/hooks/use-boolean';

import { useTranslate } from 'src/locales';
import { IIntegrationLog, IntegrationActivityStatus } from 'src/services/integration/integrations.types';

import Iconify from 'src/components/iconify';
import Label, { LabelProps } from 'src/components/label';
import { usePopover } from 'src/components/custom-popover';
import DateDisplay from 'src/components/date/date-display';

// ----------------------------------------------------------------------

type Props = {
  row: IIntegrationLog;
  onViewEntity: (row: IIntegrationLog) => void;
};

export default function ActivityTableRow({
  row,
  onViewEntity
}: Props) {
  const confirm = useBoolean();

  const quickEdit = useBoolean();

  const popover = usePopover();

  const { t } = useTranslate();

  const router = useRouter();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) {
    return (
      <Card sx={{ mx: 2, mb: 1 }}>
        <CardContent>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h6" noWrap>
              {row.id}
            </Typography>

          </Stack>
        </CardContent>
      </Card>
    )
  }

  return (
    <TableRow hover>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        {t(`enums.integration_activity_type.${row.activity_type}`)}
      </TableCell>
      <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
        <Stack direction="row" justifyContent="start" alignItems="center" gap={.5}>
          <ActivityStatusLabel status={row.status} />

          {
            row.message && (
              <Tooltip title={row.message}>
                <Iconify icon="akar-icons:info" color="text.secondary" width={18} />
              </Tooltip>
            )
          }

        </Stack>
      </TableCell>
      <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
        <DateDisplay date={row.created_at} variant='caption' />
      </TableCell>
      <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center">
          <Button onClick={() => onViewEntity(row)}>{t('common.view')}</Button>
        </Stack>
      </TableCell>
    </TableRow>
  );
}

export function ActivityStatusLabel(props: LabelProps & { status: IntegrationActivityStatus }) {
  const { status } = props;
  const activeStatus = [IntegrationActivityStatus.SUCCESS];
  const errorStatus = [IntegrationActivityStatus.ERROR];

  const { t } = useTranslate();

  return (
    <Label
      variant="soft"
      color={
        (errorStatus.includes(status) && 'error') ||
        'success'
      }
    >
      <Typography
        variant="subtitle2"
        fontSize={11}
        fontWeight="700"
        sx={{ textTransform: 'uppercase' }}
      >
        {t(`enums.integration_activity_status.${status}`)}
      </Typography>
    </Label>
  );
}
