import { useState } from 'react';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { Page, pdfjs, Document } from 'react-pdf';

import Box from '@mui/material/Box';
import { Stack } from '@mui/system';
import Typography from '@mui/material/Typography';
import { Button, CircularProgress } from '@mui/material';

import { useTranslate } from 'src/locales';


// Set the worker for react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs` as string;

// ----------------------------------------------------------------------

interface Props {
  fileUrl: string;
}

export default function DocumentViewer({ fileUrl }: Props) {

  const { t } = useTranslate();

  const [pageCount, setPageCount] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setPageCount(numPages);
  };

  const onDocumentLoadError = () => {
    setPageCount(null);
  }

  return (
    <Stack direction="column" position="relative" >
      <Box sx={{ m: 0, overflowY: 'scroll' }}>
        <Document
          className="document-viewer-document"
          loading={
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
              <CircularProgress />
            </Box>
          }
          error={
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
              {t('documents.document_viewer.load_error')}
            </Box>
          }
          file={{ url: fileUrl }}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={onDocumentLoadError}
        >
          <Page pageNumber={pageNumber} scale={0.8} />
        </Document>
      </Box>
      {
        pageCount && (
          <Box sx={{ m: 0, position: 'absolute', zIndex: 100, bottom: 0, background: 'white', width: '100%' }}>
            <Typography variant="body2" align="center" sx={{ mt: 2 }}>
              {t('common.page')} {pageNumber} of {pageCount}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                onClick={() => setPageNumber(page => Math.max(1, page - 1))}
                disabled={pageNumber <= 1}
              >
                {t('documents.document_viewer.previous_page')}
              </Button>
              <Button
                onClick={() => setPageNumber(page => Math.min(pageCount, page + 1))}
                disabled={pageNumber >= pageCount}
              >
                {t('documents.document_viewer.next_page')}
              </Button>
            </Box>
          </Box>
        )
      }
    </Stack>
  );
}
