import type { UpdateSetting } from 'src/services/settings/settings.types';

import type { Category } from '../utils/utils.types';
import type { Language } from '../system/system.types';
import { UserRole } from '../organisation/organisation.enum';
import type { PreferredLocation } from '../candidates/candidates.types';
import type { Organization, ProfileOrganization } from '../auth/auth.types';

export enum AccountStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  LOCKED = 'locked',
}

export type AccountProfile = {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  last_login_at?: Date;
  email_verified: boolean;
  phone: string;
  phone_verified: boolean;
  profile_image: {
    id: string;
    public_path: string;
  } | null;
  locations: PreferredLocation[];
  specializations: Category[];
  organization: Pick<Organization, 'id' | 'status'>;
  timezone: string;
  language: Language;
  status: AccountStatus;
  org_role: UserRole;
};

export type RestrictedAccountProfile = {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  last_login_at?: Date;
  email_verified: boolean;
  phone: string;
  phone_verified: boolean;
  profile_image: {
    id: string;
    public_path: string;
  } | null;
  locations: PreferredLocation[];
  specializations: Category[];
  organization: ProfileOrganization;
  timezone: string;
  language: Language;
  status: AccountStatus;
  org_role: UserRole;
};

export type MinimalAccount = {
  id: string;
  first_name: string;
  last_name: string;
  organization: ProfileOrganization;
};

export type CreateAccountProfile = {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  profile_image: string;
  locations: PreferredLocation[];
  specializations: Category[];
  organization: Pick<Organization, 'id' | 'status'>;
  timezone?: string;
  language_code?: string;
};

export type UpdateAccountPasswordProps = {
  current_password: string;
  new_password: string;
};

export type UpdateSettingProps = {
  settings: UpdateSetting[];
  accountId: string;
};