import React, { useCallback } from 'react';

import { Stack, useTheme, TextField, useMediaQuery, InputAdornment } from '@mui/material';

import { useTranslate } from 'src/locales';

import Iconify from 'src/components/iconify';
import { usePopover } from 'src/components/custom-popover';

type Props = {
  onFilters: (value: string) => void;
};

export default function ConnectionsTableToolbar({
  onFilters,
}: Props) {

  const popover = usePopover();
  const { t } = useTranslate();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleFilterName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onFilters(event.target.value);
    },
    [onFilters]
  );

  return (
    <Stack
      spacing={2}
      direction={{ xs: 'column', sm: 'row' }}
      sx={{
        p: 2.5,
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <TextField
        fullWidth
        onChange={handleFilterName}
        placeholder="Search..."
        name="description"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          ),
        }}
        sx={{
          maxWidth: { sm: '360px' },
        }}
      />

    </Stack>
  );
}