import { useState, ChangeEvent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { useTheme, FormGroup } from '@mui/material';
import TextField, { TextFieldProps } from '@mui/material/TextField';

// ----------------------------------------------------------------------

type Props = TextFieldProps & {
  name: string;
  options: { value: any; label: string }[];
  unit?: any;
  onChangeUnit: (value: any) => void;
};

export default function RHFUnitInput({
  name,
  options,
  helperText,
  type,
  unit,
  onChangeUnit,
  disabled,
  ...other
}: Props) {
  const { control } = useFormContext();

  const theme = useTheme();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_selectValue, setSelectValue] = useState<any>(options[0]);

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectValue(event.target.value);
    onChangeUnit(event.target.value);
  };

  const selectControl = (
    <select
      style={{
        height: '100%',
        color: theme.palette.text.primary,
        backgroundColor: 'transparent',
        border: 'none',
        marginRight: '8px',
      }}
      name="unit"
      id="unit"
      value={unit || undefined}
      onChange={handleChange}
      disabled={disabled}
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormGroup
          sx={{
            width: '100%',
          }}
        >
          <TextField
            {...field}
            fullWidth
            type={type}
            value={type === 'number' && field.value === 0 ? '' : field.value}
            onChange={(event) => {
              if (type === 'number') {
                field.onChange(Number(event.target.value));
              } else {
                field.onChange(event.target.value);
              }
            }}
            error={!!error}
            helperText={error ? error?.message : helperText}
            disabled={disabled}
            InputProps={{
              startAdornment: selectControl,
            }}
            {...other}
          />
        </FormGroup>
      )}
    />
  );
}
