import { useCallback } from 'react';

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import { useTranslate } from 'src/locales';

import Iconify from 'src/components/iconify';
import { usePopover } from 'src/components/custom-popover';

// ----------------------------------------------------------------------

type Props = {
  filterQuery: string;
  onFilters: (value: string) => void;
  onOpenCreateForm: VoidFunction;
};

export default function DomainsTableToolbar({
  filterQuery,
  onFilters,
  onOpenCreateForm,
}: Props) {
  const popover = usePopover();

  const { t } = useTranslate();

  const handleFilterName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onFilters(event.target.value);
    },
    [onFilters]
  );

  return (
    <Stack
        spacing={2}
        alignItems={{ xs: 'flex-end', md: 'center' }}
        direction={{
          xs: 'column',
          md: 'row',
        }}
        sx={{
          p: 2.5,
          pr: { xs: 2.5, md: 1 },
          width: '100%'
        }}
      >
        <Stack
          sx={{ width: '100%' }}
          direction="row"
          justifyContent="space-between"
          spacing={2}
          flexGrow={1}
        >
          <TextField
            onChange={handleFilterName}
            placeholder="Search..."
            name="description"
            fullWidth
            sx={{
              minWidth: '360px',
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              ),
            }}
          />

          <Stack display="flex" flexDirection="row"  sx={{ paddingRight: 2 }}>
            {/* <Button
              size="medium"
              endIcon={<Iconify icon="material-symbols:add" />}
              variant="contained"
              onClick={onOpenCreateForm}
              sx={{ maxHeight: 36 }}
            >
              {t('admin-organization.domains.add_domain')}
            </Button> */}
          </Stack>
        </Stack>
      </Stack>
  );
}
