import '@mdxeditor/editor/style.css'
import { useRef, useEffect } from 'react'
import { UndoRedo, MDXEditor, CreateLink, listsPlugin, ListsToggle, quotePlugin, toolbarPlugin, headingsPlugin, BlockTypeSelect, codeBlockPlugin, codeMirrorPlugin, BoldItalicUnderlineToggles } from '@mdxeditor/editor'


type Props = {
  content: string;
  onChange: (markdown: string) => void
}

export function MarkdownEditor({
  content,
  onChange
}: Props) {

  const editorRef = useRef<any>(null);

  useEffect(() => {
    if (editorRef.current && content) {
      editorRef.current?.setMarkdown(content.trim())
    }

  }, [content]);

  return (

    <MDXEditor
      ref={editorRef}
      markdown=''
      onChange={(markdown) => onChange(markdown)}
      toMarkdownOptions={{}}
      contentEditableClassName='markdown-editor'
      plugins={[
        codeBlockPlugin({ defaultCodeBlockLanguage: 'js' }),
        codeMirrorPlugin({ codeBlockLanguages: { js: 'JavaScript', css: 'CSS' } }),
        headingsPlugin(),
        quotePlugin(),
        listsPlugin(),
        toolbarPlugin({
          toolbarContents: () => (
            <>
              <BlockTypeSelect />
              <CreateLink />
              <BoldItalicUnderlineToggles />
              <ListsToggle />
              {' '}
              <UndoRedo />
            </>
          )
        })
      ]} />
  )
} 