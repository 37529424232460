import { useState } from 'react';
import { isString } from 'lodash';
import { useSnackbar } from 'notistack';

import { LoadingButton } from '@mui/lab';
import Grid from '@mui/system/Unstable_Grid/Grid';
import {
  Box,
  List,
  Alert,
  Button,
  Dialog,
  Select,
  ListItem,
  MenuItem,
  useTheme,
  InputLabel,
  Typography,
  DialogTitle,
  FormControl,
  ListItemText,
  DialogActions,
  DialogContent
} from '@mui/material';

import i18n from 'src/locales/i18n';
import { useTranslate } from 'src/locales';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { DocumentType } from 'src/services/documents/documents.types';
import {
  useGetMyProfileQuery
} from 'src/services/candidates/candidates.service';
import { useActionResourceRequestMutation } from 'src/services/applications/applications.service';
import {
  ResourceType,
  ResourceRequestAction,
  ApplicationResourceRequest,
} from 'src/services/applications/applications.types';

import { ProfileDocumentSelector } from 'src/sections/profile/documents/profile-document-selector';

import { TenantType } from 'src/types/enums';
import { BusinessErrorCodes } from 'src/types/business-errors';

type Props = {
  open: boolean;
  onClose: VoidFunction;
  request: ApplicationResourceRequest;
  profileId: string;
};

const ProfileSections = [
  'executive_summary',
  'notice_period',
  'education_level',
  'job_types',
  'salary_expectations',
  'working_styles',
  'work_rights',
  'work_experiences',
  'work_locations',
  'skills',
];

const getAcceptProfileErrorMessage = (e: any, resource: ResourceType) => {
  if (isString(e?.data?.error_code)) {
    if (e?.data.error_code === BusinessErrorCodes.MissingRequestedItems) {
      const sections = e?.data?.context?.item_types
        .map((item: string) =>
          resource === ResourceType.ProfileInformation
            ? i18n.t(`enums.candidate_profile_section.${item}`)
            : i18n.t(`enums.document_type.${item}`)
        )
        .join(', ');
      return i18n.t('applications.api.action_resource_request.accept_error_missing', { sections });
    }
  }

  return i18n.t('applications.api.action_resource_request.accept_error');
};

export default function ResourceRequestActionModal({ open, onClose, request, profileId }: Props) {
  const { t } = useTranslate();

  const theme = useTheme();

  const { enqueueSnackbar } = useSnackbar();

  const tenantType = useOrgTenant();

  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const { currentData: myProfile } = useGetMyProfileQuery(undefined, {
    skip: tenantType !== TenantType.Candidate
  });

  const [selectedProfileSections, setSelectedProfileSections] = useState<string[]>([]);

  const [actionRequest, { isLoading }] = useActionResourceRequestMutation();

  const [selectedDocuments, setSelectedDocuments] = useState<
    { profile_document_id: string; type: DocumentType }[]
  >([]);

  const onSelectDocument = (type: DocumentType, documentId: string) => {
    setSelectedDocuments((prev) => {
      const existingIndex = prev.findIndex((doc) => doc.type === type);
      if (existingIndex !== -1) {
        return prev.map((doc) => {
          if (doc.type === type) {
            return { profile_document_id: documentId, type };
          }
          return doc;
        });
      }

      return [...prev, { profile_document_id: documentId, type }];
    });
  };

  const onApproveRequest = async () => {
    try {
      await actionRequest({
        requestId: request.id,
        data: {
          action: ResourceRequestAction.ACCEPT,
          documents: request.type === ResourceType.Document ? selectedDocuments : undefined,
          ...(request.type === ResourceType.ProfileInformation
            ? {
              summary: selectedProfileSections.includes('executive_summary')
                ? myProfile?.summary
                : undefined,
              job_types: selectedProfileSections.includes('job_types')
                ? myProfile?.job_types
                : undefined,
              salary_expectations: selectedProfileSections.includes('salary_expectations')
                ? myProfile?.salary_expectations
                : undefined,
              preferred_locations: selectedProfileSections.includes('work_locations')
                ? myProfile?.preferred_locations
                : undefined,
              work_rights: selectedProfileSections.includes('work_rights')
                ? myProfile?.work_rights
                : undefined,
              work_styles: selectedProfileSections.includes('working_styles')
                ? myProfile?.work_styles
                : undefined,
              education_level: selectedProfileSections.includes('education_level')
                ? myProfile?.education_level
                : undefined,
              work_experiences: selectedProfileSections.includes('work_experiences')
                ? myProfile?.work_experiences
                : undefined,
              notice_period: selectedProfileSections.includes('notice_period')
                ? myProfile?.notice_period
                : undefined,
              skills: selectedProfileSections.includes('skills') ? myProfile?.skills : undefined,
            }
            : {}),
        },
      }).unwrap();

      onClose();
      enqueueSnackbar(t('applications.api.action_resource_request.accept_success'), {
        variant: 'success',
      });
    } catch (e) {
      setErrorMsg(getAcceptProfileErrorMessage(e, request.type));
      enqueueSnackbar(t('applications.api.action_resource_request.accept_error'), {
        variant: 'error',
      });
      console.error(e);
    }
  };

  const onDenyRequest = async () => {
    try {
      await actionRequest({
        requestId: request.id,
        data: { action: ResourceRequestAction.REJECT },
      }).unwrap();
      enqueueSnackbar(t('applications.api.action_resource_request.reject_success'), {
        variant: 'success',
      });

      onClose();
    } catch (error) {
      enqueueSnackbar(t('applications.api.action_resource_request.reject_error'), {
        variant: 'error',
      });
    }
  };

  const renderProfileRequests = (
    <ul style={{ marginTop: 5 }}>
      {request.items.map((item) => (
        <li key={item.id}>
          <Typography variant="body1">
            {request.type === ResourceType.Document
              ? t(`enums.document_type.${item.item_type}`)
              : t(`enums.candidate_profile_section.${item.item_type}`)}
          </Typography>
        </li>
      ))}
    </ul>
  );

  const renderDocumentRequests = (
    <List>
      {request.items.map((item) => (
        <ListItem
          key={item.id}
          secondaryAction={
            <ProfileDocumentSelector
              type={item.item_type as DocumentType}
              onSelect={onSelectDocument}
              profileId={profileId}
            />
          }
        >
          <ListItemText
            primary={t(`enums.document_type.${item.item_type}`)}
            secondary={item.item_type}
          />
        </ListItem>
      ))}
    </List>
  );

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{t('applications.resource_requests.action.title')}</DialogTitle>
      <DialogContent>
        <Grid container spacing={0}>
          <Grid xs={12} paddingBottom={2}>
            <Alert severity="info">{t('applications.resource_requests.action.alert')}</Alert>
          </Grid>
          <Grid xs={12}>
            <Typography variant="h6">
              {t(
                `applications.resource_requests.action.${request.type === ResourceType.Document
                  ? 'requested_documents'
                  : 'requested_profile'
                }`
              )}
              :
            </Typography>

            {request.type === ResourceType.Document
              ? renderDocumentRequests
              : renderProfileRequests}
          </Grid>
          <Grid xs={12}>
            <Box
              sx={{
                my: 1,
                padding: 2,
                borderRadius: 1,
                backgroundColor: theme.palette.grey[200],
              }}
            >
              <Typography variant="h6">
                {t('applications.resource_requests.action.request_notes')}:
              </Typography>
              <Typography variant="body1">{request.notes}</Typography>
            </Box>
          </Grid>

          {errorMsg && (
            <Grid xs={12} sx={{ py: 2 }}>
              <Alert severity="error">{errorMsg}</Alert>
            </Grid>
          )}

          {request.type === ResourceType.ProfileInformation && (
            <Grid xs={12} sx={{ py: 2 }}>
              <FormControl fullWidth sx={{}}>
                <InputLabel id="document-type-select-label">
                  {t('applications.resource_requests.action.select_profile_sections')}
                </InputLabel>
                <Select
                  label={t('applications.resource_requests.action.select_profile_sections')}
                  fullWidth
                  value={selectedProfileSections}
                  onChange={(e) => setSelectedProfileSections(e.target.value as string[])}
                  multiple
                >
                  {ProfileSections.map((section) => (
                    <MenuItem key={section} value={section}>
                      {t(`enums.candidate_profile_section.${section}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}

          <Grid xs={12}>
            <LoadingButton
              fullWidth
              variant="contained"
              onClick={onApproveRequest}
              loading={isLoading}
              disabled={
                (request.type === ResourceType.Document && !selectedDocuments.length) ||
                (request.type === ResourceType.ProfileInformation &&
                  !selectedProfileSections.length) ||
                isLoading
              }
            >
              {t('common.submit')}
            </LoadingButton>
            <LoadingButton
              fullWidth
              variant="outlined"
              onClick={onDenyRequest}
              loading={isLoading}
              sx={{ marginTop: 1 }}
            >
              {t('common.deny')}
            </LoadingButton>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('common.cancel')}</Button>
      </DialogActions>
    </Dialog>
  );
}
