import * as Yup from 'yup';
import { isString } from 'lodash';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Grid, Stack, Alert, Button, Dialog, DialogTitle, DialogContent } from '@mui/material';

import { EmailRegex } from 'src/utils/data/regex';

import i18n from 'src/locales/i18n';
import { useTranslate } from 'src/locales';
import { useRequestExperienceVerificationMutation } from 'src/services/candidates/candidates.service';

import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';

import { BusinessErrorCodes } from 'src/types/business-errors';

type Props = {
  open: boolean;
  onClose: VoidFunction;
  experienceId: string;
};

const generateErrorMessage = (e: any) => {
  if (isString(e?.data?.error_code)) {
    if (e?.data.error_code === BusinessErrorCodes.InvalidVerificationEmail) {
      return i18n.t('profile.api.verify_experience.email');
    }
    if (e?.data.error_code === BusinessErrorCodes.ExperienceVerificationPending) {
      return i18n.t('profile.api.verify_experience.pending');
    }
  }
  return i18n.t('profile.api.verify_experience.error');
};

export default function ExperienceVerificationModal({ experienceId, open, onClose }: Props) {
  const { t } = useTranslate();

  const { enqueueSnackbar } = useSnackbar();

  const [sendRequest] = useRequestExperienceVerificationMutation();

  const ConnectionSearchSchema = Yup.object().shape({
    email: Yup.string()
      .matches(EmailRegex, t('validation.email_format'))
      .required(t('validation.required')),

    first_name: Yup.string()
      .required(t('validation.required'))
      .max(25, t('validation.max_length', { length: 25 })),

    last_name: Yup.string()
      .required(t('validation.required'))
      .max(25, t('validation.max_length', { length: 25 })),
  });

  const defaultValues = {
    email: '',

    first_name: '',

    last_name: '',
  };

  const searchMethods = useForm({
    resolver: yupResolver(ConnectionSearchSchema),
    defaultValues,
    mode: 'all',
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid },
  } = searchMethods;

  const onCloseModal = () => {
    reset();
    onClose();
  };

  const submitForm = handleSubmit(async (data) => {
    try {
      await sendRequest({
        work_experience_id: experienceId,
        data,
      }).unwrap();

      enqueueSnackbar(t('profile.api.verify_experience.success'), { variant: 'success' });

      onClose();
    } catch (e) {
      console.error(e);

      enqueueSnackbar(generateErrorMessage(e), { variant: 'error' });
    }
  });

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onCloseModal}>
      <DialogTitle>{t(`profile.sections.work_history.request_verification.title`)}</DialogTitle>
      <DialogContent>
        <FormProvider methods={searchMethods} onSubmit={submitForm}>
          <Grid container spacing={2} justifyContent="center" pt={1}>
            <Grid item xs={12}>
              <Alert variant="outlined" severity="info">
                {t(`profile.sections.work_history.request_verification.alert`)}
              </Alert>
            </Grid>

            <Grid item xs={12}>
              <RHFTextField
                id="outlined-multiline-static"
                name="email"
                label={t('common.form_labels.email')}
                placeholder={t('common.placeholders.email')}
              />
            </Grid>

            <Grid item xs={6}>
              <RHFTextField
                id="outlined-multiline-static"
                name="first_name"
                label={t('common.form_labels.first_name')}
                placeholder={t('common.placeholders.first_name')}
              />
            </Grid>

            <Grid item xs={6}>
              <RHFTextField
                id="outlined-multiline-static"
                name="last_name"
                label={t('common.form_labels.last_name')}
                placeholder={t('common.placeholders.last_name')}
              />
            </Grid>

            <Stack
              sx={{ py: 2, width: '100%' }}
              flexDirection="row"
              justifyContent="flex-end"
              spacing={2}
            >
              <Button variant="text" onClick={onCloseModal}>
                {t('common.cancel')}
              </Button>
              <Button type="submit" variant="contained" disabled={!isValid}>
                {t('common.request')}
              </Button>
            </Stack>
          </Grid>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
