import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Typography, CircularProgress } from '@mui/material';

import { useTranslate } from 'src/locales';

export default function ExtensionAuthView() {
  const { t } = useTranslate();

  const renderLogo = (
    <Box
      component="img"
      alt="auth"
      src="/assets/logo/logo-icon.svg"
      sx={{
        maxWidth: {
          xs: 80,
          lg: 120,
        },
      }}
    />
  );

  return (
    <Stack direction="column" alignItems="center" spacing={2} minWidth={230}>
      {renderLogo}

      <Box p={4}>
        <CircularProgress color="info" />
      </Box>

      <Box>
        <Typography>{t('auth.extension.signing_in')}</Typography>
      </Box>
    </Stack>
  );
}
