import { t } from 'i18next';

import { paths } from 'src/routes/paths';

const AdminSteps = [
  {
    target: '#organisation-nav-menu-item',
    content: t('joyride.organisation_nav_item.content'),
    data: {
      path: paths.dashboard.root,
      navMenu: true,
    },
    disableBeacon: true,
  },
  {
    target: '#billing-nav-menu-item',
    content: t('joyride.billing_nav_item.content'),
    data: {
      path: paths.dashboard.root,
      navMenu: false,
    },
    disableBeacon: true,
  },
];

export default AdminSteps;
