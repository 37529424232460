
import { enqueueSnackbar } from 'notistack';
import { useState, useEffect } from 'react';

import { Stack } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import {
  Card,
  Grid,
  Select,
  MenuItem,
  useTheme,
  Typography,
  CardContent,
  FormControl,
  useMediaQuery
} from '@mui/material';

import { useTranslate } from 'src/locales';
import { RestrictedOrganizationSettings } from 'src/services/admin/admin.types';
import { PaymentCollectionType } from 'src/services/organisation/organisation.types';
import { useGetOrganizationQuery, useUpdateRestrictedOrganizationSettingsMutation } from 'src/services/admin/admin.service';

import QuantityInput from 'src/components/number-input/quantity-input';

type Props = {
  organizationId: string;
};

export default function SettingsCard({ organizationId }: Props) {
  const { t } = useTranslate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [organizationSettings, setOrganizationSettings] = useState<RestrictedOrganizationSettings>({ payment_collection_type: PaymentCollectionType.AUTOMATIC_COLLECTION, invoice_due_days: 14 });

  const [updateSettings, { isLoading: isSubmitting }] = useUpdateRestrictedOrganizationSettingsMutation();

  const { data: organization, isLoading: isOrgLoading } = useGetOrganizationQuery(
    {
      id: organizationId as string,
    },
    { skip: !organizationId }
  );

  useEffect(() => {
    if (organization) {
      setOrganizationSettings({
        payment_collection_type: organization.payment_collection_type ?? PaymentCollectionType.AUTOMATIC_COLLECTION,
        invoice_due_days: organization.invoice_due_days ?? 14
      });
    }
  }, [organization]);

  const handleUpdateSettings = async () => {
    try {
      await updateSettings({
        id: organizationId,
        data: organizationSettings
      }).unwrap();

      enqueueSnackbar(t('restricted.organizations.detail.settings.api.success'), { variant: 'success' });
    }
    catch (error) {
      enqueueSnackbar(t('restricted.organizations.detail.settings.api.default_error'), { variant: 'error' });
    }
  }

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Stack direction="row" alignItems="center" gap={2}>
                <Typography variant="h6">
                  {t('restricted.organizations.detail.settings.title')}
                </Typography>

              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Stack
              direction="row"
              alignItems="center"
              spacing={3}
              sx={{
                minWidth: 300,
                '& .MuiSelect-select': {
                  minWidth: 200
                }
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  color: 'text.primary',
                  minWidth: 'fit-content'
                }}
              >
                {t('restricted.organizations.detail.settings.invoice_due_days')}
              </Typography>

              <FormControl size="small">

                <QuantityInput
                  min={1}
                  value={organizationSettings.invoice_due_days}
                  onChange={(e, val) => setOrganizationSettings({ ...organizationSettings, invoice_due_days: val as number })}
                />
              </FormControl>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Stack
              direction="row"
              alignItems="center"
              spacing={3}
              sx={{
                minWidth: 300,
                '& .MuiSelect-select': {
                  minWidth: 200
                }
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  color: 'text.primary',
                  minWidth: 'fit-content'
                }}
              >
                {t('restricted.organizations.detail.settings.payment_collection_type')}
              </Typography>

              <FormControl size="small">
                <Select
                  value={organizationSettings.payment_collection_type}
                  onChange={(e) => setOrganizationSettings({ ...organizationSettings, payment_collection_type: e.target.value as PaymentCollectionType })}
                  variant="outlined"
                  sx={{
                    bgcolor: 'background.paper',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'divider'
                    }
                  }}
                >
                  <MenuItem value={PaymentCollectionType.AUTOMATIC_COLLECTION}>
                    {t('enums.payment_collection_type.automatic_collection')}
                  </MenuItem>
                  <MenuItem value={PaymentCollectionType.INVOICED}>
                    {t('enums.payment_collection_type.invoiced')}
                  </MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Stack spacing={2} direction="row" justifyContent="end">
              <LoadingButton
                variant="contained"
                color="success"
                type="submit"
                onClick={handleUpdateSettings}
                loading={isSubmitting}
                disabled={false}
              >
                {t('common.save')}
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}