import { useSnackbar } from 'notistack';
import { useState, useCallback } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import { useBoolean } from 'src/hooks/use-boolean';

import { useTranslate } from 'src/locales';
import candidate_scan from 'src/assets/lottie/candidate.json';
import { useAuthContext } from 'src/auth/hooks/useAuthContext';
import { DocumentType } from 'src/services/documents/documents.types';
import { useImportCandidateProfileMutation } from 'src/services/applications/applications.service';
import {
  useUploadFileMutation,
  useCreateDocumentMutation,
} from 'src/services/documents/documents.service';

import { Upload } from 'src/components/upload';
import { useSettingsContext } from 'src/components/settings';
import UploadItemCard from 'src/components/upload/upload-item-card';
import LottieContainer from 'src/components/lottie/lottie-container';

import { ConfirmDialog } from '../custom-dialog';

type Props = {
  applicationId: string;
  onSuccess: VoidFunction;
  onError?: (error: string) => void;
  showWarning?: boolean;
};

export default function ApplicationProfileResumeUploader({
  applicationId,
  onSuccess,
  onError,
  showWarning = false,
}: Props) {
  const { t } = useTranslate();

  const settings = useSettingsContext();

  const { enqueueSnackbar } = useSnackbar();

  const authContext = useAuthContext();

  const confirm = useBoolean();

  const [importProfile, { isLoading: importingResume }] = useImportCandidateProfileMutation();

  const [uploadFile, { isLoading: uploadingFile }] = useUploadFileMutation();

  const [createDocument, { isLoading: creatingDocument }] = useCreateDocumentMutation();

  const [file, setFile] = useState<File>();

  const [documentId, setDocumentId] = useState<string>();

  const handleDocumentDrop = useCallback(
    async (documents: File[]) => {
      setFile(documents[0]);
    },
    [setFile]
  );

  const handleDocumentProcess = useCallback(async () => {
    confirm.onFalse();

    try {
      const uploadedFile = await uploadFile({
        file: file as File,
        type: 'document' as any,
      }).unwrap();

      const document = await createDocument({
        organization_id: authContext.organization.id,
        document: {
          file_id: uploadedFile.id,
          name: (file as File).name,
          type: DocumentType.RESUME,
        },
      }).unwrap();

      await importProfile({ applicationId, documentId: document.id }).unwrap();

      enqueueSnackbar(t('applications.candidate_resume_importer.api.success'), {
        variant: 'success',
      });

      onSuccess();
    } catch (e) {
      onError?.(t('applications.candidate_resume_importer.api.default_error'));
      enqueueSnackbar(t('applications.candidate_resume_importer.api.default_error'), {
        variant: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    authContext.organization.id,
    createDocument,
    enqueueSnackbar,
    file,
    importProfile,
    onSuccess,
    uploadFile,
  ]);

  return (
    <Stack gap={2} width="100%">
      <Box width="100%" height={320} sx={{ mb: 2 }}>
        {!importingResume ? (
          <Upload accept={{ '.pdf': [] }} onDrop={handleDocumentDrop} sx={{ mt: 1 }} />
        ) : (
          <Box flex="flex" justifyContent="center" textAlign="center" alignItems="flex-end">
            <LottieContainer height={320} width={410} json={candidate_scan} loop />
            <Typography
              fontSize={13}
              className={settings.themeMode === 'light' ? 'text-gray-600' : 'text-gray-100'}
            >
              {t('applications.candidate_resume_importer.modal.loading')}
            </Typography>
          </Box>
        )}
      </Box>

      {file && (
        <UploadItemCard
          title={(file as any).path}
          onDeleteFile={() => setFile(undefined)}
          uploading={uploadingFile}
        />
      )}

      <LoadingButton
        disabled={!file}
        variant="contained"
        fullWidth
        loading={creatingDocument || uploadingFile || importingResume}
        onClick={showWarning ? confirm.onTrue : handleDocumentProcess}
      >
        {t('common.upload')}
      </LoadingButton>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title={t('profile.importer.confirm.title')}
        content={t('applications.candidate_resume_importer.modal.confirm')}
        action={
          <Button variant="contained" color="error" onClick={handleDocumentProcess}>
            {t('common.confirm')}
          </Button>
        }
      />
    </Stack>
  );
}
