import { rootApi } from '../rootApi';
import { APIResponse, HTTPRequestType } from '../api.types';
import { GetThreadProps, PostCommentProps, GetThreadResponse } from './threads.types';

const threadsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getThread: builder.query<GetThreadResponse, GetThreadProps>({
      query: (data) => ({
        url: `/threads/${data.thread_id}/comments?page=${data.page}&per_page=${data.per_page}`,
      }),
      providesTags: (_result, _error, arg) => [{ type: 'Comments', id: arg.thread_id }]
    }),

    postComment: builder.mutation<APIResponse, PostCommentProps>({
      query: (data) => ({
        url: `/threads/${data.thread_id}/comments`,
        method: HTTPRequestType.POST,
        data: {
          content: data.comment,
        },
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'Comments', id: arg.thread_id }]
    }),
  }),
});

export const { useLazyGetThreadQuery, usePostCommentMutation } = threadsApi;
