import _ from 'lodash';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useMemo, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

import Card from '@mui/material/Card';
import { LoadingButton } from '@mui/lab';
import CardHeader from '@mui/material/CardHeader';
import { Paper, useTheme, Skeleton, CardActions, CardContent } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { useTranslate } from 'src/locales';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { VisibilityRule } from 'src/services/jobs/jobs.types';
import { useSetCandidateProfileMutation } from 'src/services/candidates/candidates.service';
import { generateCandidateProfileErrorMessage } from 'src/services/candidates/candidates.utils';
import {
  CandidateProfile,
  CandidateProfileSectionType,
} from 'src/services/candidates/candidates.types';

import Markdown from 'src/components/markdown';
import RHFEditor from 'src/components/hook-form/rhf-editor';
import FormProvider from 'src/components/hook-form/form-provider';

import { TenantType } from 'src/types/enums';

type Props = {
  profile?: CandidateProfile;
  isLoading: boolean;
  mode?: 'edit' | 'view';
};

export default function ProfileBio({ profile, isLoading, mode = 'edit' }: Props) {
  const toggleEditor = useBoolean();

  const { t } = useTranslate();

  const theme = useTheme();

  const tenant = useOrgTenant();

  const { enqueueSnackbar } = useSnackbar();

  const [updateProfile] = useSetCandidateProfileMutation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const showEditor = useMemo(
    () => (toggleEditor.value || (!profile?.summary?.content && !isLoading)) && mode === 'edit',
    [isLoading, profile?.summary?.content, toggleEditor.value, mode]
  );

  const defaultValues = {
    summary: profile?.summary?.content ?? '',
    visibility: true,
  };

  const EditBioSchema = Yup.object().shape({
    summary: Yup.string().required(t('validation.required')),

    visibility: Yup.boolean(),
  });

  const summaryFormMethods = useForm({
    resolver: yupResolver(EditBioSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid, isSubmitting, isDirty },
  } = summaryFormMethods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await updateProfile({
        id: profile?.id as string,
        data: {
          summary: {
            content: data.summary,
          },
          sections: [
            {
              type: CandidateProfileSectionType.EXECUTIVE_SUMMARY,
              visibility_rule: VisibilityRule.PUBLIC,
            },
          ],
        },
      }).unwrap();

      reset();
    } catch (e) {
      console.log(e);

      enqueueSnackbar(generateCandidateProfileErrorMessage(e), { variant: 'error' });
    }
  });

  useEffect(() => {
    if (!isLoading) {
      reset(
        {
          summary: profile?.summary?.content ?? '',
        },
        { keepDirty: false }
      );
    }
  }, [isLoading, profile?.summary?.content, reset]);

  const renderForm = () => (
    <>
      {isLoading ? (
        <Skeleton variant="rectangular" height={100} />
      ) : (
        <FormProvider methods={summaryFormMethods} onSubmit={onSubmit}>
          {showEditor ? (
            <RHFEditor name="summary" id="summary_editor" />
          ) : (
            <Paper
              variant="outlined"
              id="summary_display"
              sx={{ p: 2, mb: 2, backgroundColor: theme.palette.background.neutral }}
              onClick={toggleEditor.onTrue}
            >
              <Markdown
                children={
                  !_.isEmpty(profile?.summary?.content)
                    ? profile?.summary?.content
                    : 'No summary added'
                }
              />
            </Paper>
          )}

          {mode === 'edit' && (
            <CardActions sx={{ px: 0, pt: 2, justifyContent: 'flex-end' }}>
              {/* <RHFVisibilityToggle
          name="visibility"
          labelPosition="left"
          disabled
          checked
          onLabel={t('common.visible')}
          offLabel={t('common.hidden')}
          tooltip={t('profile.form.disabled_tooltip')}
        /> */}
              <LoadingButton
                variant="contained"
                color="success"
                type="submit"
                loading={isSubmitting}
                disabled={!isValid || !showEditor || !isDirty}
              >
                {t('common.save')}
              </LoadingButton>
            </CardActions>
          )}
        </FormProvider>
      )}
    </>
  );

  return (
    <Card>
      <CardHeader
        title={isLoading ? <Skeleton width={200} /> : t('profile.sections.summary.title')}
        subheader={
          isLoading ? (
            <Skeleton width={300} />
          ) : (
            tenant === TenantType.Candidate && t('profile.sections.summary.placeholder')
          )
        }
      />
      <CardContent>{renderForm()}</CardContent>
    </Card>
  );
}
