import { rootApi } from '../rootApi';
import { APIResponse, PageOptions, HTTPRequestType, PaginatedResponse } from '../api.types';
import {
  Notification,
  MarkAsReadPayload,
  NotificationStatus,
  NotificationSchema,
} from './notifications.types';

export const notificationsBasePath = 'notifications';

export const notificationsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query<
      PaginatedResponse<Notification>,
      PageOptions & { status?: NotificationStatus }
    >({
      query: (data) => ({
        url: `/notifications?page=${data.page}&per_page=${data.per_page}&channel=in_app&status=${
          data.status ?? NotificationStatus.DELIVERED
        }`,
      }),
      providesTags: ['Notifications'],
    }),

    getNotificationsSummary: builder.query<void, APIResponse>({
      query: () => ({
        url: '/notifications/summary?channel=in_app',
      }),
    }),

    markAllAsRead: builder.mutation<APIResponse, MarkAsReadPayload>({
      query: (data) => ({
        url: '/notifications/mark-as-read',
        method: HTTPRequestType.POST,
        data,
      }),
      invalidatesTags: ['Notifications'],
    }),

    getNotificationsSchema: builder.query<NotificationSchema[], void>({
      query: () => ({
        url: '/notifications/schema',
      }),
    }),
  }),
});

export const {
  useGetNotificationsQuery,
  useGetNotificationsSummaryQuery,
  useMarkAllAsReadMutation,
  useGetNotificationsSchemaQuery,
} = notificationsApi;
