import { Helmet } from 'react-helmet-async';
import { Outlet, useLocation } from 'react-router';

import { Stack } from '@mui/material';
import Container from '@mui/material/Container';

import { paths } from 'src/routes/paths';
import { useParams } from 'src/routes/hooks';

import { useTranslate } from 'src/locales';

import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';

// ----------------------------------------------------------------------

export default function ContactsPage() {
  const settings = useSettingsContext();

  const { t } = useTranslate();

  const { connection_id } = useParams();

  const { pathname } = useLocation();

  return (
    <>
      <Helmet>
        <title>Dashboard: Contacts</title>
      </Helmet>

      <Container
        maxWidth={settings.themeStretch ? false : 'lg'}
        sx={{
          height: 1,
        }}
      >
        <Stack spacing={2}>
          <CustomBreadcrumbs
            heading={t(`contacts.title`)}
            links={[
              { name: t('common.dashboard'), href: paths.dashboard.root },
              {
                name: t(`contacts.title`),
                href: `${paths.dashboard.contacts.root}`
              }
            ]}
          />

          <Outlet />
        </Stack>
      </Container>
    </>
  );
}
