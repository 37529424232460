import React from 'react';
import { SnackbarContent, CustomContentProps } from 'notistack';

import { CircularProgress } from '@mui/material';

import { useTranslate } from 'src/locales';

const LoadingSnackbar = React.forwardRef<HTMLDivElement, CustomContentProps>((props, ref) => {
    const {
        id,
        message,
        ...other
    } = props;

    const { t } = useTranslate();

    return (
        <SnackbarContent ref={ref} role="alert" {...other}>
            <div className="flex items-center p-2 bg-white rounded-md shadow-md w-full">
                <div className="mx-3">
                    <CircularProgress size={20} color="primary" />
                </div>
                <div className="flex flex-col">
                    <span className="font-medium text-gray-900">
                        {t('common.hang_on')}
                    </span>
                    <span className="text-sm text-gray-600">{message}</span>
                </div>
            </div>
        </SnackbarContent>
    );
});

export default LoadingSnackbar;