import * as Yup from 'yup';
import moment from 'moment';
import { isDate } from 'lodash';
import { Trans } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo, useState, useEffect, useCallback } from 'react';

import { Stack } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Unstable_Grid2';
import { MobileDatePicker } from '@mui/x-date-pickers';
import {
  Alert,
  Button,
  Dialog,
  MenuItem,
  Skeleton,
  TextField,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
  CircularProgress,
} from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { useTranslate } from 'src/locales';
import { rootApi } from 'src/services/rootApi';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { PeriodType, EmploymentType } from 'src/services/jobs/jobs.types';
import { ComplianceRequirementType } from 'src/services/shared/shared.types';
import { useGetTypeDefinitionsQuery } from 'src/services/system/system.service';
import { hideOfferModal } from 'src/store/slices/applications/applicationsSlice';
import { getCreateOfferErrorMessage } from 'src/services/applications/applications.utils';
import { DocumentType, OfferDocumentTypes } from 'src/services/documents/documents.types';
import {
  Application,
  OfferStatus,
  CreateOfferDto,
} from 'src/services/applications/applications.types';
import {
  useGetOfferByIdQuery,
  useCreateOfferMutation,
  useUpdateOfferMutation,
  useApproveOfferMutation,
  useGetApplicationByIdQuery,
  useRequestOfferChangesMutation,
  useGetApplicationCandidateProfileQuery,
} from 'src/services/applications/applications.service';

import { ConfirmDialog } from 'src/components/custom-dialog';
import FormProvider from 'src/components/hook-form/form-provider';
import {
  RHFSelect,
  RHFSwitch,
  RHFCheckbox,
  RHFTextField,
  RHFMultiSelect,
} from 'src/components/hook-form';

import { TenantType } from 'src/types/enums';

type Props = {
  open: boolean;
};

export default function CreateOfferModal({ open }: Props) {
  const dispatch = useAppDispatch();

  const { t } = useTranslate();

  const tenant = useOrgTenant();

  const showConfirm = useBoolean();

  const { enqueueSnackbar } = useSnackbar();

  const { currentData: typeDefinitions } = useGetTypeDefinitionsQuery();

  const applicationId = useAppSelector((state) => state.applications.selectedApplicationId);

  const offerId = useAppSelector((state) => state.applications.selectedOfferId);

  const [notes, setNotes] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string | null>('');

  const { currentData: application, isLoading: isLoadingApplication } = useGetApplicationByIdQuery(
    applicationId as string,
    { skip: !applicationId }
  );

  const { currentData: offerData, isLoading: isLoadingOffer } = useGetOfferByIdQuery(
    { offerId: offerId as string },
    { skip: !offerId }
  );

  const [createOffer, { isLoading: isCreatingOffer }] = useCreateOfferMutation();

  const [editOffer, { isLoading: isEditingOffer }] = useUpdateOfferMutation();

  const [approveOffer, { isLoading: isApprovingOffer }] = useApproveOfferMutation();

  const [submitData, setSubmitData] = useState<CreateOfferDto | null>(null);

  const [requestChanges, { isLoading: isRequestingChanges }] = useRequestOfferChangesMutation();

  const { currentData: candidateProfile, isLoading: isLoadingCandidateProfile } =
    useGetApplicationCandidateProfileQuery(applicationId as string, { skip: !applicationId });

  const OfferValidationSchema = Yup.object().shape({
    position_title: Yup.string()
      .max(60, t('validation.max_length', { length: 60 }))
      .required(t('validation.required')),
    employer_name: Yup.string().required(t('validation.required')),
    start_date: Yup.date().required(t('validation.required')),
    end_date: Yup.date()
      .nullable()
      .when(['start_date', 'employment_type'], ([start_date, employment_type], schema) =>
        employment_type !== EmploymentType.PERMANENT && isDate(start_date)
          ? schema.min(start_date, t('validation.min_end_date'))
          : schema
      )
      .when('employment_type', ([employment_type], schema) =>
        employment_type !== EmploymentType.PERMANENT
          ? schema.required(t('validation.required'))
          : schema
      ),
    employment_type: Yup.string().required(t('validation.required')),
    entitlements_inclusive: Yup.boolean(),
    rate_period: Yup.string().required(t('validation.required')),
    pay_rate: Yup.number().required(t('validation.required')),
    other_document_type: Yup.string()
      .max(30, t('validation.max_length', { length: 30 }))
      .when(['required_documents'], ([required_documents], schema) =>
        Array.isArray(required_documents) && required_documents.includes(DocumentType.OTHER)
          ? schema.required(t('validation.required'))
          : schema
      ),
    notes: Yup.string(),
    required_documents: Yup.array().of(Yup.string()),
    submit_behaviour: Yup.boolean(),
  });

  const defaultValues = {
    position_title: '',
    employer_name: '',
    start_date: moment().toDate(),
    end_date: null,
    employment_type: '',
    entitlements_inclusive: false,
    rate_period: PeriodType.YEAR,
    pay_rate: 0,
    notes: '',
    other_document_type: '',
    required_documents: [],
    submit_behaviour: false,
  };

  const form = useForm({
    resolver: yupResolver(OfferValidationSchema),
    defaultValues,
  });

  const { handleSubmit, setValue, reset, watch } = form;

  const submit_behaviour = watch('submit_behaviour');
  const required_documents = watch('required_documents');

  const onClose = useCallback(() => {
    reset();
    dispatch(rootApi.util.invalidateTags(['Applications']));
    dispatch(hideOfferModal());
  }, [dispatch, reset]);

  const onSubmit = (data: any) => {
    setErrorMessage(null);

    const requiredDocuments = Array.isArray(data.required_documents)
      ? data.required_documents.map((item: any) => ({
          item_type: item,
          custom_type_name: item === DocumentType.OTHER ? data.other_document_type : undefined,
        }))
      : [];

    const offer: CreateOfferDto = {
      position_title: data.position_title,
      employer_name: data.employer_name,
      start_date: moment(data.start_date).format('YYYY-MM-DD'),
      end_date: data.end_date ? moment(data.end_date).format('YYYY-MM-DD') : null,
      employment_type: data.employment_type as EmploymentType,
      entitlements_inclusive: data.entitlements_inclusive ?? false,
      rate_period: data.rate_period as PeriodType,
      pay_rate: data.pay_rate.toString(),
      notes: data.notes,
      required_documents: requiredDocuments,
      other_document_type: data.other_document_type,
      submit_behaviour: data.submit_behaviour ? 'save_as_draft' : 'submit',
    };

    setSubmitData(offer);

    showConfirm.onTrue();
  };

  const onCreateOffer = useCallback(async () => {
    if (!submitData) return;

    showConfirm.onFalse();

    try {
      await createOffer({
        applicationId: applicationId as string,
        offer: submitData,
      }).unwrap();

      onClose();

      enqueueSnackbar(t('applications.api.create_offer.success'), { variant: 'success' });
    } catch (error) {
      console.error(error);

      setErrorMessage(getCreateOfferErrorMessage(error));

      enqueueSnackbar(getCreateOfferErrorMessage(error), { variant: 'error' });
    }
  }, [applicationId, createOffer, enqueueSnackbar, onClose, showConfirm, submitData, t]);

  const onEditOffer = useCallback(async () => {
    if (!submitData || !offerId) return;

    showConfirm.onFalse();

    try {
      await editOffer({
        offerId: offerId as string,
        offerData: {
          ...submitData,
        },
      }).unwrap();

      onClose();
    } catch (error) {
      console.error(error);
    }
  }, [editOffer, offerId, onClose, showConfirm, submitData]);

  const onApproveOffer = useCallback(async () => {
    if (!offerId) return;

    showConfirm.onFalse();

    try {
      await approveOffer({ offerId: offerId as string }).unwrap();

      onClose();
    } catch (error) {
      console.error(error);
    }
  }, [approveOffer, offerId, onClose, showConfirm]);

  const onRequestChanges = useCallback(async () => {
    if (!offerId) return;

    showConfirm.onFalse();

    try {
      await requestChanges({ offerId: offerId as string, notes }).unwrap();

      onClose();
    } catch (error) {
      console.error(error);
    }
  }, [notes, offerId, onClose, requestChanges, showConfirm]);

  const getConfirmDialogContent = () => {
    if (!application) return 'test';

    if (submit_behaviour) {
      return t('applications.create_offer_modal.form.confirm.draft');
    }

    if (tenant === TenantType.Recruiter) {
      return t('applications.create_offer_modal.form.confirm.recruiter');
    }

    if ((application as Application).manager_type === TenantType.Recruiter) {
      return t('applications.create_offer_modal.form.confirm.client.managed');
    }

    return t('applications.create_offer_modal.form.confirm.client.direct');
  };

  const getConfirmDialogActionTitle = () => {
    if (submit_behaviour) {
      return t('applications.create_offer_modal.form.save');
    }

    if (offerData?.status === OfferStatus.CHANGES_REQUESTED) {
      return t(`applications.create_offer_modal.form.submit_changes`);
    }

    return t('applications.create_offer_modal.form.submit');
  };

  const getAlertMessage = () => {
    if (offerData) {
      if (offerData?.status === OfferStatus.IN_REVIEW) {
        return t(`applications.create_offer_modal.in_review_${tenant}`);
      }

      if (offerData?.status === OfferStatus.CHANGES_REQUESTED) {
        return t(`applications.create_offer_modal.changes_requested_${tenant}`, {
          notes: notes ?? '',
        });
      }

      if ([OfferStatus.ACCEPTED, OfferStatus.DECLINED].includes(offerData?.status as OfferStatus)) {
        return t(`applications.create_offer_modal.finalized`);
      }

      if (offerData?.status === OfferStatus.RESCINDED) {
        return t(`applications.create_offer_modal.rescinded_${tenant}`);
      }
    }

    return t('applications.create_offer_modal.not_editable');
  };

  const employmentType = watch('employment_type');

  useEffect(() => {
    if (employmentType === EmploymentType.PERMANENT) {
      setValue('end_date', null, { shouldValidate: true });
    }
  }, [employmentType, setValue]);

  const isLoading = useMemo(
    () => isLoadingApplication || isLoadingOffer,
    [isLoadingApplication, isLoadingOffer]
  );

  const hasBeenSent = useMemo(
    () =>
      offerData?.status &&
      [OfferStatus.ACCEPTED, OfferStatus.DECLINED, OfferStatus.SENT].includes(offerData.status),
    [offerData?.status]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isDisabled = useMemo(() => {
    if (!offerId) return false;

    if (!offerData) return true;

    if (hasBeenSent) return true;

    const managerType = application?.manager_type;

    if (offerData.status === OfferStatus.DRAFT) {
      return tenant !== managerType;
    }

    if (offerData.status === OfferStatus.CHANGES_REQUESTED) {
      return tenant === TenantType.Recruiter;
    }

    if (offerData.status === OfferStatus.IN_REVIEW) {
      return true;
    }

    if (offerData.status === OfferStatus.RESCINDED) {
      return true;
    }

    return false;
  }, [application?.manager_type, hasBeenSent, offerData, offerId, tenant]);

  useEffect(() => {
    if (!application || isLoadingApplication) return;

    if (!offerData) {
      reset({
        position_title: application.job.title,
        employment_type: application.job.employment_type,
        start_date: moment().toDate(),
        employer_name: application.client?.company_profile?.name ?? '',
        rate_period: PeriodType.YEAR,
      });
    } else {
      setValue('position_title', offerData.position_title);
      setValue('employer_name', offerData.employer_name);
      setValue('start_date', moment(offerData.start_date).toDate());
      setValue(
        'end_date',
        offerData.end_date ? (moment(offerData.end_date).toDate() as Date) : null
      );
      setValue('employment_type', offerData.employment_type);
      setValue('entitlements_inclusive', offerData.entitlements_inclusive);
      setValue('rate_period', offerData.rate_period);
      setValue('pay_rate', offerData.pay_rate ? +offerData.pay_rate : 0);
      setValue('submit_behaviour', offerData.status === OfferStatus.DRAFT);

      const requiredDocs =
        offerData.compliance_requirements
          ?.filter((item) => item.requirement_type === ComplianceRequirementType.DOCUMENT)
          .map((doc) => doc.item_type) ?? [];
      if (Array.isArray(requiredDocs)) {
        setValue('required_documents', requiredDocs);
      }

      const otherDocumentType = offerData.compliance_requirements?.find(
        (item) =>
          item.requirement_type === ComplianceRequirementType.DOCUMENT &&
          item.item_type === DocumentType.OTHER
      );
      if (otherDocumentType) {
        setValue('other_document_type', otherDocumentType.custom_type_name);
      }

      setNotes(offerData.notes ?? '');
    }
  }, [offerData, application, isLoadingApplication, reset, setValue]);

  useEffect(() => {
    if (!applicationId) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationId]);

  const onConfirmAction = useCallback(() => {
    if (offerId) {
      if (offerData?.status === OfferStatus.IN_REVIEW && tenant === TenantType.Recruiter) {
        onApproveOffer();
      }

      if (offerData?.status === OfferStatus.CHANGES_REQUESTED && tenant === TenantType.Client) {
        onEditOffer();
      }
    } else {
      onCreateOffer();
    }
  }, [offerData?.status, offerId, onApproveOffer, onCreateOffer, onEditOffer, tenant]);

  const documentTypeOptions = useMemo(() => {
    if (!typeDefinitions) return [];

    return OfferDocumentTypes.map((type) => ({
      value: type,
      label: t(`enums.document_type.${type}`),
    }));
  }, [t, typeDefinitions]);

  const renderForm = () => {
    const start_date = watch('start_date');
    const end_date = watch('end_date');

    return (
      <FormProvider methods={form}>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Typography variant="h6">
              {t('applications.create_offer_modal.application_details')}
            </Typography>
            {!!offerId && offerData?.status !== OfferStatus.DRAFT && (
              <Alert severity="error" sx={{ mt: 2 }}>
                <Trans>{getAlertMessage()}</Trans>
              </Alert>
            )}
          </Grid>
          {tenant !== TenantType.Candidate && (
            <Grid xs={12}>
              {isLoadingCandidateProfile ? (
                <Skeleton width={200} height={40} />
              ) : (
                <>
                  <Typography variant="h6">
                    {candidateProfile?.first_name} {candidateProfile?.last_name}
                  </Typography>
                  {candidateProfile?.position_title && (
                    <Typography variant="subtitle2">{candidateProfile?.position_title}</Typography>
                  )}
                </>
              )}
            </Grid>
          )}
          <Grid xs={12}>
            <RHFTextField
              name="position_title"
              label={t('applications.create_offer_modal.form.position_title.label')}
              placeholder={t('applications.create_offer_modal.form.position_title.placeholder')}
              disabled={isDisabled || isLoading}
            />
          </Grid>
          <Grid xs={12}>
            <RHFTextField
              name="employer_name"
              label={t('applications.create_offer_modal.form.employer_name.label')}
              placeholder={t('applications.create_offer_modal.form.employer_name.placeholder')}
              disabled={isDisabled || isLoading}
            />
          </Grid>
          <Grid xs={12}>
            <RHFSelect
              fullWidth
              name="employment_type"
              id="employment_type_input"
              label={t('jobs.create.form.employment_type.label')}
              placeholder={t('jobs.create.form.employment_type.placeholder')}
              disabled={isDisabled || isLoading}
              InputLabelProps={{ shrink: true }}
            >
              <MenuItem key="no-value" value="">
                {t('common.none')}
              </MenuItem>
              {typeDefinitions &&
                typeDefinitions.EmploymentType.map((employment_type: string) => (
                  <MenuItem key={employment_type} value={employment_type}>
                    {t(`enums.employment_type.${employment_type}`)}
                  </MenuItem>
                ))}
            </RHFSelect>
          </Grid>
          <Grid xs={12}>
            <RHFMultiSelect
              fullWidth
              checkbox
              name="required_documents"
              label={t('applications.create_offer_modal.form.required_documents.label')}
              options={documentTypeOptions}
              disabled={isDisabled || isLoading}
            />
          </Grid>

          {Array.isArray(required_documents) && required_documents.includes(DocumentType.OTHER) && (
            <Grid xs={12}>
              <RHFTextField
                name="other_document_type"
                id="other_document_type_input"
                placeholder={t(
                  'applications.create_offer_modal.form.other_document_type.placeholder'
                )}
                label={t('applications.create_offer_modal.form.other_document_type.label')}
                disabled={isDisabled || isLoading}
              />
            </Grid>
          )}

          <Grid xs={6}>
            <MobileDatePicker
              orientation="portrait"
              label={t('common.form_labels.start_date')}
              value={start_date}
              onChange={(newValue) => {
                if (newValue) setValue('start_date', newValue);
              }}
              slotProps={{
                textField: {
                  fullWidth: true,
                },
              }}
              minDate={new Date()}
              disabled={isDisabled || isLoading}
            />
          </Grid>
          <Grid xs={6}>
            <MobileDatePicker
              orientation="portrait"
              label={t('common.form_labels.end_date')}
              value={end_date}
              onChange={(newValue) => {
                if (newValue) setValue('end_date', newValue as Date);
              }}
              slotProps={{
                textField: {
                  fullWidth: true,
                },
              }}
              minDate={new Date()}
              disabled={employmentType === EmploymentType.PERMANENT || isDisabled || isLoading}
            />
          </Grid>

          <Grid xs={6} sm={6}>
            <RHFSelect
              fullWidth
              name="rate_period"
              id="rate_period_input"
              label={t('jobs.create.form.rate_period.label')}
              disabled={isDisabled || isLoading}
              InputLabelProps={{ shrink: true }}
            >
              <MenuItem key="no-value" value="">
                {t('common.none')}
              </MenuItem>
              {typeDefinitions &&
                typeDefinitions.PeriodType.map((period: string) => (
                  <MenuItem key={period} value={period}>
                    {t(`enums.rate_period_per.${period}`)}
                  </MenuItem>
                ))}
            </RHFSelect>
          </Grid>

          <Grid xs={6}>
            <RHFTextField
              InputLabelProps={{ shrink: true }}
              type="number"
              name="pay_rate"
              id="pay_rate"
              placeholder={t('applications.create_offer_modal.form.pay_rate.placeholder')}
              label={t('applications.create_offer_modal.form.pay_rate.label')}
              disabled={isDisabled || isLoading}
            />
          </Grid>

          <Grid xs={6} paddingTop={0} paddingLeft={4}>
            <RHFCheckbox
              name="entitlements_inclusive"
              label={t('applications.create_offer_modal.form.entitlements_inclusive.label')}
              disabled={isDisabled || isLoading}
            />
          </Grid>

          <Grid xs={4} xsOffset={2} paddingTop={0} justifyContent="flex-end">
            {(!offerData || [OfferStatus.DRAFT].includes(offerData.status)) && (
              <RHFSwitch
                label={t('jobs.create.form.submit_behaviour.label')}
                name="submit_behaviour"
                labelPlacement="start"
                disabled={isDisabled || isLoading}
                sx={{ justifyContent: 'flex-end' }}
              />
            )}
          </Grid>

          {offerId &&
            offerData &&
            tenant === TenantType.Recruiter &&
            [OfferStatus.IN_REVIEW].includes(offerData.status) && (
              <Grid xs={12}>
                <TextField
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  multiline
                  rows={4}
                  name="notes"
                  id="notes_input"
                  placeholder={t('applications.create_offer_modal.form.notes.placeholder')}
                  label={t('applications.create_offer_modal.form.notes.label')}
                  disabled={
                    isLoadingApplication ||
                    tenant !== TenantType.Recruiter ||
                    offerData?.status === OfferStatus.CHANGES_REQUESTED
                  }
                />
              </Grid>
            )}
        </Grid>
      </FormProvider>
    );
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {t(`applications.create_offer_modal.title_${offerId ? 'edit' : 'create'}`)}
      </DialogTitle>

      <DialogContent>
        {errorMessage && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {errorMessage}
          </Alert>
        )}

        {isLoadingApplication || isLoadingOffer ? (
          <Stack
            width="100%"
            minHeight={400}
            minWidth={400}
            justifyContent="center"
            alignItems="center"
            paddingTop={4}
          >
            <CircularProgress />
          </Stack>
        ) : (
          renderForm()
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{t('common.close')}</Button>

        {(!offerData || offerData?.status !== OfferStatus.RESCINDED) && (
          <>
            {isDisabled && tenant === TenantType.Recruiter ? (
              <>
                <LoadingButton
                  loading={isRequestingChanges || isApprovingOffer}
                  variant="contained"
                  onClick={onRequestChanges}
                  disabled={!notes.length || offerData?.status !== OfferStatus.IN_REVIEW}
                >
                  {t('applications.create_offer_modal.form.request_changes')}
                </LoadingButton>
                <LoadingButton
                  loading={isRequestingChanges || isApprovingOffer}
                  variant="contained"
                  color="success"
                  onClick={showConfirm.onTrue}
                  disabled={isLoading || offerData?.status !== OfferStatus.IN_REVIEW}
                >
                  {t('applications.create_offer_modal.form.approve')}
                </LoadingButton>
              </>
            ) : (
              <LoadingButton
                loading={isCreatingOffer || isEditingOffer}
                variant="contained"
                color="success"
                disabled={isDisabled || isLoading}
                onClick={() => handleSubmit(onSubmit)()}
              >
                {getConfirmDialogActionTitle()}
              </LoadingButton>
            )}
          </>
        )}
      </DialogActions>

      <ConfirmDialog
        open={showConfirm.value}
        onClose={showConfirm.onFalse}
        title={t('common.confirm')}
        content={getConfirmDialogContent()}
        action={<Button onClick={onConfirmAction}>{t('common.confirm')}</Button>}
      />
    </Dialog>
  );
}
